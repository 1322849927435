import React from 'react';
import Header from "components/Headers/Header.js";
import SearchForm from '../Search/SearchForm';
import { CurrentUser } from '../utils/Utilities';



const Index = (props) => {
    const currentUser = CurrentUser();

    return (<>
        <Header subText="Consultar agendamentos concluídos" />
        <SearchForm currentUser={currentUser} />
    </>);
}

export default Index;

