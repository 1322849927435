import React from 'react';
import { Row, Col, Container, CardHeader, Card, CardBody, Button, CardTitle, CardText } from "reactstrap";
import axios from 'axios';
import { notification, GetHeader, ApiError, Role, Loading } from '../utils/Utilities';

class SelectDoc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            documentation: null,
            loading: false,
            selectdDoc: null
        };
    }
    componentDidMount() { this.getDocumentation(); }
    handleValue = (name, value) => { this.setState({ [name]: value }); }



    getDocumentation = () => {
        this.handleValue("loading", true)
        var headers = GetHeader();
        axios.get(global.api_url + "Documentation", { headers })
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data;
                    this.setState({ documentation: data });
                }
                this.handleValue("loading", false)
            }).catch(error => {
                this.handleValue("loading", false)
                ApiError(error, "Documentação").then(call => call === true ? this.getDocumentation() : "");
            });
    }
    onSelect(selected) {
        this.props.onChange(selected);
    }


    removeDoc = (doc) => {
        this.handleValue("loading", true)
        var headers = GetHeader();
        axios.delete(global.api_url + "Documentation/" + doc.id, { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.getDocumentation();
                }
                this.handleValue("loading", false)
            }).catch(error => {
                this.handleValue("loading", false)
                ApiError(error, "Documentação").then(call => call === true ? this.removeDoc(doc) : "");
            });

    }

    selectedDoc() {
        if (this.state.selectdDoc !== null) {
            this.props.onChange(this.state.selectdDoc);
        } else { notification("Documetação", "Precisa de selecionar um documento para visualizar!", "warning", null, "top-right", null, false); }
    }

    render() {
        const { documentation, currentUser, loading } = this.state;
        return (
            <>
                <Container className="mt--7" fluid>

                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col>
                                            <h3 className="mb-0">Documentos</h3>
                                        </Col>
                                        <Col className="text-right" xl="4">
                                            {currentUser.role === Role.Admin && <Button color="success" size="sm" onClick={(e) => { this.props.onClick(true); }}><i className="fas fa-plus-circle"></i>{' '} Adicionar documento</Button>}

                                        </Col>

                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="pl-lg-4">
                                        {Loading(loading)}
                                        <Row>
                                            {documentation?.length > 0 && documentation.map(doc => {
                                                return (
                                                    doc.workShopId === 0 || currentUser.role === Role.Admin || (doc.workShopId !== 0 && doc.workShopId === +currentUser.defaultWorkshop) ?
                                                        <Col key={doc.id} sm="4">
                                                            <Card body style={{ height: '100%' }}>
                                                                <CardTitle tag="h2">
                                                                    <Row>
                                                                        <Col xs="8">
                                                                            {doc?.name} {doc?.workShopName !== undefined ? " - " + doc?.workShopName : ""}
                                                                        </Col>
                                                                        {currentUser.role === Role.Admin &&
                                                                            <Col className="text-right">
                                                                                <Button color="warning" size="sm" onClick={(e) => { this.props.onClick(doc); }}><i className="fas fa-edit"></i>{' '} Editar</Button>
                                                                                <Button color="danger" size="sm" onClick={(e) => { this.removeDoc(doc) }}><i className="fas fa-book"></i>{' '} Eliminar</Button>
                                                                            </Col>
                                                                        }
                                                                    </Row>
                                                                </CardTitle>
                                                                <CardText>
                                                                    {doc?.description}
                                                                </CardText>

                                                                <Button color="info" size="sm" onClick={(e) => { this.onSelect(doc) }}><i className="fas fa-book"></i>{' '} Ver Documento</Button>
                                                            </Card>
                                                        </Col>
                                                        : null
                                                )
                                            })}
                                        </Row>

                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </>);
    }
}

export default SelectDoc;

