// reactstrap components
import { Button, Card, CardBody, Form, Col } from "reactstrap";
import React, { useState } from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { removeAllAccounts } from 'store/accounts/accounts.js';
import { ApiError, GetHeader, notification } from "../../backoffice/utils/Utilities";



const Logout = () => {
    //const accounts = useSelector(state => state.accounts);
    const dispatch = useDispatch();
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const toggleSpinner = (state) => { setSpinnerVisible(state); };

    const click_Logout = (event) => {
        event.preventDefault();
        toggleSpinner(true);
        var headers = GetHeader();
        axios.post(global.api_url + "Accounts/revoke-token", { 'token': localStorage.getItem('refreshToken') }, { headers })
            .then(response => {
                global.currentUser = response;
                if (response.status === 200) {
                    dispatch(removeAllAccounts());
                    localStorage.setItem('refreshToken', '');
                    localStorage.setItem('jwtToken', '');
                    localStorage.setItem('path', '');
                    notification("Logout", "Efetuado com sucesso!", "success", null, "top-right", null, false);
                };
            })
            .catch(error => {
                ApiError(error, "Login").then(call => call === true ? click_Logout(event) : "");
            })
            .then(function () {
                toggleSpinner(false);
                return (<Redirect to="/login" />)
            });
    };

    return (
        <>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>Fazer logout da conta.</small>
                        </div>
                        <Form role="form">
                            {spinnerVisible ?
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <div className="text-center">
                                    <Button className="my-4" color="primary" type="button" onClick={(e) => click_Logout(e)}>
                                        Logout
                                    </Button>
                                </div>
                            }
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};


export default Logout;
