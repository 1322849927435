import React from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { AcessPage } from 'views/backoffice/utils/Utilities';
import logo from '../assets/img/RS/Logo_002_sem moldura.png';


const Admin = (props) => {
    const mainContent = React.useRef(null);
    const location = useLocation();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                var grantPage = AcessPage(prop.path);
                if (grantPage !== null) {
                    return (<Route exact={grantPage.slideMenu} path={prop.layout + prop.path} component={prop.component} key={key} />)
                } else { return null; }
            } else { return null; }
        });
    };

    const getBrandText = (path) => {
        for (let i = 0; i < routes.length; i++) {
            if (props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) { return routes[i].name; }
        }
        return "Brand";
    };

    return (
        <>
            <Sidebar
                {...props}
                routes={routes}
                logo={{
                    innerLink: "/admin/Dashboard", imgSrc: logo,
                    imgAlt: "...",
                }}
            />
            <div className="main-content" ref={mainContent}>
                <AdminNavbar {...props} brandText={getBrandText(props.location.pathname)} />
                <Switch>
                    {getRoutes(routes)}
                </Switch>
                <Container fluid>
                    <AdminFooter />
                </Container>
            </div>
        </>
    );
};

export default Admin;
