// reactstrap components
import { Button, Card, CardBody, FormGroup, Form, Input,  InputGroupText, InputGroup, Row, Col, Modal } from "reactstrap";
import React, { useState } from 'react';
import axios from 'axios';




const Register = () => {
    const [form, setForm] = useState({ firstName: '', lastName: '', email: '', password: '', confirmPassword: '', acceptTerms: false });
    const updateField = e => { setForm({ ...form, [e.target.name]: e.target.value }); };

    const [modalVisible, setModalVisible] = useState(false);
    const toggleModal = () => { setModalVisible(!modalVisible); };

    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const toggleSpinner = (state) => { setSpinnerVisible(state); };

    const [result, setResult] = useState([]);
    const changeResult = (data) => { setResult(data) };


    const click_CreateUser = function (event) {
        event.preventDefault();
        toggleSpinner(true);
        axios.post(global.api_url + "Accounts/register", form)
            .then(response => {
                changeResult(response);
                if (response.status === 200) { setForm({ firstName: '', lastName: '', email: '', password: '', confirmPassword: '', acceptTerms: false }) };
            })
            .catch(error => {
                changeResult(error.response);
            })
            .then(function () {
                toggleModal();
                toggleSpinner(false);
            });
    };

    return (<>
        <Col lg="6" md="8">
            <Card className="bg-secondary shadow border-0">
                {/*<CardHeader className="bg-transparent pb-5">*/}
                {/*    <div className="text-muted text-center mt-2 mb-4">*/}
                {/*        <small>Sign up with</small>*/}
                {/*    </div>*/}
                {/*    <div className="text-center">*/}
                {/*        <Button*/}
                {/*            className="btn-neutral btn-icon mr-4"*/}
                {/*            color="default"*/}
                {/*            href="#pablo"*/}
                {/*            onClick={(e) => e.preventDefault()}>*/}
                {/*            <span className="btn-inner--icon">*/}
                {/*                <img alt="..." src={require("../../assets/img/icons/common/github.svg").default} />*/}
                {/*            </span>*/}
                {/*            <span className="btn-inner--text">Github</span>*/}
                {/*        </Button>*/}
                {/*        <Button*/}
                {/*            className="btn-neutral btn-icon"*/}
                {/*            color="default"*/}
                {/*            href="#pablo"*/}
                {/*            onClick={(e) => e.preventDefault()}*/}
                {/*        >*/}
                {/*            <span className="btn-inner--icon">*/}
                {/*                <img alt="..." src={require("../../assets/img/icons/common/google.svg").default} />*/}
                {/*            </span>*/}
                {/*            <span className="btn-inner--text">Google</span>*/}
                {/*        </Button>*/}
                {/*    </div>*/}
                {/*</CardHeader>*/}
                <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                        {/*<small>Or sign up with credentials</small>*/}
                        Registo
                    </div>
                    <Form role="form">
                        <FormGroup>
                            <InputGroup className="input-group-alternative mb-3">
                                <InputGroupText addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-hat-3" />
                                    </InputGroupText>
                                </InputGroupText>
                                <Input value={form.username} placeholder="Nome" name="firstName" onChange={updateField} />
                            </InputGroup>
                        </FormGroup>

                        <FormGroup>
                            <InputGroup className="input-group-alternative mb-3">
                                <InputGroupText addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-hat-3" />
                                    </InputGroupText>
                                </InputGroupText>
                                <Input value={form.lastName} placeholder="Apelido" name="lastName" onChange={updateField} />
                            </InputGroup>
                        </FormGroup>

                        <FormGroup>
                            <InputGroup className="input-group-alternative mb-3">
                                <InputGroupText addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-email-83" />
                                    </InputGroupText>
                                </InputGroupText>
                                <Input placeholder="Email" autoComplete="new-email" name="email" value={form.email} onChange={updateField} />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <InputGroup className="input-group-alternative">
                                <InputGroupText addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-lock-circle-open" />
                                    </InputGroupText>
                                </InputGroupText>
                                <Input placeholder="Password" type="password" autoComplete="new-password" name="password" value={form.password} onChange={updateField} />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <InputGroup className="input-group-alternative">
                                <InputGroupText addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-lock-circle-open" />
                                    </InputGroupText>
                                </InputGroupText>
                                <Input placeholder="Confirmação Password" type="password" autoComplete="new-password" name="confirmPassword" value={form.confirmPassword} onChange={updateField} />
                            </InputGroup>
                        </FormGroup>
                        <div className="text-muted font-italic">
                            <small>
                                password strength:{" "}
                                <span className="text-success font-weight-700">strong</span>
                            </small>
                        </div>
                        <Row className="my-4">
                            <Col xs="12">
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <label className="custom-control-label" htmlFor="customCheckRegister">
                                        <span className="text-muted">
                                            I agree with the{" "}
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                Privacy Policy
                                            </a>
                                        </span>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        {spinnerVisible ?
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            <div className="text-center">
                                <Button className="mt-4" color="primary" type="button" onClick={(e) => click_CreateUser(e)}>
                                    Create account
                                </Button>
                            </div>
                        }
                    </Form>
                </CardBody>
            </Card>

            <Modal
                className="modal-dialog-centered modal-danger"
                contentClassName="bg-gradient-info"
                isOpen={modalVisible}
                toggle={() => toggleModal()}>

                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-notification">
                        Registo!
                    </h6>
                    {/*<button*/}
                    {/*    aria-label="Close"*/}
                    {/*    className="close"*/}
                    {/*    data-dismiss="modal"*/}
                    {/*    type="button"*/}
                    {/*    onClick={() => toggleModal()}>*/}
                    {/*    <span aria-hidden={true}>×</span>*/}
                    {/*</button>*/}
                </div>
                <div className="modal-body">
                    <div className="py-3 text-center">

                        {result.status !== undefined ? (result.status === 200 ? <i className="far fa-4x fa-smile-beam"></i> : <i className="far fa-4x fa-frown"></i>) : ""}

                        {result.status !== undefined ? (< h4 className="heading mt-4">{result.status === 200 ? 'Registo Efetuado com sucesso!' : 'Erro a efetuar o Registo'}</h4>) : null}

                        {result.status !== undefined ?
                            (result.status === 200 ?
                                (<p>
                                    Consulte a sua caixa de email para efetuar a validação do email.
                                </p>)
                                :
                                null
                                //<p>{console.log(result.data.errors)}</p>
                                //(
                                //    {
                                //        result.data.errors.foreach(data => {

                                //        })
                                //    }
                                //)
                            )
                            : ""}



                    </div>
                </div>
                <div className="modal-footer">
                    <Button className="btn-white" color="default" type="button">
                        Ok, Got it
                    </Button>
                    <Button
                        className="text-white ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleModal()}>
                        Close
                    </Button>
                </div>
            </Modal>


        </Col>
    </>
    );
};

export default Register;


