import React from 'react';
import { Table } from "reactstrap";
import BannerItem from '../Banners/BannerItem'

class TableList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            banners: props.currentBanners !== undefined ? props.currentBanners : null
        };
    }


    onChange = (action) => {
        this.props.onChange(action);
    }

    render() {
        const { banners } = this.state;

        return (
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <tr>
                        <th scope="col">Ativo</th>
                        <th scope="col">Título</th>
                        <th scope="col">Descrição</th>
                        <th scope="col">Imagem</th>
                        {/*  <th scope="col">Data</th>*/}
                        <th scope="col">Início</th>
                        <th scope="col">Remover</th>
                        <th scope="col" />
                    </tr>
                </thead>
                <tbody>

                    {banners.map(item => { return (<BannerItem item={item} onChange={this.onChange} />) })}
                </tbody>
            </Table>

        );
    }
};

export default TableList;
