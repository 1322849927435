import React from 'react';
import { Card, CardHeader, Container, Col, Row, Table, Button, FormGroup, Input, InputGroup, InputGroupText, CardFooter } from "reactstrap";
import axios from 'axios';
import { notification, ApiError, GetHeader } from "../utils/Utilities";
import JoinUsRow from './JoinUsRow';
import CustomPagination from '../utils/CustomPagination';


class JoinUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            joinUsData: [],
            joinDetails: null,
            search: "",
            pagination: null,
            size: 10
        }
    }

    getJoinUs = (pageNumber, pageSize, search) => {
        axios.get(global.api_url + "JoinUs?PageNumber=" + pageNumber + "&PageSize=" + pageSize + "&Search=" + search).then((response) => {
            if (response.status === 200) {
                if (response.headers["x-pagination"]) {
                    this.setState({ pagination: JSON.parse(response.headers["x-pagination"]), joinUsData: response.data });
                } else {
                    this.setState({ joinUsData: response.data })
                }
            } else {
                notification("Pedido RedWaste", "Erro a obter os pedidos.", "warning", null, "top-right", null, false);
            }
        }).catch(error => {
            ApiError(error, "Pedido RedWaste").then(call => call === true ? this.getJoinUs(pageNumber, pageSize, search) : "");
        })
    }

    showDetail = (data) => { this.setState({ joinDetails: data }) }

    makeDone = (data) => {
        var headers = GetHeader();
        axios.post(global.api_url + "JoinUs/MakeDone", { id: data?.id }, { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ joinDetails: response.data })
                } else {
                    notification("Pedido RedWaste", "Pedido marcado como tratado.", "warning", null, "top-right", null, false);
                }
                this.setState({ joinDetails: null });
            }).catch(error => {
                ApiError(error, "Pedido RedWaste").then(call => call === true ? this.makeDone(data) : "");
            })
    }

    changeHandler = (pageNumber, pagesize) => { this.getJoinUs(pageNumber, pagesize, ""); }

    componentDidMount() { this.getJoinUs(1, this.state.size, ""); }

    render() {
        const { joinUsData, joinDetails, search, pagination } = this.state;

        return (
            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <Col>
                                        <h6 className="heading-small text-muted mb-4">Pedidos RedService</h6>
                                    </Col>
                                    {joinDetails === null &&
                                        <Col className="text-right" xs="4">
                                            <InputGroup>
                                                <Input type="text" name="seacrh" id="input-seacrh"
                                                    bsSize="sm"
                                                    value={search}
                                                    placeholder="Pesquisar pedido"
                                                    onChange={(e) => this.setState({ search: e.target.value })}
                                                    disabled={false}
                                                />
                                                {/*<InputGroupText addonType="append">*/}
                                                <Button size="sm" color="success" id="btn_search" onClick={(e) => {
                                                    this.getJoinUs(1, this.state.size, this.state.search);
                                                }}><i className="fas fa-search"></i></Button>
                                                {/*</InputGroupText>*/}
                                            </InputGroup>
                                        </Col>}
                                    <Col className="text-right" xl="4">
                                        {joinDetails !== null && <>
                                            <Button color="danger" type="button" size="sm" onClick={(e) => this.setState({ joinDetails: null })} ><i className="fas fa-angle-left"></i> Voltar</Button>
                                            {joinDetails?.done === false && <Button color="success" type="button" size="sm" onClick={(e) => this.makeDone(joinDetails)} ><i className="fas fa-check"></i> Marca como tratado</Button>}
                                        </>}
                                    </Col>
                                </Row>
                            </CardHeader>

                            {joinDetails === null ?
                                <>
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Nome</th>
                                                <th scope="col">Empresa</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Localidade</th>
                                                <th scope="col">Data criação</th>
                                                <th scope="col">Completo</th>
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {joinUsData.map((join, key) => { return (<JoinUsRow key={key} join={join} showDetail={this.showDetail} />); })}
                                        </tbody>
                                    </Table>
                                    <CardFooter className="py-4">
                                        {pagination !== null /*&& workshops.length > 0 */ ? (
                                            <CustomPagination
                                                pagination={pagination}
                                                array={joinUsData}
                                                onChange={this.changeHandler} />
                                        ) : null}

                                    </CardFooter>

                                </>
                                : <div className="pl-lg-4" style={{ margin: 30 }}>
                                    <h6 className="heading-small text-muted mb-4">
                                        INFORMAÇÃO BÁSICA
                                    </h6>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-role">
                                                    Nome
                                                </label >
                                                <Input
                                                    type="text"
                                                    id="name"
                                                    value={joinDetails.name}
                                                    onChange={(e) => { }}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-role">
                                                    Empresa
                                                </label >
                                                <Input
                                                    type="text"
                                                    id="company"
                                                    value={joinDetails.company}
                                                    onChange={(e) => { }}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-role">
                                                    Contacto
                                                </label >
                                                <Input
                                                    type="text"
                                                    id="contact"
                                                    value={joinDetails.contact}
                                                    onChange={(e) => { }}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-role">
                                                    Email
                                                </label >
                                                <Input
                                                    type="text"
                                                    id="email"
                                                    value={joinDetails.email}
                                                    onChange={(e) => { }}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">
                                        OFICINA
                                    </h6>
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-role">
                                                    Morada
                                                </label >
                                                <Input
                                                    type="text"
                                                    id="adress"
                                                    value={joinDetails.adress}
                                                    onChange={(e) => { }}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-role">
                                                    Código Postal
                                                </label >
                                                <Input
                                                    type="text"
                                                    id="postalcode"
                                                    value={joinDetails.postalcode}
                                                    onChange={(e) => { }}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-role">
                                                    Localidade
                                                </label >
                                                <Input
                                                    type="text"
                                                    id="city"
                                                    value={joinDetails.city}
                                                    onChange={(e) => { }}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-role">
                                                    Némero de empregados
                                                </label >
                                                <Input
                                                    type="text"
                                                    id="employees"
                                                    value={joinDetails.employees}
                                                    onChange={(e) => { }}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-role">
                                                    Área
                                                </label >
                                                <Input
                                                    type="text"
                                                    id="area"
                                                    value={joinDetails.area}
                                                    onChange={(e) => { }}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">
                                        OBSERVAÇÕES
                                    </h6>
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-role">
                                                    Observações
                                                </label >
                                                <Input
                                                    type="textarea"
                                                    id="observations"
                                                    value={joinDetails.observations}
                                                    onChange={(e) => { }}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </div>
                            }
                        </Card>
                    </div>
                </Row>
            </Container>
        );

    }


}


export default JoinUs;