import React from 'react';
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import moment, { locale } from "moment";
import QRCode from "react-qr-code";
locale('pt-pt');

class CuponsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cupon: props.cupon,
            loadingData: false,
            currentUser: props.currentUser,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.currentUser !== state.currentUser) {
            return { currentUser: props.currentUser };
        }
        return null;
    }

    openEditVehicule(e) {
        e.preventDefault()
        const { cupon } = this.state;
        this.props.onChange(cupon);
    }

    render() {

        const { cupon } = this.state;

        return (
            <>
                <tr>
                    <th scope="row">
                        <QRCode value={cupon.code} size={50} />
                    </th>
                    <td>
                        {cupon.cuponType.name}
                    </td>
                    <td>
                        {cupon.description}
                    </td>
                    <td>
                        {cupon.value}
                    </td>
                    <td>
                        {moment(cupon.created).format('llll')}
                    </td>
                    <td>
                        <Badge color="" className="badge-dot mr-4">
                            {moment(cupon.validate).isAfter(new Date()) ?
                                (<i className="bg-success" style={{ 'zoom': 3 }} />) :
                                (<i className="bg-danger" style={{ 'zoom': 3 }} />)}
                            {moment(cupon.validate).locale('pt-br').format('llll')}
                        </Badge>
                       
                    </td>

                    <td className="text-right">
                        <Button id="btn_revoke_cupon" color="danger" size="edit" name="update" type="button" size="sm" ><i className="far fa-trash-alt"></i></Button>
                        <UncontrolledTooltip delay={0} placement="top" target="btn_revoke_cupon">
                            Remover Cupão
                        </UncontrolledTooltip>
                                              
                    </td>

                </tr>
            </>
        );
    }
}


export default CuponsList;