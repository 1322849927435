import React, { useState, useEffect } from 'react';
import { CurrentUser, Role, Loading, ApiError, GetHeader } from "views/backoffice/utils/Utilities";
import Header from '../../../components/Headers/Header';
import Schedule from './Schedule';
import axios from 'axios';
import ScheduleSelect from './ScheduleSelect';

const Index = (props) => {
    let currentUser = CurrentUser();
    const [workshop, setworkshop] = useState(null);
    const [dataLoad, setdataLoad] = useState(currentUser.role === Role.Admin ? false : true);
    const changeHandler = (workshop) => { setworkshop(workshop); }

    useEffect(() => {getWorkshop();}, []);

    const getWorkshop = () => {
        if (currentUser.role !== Role.Admin) {
            setdataLoad(true);
            var headers = GetHeader();
            axios.get(global.api_url + "Workshops/" + currentUser.defaultWorkshop, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data;
                        setworkshop(data);
                    }
                    setdataLoad(false);
                }).catch(error => {
                    ApiError(error, "Oficina").then(call => call === true ? getWorkshop() : "");
                    setdataLoad(false);
                })
        }
    }

    return (
        <>
            <Header subText=""/>
            {Loading(dataLoad)}
            {currentUser.role === Role.Admin && workshop === null ? (<ScheduleSelect currentUser={currentUser} onChange={changeHandler} />) : (workshop !== null ? (<Schedule currentUser={currentUser} workshop={workshop} onChange={changeHandler} />) : null)}
        </>
    );
}

export default Index;

