import React from 'react';
import { notification, GetHeader, ApiError, Loading, Role, DeviceType, Device } from "views/backoffice/utils/Utilities";
import { Button, FormGroup, Input, Row, Col, Container, CardHeader, Card, CardBody, Table, CardFooter, UncontrolledTooltip, InputGroup } from "reactstrap";
import axios from 'axios';
import SearchItem from '../Search/SearchItem';
import ModalScheduleInfo from '../../backoffice/Schedules/ModalScheduleInfo';
import CustomPagination from "views/backoffice/utils/CustomPagination";
import InputMask from 'react-input-mask';

class SearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            loadingData: false,
            edit: false,
            search: {
                email: '',
                plate: '',
                brand: '',
                model: '',
                nif: '',
                start: null,
                end: null
            },
            data: null,
            workshopAccounts: null,
            currentWorkshop: null,
            item: null,
            pagination: null,
            searchVal: null,
        };
    }

    searchSchedule = (pageNumber, pagesize, search, searchVal) => {
        this.setState({ loadingData: true });
        var headers = GetHeader();
        return axios.post(global.api_url + "Schedules/Search?PageNumber=" + pageNumber + "&PageSize=" + pagesize + (searchVal !== null ? "&search=" + searchVal : ""), search, { headers })
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data;
                    if (response.headers["x-pagination"] !== undefined) {
                        this.setState({ pagination: JSON.parse(response.headers["x-pagination"]) });
                    }
                    if (data.length === 0) {
                        notification("Procura", "Não foram encontrados registos para a pesquisa", "warning", null, "top-right", null, false);
                    }
                    this.setState({ data: data });
                    this.setState({ loadingData: false });
                    return data;
                }
            }).catch(error => {
                this.setState({ loadingData: false });
                ApiError(error, "Utilizador").then(call => call === true ? this.searchSchedule(pageNumber, pagesize, search, searchVal) : "");
                return null;
            })
    }

    changeHandler = (pageNumber, pagesize, search) => {
        this.searchSchedule(pageNumber, pagesize, search, this.state.searchVal);
    }

    getWorkshopAccounts() {
        const { currentUser } = this.state;
        if (currentUser !== null && currentUser !== undefined) {
            var headers = GetHeader();
            axios.get(global.api_url + "Accounts/Workshop/" + currentUser.defaultWorkshop, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        this.setState({ workshopAccounts: response.data })
                    }
                }).catch(error => {
                    ApiError(error, "Agendamento").then(call => call === true ? this.getWorkshopAccounts() : "");
                })
        } else {
            notification("Oficinas", "Não foi possível obter as oficinas.", "warning", null, "top-right", null, false);
        }
    }
    getcurrentWorkshop() {
        const { currentUser } = this.state;
        if (currentUser !== null && currentUser !== undefined) {
            var headers = GetHeader();
            axios.get(global.api_url + "Workshops/" + currentUser.defaultWorkshop, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        this.setState({ currentWorkshop: response.data })
                    }
                }).catch(error => {
                    ApiError(error, "Agendamento").then(call => call === true ? this.getcurrentWorkshop() : "");
                })
        } else {
            notification("Oficinas", "Não foi possível obter as oficinas.", "warning", null, "top-right", null, false);
        }
    }

    componentDidMount() {
        this.getWorkshopAccounts();
        this.getcurrentWorkshop();
    }


    validatePlate(e) {
        const regex = /^(([A-Z]{2}-\d{2}-(\d{2}|[A-Z]{2}))|(\d{2}-(\d{2}-[A-Z]{2}|[A-Z]{2}-\d{2})))$/;
        const { validate } = this.state;

        if (regex.test(e.target.value)) {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }

    openSchedule = (item) => { this.setState({ item: item }); }

    validateModal = (loadData, eventData, toRemoveId, toLoadId) => { this.setState({ item: eventData }) }

    render() {
        const { edit, loadingData, search, data, currentUser, currentWorkshop, workshopAccounts, item, pagination, searchVal } = this.state;

        return (
            <>
                {item !== null && <ModalScheduleInfo event={item} currentUser={currentUser} currentWorkshop={currentWorkshop} workshopAccounts={workshopAccounts} onChange={this.validateModal} />}
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xs="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col>
                                            <h3 className="mb-0">Procurar agendamento {currentUser.role === Role.Admin ? " (ADMIN)" : ""}</h3>
                                        </Col>

                                        {data !== null &&
                                            <Col className="text-right" xs={DeviceType(window.innerWidth) === Device.Mobile ? "12" : "4"}>
                                                <InputGroup>
                                                    <Input type="text" name="seacrh" id="input-seacrh"
                                                        bsSize="sm"
                                                        value={searchVal}
                                                        placeholder="Pesquisar agendamento"
                                                        onChange={(e) => this.setState({ searchVal: e.target.value !== null && e.target.value !== undefined ? e.target.value : "" })}
                                                        onKeyDown={(e) => { if (e.key === 'Enter') { this.searchSchedule(1, 15, search, searchVal) } }}
                                                        disabled={false}
                                                    />
                                                    <Button size="sm" color="success" id="btn_search" onClick={(e) => {
                                                        this.searchSchedule(1, 15, search, searchVal)
                                                    }}><i className="fas fa-search"></i></Button>
                                                </InputGroup>
                                            </Col>
                                        }

                                        <Col className="text-right" xs={DeviceType(window.innerWidth) === Device.Mobile ? "12" : "4"}>
                                            {data !== null &&
                                                <>
                                                <Button id="btn_new_search" color="warning" size="sm" onClick={(e) => { this.setState({ data: null }); }}>
                                                        <i className="fas fa-bolt"></i>{DeviceType(window.innerWidth) !== Device.Mobile ? "Nova Pesquisa" : ""}
                                                </Button>
                                                <UncontrolledTooltip delay={0} placement="top" target="btn_new_search">
                                                    Efetuar nova pesquisa
                                                </UncontrolledTooltip>
                                                </>
                                            }
                                            {data === null &&
                                                <>
                                                    <Button id="btn_clear" color="warning" size="sm" onClick={(e) => { this.setState({ search: { email: "", plate: "", brand: "", model: "", nif: "", start: null, end: null } }); }}>
                                                        <i className="fas fa-broom"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Limpar" : ""}
                                                    </Button>
                                                    <UncontrolledTooltip delay={0} placement="top" target="btn_clear">
                                                        Limpar toda a pesquisa
                                                    </UncontrolledTooltip>
                                                    <Button id="btn_search" color="success" size="sm" onClick={(e) => { this.setState({ searchVal: null }); this.searchSchedule(1, 15, search, null); }}>
                                                        <i className="fas fa-search"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Procurar" : ""}
                                                    </Button>
                                                    <UncontrolledTooltip delay={0} placement="top" target="btn_search">
                                                        Pesquisar agendamento
                                                    </UncontrolledTooltip>
                                                </>}
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="pl-lg-4">
                                        {Loading(loadingData)}
                                        {(data === null) ?
                                            <Row>
                                                <Col lg="12">
                                                    <Row>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-email">
                                                                    Email
                                                                </label >

                                                                <Input type="text" name="email" id="input-email"
                                                                    value={search.email}
                                                                    onChange={(e) => { this.setState(state => ({ search: { ...state.search, email: e.target.value } })); }}
                                                                    disabled={edit}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-nif">
                                                                    Contribuinte
                                                                </label >

                                                                <Input type="number" id="input-nif"
                                                                    value={search.nif}
                                                                    onChange={(e) => { this.setState(state => ({ search: { ...state.search, nif: e.target.value } })); }}
                                                                    disabled={edit}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="4">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-plate">
                                                                    Matrícula
                                                                </label >

                                                                <Input type="text" name="plate" id="input-plate"
                                                                    value={search.plate}
                                                                    onChange={(e) => { this.setState(state => ({ search: { ...state.search, plate: e.target.value } })); }}
                                                                    disabled={edit}
                                                                    //type="tel"
                                                                    mask="**-**-**"
                                                                    maskChar=" "
                                                                    tag={InputMask}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="4">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-brand">
                                                                    Marca
                                                                </label >

                                                                <Input type="text" name="brand" id="input-brand"
                                                                    value={search.brand}
                                                                    onChange={(e) => { this.setState(state => ({ search: { ...state.search, brand: e.target.value } })); }}
                                                                    disabled={edit}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="4">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-model">
                                                                    Modelo
                                                                </label >

                                                                <Input type="text" name="model" id="input-model"
                                                                    value={search.model}
                                                                    onChange={(e) => { this.setState(state => ({ search: { ...state.search, model: e.target.value } })); }}
                                                                    disabled={edit}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-start">
                                                                    Data íncio
                                                                </label >

                                                                <Input type="date" name="start" id="input-start"
                                                                    value={search.start === null ? "" : search.start}
                                                                    onChange={(e) => { this.setState(state => ({ search: { ...state.search, start: e.target.value } })); }}
                                                                    disabled={edit}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-end">
                                                                    Data fim
                                                                </label >

                                                                <Input type="date" name="end" id="input-end"
                                                                    value={search.end === null ? "" : search.end}
                                                                    onChange={(e) => { this.setState(state => ({ search: { ...state.search, end: e.target.value } })); }}
                                                                    disabled={edit}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                </Col>
                                            </Row>
                                            :
                                            <>
                                                <Table className="align-items-center table-flush" responsive>
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">Agendamento</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Nome</th>
                                                            <th scope="col">Nif</th>
                                                            <th scope="col">Matrícula</th>
                                                            <th scope="col">Marca</th>
                                                            <th scope="col">Modelo</th>
                                                            <th scope="col">Ínicio</th>
                                                            <th scope="col">Fim</th>
                                                            <th scope="col" />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.map(item => { return (<SearchItem item={item} currentUser={currentUser} currentWorkshop={currentWorkshop} workshopAccounts={workshopAccounts} key={item.id} onClick={this.openSchedule} />) })}
                                                    </tbody>
                                                </Table>
                                                <CardFooter className="py-4">
                                                    {pagination !== null && data.length > 0 ? (
                                                        <CustomPagination pagination={pagination} array={data} onChange={(pageNumber, pagesize) => { this.changeHandler(pageNumber, pagesize, search); }} />
                                                    ) : null}
                                                </CardFooter>
                                            </>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default SearchForm;

