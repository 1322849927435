import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from "react-router-dom";
import { RingLoader, FadeLoader } from "react-spinners";
import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { removeAllAccounts, changeAccount } from 'store/accounts/accounts.js';
import Moment from 'moment';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import axios from 'axios';
import { Views } from "react-big-calendar";

//ERP

export function HaveErpConfig(erpConfigs) {
    if (erpConfigs?.erpData !== null
        && erpConfigs?.erpData?.credentials?.APIKey !== null
        && erpConfigs?.erpData?.credentials?.APIKey !== ""
        && erpConfigs?.erpData?.credentials?.APISecret !== null
        && erpConfigs?.erpData?.credentials?.APISecret !== ""
        && erpConfigs?.erpData?.credentials?.backendUrl !== null
        && erpConfigs?.erpData?.credentials?.backendUrl !== ""

    ) {
        return true;
    } else {
        return false;
    }
}

export function DeviceType(innerWidth) {
    if (innerWidth >= 1024) {
        return Device.Desktop
    } else if (innerWidth >= 768) {
        return Device.Tablet
    } else {
        return Device.Mobile
    }
};

export function ViewToolBar(view) {
    switch (view) {
        case Views.WEEK:
            return "Semana";
        case Views.DAY:
            return "Dia";
        case Views.MONTH:
            return "Mês";
        default:
            return "";
    }
}


//Loader
export function Loading(visible, size) {
    return (visible === true ?
        <div style={{ position: 'absolute', zIndex: 100, left: "50%", marginleft: "-4em", top: "50%", marginTop: "0em", }}>
            <FadeLoader justify="center" color={'#E2241A'} css={'display: block; margin: auto; border-color: #E2241A; flex: 1;'} size={size !== null ? size : 20} />
        </div>
        : null)
}

export function LoadingFixed(visible, size) {
    return (visible === true ?
        <FadeLoader color={'#E2241A'} css={'border-color: #E2241A'} size={size !== null ? size : 20} />
        : null)
}


//Usar moment.js
export function ConvertDate(date) { return Moment(date).format('LLL'); }


export async function joinSignalR(accountId, workshopId) {
    try {
        const connectionSignalR = new HubConnectionBuilder()
            .withUrl(global.api_url + "hub")
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        connectionSignalR.on("ReceiveMessage", (accountId, workshopId) => {
            //console.log("message received " + accountId + " - " + workshopId);
        });
        await connectionSignalR.start();
        connectionSignalR.invoke("joinRoom", { accountId: +accountId, workshopId: +workshopId })
        global.connectionSignalR = connectionSignalR;

    } catch (e) {
        notification("SignalR", e, "warning", null, "top-right", null, false);
    }
}


export async function RenewLogin() {
    if (localStorage.getItem('refreshToken') !== undefined && localStorage.getItem('refreshToken') !== null && localStorage.getItem('refreshToken') !== "") {
        return axios.post(global.api_url + "Accounts/refresh-token", { 'token': localStorage.getItem('refreshToken') }).then(response => {
            global.currentUser = response;
            if (response.status === 200) {
                localStorage.setItem('refreshToken', response.data.refreshToken)
                localStorage.setItem('jwtToken', response.data.jwtToken)
                UpdateUser(response.data);
                return response.data;
            } else {
                return null;
            }
        }).catch(error => {
            return null;
        })
    } else {
        notification("Autenticação", "Login renovado", "success", null, "top-right", null, false);
        return null;
    }
}
export function GetHeader() {
    return { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` };
}
export function GetHeaderPut() {
    return { 'Accept': '*/*', 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('jwtToken')} ` };
}


export async function ApiError(error, notificationName) {
    if (error !== null && error !== undefined && error !== "" && notificationName !== null && notificationName !== undefined && notificationName !== "") {
        switch (error.response?.status) {
            case 400:
                var errorsTxt = "";
                if (error.response.data.message !== undefined) {
                    errorsTxt = error.response.data.message;
                } else {
                    Object.entries(error.response.data.errors).forEach(erro => { errorsTxt = errorsTxt + " \n" + erro[1][0] });
                }

                notification(notificationName, "Erro: " + errorsTxt, "warning", null, "top-right", null, false);
                break;
            case 405:
                notification(notificationName, "Erro: " + error.response.statusText, "warning", null, "top-right", null, false);
                break;
            case 500:
                notification(notificationName, "Erro: " + error.response.data.message, "warning", null, "top-right", null, false);
                //RemoveUser(global.dispatch)
                break;
            case 503:
                notification(notificationName, "Erro: Erro no servidor.", "warning", null, "top-right", null, false);
                break;
            case 401:
                return RenewLogin().then(newCurrentUser => {
                    if (newCurrentUser !== null) {
                        //notification("Autenticação", "Login renovado", "success", null, "top-right", null, false);

                        if (isNaN(+localStorage.getItem('try'))) {
                            localStorage.setItem('try', "0");
                            return true
                        } else {
                            var trys = +localStorage.getItem('try');
                            if (trys > 0) {
                                trys = 0;
                                localStorage.setItem('try', "0");
                                notification("Autenticação", "Erro servidor: " + error.response.data.message, "warning", null, "top-right", null, false);
                                return false;
                            }

                            if (trys < 1) {
                                trys = trys + 1;
                                localStorage.setItem('try', trys.toString());
                                return true;
                            } else {
                                localStorage.setItem('try', "0");
                                return false;
                            }
                        }
                    } else {
                        notification("Autenticação", "Login expirado.", "warning", null, "top-right", null, false);
                        RemoveUser(global.dispatch);
                        <Redirect to="/login" />
                        return false;
                    }
                });
            default:
                notification(notificationName, "Erro: " + error.toString(), "danger", null, "top-right", null, false);
                break;
        }
    }
}

export function StatusColor(status) {
    var backgroundColor = '';
    switch (status) {
        case Status.Validacao:
            backgroundColor = '#C1C1C1';
            break;
        case Status.Pendente:
            backgroundColor = '#F7F762';
            break;
        case Status.Aceite:
            backgroundColor = '#c0ffbf';
            break;
        case Status.Rejeitado:
            backgroundColor = '#fbb81e';
            break;
        case Status.Oficina:
            backgroundColor = '#E2231A';
            break;
        case Status.Concluido:
            backgroundColor = '#81FF51';
            break;
        case Status.Entregue:
            backgroundColor = '#00B93D';
            break;
        case Status.Cancelado:
            backgroundColor = '#ffc9c6';
            break;
        case Status.Orcamento:
            backgroundColor = '#09626F';
            break;
        default:
            backgroundColor = '#D2691E';
            break;
    }
    return backgroundColor;
}

export function StatusText(status) {
    var text = '';
    switch (status) {
        case Status.Validacao:
            text = 'O agendamento está á espera de ser validada pela oficina.';
            break;
        case Status.Pendente:
            text = 'O Cliente está á espera de uma resposta de horário da oficina.';
            break;
        case Status.Aceite:
            text = 'O agendamento está aprovado';
            break;
        case Status.Rejeitado:
            text = 'O horário apresentado pela oficina não foi aprovado pelo cliente.';
            break;
        case Status.Oficina:
            text = 'O veículo encontra-se na oficina.';
            break;
        case Status.Concluido:
            text = 'Veículo pronto para a entrega.';
            break;
        case Status.Entregue:
            text = 'O veículo foi entregue ao cliente. Processo concluido.';
            break;
        case Status.Cancelado:
            text = 'O agendamento foi cancelado pelo cliente/oficina';
            break;
        case Status.Orcamento:
            text = 'Orçamento';
            break;
        default:
            break;
    }
    return text;
}


//NOTIFICATIONS

export function notification(title, message, type, insert, container, duration, onscreen) {
    Store.addNotification({
        title: title !== null ? title : "Default title",
        message: message !== null ? message : "Default message",
        type: type !== null ? type : "success",
        insert: insert !== null ? insert : "top",
        container: container !== null ? container : "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: duration !== null ? duration : 5000,
            onScreen: onscreen !== null ? onscreen : true
        }
    });
}

export function RemoveNotifications() {
    Store.removeAllNotifications();
}



export function LoadingUser(visible) {
    return (visible === true ? (<RingLoader color={'#E2241A'} css={'left: 50%; top: 50%; transform: translateX(-50%) border-color: #E2241A; flex: 1;margin-top:50px;'} size={50} />) : null)
}


//Users
export function CurrentUser() {
    const accounts = useSelector(state => state.accounts);
    const currentUser = accounts.length > 0 ? accounts[accounts.length - 1] : null;
    return currentUser !== null && currentUser !== undefined ? currentUser : null;
}

export function UpdateUser(account) {
    global.dispatch(changeAccount(account));
}



export function RemoveLoginCockies() {
    localStorage.setItem('refreshToken', '')
    localStorage.setItem('jwtToken', '')
}

export function RemoveUser(dispatch) {
    dispatch(removeAllAccounts());
    RemoveLoginCockies();
    return <Redirect to="/login" />;
}



//Enum Roles
export const Role = {
    Admin: "Admin",
    User: "User",
    Oficina: "Oficina",
    GestorOficina: "GestorOficina",
    Mecanico: "Mecanico"
};

export const Status = {
    Validacao: 'Validacao',
    Pendente: 'Pendente',
    Aceite: 'Aceite',
    Rejeitado: 'Rejeitado',
    Oficina: 'Oficina',
    Concluido: 'Concluido',
    Entregue: 'Entregue',
    Cancelado: 'Cancelado',
    Orcamento: 'Orcamento'
}

export const StatusService = {
    Validado: "Validado",
    Montado: "Montado",
    Cancelado: "Cancelado",
    Pendente: "Pendente",
    Sugestao: "Sugestao"
}

export const StatusLine = {
    Aceite: "Aceite",
    Rejeitado: "Rejeitado",
    Pendente: "Pendente",
}

export const ServiceType = {
    Pack: "Pack",
    Service: "Service"
};

export const Oficina = {
    Leiria: "Leiria",
    Teste: "Teste",
};

export const NotificationType = {
    Normal: "Normal",
    Orcamento: "Orcamento",
    Recolha: "Recolha",
    NovoServico: "NovoServico",
    NovoAgendamento: "NovoAgendamento",
    Promo: "Promo",
    Aceite: "Aceite"
};

export const NotificationSend = {
    FirebaseMessage: "FirebaseMessage",
    Email: "Email"
};

export const Device = {
    Desktop: "DeskTop",
    Mobile: "Mobile",
    Tablet: "Tablet"
};


//PAGE ROLES
export function AcessPage(path) {
    var currentUser = CurrentUser();
    var rolesPage = [
        { path: "/Dashboard", roles: [Role.Admin, Role.Oficina, Role.GestorOficina, Role.Mecanico] },
        { path: "/Users", roles: [Role.Admin, Role.GestorOficina] },
        { path: "/Profile", roles: [Role.Admin, Role.Oficina, Role.GestorOficina, Role.Mecanico] },
        { path: "/Workshops", roles: [Role.Admin] },
        { path: "/Workshop", roles: [Role.Admin, Role.Oficina, Role.GestorOficina, Role.Mecanico] },
        { path: "/Schedule", roles: [Role.Admin, Role.Oficina, Role.GestorOficina, Role.Mecanico] },
        { path: "/Budget", roles: [Role.Admin, Role.GestorOficina] },
        { path: "/ServiceType", roles: [Role.Admin] },
        { path: "/Cupons", roles: [Role.Admin] },
        { path: "/Mail", roles: [Role.Admin, Role.GestorOficina] },
        { path: "/Configurations", roles: [Role.Admin, Role.GestorOficina] },
        { path: "/JoinUs", roles: [Role.Admin] },

        { path: "/Banners", roles: [Role.Admin] },
        { path: "/BannerText", roles: [Role.Admin] },
        { path: "/Text", roles: [Role.Admin] },
        { path: "/Services", roles: [Role.Admin] },
        { path: "/Campaigns", roles: [Role.Admin] },
        { path: "/Agreements", roles: [Role.Admin] },
        { path: "/Advices", roles: [Role.Admin] },
        { path: "/MasterWay", roles: [Role.Admin, Role.Oficina, Role.GestorOficina, Role.Mecanico] },
        { path: "/Documentation", roles: [Role.Admin, Role.Oficina, Role.GestorOficina, Role.Mecanico] },
        { path: "/Search", roles: [Role.Admin, Role.Oficina, Role.GestorOficina, Role.Mecanico] },
    ];

    var grant = null;
    Object.keys(rolesPage).forEach(function (key, index) {
        var role = rolesPage[key];
        if (role.path === path) { if (role.roles.includes(currentUser.role)) { grant = role; } }
    });
    return grant;
};

