import React from 'react';
import { Loading, notification, StatusService, Status, GetHeader, GetHeaderPut, ApiError } from "../utils/Utilities";
import { Badge, Button, UncontrolledTooltip, Input } from "reactstrap";
import axios from 'axios';
import Moment from 'moment';
import confirm from "reactstrap-confirm";
import 'moment/locale/pt'
import ModalCarrousel from '../utils/ModalCarrousel';
Moment.locale('pt');

class ServiceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: props.disabled,
            currentUser: props.currentUser,
            service: props.service,
            old_service: props.service,
            workshop: props.workshop,
            loadingData: false,
            assets: null,
            validate: { nameState: '', descriptionState: '', cuponState: '', priceState: '' },
            edit: false,
            event: props.event
        };
    }

    openEdit(e) {
        e.preventDefault()
        this.handleValue("edit", true);
    }

    cancelEdit(e) {
        e.preventDefault()
        this.setState({ service: this.state.old_service });
        this.handleValue("edit", false);
    }

    handleValueService = async (name, value) => {
        await this.setState(state => ({ service: { ...state.service, [name]: value } }));
        return this.state.service;
    }

    handleValue = async (name, value) => {
        await this.setState(state => ({ ...state, [name]: value }));
        return this.state.service;
    }

    completeService = (e) => {
        e.preventDefault();
        var service = this.handleValueService('statusService', StatusService.Montado);
        service.then(service => { this.updateService(service); });
    }

    update = (e) => {
        e.preventDefault();
        this.updateService(this.state.service);
    }

    delete = (e) => {
        e.preventDefault();
        var { service } = this.state;

        let result = confirm({
            title: (<><strong>Eliminar linha de serviço</strong></>),
            message: (<>
                <h4>Serviço:</h4>
                <h5>•Nome: {service?.name}</h5>
                <h5>•Descrição: {service?.description}</h5>
                <br></br>
                <h4>Deseja eliminar?</h4>
            </>),
            confirmText: "Sim",
            confirmColor: "success",
            cancelText: "Não",
            cancelColor: "danger"
        });
        result.then(response => {
            if (response) {
                this.deleteService(service);
                this.props.onChange('DELETE', service);
            }
        })
    }


    makeBudget = (e) => {
        e.preventDefault();
        this.props.onChange('BUDGET', this.state.service);
    }

    showPhotos = (e) => {
        e.preventDefault();
        const { service } = this.state;
        if (service.assets === "True") {
            var headers = GetHeader();
            axios.get(global.api_url + "Services/Assets/" + service.scheduleId + "/" + service.id, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        this.handleValue('assets', response.data);
                    }
                }).catch(error => {
                    ApiError(error, "Anexos").then(call => call === true ? this.showPhotos(e) : "");
                })
        }
    }

    updateService = (service) => {
        this.handleValue('loadingData', true);
        var headers = GetHeaderPut();
        axios.put(global.api_url + "Services", service, { headers })
            .then((response) => {
                if (response.status === 200) {
                    //this.state.service = response.data;
                    this.setState({ service: response.data });
                    notification("Serviço", "Serviço atualizado com sucesso.", "success", null, "top-right", null, false);
                } else { notification("Serviço", "Erro a atualizar o serviço do cliente.", "warning", null, "top-right", null, false); }
                this.handleValue('loadingData', false);
                this.handleValue('edit', false);
            }).catch(error => {
                ApiError(error, "Atualizar Serviço").then(call => call === true ? this.updateService(service) : "");
                this.handleValue('loadingData', false);
            })
    }

    deleteService = (service) => {
        this.handleValue('loadingData', true);
        var headers = GetHeader();
        axios.delete(global.api_url + "Services/RemoveAll/" + service.id, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Serviço", "Serviço removido com sucesso.", "success", null, "top-right", null, false);
                } else { notification("Serviço", "Erro a remover o serviço do cliente.", "warning", null, "top-right", null, false); }
                this.handleValue('loadingData', false);
            }).catch(error => {
                this.handleValue('loadingData', false);
                this.handleValueService('statusService', StatusService.Validado)
                ApiError(error, "Eliminar Serviço").then(call => call === true ? this.deleteService(service) : "");
            })
    }

    calculateTimer = (item) => {
        var lastTimer = null;
        var totalTimeAux = 0;
        lastTimer = item.servicesTimers !== undefined ? item.servicesTimers[0] : null;
        lastTimer !== null && item.servicesTimers.forEach(t => { if (t.stop !== undefined) { totalTimeAux += Moment.utc(Moment(t.stop).diff(Moment(t.start))); } })
        return totalTimeAux;
    }

    validateString(e) {
        const { validate } = this.state;

        if (e.target.value !== null && e.target.value !== "") {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }

        this.setState(({ validate }));
    }

    handleChangeService = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        if (name === '' || name === '') {
            this.setState(state => ({ service: { ...state.service, [name]: parseInt(value) } }));
        } else if (name === 'calculatedTime') {
            this.setState(state => ({ service: { ...state.service, [name]: value } }));
        } else {
            this.setState(state => ({ service: { ...state.service, [name]: value } }));
        }
    };

    formatcalculatedTime = (service) => {
        var duration = Moment.duration(service !== undefined && service.calculatedTime !== undefined ? service.calculatedTime : "0", 'second');
        var formatted = Moment(duration).format("HH:MM:ss");
        return formatted;
    }

    render() {
        const { service, loadingData, disabled, workshop, validate, edit, assets } = this.state;
        const changeStatus = (status) => {
            switch (status) {
                case StatusService.Validado:
                    return (<>
                        <i className="bg-yellow-rs" id="bagde-valid" style={{ 'zoom': 3 }} />
                        < UncontrolledTooltip delay={0} placement="top" target="bagde-valid" >
                            Serviço validado para montagem.
                        </UncontrolledTooltip >
                    </>);
                case StatusService.Montado:
                    return (<>
                        <i className="bg-success" id="bagde-mount" style={{ 'zoom': 3 }} />
                        < UncontrolledTooltip delay={0} placement="top" target="bagde-mount" >
                            Serviço Montado.
                        </UncontrolledTooltip >
                    </>);
                case StatusService.Cancelado:
                    return (<>
                        <i className="bg-danger" id="bagde-cancel" style={{ 'zoom': 3 }} />
                        < UncontrolledTooltip delay={0} placement="top" target="bagde-cancel" >
                            Serviço Canelado.
                        </UncontrolledTooltip >
                    </>);
                case StatusService.Pendente:
                    return (<>
                        <i className="bg-orange" id="bagde-confirm" style={{ 'zoom': 3 }} />
                        < UncontrolledTooltip delay={0} placement="top" target="bagde-confirm" >
                            A aguardar resposta do novo serviço proposto ao cliente.
                        </UncontrolledTooltip >
                    </>);
                case StatusService.Sugestao:
                    return (<>
                        <i className="bg-blue" id="bagde-sugest" style={{ 'zoom': 3 }} />
                        < UncontrolledTooltip delay={0} placement="top" target="bagde-sugest" >
                            Efetuar uma sugestão de serviço ao cliente.
                        </UncontrolledTooltip >
                    </>);
                default:
                    break;
            }
        }

        const changeTimer = (timers) => {

            if (service.statusService === StatusService.Montado) {
                return (<>
                    <i className="fas fa-check rs-text-green " id="Check" style={{ 'zoom': 1.5 }}></i>
                    < UncontrolledTooltip delay={0} placement="top" target="Check" >
                        Serviço concluido
                    </UncontrolledTooltip >
                </>);

            } else if (service.statusService === StatusService.Sugestao || service.statusService === StatusService.Pendente || service.statusService === StatusService.Sugestao || service.statusService === StatusService.Cancelado) {
                return (<></>);
            } else {
                if (timers !== undefined) {
                    var working = false;
                    Object.assign(timers).forEach(x => { if (x?.stop === undefined) { working = true; } })
                    if (working) {
                        return (<>
                            <i className="fas fa-play rs-text-green " id="Started" style={{ 'zoom': 1.5 }}></i>
                            < UncontrolledTooltip delay={0} placement="top" target="Started" >
                                Serviço a ser efetuado
                            </UncontrolledTooltip >
                        </>);
                    } else {
                        return (<>
                            <i className="fas fa-pause rs-text-red" id="Pause" style={{ 'zoom': 1.5 }}></i>
                            < UncontrolledTooltip delay={0} placement="top" target="Pause" >
                                Serviço em pausa
                            </UncontrolledTooltip >
                        </>);
                    }
                } else {
                    return (<>
                        <i className="fas fa-hourglass-start" style={{ 'zoom': 1.5 }} id="Not_Start"></i>
                        < UncontrolledTooltip delay={0} placement="top" target="Not_Start" >
                            Serviço por iniciar
                        </UncontrolledTooltip >
                    </>);
                }
            }
        }

        return (
            <>
                {assets !== null && assets.length > 0 && <ModalCarrousel title={"Anexos de sugestão de orçamento"} assets={assets} onClick={() => { this.handleValue('assets', null); }} />}

                <tr>
                    <td style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        paddingLeft: "40%"
                    }}>
                        <Badge className="badge-dot">
                            {changeStatus(service.statusService)}
                        </Badge>
                    </td>


                    <td style={{ textAlign: 'center' }}>
                        {changeTimer(service?.servicesTimers)}
                    </td>
                    <td style={{
                        textAlign: 'left',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap'
                    }} >

                        {!edit ?
                            service.name :
                            <Input type="text" name="name" id="name"
                                valid={validate.nameState === "has-success"}
                                invalid={validate.nameState === "has-danger"}
                                value={service.name}
                                onChange={(e) => { this.validateString(e); this.handleChangeService(e); }}
                                disabled={!edit}
                            />
                        }

                    </td>
                    <td style={{ textAlign: 'center', minWidth: 350 }}>
                        {!edit ?
                            <Input type="textarea"
                                name="description"
                                id="description"
                                value={service.description}
                                disabled={true}
                            />
                            :
                            <Input type="textarea" name="description" id="description"
                                valid={validate.descriptionState === "has-success"}
                                invalid={validate.descriptionState === "has-danger"}
                                value={service.description}
                                onChange={(e) => { this.validateString(e); this.handleChangeService(e); }}
                                disabled={!edit}
                            />
                        }
                    </td>

                    <td style={{ textAlign: 'center'/*, minWidth: 150 */ }}>
                        {service.price !== undefined && service.price !== "" ? service.price.toFixed(2) + " €" : " -- "}
                    </td>
                    <td style={{ textAlign: 'center'/*, minWidth: 150*/ }}>
                        {service.calculatedTime !== undefined ? service.calculatedTime.split('.')[0] : "00:00:00"}
                    </td>

                    <td className="text-right">
                        {loadingData === true ? Loading(loadingData, 5) : (<>
                            {service.statusService === StatusService.Cancelado &&
                                <> <Button id="btn_trash" color="danger" name="update" type="button" size="sm" disabled={disabled} onClick={e => { this.makeBudget(e) }} ><i className="fas fa-recycle"></i></Button>
                                    <UncontrolledTooltip delay={0} placement="top" target="btn_trash">
                                        Reabrir proposta
                                    </UncontrolledTooltip>
                                </>
                            }
                            {service.statusService === StatusService.Sugestao && this.state?.event?.resources?.workshop?.status === Status.Aceite &&
                                <>
                                    <Button id="btn_trash" color="danger" name="update" type="button" size="sm" disabled={disabled} onClick={e => {
                                        var headers = GetHeader();
                                        axios.post(global.api_url + "Notifications/Send/Sugestion/" + this.state?.event?.id + "/" + service?.id, null, { headers })
                                            .then((response) => {
                                                if (response.status === 200) {
                                                    notification("Notificação", "Sugestão enviada com sucesso!", "success", null, "top-right", null, false);
                                                }
                                                this.handleValue('load', false);
                                            }).catch(error => {
                                                ApiError(error, "Notificação").then(call => call === true ? this.sendSugestion(e) : "");
                                            }).then(() => { this.handleValue('load', false); })
                                    }} ><i className="fas fa-paper-plane"></i></Button>
                                    <UncontrolledTooltip delay={0} placement="top" target="btn_trash">
                                        Enviar
                                    </UncontrolledTooltip>
                                </>
                            }
                            {
                                service.statusService !== StatusService.Cancelado &&
                                <>
                                    {
                                        service.statusService !== StatusService.Montado &&
                                        <>
                                            {!edit ? <>

                                                <Button color="info" id={"btn_doc_filter_"} name="update" type="button" size="sm" disabled={disabled} onClick={e => { this.props.onClick(service); }} ><i className="fas fa-file-invoice"></i></Button>
                                                <UncontrolledTooltip delay={0} placement="top" target={"btn_doc_filter_"}>
                                                    Documento filtrado pelo serviço <b>{service.name}</b>
                                                </UncontrolledTooltip>

                                                <Button color="warning" id="btn_edit" name="update" type="button" size="sm" disabled={disabled} onClick={e => { this.openEdit(e) }} ><i className="fas fa-edit"></i></Button>
                                                <UncontrolledTooltip delay={0} placement="top" target="btn_edit">
                                                    Editar linha
                                                </UncontrolledTooltip>
                                            </> : <>
                                                {(this.state.event.resources.workshop.status === Status.Pendente || service.statusService !== StatusService.Validado) &&
                                                    <> <Button id="btn_trash" color="danger" name="update" type="button" size="sm" disabled={disabled} onClick={e => { this.delete(e) }} ><i className="far fa-trash-alt"></i></Button>
                                                        <UncontrolledTooltip delay={0} placement="top" target="btn_trash">
                                                            Eliminar linha
                                                        </UncontrolledTooltip>
                                                    </>}

                                                <Button color="success" id="btn_update" name="update" type="button" size="sm" disabled={disabled} onClick={e => { this.updateService(service) }} ><i className="fa fa-save"></i></Button>
                                                <UncontrolledTooltip delay={0} placement="top" target="btn_update">
                                                    Atualizar linha
                                                </UncontrolledTooltip>

                                                <Button color="warning" id="btn_rollback" name="update" type="button" size="sm" disabled={disabled} onClick={e => { this.cancelEdit(e) }} ><i className="fas fa-times"></i></Button>
                                                <UncontrolledTooltip delay={0} placement="top" target="btn_rollback">
                                                    Cancelar edição
                                                </UncontrolledTooltip>

                                            </>}
                                        </>
                                    }
                                    {service.statusService === StatusService.Validado && workshop.status === Status.Oficina && edit === false &&
                                        <>
                                            <Button color="success" id="btn_finish" name="update" type="button" size="sm" disabled={disabled} onClick={e => { this.completeService(e) }} ><i className="fas fa-clipboard-check"></i></Button>
                                            <UncontrolledTooltip delay={0} placement="top" target="btn_finish">
                                                Serviço efetuado
                                            </UncontrolledTooltip>
                                        </>
                                    }
                                    {service.statusService === StatusService.Sugestao && workshop.status === Status.Oficina &&
                                        <>
                                            <Button color="info" id="btn_orc" name="orc" type="button" size="sm" disabled={disabled} onClick={e => { this.makeBudget(e) }} ><i className="fas fa-search-dollar"></i></Button>
                                            <UncontrolledTooltip delay={0} placement="top" target="btn_orc">
                                                Orçamentar Sugestão
                                            </UncontrolledTooltip>
                                        </>
                                    }
                                    {service.assets === "True" && <>
                                        <Button color="info" id="btn_photos" name="update" type="button" size="sm" disabled={disabled} onClick={e => { this.showPhotos(e) }} ><i className="fas fa-images"></i></Button>
                                        <UncontrolledTooltip delay={0} placement="top" target="btn_photos">
                                            Visualizar foto(s)
                                        </UncontrolledTooltip>
                                    </>}
                                </>
                            }
                        </>)}
                    </td>
                </tr>
            </>);
    }
}


export default ServiceList;