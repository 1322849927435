import React, {  useState } from 'react';
import Header from '../../../../components/Headers/Header';
import Language from '../BackOffice/Language'
import BackOffice from '../Text/Backoffice'

const Index = (props) => {

    const [selectLanguage, setSelectLanguage] = useState(null);

    const onchange = (lng) => {
        setSelectLanguage(lng);
    }

    const onClick = () => {
        setSelectLanguage(null);
    }

    return (
        <>
            <Header />
            {selectLanguage === null ?
                <Language onChange={onchange} />
                : <BackOffice language={selectLanguage} onClick={onClick} />}
        </>
    );
}

export default Index;

