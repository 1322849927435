import React from 'react';
import { Card, CardFooter, Table, Row } from "reactstrap";
import { Loading, notification, ApiError } from "../utils/Utilities";
import axios from 'axios';
import ServiceTypesList from '../ServiceTypes/ServiceTypesList';



class ServiceTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            //reOrder: props.reOrder,
            loadingData: true,
            serviceTypes: [],
        };
    }

    componentWillReceiveProps(props) {
        this.setState({ reOrder: props?.reOrder });
    }


    componentDidMount() {
        this.getServiceTypes();
    }

    handleValue = (name, value) => {
        this.setState({ [name]: value });
    }

    getServiceTypes() {
        this.handleValue('loadingData', true);
        axios.get(global.api_url + "ServiceTypes")
            .then((response) => {
                if (response.status === 200) {
                    this.state.serviceTypes = response.data;
                } else {
                    notification("Serviços", "Erro a obter os tipos de serviço.", "warning", null, "top-right", null, false);
                }
                this.handleValue('loadingData', false);
            }).catch(error => {
                this.handleValue('loadingData', false);
                ApiError(error, "Serviços").then(call => call === true ? this.getServiceTypes() : "");
            })

    }

    onEdit(e) { this.props.onChange(e); }


    //renderOrder(order, serviceT) {
    //    console.log(order)
    //    console.log(serviceT)

    //    if (serviceT?.ordination !== undefined) {
    //        var oldpos = serviceT.ordination;
    //        if (order === "UP") {
    //            var newPos = serviceT.ordination + 1;
    //            var a = this.state.serviceTypes.filter(x => x.ordination === oldpos);
    //            console.log(a);
    //        } else {
    //            var newPos = serviceT.ordination - 1;
    //        }
    //    } else {

    //    }
    //}

    render() {
        const { loadingData, serviceTypes, currentUser, reOrder } = this.state;

        return (<>
            {Loading(loadingData)}
            {serviceTypes.length > 0 && loadingData === false ? (
                <>
                    <Row>
                        <div className="col">
                            <Card className="shadow">

                                {loadingData ? (Loading(loadingData)) : (
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Logo</th>
                                                <th scope="col">Nome</th>
                                                <th scope="col">Descrição</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {serviceTypes.map((serviceType, key) => {
                                                return <ServiceTypesList key={key} currentUser={currentUser} serviceType={serviceType} onChange={(e) => this.onEdit(e)} reOrder={reOrder} length={serviceTypes.length} renderOrder={this.renderOrder} />
                                            })}
                                        </tbody>
                                    </Table>
                                )}
                                <CardFooter className="py-4">

                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                </>
            ) : null}





        </>)
    }
}


export default ServiceTypes;