import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from "reactstrap"

class ModalValidate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: props.title,
            body: props.body,
            visible: props.visible ? props.visible : false,
        };
    }


    validateModal(validation) {
        this.props.onChange(validation);
    }

    render() {
        const { title, body, visible } = this.state;


        return (
            <Modal
                className="modal-dialog-centered modal-danger"
                contentClassName="bg-RS-grey"
                isOpen={visible}
            >

                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-notification">
                        {title}
                    </h6>
                </div>
                <div className="modal-body">
                    <div className="py-3 text-center">
                        {body}
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        className="text-white ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => { this.validateModal(false) }}>
                        Cancelar
                    </Button>
                    <Button
                        className="text-white ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => { this.validateModal(true) }}>
                        Confirmar
                    </Button>
                </div>
            </Modal>
        )
    }
}

export default ModalValidate;