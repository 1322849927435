import React from 'react';
import { Button, FormGroup, Form, Input, FormFeedback, Row, Col, Container, CardHeader, Card, CardBody } from "reactstrap";
import { Loading, GetHeader, notification, GetHeaderPut, ApiError } from "../utils/Utilities";
import InputMask from 'react-input-mask';
import axios from 'axios';
import Select from 'react-select';


class VehiculeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicule: {
                id: props.formVehicule.id !== 0 ? props.formVehicule.id : 0,
                accountId: props.formVehicule.id !== 0 ? props.formVehicule.account_Id : props.user.id,
                brand: props.formVehicule.id !== 0 ? props.formVehicule.brand : '',
                model: props.formVehicule.id !== 0 ? props.formVehicule.model : '',
                plate: props.formVehicule.id !== 0 ? props.formVehicule.plate : '',
                year: props.formVehicule.id !== 0 ? props.formVehicule.year : '',
                kms: props.formVehicule.id !== 0 ? props.formVehicule.kms : '',
                kmsYear: props.formVehicule.id !== 0 ? props.formVehicule.kmsYear : '',
            },
            old_vehicule: props.formVehicule.id !== 0 ? props.formVehicule : {},
            validate: {
                account_IdState: '',
                brandState: '',
                modelState: '',
                plateState: '',
                yearState: '',
                kmsState: '',
                kms_yearState: ''
            },
            user: props.user,
            currentUser: props.currentUser,
            edit: props.formVehicule.id !== 0 ? false : true,
            loadingData: false,
            loadingDataSelect: false,
            brandsList: [],
            modelList: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeWorkshop = this.handleChangeWorkshop.bind(this);
    }

    handleValue = (name, value) => {
        this.setState({ [name]: value });
    }

    handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        this.setState({ [name]: value });
    };

    handleChangeWorkshop = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value.toUpperCase();
        const { name } = target;
        if (name === 'kms' || name === 'kmsYear' || name === 'year') {
            this.setState(state => ({ vehicule: { ...state.vehicule, [name]: parseInt(value) } }));
        } else if (name === 'plate') {


            console.log((target.value.length === 2 && (!this.state?.vehicule?.plate.includes("-", 2))) || (target.value.length === 5 && (!this.state?.vehicule?.plate.includes("-", 5))))


            if ((target.value.length === 2 && (!this.state?.vehicule?.plate.includes("-", 2))) || (target.value.length === 5 && (!this.state?.vehicule?.plate.includes("-", 5)))) {
                //target.value = target.value + "-";
                this.setState(state => ({ vehicule: { ...state.vehicule, [name]: value.toUpperCase() + "-" } }));
            } else {
                this.setState(state => ({ vehicule: { ...state.vehicule, [name]: value.toUpperCase() } }));
            }

            if (target.value.length === 8) {
                this.GetPlate(target.value.toUpperCase()).then(response => {
                    if (response !== null) {
                        this.setState(state => ({ vehicule: { ...state.vehicule, plate: value, brand: response?.marca, model: response?.modelo, year: response?.anoFab, kms: 0, ktypnr: response?.ktypnr.toString() } }));
                    }
                })

            }
        } else {
            this.setState(state => ({ vehicule: { ...state.vehicule, [name]: value } }));
        }
    };
    /*VALIDATIONS*/

    validateString(e) {
        const { validate } = this.state;

        if (e.target.value !== null && e.target.value !== "") {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }

        this.setState(({ validate }));
    }
    validatePlate(e) {
        const regex = /^(([A-Z]{2}-\d{2}-(\d{2}|[A-Z]{2}))|(\d{2}-(\d{2}-[A-Z]{2}|[A-Z]{2}-\d{2})))$/;
        const { validate } = this.state;

        if (regex.test(e.target.value.toUpperCase())) {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }

    regexValidate(e, regex) {
        const { validate } = this.state;
        if (regex.test(e.target.value)) {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }

    validForm() {
        var { vehicule, validate } = this.state;
        var valid = true;
        Object.entries(vehicule).forEach(data => {
            if (data[1] === '' && (data[0] === 'plate' || data[0] === 'brand' || data[0] === 'Kms' || data[0] === 'year' || data[0] === 'model')) {
                validate[data[0] + 'State'] = 'has-danger';
                this.setState({ validate });
                valid = false;
            }
        })
        Object.entries(validate).forEach(data => { if (data[1] === 'has-danger') { valid = false; } });
        return valid;
    }

    /*SUBMISSIONS*/

    submitForm(e) {
        e.preventDefault();
        var { workshop } = this.state;
        workshop.id === 0 ? this.createWorkShop() : this.updateWorkshop();
    }

    updateVehicule() {
        this.handleValue('loadingData', true);
        var { vehicule } = this.state;
        var headers = GetHeaderPut();
        axios.put(global.api_url + "Vehicules", vehicule, { headers })
            .then((response) => {
                notification("Veículo", "Veículo atualizado com sucesso!", "success", null, "top-right", null, false);
                this.handleValue('edit', false);
                this.handleValue('loadingData', false);
                this.handleValue('validate', {});
                this.setState({ formVehicule: response });
            }).catch(error => {
                ApiError(error, "Atualização do Veículo").then(call => call === true ? this.updateVehicule() : "");
            }).then(() => {
                this.handleValue('loadingData', false);
            })
        this.handleValue('edit', false);
    }

    createVehicule(e) {
        e.preventDefault();
        if (this.validForm()) {
            this.handleValue('loadingData', true);
            var { vehicule, user } = this.state;
            var headers = GetHeader();
            axios.post(global.api_url + "Vehicules", vehicule, { headers })
                .then((response) => {
                    if (response.status === 200) { notification("Veículo", "Foi adicionado um veiculo ao utilizador " + user.firstName + ".", "success", null, "top-right", null, false); this.props.onChange(false); }
                    this.handleValue('edit', false);
                    this.handleValue('validate', {});
                }).catch(error => {
                    ApiError(error, "Criação do Veículo").then(call => call === true ? this.createVehicule(e) : "");
                }).then(() => {
                    this.handleValue('loadingData', false);
                })
        } else {
            notification("Veículo", "Campos em Falta ou mal preenchidos.", "danger", null, "top-right", null, false);
        }
    }
    cancelVehicule(e) {
        e.preventDefault();
        this.props.onChange(false);
    }

    edit(e) {
        e.preventDefault();
        this.handleValue('edit', true);
    }

    /*GET VALUES */



    componentDidMount() {
        this.GetTecDoc("getManufacturers", { articleCountry: "PT", country: "pt", lang: "pt", linkingTargetType: "P" }).then(response => {
            this.setState({ brandsList: response });
        });
    }


    GetTecDoc = async (functionName, parameters) => {
        return axios.get(global.api_url + "TecDoc/" + functionName, { params: parameters })
            .then((response) => {
                if (response.status === 200) {
                    switch (functionName) {
                        case 'getManufacturers':
                            var carBrands = JSON.parse(response.data.result.data)
                            return carBrands;
                        case 'getModelSeries':
                            var carModels = JSON.parse(response.data.result.data)
                            return carModels;
                        default:
                            return [];
                    }
                }
                return null;
            }).catch(error => {
                notification("TecDoc", error, "danger", null, "top-right", null, false)
                return [];
            })
    }

    GetPlate = async (plate) => {
        this.setState({ loadingData: true });
        return axios.get(`${global.api_url}TecDoc/GetByMatricula/${plate}`)
            .then((response) => {
                this.setState({ loadingData: false });
                if (response.status === 200) {
                    return response.data;
                }
                return null;
            }).catch(error => {
                notification("TecDoc", "Não foi possível obter os dados do veículo.", "success", null, "top-right", null, false)
                this.setState({ loadingData: false });
                return null;
            })
    }


    render() {
        const { edit, loadingData, currentUser, old_vehicule, brandsList } = this.state;
        const { id, brand, model, plate, year, kms, kmsYear } = this.state.vehicule;
        const { plateState, yearState, kmsState, kms_yearState } = this.state.validate;

        return (
            <>
                {currentUser !== null &&

                    <Container className="mt--7" fluid>
                        {Loading(loadingData)}
                        <Form className="form" onSubmit={(e) => this.submitForm(e)}>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <Row className="align-items-center">

                                                <Col xl="8">
                                                    {id === 0 ? (
                                                        <h3 className="mb-0">Adicionar Veiculo</h3>
                                                    ) : (<h3 className="mb-0">Editar veiculo</h3>)}
                                                </Col>
                                                <Col className="text-right" xl="4">
                                                    {id === 0 ? (
                                                        <>
                                                            <Button color="warning" size="sm" type="button" onClick={e => alert("implemntar")}><i className="fas fa-times"></i> Voltar</Button>
                                                            <Button color="success" size="sm" type="button" onClick={e => this.createVehicule(e)}><i className="far fa-save"></i> Criar</Button>
                                                        </>
                                                    )
                                                        : (!edit ?
                                                            (<>
                                                                <Button color="warning" size="sm" type="button" onClick={e => this.cancelVehicule(e)}><i className="fas fa-times"></i> Voltar</Button>
                                                                <Button color="success" size="sm" name="update" type="button" onClick={e => { this.edit(e); }} ><i className="far fa-edit"></i> Editar</Button>
                                                            </>)
                                                            : (
                                                                <>
                                                                    <Button color="info" name="update" type="button" size="sm" onClick={e => { this.updateVehicule(e); }} ><i className="far fa-save"></i> Atualizar</Button>
                                                                    <Button color="warning" name="update" type="button" size="sm" onClick={e => { this.setState({ vehicule: old_vehicule }); this.setState({ edit: false }); }} ><i className="fas fa-times"></i> Cancelar</Button>
                                                                </>
                                                            ))}

                                                </Col>

                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <div>
                                                <h6 className="heading-small text-muted mb-4">
                                                    Informações Oficina
                                                </h6>
                                                <div className="pl-lg-4">
                                                    <Row>
                                                        <Col lg="4">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-plate" >
                                                                    Matricula
                                                                </label>

                                                                <Input type="text" name="plate" id="plate"
                                                                    valid={plateState === "has-success"}
                                                                    invalid={plateState === "has-danger"}
                                                                    value={plate}
                                                                    onChange={(e) => { this.validatePlate(e); this.handleChangeWorkshop(e); }}
                                                                    disabled={!edit}
                                                                    //type="tel"
                                                                    //mask="**-**-**"
                                                                    maskChar=" "
                                                                    tag={InputMask}
                                                                />
                                                                <FormFeedback>
                                                                    Matricula inválida.
                                                                </FormFeedback>
                                                                <FormFeedback valid>
                                                                    Matricula válida.
                                                                </FormFeedback>

                                                            </FormGroup>
                                                        </Col>

                                                        <Col lg="4">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-brand" >
                                                                    Marca
                                                                </label>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    isLoading={this.state.loadingDataSelect}
                                                                    isClearable={true}
                                                                    isRtl={false}
                                                                    isSearchable={true}
                                                                    placeholder="Selecionar marca"
                                                                    options={brandsList}
                                                                    getOptionLabel={(option) => `${option.manuName}`}
                                                                    value={brandsList.filter(x => x?.manuName === brand)}
                                                                    isDisabled={!edit}
                                                                    onChange={(e) => {
                                                                        this.setState(state => ({ vehicule: { ...state.vehicule, brand: e } }))
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="4">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-model">
                                                                    Modelo
                                                                </label>

                                                                <Input type="text" name="model" id="model"
                                                                    //valid={modelState === "has-success"}
                                                                    //invalid={modelState === "has-danger"}
                                                                    value={model}
                                                                    onChange={(e) => { this.validateString(e); this.handleChangeWorkshop(e); }}
                                                                    disabled={!edit}
                                                                />
                                                                <FormFeedback>
                                                                    Modelo inválido.
                                                                </FormFeedback>
                                                                <FormFeedback valid>
                                                                    Modelo válido.
                                                                </FormFeedback>

                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="4">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-year">
                                                                    Ano
                                                                </label>
                                                                <Input type="number" name="year" id="year"
                                                                    valid={yearState === "has-success"}
                                                                    invalid={yearState === "has-danger"}
                                                                    value={year}
                                                                    onChange={(e) => { this.validateString(e); this.handleChangeWorkshop(e); }}
                                                                    disabled={!edit}
                                                                    //type="year"
                                                                    mask="9999"
                                                                    maskChar=" "
                                                                    tag={InputMask}
                                                                />
                                                                <FormFeedback>
                                                                    Ano inválido.
                                                                </FormFeedback>
                                                                <FormFeedback valid>
                                                                    Ano válido.
                                                                </FormFeedback>

                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="4">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-kms">
                                                                    Kms
                                                                </label>
                                                                <Input type="number" name="kms" id="kms"
                                                                    valid={kmsState === "has-success"}
                                                                    invalid={kmsState === "has-danger"}
                                                                    value={kms}
                                                                    onChange={(e) => { this.validateString(e); this.handleChangeWorkshop(e); }}
                                                                    disabled={!edit}
                                                                    //type="tel"
                                                                    //mask="999 999 999"
                                                                    maskChar=" "
                                                                    tag={InputMask}
                                                                />
                                                                <FormFeedback>
                                                                    Kms inválidos.
                                                                </FormFeedback>
                                                                <FormFeedback valid>
                                                                    Kms válidos.
                                                                </FormFeedback>



                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="4">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-kmsYear">
                                                                    Kms por ano
                                                                </label>

                                                                <Input type="number" name="kmsYear" id="kmsYear"
                                                                    valid={kms_yearState === "has-success"}
                                                                    invalid={kms_yearState === "has-danger"}
                                                                    value={kmsYear}
                                                                    onChange={(e) => { this.validateString(e); this.handleChangeWorkshop(e); }}
                                                                    disabled={!edit}
                                                                    //type="tel"
                                                                    //mask="99 999"
                                                                    //maskChar=" "
                                                                    tag={InputMask}
                                                                />
                                                                <FormFeedback>
                                                                    Kms inválidos.
                                                                </FormFeedback>
                                                                <FormFeedback valid>
                                                                    Kms válidos.
                                                                </FormFeedback>

                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                }
            </>
        );
    }
}

export default VehiculeForm;
