import React, { useState } from 'react';
import Header from '../../../../../components/Headers/Header';
import Language from '../../BackOffice/Language'
import TableData from '../Services/TableData'


const Index = (props) => {

    const [selectLanguage, setSelectLanguage] = useState(null);
    const onchange = (lng) => { setSelectLanguage(lng); }
    const onClick = () => { setSelectLanguage(null); }

    return (
        <>
            <Header />
            {selectLanguage === null ?
                <Language onChange={onchange} />
                : <TableData onClick={onClick} language={selectLanguage} page="RedService" />}
        </>
    );
}

export default Index;

