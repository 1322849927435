import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalHeader, ModalBody, Button, Row, Col, UncontrolledTooltip } from "reactstrap"
import Header from './Header'
import LineList from './LineList'
import axios from 'axios';
import Moment from 'moment';
import 'moment/locale/pt'
import { Loading, notification, Status, ApiError, GetHeader, RemoveNotifications, HaveErpConfig } from "../../utils/Utilities";

class ModalScheduleDocument extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            event: props.event,
            currentUser: props.currentUser,
            currentWorkshop: props.currentWorkshop,
            currentService: props?.currentService !== null && props?.currentService !== undefined ? props.currentService : null,
            load: false,
            loadDoc: false,
            header: "",
            lines: null,
            scheduleDocuments: "",
            filter_ProdutosAceites: (props.event.resources.workshop.status === Status.Aceite || props.event.resources.workshop.status === Status.Oficina || props.event.resources.workshop.status === Status.Entregue) ? true : false,
            lineEdited: false,
            erpConfigs: null,
            services: null,
        };
        this.loadHeader = this.loadHeader.bind(this);
        this.loadLines = this.loadLines.bind(this);
        this.getScheduleDocuments = this.getScheduleDocuments.bind(this);
    }

    handleValue = async (name, value) => { await this.setState({ [name]: value }); return value; }

    loadHeader = (header) => { this.handleValue('header', header); }

    loadLines = (linesUpdate, lineRemoved) => {

        this.setState({ lineEdited: true }, () => { this.setState({ lines: linesUpdate }); });


        if (lineRemoved !== undefined && lineRemoved !== null && lineRemoved?.id > 0) {
            this.deleteLineSugestion(lineRemoved?.headerId, lineRemoved?.id);
        }
    }

    componentDidMount() {

        this.getErpConfigs();
        this.getServiceList();
        this.signalRConnection(this.currentUser, this.currentWorkshop);
        this.getScheduleDocuments();
    }
    signalRConnection = (currentUser, currentWorkshop) => {
        if (global.connectionSignalR !== null && global.connectionSignalR !== undefined) {

            global.connectionSignalR.on("HaynesProResponse", (_botUser, scheduleId, docId) => {
                notification("HaynesPro", "Recebeu o orçamento do HaynesPro - DpAutomotive, faça refresh ao documento.", "info", null, "bottom-right", null, false)
                if (docId !== null && docId !== undefined) {
                    this.getDocument(docId);
                }
            });
        }
    }

    getScheduleDocuments = () => {
        this.setState({ lines: [], loadDoc: true }, () => {
            const { event, currentService } = this.state;
            var diary = currentService !== undefined && currentService !== null ? "?Diary=BUDGET" : "";
            var headers = GetHeader();
            axios.get(global.api_url + "Document/Schedule/" + event.id + diary, { headers })
                .then((response) => {

                    console.log(response);

                    if (response.status === 200) {
                        if (response.data.length > 0 && response.data !== null) {
                            this.setState({ header: response.data[0].header }, () => {
                                if (currentService !== undefined && currentService !== null) {
                                    var result = response.data[0].line.filter(d => d.serviceId === currentService.id)


                                    this.setState({ lines: result.length !== undefined ? result : [], scheduleDocuments: response.data }, () => {
                                        this.setState({ loadDoc: false });
                                    });
                                } else {
                                    var lines = /*filter_ProdutosAceites === true ? response.data[0].line.filter(line => line.accept === true) : */response.data[0].line;
                                    this.setState({ lines: lines, scheduleDocuments: response.data }, () => {
                                        this.setState({ loadDoc: false });
                                    });
                                }
                            });
                        } else {
                            this.handleValue('header', null);
                            this.handleValue('lines', null);
                            this.handleValue('scheduleDocuments', null);
                            this.handleValue('loadDoc', false);
                        }
                    } else {
                        this.handleValue('header', null);
                        this.handleValue('lines', null);
                        this.handleValue('scheduleDocuments', null);
                        this.handleValue('loadDoc', false);
                    }

                }).catch(error => {
                    ApiError(error, "Documento").then(call => call === true ? this.getScheduleDocuments() : "");
                })
        });
    }

    saveDocument = (e) => {
        this.handleValue('load', true);
        const { header, lines, event } = this.state;
        header.workShopId = event.resources.workshop.workShopId
        header.scheduleId = event.id;
        header.lines = lines;
        header.accountId = event.resources.client.accountId !== null && event.resources.client.accountId !== undefined ? event.resources.client.accountId : 0;
        header.diary = event.resources.workshop.status === Status.Aceite ? "WORKSHEET" : event.resources.workshop.status !== Status.Aceite ? "BUDGET" : "";
        header.year = +Moment().format('yyyy');

        var headers = GetHeader();
        axios.post(global.api_url + "Document", header, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Documento", "Documento gerado com sucesso!.", "success", null, "top-right", null, false);
                    this.handleValue("header", response.data.header);
                    this.handleValue("lines", response.data.line);
                }
                this.handleValue('load', false);
            }).catch(error => {
                ApiError(error, "Documento").then(call => call === true ? this.saveDocument(e) : "");
            }).then(() => { this.handleValue('load', false); })
    }

    saveSugestion = (e) => {
        const { header, lines, currentService } = this.state;
        var setLines = lines.filter(l => l.id < 0 || (l.serviceId === currentService?.id && l.accept === false));

        var addSugestion = { headerId: header?.id, ServiceId: currentService?.id, scheduleId: header?.scheduleId, lines: setLines }
        var headers = GetHeader();

        axios.post(global.api_url + "Document/Sugestion", addSugestion, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Linhas de sugestão", "Linhas adicionadas com sucesso!", "success", null, "top-right", null, false);
                    this.handleValue("header", response.data.header);
                    this.handleValue("lines", response.data.line);
                    this.handleValue("lineEdited", false);
                }
                this.handleValue('load', false);
            }).catch(error => {
                ApiError(error, "Documento").then(call => call === true ? this.saveSugestion(e) : "");
            }).then(() => { this.handleValue('load', false); })

    }


    deleteLineSugestion = (headerId, lineId) => {
        var headers = GetHeader();
        axios.post(global.api_url + "Document/Sugestion/Remove/" + headerId + "/" + lineId, null, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Linhas de sugestão", "Linha removida com sucesso!", "success", null, "top-right", null, false);
                    this.handleValue("header", response.data.header);
                    this.handleValue("lines", response.data.line);
                    this.handleValue("lineEdited", false);
                }
                this.handleValue('load', false);
            }).catch(error => {
                ApiError(error, "Documento").then(call => call === true ? this.saveSugestion(headerId, lineId) : "");
            }).then(() => { this.handleValue('load', false); })
    }


    sendSugestion = (e) => {
        this.handleValue('load', true);
        const { header, lines, currentService } = this.state;
        var headers = GetHeader();
        axios.post(global.api_url + "Notifications/Send/Sugestion/" + header?.scheduleId + "/" + (currentService !== null ? currentService.id : lines[0].serviceId) + "", null, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Notificação", "Sugestão enviada com sucesso!", "success", null, "top-right", null, false);
                }
                this.handleValue('load', false);
            }).catch(error => {
                ApiError(error, "Notificação").then(call => call === true ? this.sendSugestion(e) : "");
            }).then(() => { this.handleValue('load', false); })

    }

    updateDocument = () => {
        this.handleValue('load', true);
        const { header, lines } = this.state;
        header.lines = lines;
        var headers = GetHeader();
        axios.post(global.api_url + "Document/Update", header, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Documento", "Documento atualizado com sucesso!", "success", null, "top-right", null, false);
                    this.setState({ header: response.data.header, lines: response.data.line });
                    this.componentDidMount();
                }
                this.handleValue('lineEdited', false);
                this.handleValue('load', false);
            }).catch(error => {
                ApiError(error, "Documento").then(call => call === true ? this.updateDocument() : "");
            }).then(() => { this.handleValue('load', false); })
    }

    getServiceList = () => {
        const { currentUser, event } = this.state;

        if (event.id !== 0) {

            var headers = { 'Authorization': `Bearer ${currentUser.jwtToken}` };
            axios.get(global.api_url + "Services/" + event.id, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        this.handleValue('services', response.data);
                    }
                }).catch(error => {
                    if (error.response && error.response.status === 401) {
                        notification("Autenticação", "Login expirado.", "danger", null, "top-right", null, false);
                    }
                });
        } else {
            this.handleValue('services', []);
        }
    }

    getDocument = (id) => {
        this.handleValue('load', true);
        var headers = GetHeader();
        axios.get(global.api_url + "Document/" + id, { headers })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.length > 0) {
                        var doc = response.data[0];
                        if (this.state.currentService !== null) {
                            doc.line = doc?.line?.filter(x => x?.serviceId === this.state.currentService?.id);
                        }
                        this.setState({ header: null, lines: null }, () => {
                            this.setState({ header: doc.header, lines: doc.line }, () => {
                                RemoveNotifications();
                                notification("Documento RedService", doc?.header?.numDoc + " " + doc?.header?.diary + " " + doc.header?.year + (this.state?.currentService !== null ? " - Serviço: " + this.state?.currentService?.name : ""), "success", null, "top-right", null, false);
                            });
                        });
                    } else {
                        RemoveNotifications();
                        notification("Documento RedService", "Não foi possivel obter o documento!", "warning", null, "top-right", null, false);
                    }
                }
                this.handleValue('load', false);
            }).catch(error => {
                ApiError(error, "Documento").then(call => call === true ? this.getDocument(id) : "");
            }).then(() => { this.handleValue('load', false); })
    }

    onChangeDoc = (e) => { this.handleValue('filter_ProdutosAceites', e.target.checked).then(() => { this.getScheduleDocuments(); }); }

    //ERP
    getErpConfigs = () => {
        var headers = GetHeader();
        axios.get(global.api_url + "WorkshopConfigs", { headers })
            .then((response) => {
                if (response.status === 200) {
                    var data = response.data;
                    if (data?.erpData !== undefined) {
                        data.erpData = JSON.parse(data?.erpData);
                    } else {
                        data.erpData = null;
                    }

                    this.setState({ erpConfigs: data });
                } else {
                    notification("Erp", "Não foi possivel obter os dados do ERP.", "warning", null, "top-right", null, false);
                }
                this.handleValue('load', false);
            }).catch(error => {
                ApiError(error, "Erp").then(call => call === true ? this.getErpConfigs() : "");
            }).then(() => { this.handleValue('load', false); })
    }

    MakeDocErp = (e) => {
        const { erpConfigs } = this.state;
        if (erpConfigs?.erpName !== undefined && erpConfigs?.erpName !== "") {
            switch (erpConfigs.erpName) {
                case "MasterWay":
                    this.buildMasterWayErp();
                    break;
                default:
                    notification("Erp", erpConfigs.erpName + " precisa de ser configurado no sistema.", "warning", null, "top-right", null, false);
                    break;
            }
        } else {
            notification("Erp", "Não possui um ERP configurado.", "warning", null, "top-right", null, false);
        }
    }


    buildMasterWayErp = (e) => {
        const { header, lines, erpConfigs } = this.state;
        this.setState({ load: true });
        var comercialDoc = {
            doctype: header?.diary === "WORKSHEET" ? "ENC_V" : header?.diary === "BUDGET" ? "ORC_V" : "",
            reference: "",
            companyCode: erpConfigs?.erpData?.credentials?.CompanyCode !== undefined ? erpConfigs?.erpData?.credentials?.CompanyCode : "",
            serie: erpConfigs?.erpData?.credentials?.Serie !== undefined ? erpConfigs?.erpData?.credentials?.Serie : "",
            entityCode: header?.nif !== undefined && header?.nif !== "" && header?.nif !== "999999999" ? "E" : "CF",
            entityName: header?.name !== undefined && header?.name !== "" ? header?.name : "Consumidor Final",
            entityTypeCode: "CN",
            taxID: header?.nif !== undefined && header?.nif !== "" ? header?.nif : "",
            country: erpConfigs?.erpData?.credentials?.TaxRegion !== undefined ? erpConfigs?.erpData?.credentials?.TaxRegion : "",
            classification: "E",
            vatType: erpConfigs?.erpData?.credentials?.TaxRegion !== undefined ? erpConfigs?.erpData?.credentials?.TaxRegion : "",
            addressDetail: header?.adress !== undefined ? header?.adress : "",
            city: header?.city !== undefined && header?.city !== "" ? header?.city : "Lisboa",
            postalCode: header?.postalCode !== undefined ? header?.postalCode : "",
            contactName: "",
            phone: "",
            fax: "",
            email: "",
            productLine: []
        }

        lines.forEach(line => {
            if ((line?.referenceErp === null || line?.referenceErp === undefined || line?.referenceErp === "") && (line?.accept === true)) {
                comercialDoc.productLine.push(
                    {
                        productCode: line?.prodRef,
                        productType: "P",
                        productInventoryType: "A",
                        productDescription: line?.prodDescription,
                        quantity: line?.prodQtd.toString(),
                        unitPrice: line?.prodPrice.toString(),
                        discount: line?.prodDiscount.toString(),
                        taxCode: "NOR",
                        taxRegion: erpConfigs?.erpData?.credentials?.TaxRegion !== undefined ? erpConfigs?.erpData?.credentials?.TaxRegion : "",
                        exemptionReasonCode: ""
                    }
                );
            }
        })

        if (comercialDoc.productLine.length === 0) { notification("Linhas", "Não tem linhas aprovadas", "warning", null, "top-right", null, false); }

        var headers = GetHeader();
        axios.post(global.api_url + "ERP/MWComercialDocsAsync", comercialDoc, { headers })
            .then((response) => {
                if (response.status === 200) {
                    var data = response.data;
                    if (data?.reference !== undefined) {
                        notification("ERP", "Foi gerado um novo documento com a referência " + data?.reference + " .", "success", null, "top-right", null, false);
                        var auxLines = []
                        lines.forEach(line => {
                            if (line.referenceErp === undefined || line.referenceErp === "") {
                                line.referenceErp = data?.reference;
                                auxLines.push(line);
                            }
                        });

                        this.setState({ lines: auxLines }, () => {
                            var ref = header?.reference?.length !== undefined && header?.reference?.length >= 0 ? header?.reference : [];
                            if (typeof (ref) === "string") { ref = JSON.parse(ref); }
                            ref.push(data?.reference);
                            var gen = header?.generated?.length !== undefined && header?.generated?.length >= 0 ? header?.generated : [];
                            if (typeof (gen) === "string") { gen = JSON.parse(gen); }

                            gen.push(JSON.stringify(data));
                            this.setState(state => ({ header: { ...state.header, reference: JSON.stringify(ref)/*data?.reference*/, generated: JSON.stringify(gen) } }), () => { this.updateDocument(); });
                        });
                    } else { notification("ERP", "Não foi possível criar o documento.", "warning", null, "top-right", null, false); }
                } else { notification("ERP", "Não foi possível criar o documento.", "warning", null, "top-right", null, false); }
            }).catch(error => {
                ApiError(error, "Documento").then(call => call === true ? this.buildMasterWayErp() : "");
            }).then(() => { this.handleValue('load', false); })
    }


    MakePDFErp = (e) => {
        const { erpConfigs } = this.state;
        if (erpConfigs?.erpName !== undefined && erpConfigs?.erpName !== "") {
            switch (erpConfigs.erpName) {
                case "MasterWay":
                    this.buildPDFMasterWayErp();
                    break;
                default:
                    notification("Erp", erpConfigs.erpName + " precisa de ser configurado no sistema.", "warning", null, "top-right", null, false);
                    break;
            }
        } else { notification("Erp", "Não possui um ERP configurado.", "warning", null, "top-right", null, false); }
    }


    buildPDFMasterWayErp = () => {
        const { header } = this.state;
        this.setState({ load: true });
        if (header?.generated !== undefined) {
            var data = JSON.parse(header?.generated);
            var document = null;
            if (Array.isArray(data)) {
                data = JSON.parse(data);
                document = {
                    companyCode: data.companyCode,
                    documentType: data.documentType,
                    serie: data.serie,
                    documentNumber: data.documentNumber,
                    copies: "1"
                }
            } else {
                document = {
                    companyCode: data.companyCode,
                    documentType: data.documentType,
                    serie: data.serie,
                    documentNumber: data.documentNumber,
                    copies: "1"
                }
            }

            var headers = GetHeader();
            axios.post(global.api_url + "ERP/MWGetDocBinaryPdf", document, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        var data = response.data;
                        notification("ERP", "Foi iniciada a descarga de um ficheiro. ", "success", null, "top-right", null, false);
                        this.openBase64NewTab(data.binary)
                    } else {
                        notification("ERP", "Não foi possível obter o documento. Se o erro persistir, use o ERP.", "warning", null, "top-right", null, false);
                    }
                }).catch(error => {
                    ApiError(error, "Documento").then(call => call === true ? this.buildPDFMasterWayErp() : "");
                }).then(() => { this.handleValue('load', false); })
        } else {
            notification("Documento", "Existiu um erro a obter o documento gerado.", "warning", null, "top-right", null, false);
        }
    }


    //MakeRSPdf = (headerId) => {
    //    this.setState({ load: true });
    //    var headers = GetHeader();
    //    axios.get(global.api_url + "Document/MakeRSPdf/" + headerId, { headers })
    //        .then((response) => {
    //            if (response.status === 200) {
    //                var data = response.data;
    //                notification("RS PDF", "Foi iniciada a descarga de um ficheiro. ", "success", null, "top-right", null, false);
    //                this.openBase64NewTab(data.binary)
    //            } else {
    //                notification("RS PDF", "Não foi possível obter o documento.", "warning", null, "top-right", null, false);
    //            }
    //            this.setState({ load: false });
    //        }).catch(error => {
    //            ApiError(error, "Documento").then(call => call === true ? this.buildPDFMasterWayErp() : "");
    //        }).then(() => { this.handleValue('load', false); })
    //}


    MakeRSPdf = (headerId, diary = "") => {
        this.setState({ load: true });
        var headers = GetHeader();
        axios.get(global.api_url + "Document/MakeRSPdf/" + headerId + (diary !== "" ? "?diary=" + diary : ""), { headers })
            .then((response) => {
                if (response.status === 200) {
                    var data = response.data;
                    notification("RS PDF", "Foi iniciada a descarga de um ficheiro. ", "success", null, "top-right", null, false);
                    this.openBase64NewTab(data.binary)
                } else {
                    notification("RS PDF", "Não foi possível obter o documento.", "warning", null, "top-right", null, false);
                }
                this.setState({ load: false });
            }).catch(error => {
                ApiError(error, "Documento").then(call => call === true ? this.buildPDFMasterWayErp() : "");
            }).then(() => { this.handleValue('load', false); })
    }

    openBase64NewTab = (base64Pdf) => {
        var blob = this.base64toBlob(base64Pdf);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
        } else {
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl);
        }
    }


    base64toBlob = (base64Data) => {
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: "application/pdf" });
    }

    buildWorksheet = () => {
        this.state.services?.forEach(serv => { this.toWorksheet(serv.id); });
        this.getScheduleDocuments();
    }




    toWorksheet = (serviceId) => {
        var headers = { 'Authorization': `Bearer ${this.state.currentUser.jwtToken}` };
        axios.post(global.api_url + "Document/ToWorSheet", { serviceId: serviceId }, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Documento", "Folha de Obra gerada com sucesso.", "success", null, "top-right", null, false);
                    this.componentDidMount();
                }
            }).catch(error => {
                if (error.response && error.response.status === 401) {
                    notification("Autenticação", "Login expirado.", "danger", null, "top-right", null, false);
                }
            })
    }


    render() {
        const { event, currentUser, header, lines, load, loadDoc, currentService, lineEdited, erpConfigs, services } = this.state;

        const documentName = header !== null && header?.id !== 0 ? " - " + (header?.numDoc !== undefined ? header?.numDoc : "") + " " + (header?.diary !== undefined ? header?.diary : "") + " " + (header?.year !== undefined ? header?.year : "") :
            event.resources.workshop.status === Status.Pendente ? " - Orçamento" : event.resources.workshop.status === Status.Aceite || event.resources.workshop.status === Status.Oficina ? " - Folha de Obra" : " - Novo";
        var docERP = "";

        if (header?.generated !== undefined) {
            var aux = JSON.parse(header.generated);
            if (Array.isArray(aux)) {
                aux = JSON.parse(aux);
                docERP = "- " + aux.documentNumber + " " + aux.documentType + " " + aux.serie
            } else {
                docERP = "- " + aux.documentNumber + " " + aux.documentType + " " + aux.serie
            }
        }

        return (
            <Modal className="modal-dialog-centered Modal-Mobile-90"
                contentClassName="bg-RS-grey-modal"
                isOpen={true}
                size="xl"
            //style={{ minWidth: '90vw' }}
            >
                <ModalHeader >
                    {Loading(load)}
                    <Row style={{ marginLeft: 10 }}>
                        <h4 className="mb-0">Documento Redservice {documentName}</h4>
                        <div style={{ paddingLeft: 20 }}>
                            {currentService === null ?
                                <>
                                    <Button disabled={header?.origin ? false : true} color="success" size="sm" onClick={(e) => { this.getDocument(header.origin) }}><i className="fa fa-backward"></i></Button>
                                    <Button color="success" size="sm" onClick={(e) => { this.getDocument(header?.numDoc + "_" + header?.diary + "_" + header?.year + "_" + + header?.workShopId) }}><i className="fa fa-sync"></i></Button>
                                    <Button disabled={header?.destin ? false : true} color="success" size="sm" onClick={(e) => { this.getDocument(header.destin) }}><i className="fa fa-forward"></i></Button>
                                    {header?.destin !== "" && (event.resources.workshop.status === Status.Pendente || event.resources.workshop.status === Status.Rejeitado) && <Button color="warning" size="sm" onClick={(e) => { }}><i className="fas fa-file"></i> Novo orçamento </Button>}
                                </>
                                : <>
                                    <Button color="success" size="sm" onClick={(e) => { this.getDocument(header?.numDoc + "_" + header?.diary + "_" + header?.year + "_" + + header?.workShopId) }}><i className="fa fa-sync"></i></Button>
                                </>
                            }
                        </div>
                    </Row>
                    <Row style={{ marginLeft: 10 }}>
                        {erpConfigs !== null && header?.generated !== undefined && <h4 className="mb-0"><i className="fas fa-file-pdf"></i> {erpConfigs.erpName + " " + docERP}</h4>}
                    </Row>

                </ModalHeader>
                <ModalBody >
                    {header !== "" && header !== null && <Header event={event} currentUser={currentUser} onChange={this.loadHeader} header={header} />}


                    {lines !== null && loadDoc === false ? <LineList erpConfigs={erpConfigs} onChange={this.loadLines} event={event} currentUser={currentUser} services={services} currentService={currentService} lines={lines} currentHeader={header} /> : Loading(loadDoc)}


                    <Row style={{ paddingTop: 20 }}>
                        <Col lg="6">

                            {this.state.header?.generated !== undefined && this.state.header?.generated !== "{}" && this.state.header?.generated !== null ? <>
                                <Button size="sm" color="info" onClick={(e) => { e.preventDefault(); this.MakePDFErp(e); }}><i className="fas fa-file-pdf"></i> Visualizar PDF</Button>
                            </>
                                : <>
                                    {header?.diary === "BUDGET" ?
                                        <>
                                            {HaveErpConfig(erpConfigs)
                                                && lines !== null && lines?.filter(l => l.accept === true).length > 0
                                                && <Button size="sm" color="danger" onClick={(e) => this.MakeDocErp(e)}><i className="fas fa-file-pdf"></i> Gerar Orçamento ERP</Button>}

                                        </>
                                        :
                                        ((lineEdited === false) || (header?.diary === "WORKSHEET"
                                            && lines.length > 0 && lines.filter(l => l.id > 0).length > 0
                                            && lines.filter(l => l.id <= 0).length === 0
                                            && (event.resources.workshop.status === Status.Oficina || event.resources.workshop.status === Status.Concluido || event.resources.workshop.status === Status.Entregue)
                                            && header?.id > 0))
                                        && HaveErpConfig(erpConfigs)

                                        && <Button color="danger" size="sm" onClick={(e) => { this.MakeDocErp(e) }}><i className="fas fa-file-invoice"></i> Emitir Folha de Obra ERP</Button>
                                    }
                                </>
                            }

                            {header?.diary === "BUDGET" &&
                                (event.resources.workshop.status === Status.Oficina || event.resources.workshop.status === Status.Concluido)
                                && (header?.destin === null || header?.destin === "" || header?.destin === undefined) &&
                                <Button color="warning" size="sm" onClick={(e) => { this.buildWorksheet() }}><i className="fas fa-file-invoice"></i> Gerar folha de obra</Button>
                            }

                            <Button id="btn_pdf_file" size="sm" color={header?.diary === "BUDGET" ? "info" : "warning"} onClick={(e) => { this.MakeRSPdf(header?.id) }}><i className="far fa-file-pdf"></i> {header?.diary === "BUDGET" ? "Orçamento" : "Folha de obra"}</Button>
                            <UncontrolledTooltip delay={0} placement="top" target="btn_pdf_file">
                                Obter pdf {header?.diary === "BUDGET" ? "do orçamento do cliente." : "da folha de obra"}
                            </UncontrolledTooltip>

                            {header?.diary === "BUDGET" && <>
                                <Button id="btn_pdf_file1" size="sm" color={"warning"} onClick={(e) => { this.MakeRSPdf(header?.id, "WORKSHEET") }}><i className="far fa-file-pdf"></i> {"Folha de obra"}</Button>
                                <UncontrolledTooltip delay={0} placement="top" target="btn_pdf_file1">
                                    Obter pdf do Orçamanto para efetuar a recepçãp do veículo.
                                </UncontrolledTooltip>
                            </>
                            }

                        </Col>
                        <Col lg="6" className="text-right">
                            {(event.resources.workshop.status === Status.Pendente || event.resources.workshop.status === Status.Rejeitado) && header?.id === 0 && <Button color="success" size="sm" onClick={(e) => { this.saveDocument(e); }}><i className="fas fa-save"></i> Guardar </Button>}
                            {(
                                (lineEdited === false) &&
                                (header?.diary === "BUDGET" &&
                                    lines.length > 0 && lines.filter(l => l.id > 0).length > 0 && lines.filter(l => l.id <= 0).length === 0 &&
                                    (event.resources.workshop.status === Status.Oficina) && header?.id > 0 && currentService !== null)
                            ) &&
                                <Button color="success" size="sm" onClick={(e) => { this.sendSugestion(e); }}><i className="fas fa-paper-plane"></i> Enviar sugestão </Button>
                            }
                            {((lineEdited === true) || (header?.diary === "BUDGET" &&
                                lines.length > 0 && lines.filter(l => l.id < 0).length > 0 &&
                                (event.resources.workshop.status === Status.Oficina) && header?.id > 0 && currentService !== null)) &&
                                <Button color="warning" size="sm" onClick={(e) => { e.preventDefault(); this.updateDocument() }}><i className="fas fa-wrench"></i> Atualizar</Button>
                            }

                            {((header?.diary === "BUDGET" &&
                                lines.length > 0 && lines.filter(l => l.id < 0).length > 0 &&
                                (event.resources.workshop.status === Status.Oficina) && header?.id < 0 && currentService !== null)) &&
                                <Button color="warning" size="sm" onClick={(e) => { this.saveSugestion(e) }}><i className="fas fa-wrench"></i> Guardar</Button>
                            }
                            <Button size="sm" onClick={(e) => this.props.onClick(false)}><i className="far fa-window-close"></i> Fechar </Button>
                        </Col>

                    </Row>
                </ModalBody>
            </Modal >
        )
    }
}

export default ModalScheduleDocument;