import React from 'react';
import { Card, Button, CardBody, CardTitle, CardText, FormGroup, Form, Input, FormFeedback, Row, Col } from "reactstrap";
import { ApiError, GetHeader, Loading, notification } from "../utils/Utilities";
import axios from 'axios';

class LeirilisConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            currentWorkShopConfigs: props.currentWorkShopConfigs,
            edit: false,
            loadingData: false,
            validate: {
                emailState: '',
                passwordState: ''
            },
            emailLogin: props.currentWorkShopConfigs?.emailLogin !== undefined ? JSON.parse(props.currentWorkShopConfigs?.emailLogin) : {
                //Url: "https://webmail.redservice.pt/mondo/lang/sys/login.aspx?LanguageID=EN&UserID=noreply@redservice.pt&Password=RedService2022Leirilis&Method=Auto&skin=default&offset=-600",
                WorkshopId: props.currentWorkShopConfigs?.workshopId,
                Email: "",
                Password: ""
            }
        }
    }


    validateString(e) {
        const { validate } = this.state;
        if (e.target.value !== null && e.target.value !== "") {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState(({ validate }));
    }

    handleValue = async (name, value) => { await this.setState({ [name]: value }); return value; }
    handleCurrentWorkShopConfigs = (e) => {
        this.setState(state => ({ emailLogin: { ...state.emailLogin, [e.target.name]: e.target.value } }));
    }
    changeEdit = (state) => { this.handleValue('edit', state); }


    saveConfig = () => {
        const { emailLogin } = this.state;

        this.handleValue('loadingData', true);
        var headers = GetHeader();
        axios.post(global.api_url + "WorkshopConfigs/ChangeEmail", emailLogin, { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.handleValue('currentWorkShopConfigs', response.data);
                    notification("Email RedService", "Dados atualizados com sucesso!", "success", null, "top-right", null, false);
                }
                this.cleanValidate();
                this.handleValue('loadingData', false);
            }).catch(error => {
                this.handleValue('loadingData', false);
                ApiError(error, "Configurações").then(call => call === true ? this.saveConfig() : "");
            })

    }

    cleanValidate = () => {
        this.setState({ validate: { webSiteLeirilisState: '', userLeirilisState: '', pwLeirilisState: '' } })
    }

    render() {
        const { edit, validate, loadingData, emailLogin } = this.state;

        return (
            <Card style={{ height: '100%' }}>
                {Loading(loadingData)}
                <CardBody>
                    <CardTitle tag="h3">
                        Acesso ao Email RedService
                    </CardTitle>
                    <CardText>
                        <Form>

                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="input-userLeirilis" >
                                            Email
                                        </label>
                                        <Input type="text" name="Email" id="Email"
                                            valid={validate.emailState === "has-success"}
                                            invalid={validate.emailState === "has-danger"}
                                            value={emailLogin.Email}
                                            onChange={(e) => { this.validateString(e); this.handleCurrentWorkShopConfigs(e); }}
                                            disabled={!edit}
                                            required
                                        />
                                        <FormFeedback>
                                            Utilizador inválido.
                                        </FormFeedback>
                                        <FormFeedback valid>
                                            Utilizador válido.
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="input-pwLeirilis" >
                                            Password
                                        </label>
                                        <Input type="password" name="Password" id="Password"
                                            valid={validate.passwordState === "has-success"}
                                            invalid={validate.passwordState === "has-danger"}
                                            value={emailLogin.Password}
                                            onChange={(e) => { this.validateString(e); this.handleCurrentWorkShopConfigs(e); }}
                                            disabled={!edit}
                                            required
                                        />
                                        <FormFeedback>
                                            Password inválido.
                                        </FormFeedback>
                                        <FormFeedback valid>
                                            Password válido.
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Form>

                    </CardText>
                    <div className="text-right">
                        {!edit && <Button color="danger" size="sm" onClick={(e) => { e.preventDefault(); this.handleValue('edit', true) }}><i className="far fa-edit"></i> Editar</Button>}
                        {/*{edit && <Button color="success" size="sm" onClick={(e) => { e.preventDefault(); this.handleValue('edit', false) }}><i className="fas fa-link"></i> Testar conecção</Button>}*/}
                        {edit && <Button color="success" size="sm" onClick={(e) => { e.preventDefault(); this.handleValue('edit', false); this.saveConfig() }}><i className="far fa-save"></i> Guardar</Button>}
                        {edit && <Button color="primary" size="sm" onClick={(e) => { e.preventDefault(); this.handleValue('edit', false) }}><i className="far fa-window-close"></i> Fechar</Button>}

                    </div>
                </CardBody>
            </Card>
        );
    }
}


export default LeirilisConfig;