import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalHeader, ModalBody, Row, Card, Table, Button, Col, InputGroup, Input, CardFooter } from "reactstrap"
import CustomPagination from "views/backoffice/utils/CustomPagination";
import 'moment/locale/pt'
import { Loading, GetHeader, notification, ApiError } from "../../utils/Utilities";
import ProductLine from './ProductLine'
import axios from 'axios';

class ModalErpProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allErpReferences: [],
            pagination: null,
            currentPage: 1,
            search: "",
            loading: false,
            defaultPagesize: 15
        };
    }
    selectedProduct = (product) => { this.props.selectedProduct(product); }
    componentDidMount = () => { this.getAllReferences(this.state.currentPage, this.state.defaultPagesize, this.state.search); }

    getAllReferences = (pageNumber, pageSize, search) => {
        this.setState({ loading: true });
        this.setState({ allErpReferences: [] });
        var headers = GetHeader();
        var params = { search: search, pageNumber: pageNumber, pageSize: pageSize };
        axios.get(global.api_url + "ERP/MWGetCmProduto", { params: params, headers })
            .then((response) => {
                this.setState({ loading: false });
                if (response.status === 200) {
                    var prod = response.data;
                    if (response.headers["x-pagination"] !== undefined) { this.setState({ pagination: JSON.parse(response.headers["x-pagination"]) }); }
                    this.setState({ allErpReferences: prod });
                } else {
                    notification("Referência", "Não foi possível encontrar a referência " + search + " .", "warning", null, "top-right", null, false);
                }
            }).catch(error => {
                ApiError(error, "Procurar referência").then(call => call === true ? this.getAllReferences(pageNumber, pageSize, search) : "");
                this.setState({ loading: false });
            }).then(() => { })
    }



    searchProduct = (search, force) => {
        this.setState({ search: search });
        //if (search.length > 2 || search.length === 0 || force === true) {
        this.getAllReferences(1, this.state.defaultPagesize, search)
        //}
    }

    changeHandler = (pageNumber, pagesize) => {
        this.setState({ currentPage: pageNumber });
        this.getAllReferences(pageNumber, this.state.defaultPagesize, this.state.search)
    }

    render() {
        const { load, loading, allErpReferences, search, pagination } = this.state;
        return (
            <Modal className="modal-dialog-centered"
                contentClassName="bg-RS-grey-modal"
                isOpen={true}
                size="xl"
                style={{ maxWidth: '85vw' }}>
                <ModalHeader >
                    {Loading(load)}
                    <Row style={{ marginLeft: 10 }}>
                        <h4 className="mb-0">Produtos MasterWay</h4>
                    </Row>
                </ModalHeader>
                <ModalBody >
                    <Row>
                        <Col className="text-right" xl="12">
                            <InputGroup>
                                <Input type="text" name="seacrh" id="input-seacrh"
                                    bsSize="sm"
                                    value={search}
                                    placeholder="Pesquisar referência"
                                    onChange={(e) => { this.setState({ search: e.target.value }) }}
                                    disabled={false}
                                    onKeyDown={(e) => { if (e.key === 'Enter') { this.setState({ search: e.target.value }); this.searchProduct(e.target.value, true); } }}
                                />
                                <Button size="sm" color="success" id="btn_search" onClick={(e) => {
                                    this.searchProduct(search, true);
                                }}><i className="fas fa-search"></i></Button>

                            </InputGroup>
                        </Col>
                    </Row>


                    <Card className="shadow" style={{ minHeight: '500px', height: '450px' }}>
                        <Table className="align-items-center table-flush" responsive striped size="sm">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Codigo Produto</th>
                                    <th scope="col">Designação</th>
                                    <th scope="col">Preço de Venda (€)</th>
                                    <th scope="col">Código Iva (%)</th>
                                    <th scope="col" className="text-right">

                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{ height: '100%' }} >
                                {allErpReferences.map((product, key) => {
                                    return <ProductLine key={key} product={product} selectedProduct={this.selectedProduct} />
                                })}
                                {Loading(loading)}
                            </tbody>
                        </Table>
                    </Card >
                    <CardFooter className="py-4">
                        {pagination !== null && allErpReferences.length > 0 ? (
                            <CustomPagination pagination={pagination} array={allErpReferences} onChange={this.changeHandler} />) : null}
                    </CardFooter>


                    <div className="text-right" style={{ marginTop: 20 }}>
                        <Button color="primary" size="sm" onClick={(e) => { e.preventDefault(); this.props.closeModalErpProducts(); }}><i className="fas fa-times"></i> Fechar</Button>
                    </div>
                </ModalBody>
            </Modal >
        )
    }
}

export default ModalErpProducts;