import React from 'react';
import { Card, Table } from "reactstrap";
import { Loading, GetHeader, notification, ApiError } from "../utils/Utilities";
import axios from 'axios';
import VehiculesList from '../Vehicles/VehiculesList';



class Vehicules extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            currentUser: props.currentUser,
            loadingData: true,
            vehicules: []
        };
    }

    componentDidMount() {
        this.getVehicules(this.state.user);
    }

    getVehicules(user) {
        this.handleValue('loadingData', true);
        var headers = GetHeader();
        axios.get(global.api_url + "Vehicules/Account/" + user.id, { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.handleValue('vehicules', response.data);
                } else { notification("Veiculos", "Erro a obter os veiculos do utilizador.", "warning", null, "top-right", null, false); }
                this.handleValue('loadingData', false);
            }).catch(error => {
                this.handleValue('loadingData', false);
                ApiError(error, "Ve�culos").then(call => call === true ? this.getVehicules(user) : "");
            })
    }

    handleValue = (name, value) => { this.setState({ [name]: value }); }

    onEdit(e) { this.props.onChange(e); }

    loadVehicule = () => {
        this.getVehicules(this.state.user)
    }

    render() {
        const { vehicules, loadingData } = this.state;

        return (
            <Card className="shadow">
                {loadingData && Loading(loadingData, 10)}
                {vehicules.length > 0 &&
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Marca</th>
                                <th scope="col">Modelo</th>
                                <th scope="col">Ano</th>
                                <th scope="col">Matricula</th>
                                <th scope="col">Kms</th>
                                <th scope="col" />
                            </tr>
                        </thead>
                        <tbody>
                            {vehicules.map((vehicule, key) => { return <VehiculesList key={key} vehicule={vehicule} onChange={(e) => { this.onEdit(e) }} onLoad={(e) => this.loadVehicule()} /> })}
                        </tbody>
                    </Table>
                }
            </Card>
        )
    }
}


export default Vehicules;