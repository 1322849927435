import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { GetHeader, ApiError, Loading } from "../utils/Utilities";
import { Modal, ModalBody, Col, Row, Button, UncontrolledTooltip } from "reactstrap"
import axios from 'axios';
import ScheduleForm from '../Schedules/ScheduleForm';


class ModalScheduleInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            event: props.event,
            currentUser: props.currentUser,
            currentWorkshop: props.currentWorkshop,
            workshopAccounts: props.workshopAccounts,
            loading: false
        };
    }

    getSchedule = (scheduleId) => {
        this.setState({ loading: true });
        var headers = GetHeader();
        axios.get(global.api_url + "Schedules/Grid/" + scheduleId, { headers })
            .then((response) => {
                if (response.status === 200) {
                    const schedule = response.data;
                    this.setState({ event: schedule });
                }
                this.setState({ loading: false });
            }).catch(error => {
                ApiError(error, "Utilizador").then(call => call === true ? this.getSchedule(scheduleId) : "");
                this.setState({ loading: false });
            })
    }



    validateModal = (loadData, eventData, toRemoveId, toLoadId, refreshEventId) => {
        this.props.onChange(loadData, eventData, toRemoveId, toLoadId, refreshEventId);
    }

    render() {
        const { event, currentUser, currentWorkshop, workshopAccounts, loading } = this.state;

        return (
            <Modal className="modal-dialog-centered Modal-Mobile" contentClassName="bg-RS-grey-modal" isOpen={true} size="xl" /*style={{ minWidth: '85vw' }}*/>
                <Row style={{ paddingTop: 20, paddingLeft: 30, paddingRight: 30 }} lg="12">
                    <Col lg="6">
                        {/*<div style={{ fontWeight: 'bold' }}>{event.id === 0 ? ("Novo agendamento") : (event.title)}</div>*/}
                        <h4 className="mb-0">{event?.id === 0 ? ("Novo agendamento") : (event.title)}</h4>
                    </Col>
                    <Col lg="6" className="text-right" >
                        {event?.id !== 0 &&
                            <>
                                <Button id="btn_refresh_schedule" size="sm" color="success" onClick={(e) => {
                                    this.getSchedule(event?.id);
                                }}><i className="fas fa-sync"></i></Button>
                                <UncontrolledTooltip delay={0} placement="top" target="btn_refresh_schedule">
                                    Atualizar agendamento
                                </UncontrolledTooltip>
                            </>}
                    </Col>
                </Row>
                <ModalBody className="">
                    {loading === false ?
                        <ScheduleForm formEvent={event} currentWorkshop={currentWorkshop} currentUser={currentUser} workshopAccounts={workshopAccounts} onChange={this.validateModal} />
                        : Loading(true)}
                </ModalBody>
            </Modal>
        )
    }
}

export default ModalScheduleInfo;