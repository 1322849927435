import React, { useState } from 'react';
import { CurrentUser } from "views/backoffice/utils/Utilities";
import { Button, Row, Col, Container, CardHeader, Card } from "reactstrap";
import Header from '../../../components/Headers/Header';

import ServiceTypes from '../ServiceTypes/ServiceTypes'
import ServiceTypesForm from '../ServiceTypes/ServiceTypesForm'



const Index = (props) => {
    const currentUser = CurrentUser();
    const [form, setForm] = useState([]);
    const openForm = (serviceType) => { setForm(serviceType); }
    const closeForm = (e) => { setForm([]); }

    return (
        <>
            <Header />
            <Container className="mt--7" fluid>
                {form.id === undefined ? (<>
                    <Row >
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <Col>
                                            <h6 className="heading-small text-muted mb-4">Serviços</h6>
                                        </Col>
                                        <Col className="text-right" xl="4">
                                            {/*{reOrder === false && <Button color="info" name="createVehicule" type="button" size="sm" onClick={e => { setReorder(true); }} ><i className="fas fa-arrows-alt-v"></i> Reordenar</Button>}*/}
                                            {/*{reOrder === true && <Button color="success" name="createVehicule" type="button" size="sm" onClick={e => { saveOrder() }} ><i className="fas fa-save"></i> Guardar ordem</Button>}*/}
                                            <Button color="success" name="createVehicule" type="button" size="sm" onClick={e => { openForm({ id: 0 }); }} ><i className="fas fa-plus-circle"></i> Adicionar Serviço</Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <div>
                                    <ServiceTypes currentUser={currentUser} onChange={(serviceType) => openForm(serviceType)} />

                                </div>
                            </Card>
                        </div>
                    </Row>
                </>) : (<>
                    <ServiceTypesForm formServiceType={form} currentUser={currentUser} onChange={(e) => closeForm(e)} />
                </>)}
            </Container>
        </>);
}

export default Index;


