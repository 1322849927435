import React from 'react';
import { FormGroup, Row, Col, Container, CardHeader, Card, CardBody, Button } from "reactstrap";
import axios from 'axios';
import { notification, GetHeader, ApiError } from '../utils/Utilities';

import Select from 'react-select';

class Schedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            workshops: [],
            selectedWorkshops: [],
            loadingData: false
        };
    }
    componentDidMount() { this.getAllWorkshops(); }
    handleValue = (name, value) => { this.setState({ [name]: value }); }
    onSelect(selected) { this.handleValue("selectedWorkshops", selected) }

    selectWorkshop() {
        if (this.state.selectedWorkshops !== null && this.state.selectedWorkshops !== undefined && this.state.selectedWorkshops.length !== 0) {
            this.props.onChange(this.state.selectedWorkshops);
        } else { notification("Oficina", "Precisa de selecionar uma oficina!", "warning", null, "top-right", null, false); }
    }

    getAllWorkshops() {
        this.handleValue('loadingData', true);
        var headers = GetHeader();
        axios.get(global.api_url + "Workshops?Pagination=" + false, { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.handleValue('workshops', response.data);
                }
                this.handleValue('loadingData', false);
            }).catch(error => {
                this.handleValue('loadingData', false);
                ApiError(error, "Oficinas").then(call => call === true ? this.getAllWorkshops() : "");
            })
    }
    render() {

        const { workshops } = this.state;
        return (
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col>
                                        <h3 className="mb-0">Selecionar Oficina</h3>
                                    </Col>
                                    <Col className="text-right" xl="4">
                                        <Button color="success" size="sm" onClick={(e) => { this.selectWorkshop() }}><i className="fas fa-warehouse"></i>{' '} Obter agendamento</Button>
                                    </Col>

                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-defaultWorkshop">
                                                    Oficina

                                                </label >
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    isLoading={this.state.loadingData}
                                                    isClearable={true}
                                                    isRtl={false}
                                                    isSearchable={true}
                                                    placeholder="Selecionar oficina"
                                                    options={workshops}
                                                    getOptionLabel={(option) => `${option.name}`}
                                                    onChange={(e) => this.onSelect(e)}
                                                />

                                            </FormGroup>
                                        </Col>
                                        <Col>

                                        </Col>
                                    </Row>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Schedule;

