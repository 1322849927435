import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { StatusService, notification, StatusLine, ApiError, GetHeader, Loading, HaveErpConfig, Status } from "../../utils/Utilities";
import { Input, UncontrolledTooltip, Button, Badge, InputGroup } from "reactstrap"
import Select from 'react-select';
import axios from 'axios';
import ModalErpProducts from './../ErpProducts/ModalErpProducts';
import confirm from "reactstrap-confirm";

class Line extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            event: props.event,
            currentUser: props.currentUser,
            currentService: props.currentService !== null && props.currentService !== undefined ? props.currentService : null,
            currentHeader: props.currentHeader !== null && props.currentHeader !== undefined ? props.currentHeader : null,
            loading: false,
            edit: props.line?.id < 0 ? true : false,
            line: props.line,
            services: props.services,
            erpConfigs: props.erpConfigs,
            validate: {
                serviceIdState: '',
                prodRefState: '',
                refTecDocState: '',
                prodDescriptionState: '',
                prodQtdState: '',
                prodPriceState: '',
                prodIva: '',
                prodDiscount: '',
                totalLine: '',
            },
            showAllErpReferences: false,
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.currentUser !== state.currentUser) {
            return { currentUser: props.currentUser };
        }
        return null;
    }

    componentDidMount() { this.recalculeLine(); }

    handleValue = (name, value) => { this.setState({ [name]: value }); }
    handleValueLine = (name, value) => { this.setState(state => ({ line: { ...state.line, [name]: value } })); }

    handleChangeLine = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        if (name === '' || name === 'prodQtd' || name === 'prodPrice' || name === 'prodIva' || name === 'prodDiscount' || name === 'totalLine') {
            this.setState(state => ({ line: { ...state.line, [name]: +value } }), () => { this.recalculeLine(); });
        } else {
            this.setState(state => ({ line: { ...state.line, [name]: value } }), () => { this.recalculeLine(); });
        }
    }

    validateString(e) {
        const { validate } = this.state;
        if (e.target.value !== null && e.target.value !== "") {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState(({ validate }));
    }

    recalculeLine = () => {
        const { line } = this.state;
        let price = ((+line.prodQtd * (+line.prodPrice)) * (100 - (+line.prodDiscount))) / 100
        let iva = (price * (+line.prodIva)) / 100;
        this.handleValueLine('totalIva', +iva.toFixed(2));
        this.handleValueLine('totalLine', (price + iva).toFixed(3));
    }

    removeLine = (e) => {
        const { line } = this.state;
        let result = confirm({
            title: (<><strong>Eliminar linha de produto</strong></>),
            message: (<>
                <h4>Linha:</h4>
                <h5>•Quanridade: {line?.prodQtd}</h5>
                <h5>•Referência: {line?.prodRef}</h5>
                <h5>•Descrição: {line?.prodDescription}</h5>
                <br></br>
                <h4>Deseja eliminar?</h4>
            </>),
            confirmText: "Sim",
            confirmColor: "success",
            cancelText: "Não",
            cancelColor: "danger"
        });
        result.then(response => { if (response) { this.props.removeLine(line); } })
    }

    checkLine = (line) => {
        var errors = [];

        if (line.prodPrice < 0) {
            errors.push(" - Preço tem de ser igual ou superior a 0.");
        }
        if (line.prodQtd < 0) {
            errors.push(" - Quantidade tem de ser igual ou superior a 0.");
        }
        if (line.prodDiscount < 0 && line.prodDiscount > 100) {
            errors.push(" - Desconto tem de estar entre 0 e 100%.");
        }

        if (line.prodIva <= 0 && line.prodIva >= 23) {
            errors.push(" - Iva tem de estar entre 0 e 23%.");
        }
        if (line.prodRef === "" || line.prodRef === null || line.prodRef === undefined) {
            errors.push(" - É necessária uma referência.");
        }
        if (line.prodDescription === "" || line.prodDescription === null || line.prodDescription === undefined) {
            errors.push(" - É necessária uma descrição.");
        }

        if (errors.length > 0) {
            errors.forEach(error => notification("Linha", error, "warning", null, "top-right", null, false))
        }
        return errors.length === 0 ? true : false;
    }

    linetoWorksheet = (line) => {
        var headers = GetHeader();
        axios.post(global.api_url + "Document/lineToWorSheet", line, { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ line: response.data });
                    notification("Documento", "Foi adicionada a linha á folha de obra.", "success", null, "top-right", null, false);
                }
            }).catch(error => {
                ApiError(error, "Documento").then(call => call === true ? this.linetoWorksheet(line) : "");
            })
    }

    validateLine = (line) => { line.accept = true; }


    searchReference = (reference) => {

        if (reference !== "" && reference !== null) {

            this.setState({ loading: true });
            var headers = GetHeader();
            var params = reference !== "" && reference !== null ? { codProduto: reference } : {};
            axios.get(global.api_url + "ERP/MWGetCmProduto", { params: params, headers })
                .then((response) => {
                    this.setState({ loading: false });
                    if (response.status === 200) {
                        var prod = response.data;
                        if (prod?.length === 1) {
                            prod = prod[0];
                            this.setState(state => ({ line: { ...state.line, prodDescription: prod?.produtoDesignacao, prodIva: prod?.codIva !== undefined ? prod?.codIva : 0, prodPrice: prod?.precoVenda !== undefined ? prod?.precoVenda : 0 } }), () => { this.recalculeLine(); });
                            notification("Referência", "Foi encontrada a referência " + reference + " .", "success", null, "top-right", null, false);
                        } else {
                            this.setState({ showAllErpReferences: true });
                        }
                    } else {
                        notification("Referência", "Não foi possível encontrar a referência " + reference + " .", "warning", null, "top-right", null, false);
                    }
                }).catch(error => {

                    console.log(error)

                    ApiError(error, "Procurar referência").then(call => call === true ? this.searchReference(reference) : "");
                    this.setState({ loading: false });
                }).then(() => { })
        } else {
            this.setState({ showAllErpReferences: true });
        }
    }

    selectedProduct = (product) => {
        this.setState({ showAllErpReferences: false });
        if (product !== undefined && product !== null) {
            this.setState(state => ({ line: { ...state.line, prodRef: product?.codProduto, prodDescription: product?.produtoDesignacao, prodIva: product?.codIva !== undefined ? product?.codIva : 0, prodPrice: product?.precoVenda !== undefined ? product?.precoVenda : 0 } }), () => { this.recalculeLine(); });
        }
    }

    closeModalErpProducts = () => {
        this.setState({ showAllErpReferences: false });
    }

    onClickLine = (event) => {
        var { line, currentHeader } = this.state;

        if (event.detail === 2) {

            if ((!line.accept && currentHeader?.diary === "BUDGET") || (currentHeader?.diary === "WORKSHEET" && [undefined, "", null].includes(currentHeader?.generated))) {
                //if (!line.accept && (currentHeader?.diary === "BUDGET" || currentHeader?.diary === "WORKSHEET") && [undefined, "", null].includes(currentHeader?.generated)) {
                this.handleValue('edit', true);
            } else {
                notification("Documento", "Este documento não pode ser editado.", "warning", null, "top-right", null, false);
            }
        }
    }

    render() {
        const { line, validate, edit, services, currentHeader, currentService, erpConfigs, showAllErpReferences, loading, event } = this.state;
        const changeStatus = (line) => {
            switch (line.status) {
                case StatusLine.Rejeitado:
                    if (line.id > 0) {
                        return (<>
                            <i className="bg-danger" id="bagde-danger123" style={{ 'zoom': 3 }} />
                            < UncontrolledTooltip delay={0} placement="top" target="bagde-danger123" >
                                Recusado
                            </UncontrolledTooltip >
                        </>)
                    } else {
                        return (<>
                            <i className="bg-danger" id="bagde-valid" style={{ 'zoom': 3 }} />
                            < UncontrolledTooltip delay={0} placement="top" target="bagde-valid" >
                                Em Edição
                            </UncontrolledTooltip >
                        </>)
                    }

                case StatusLine.Aceite:
                    return (<>
                        <i className="bg-success" id="bagde-aceite" style={{ 'zoom': 3 }} />
                        < UncontrolledTooltip delay={0} placement="top" target="bagde-aceite" >
                            Aceite
                        </UncontrolledTooltip >
                    </>)
                case StatusLine.Pendente:
                    return (<>
                        <i className="bg-warning" id="bagde-pendente" style={{ 'zoom': 3 }} />
                        < UncontrolledTooltip delay={0} placement="top" target="bagde-pendente" >
                            Pendente
                        </UncontrolledTooltip >
                    </>)
                default:
                    return (<>
                        <i className="bg-danger" id="bagde-mount-er" style={{ 'zoom': 3 }} />
                        < UncontrolledTooltip delay={0} placement="top" target="bagde-mount-er" >
                            Erro - Sem estado da linha
                        </UncontrolledTooltip >
                    </>)
            }
        }

        return (
            <>
                {showAllErpReferences === true && <ModalErpProducts selectedProduct={this.selectedProduct} closeModalErpProducts={this.closeModalErpProducts} />}

                {Loading(loading)}

                <tr onClick={this.onClickLine}>
                    <td style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center'
                    }}>
                        <Badge color="" className="badge-dot" placement="bottom">
                            {changeStatus(line)}
                        </Badge>
                    </td>
                    <td style={{
                        textAlign: 'center',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap'
                    }} >
                        {line?.referenceErp !== undefined && line?.referenceErp !== null && line?.referenceErp !== "" ? <>
                            <i id="erp_suc" className="fas fa-2x fa-file-pdf"></i>
                            <UncontrolledTooltip delay={0} placement="top" target="erp_suc" >
                                {line?.referenceErp}
                            </UncontrolledTooltip >
                        </> : <>
                            <i className="fas fa-exclamation" id="erp_warn" style={{ color: '#fa3a0e' }}></i>
                            <UncontrolledTooltip delay={0} placement="top" target="erp_warn" >
                                Linha não exportada para ERP
                            </UncontrolledTooltip >
                        </>}
                    </td>
                    <td style={{
                        textAlign: 'left',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap'
                    }} >
                        {!edit || currentService !== null ?
                            line.serviceId === 0 ? "" :/* line.serviceId + " " +*/ line.serviceName :
                            <>
                                <Select
                                    value={services.filter(function (option) { return option.id === line.serviceId; })}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isLoading={services.lenght > 0 ? true : false}
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={false}
                                    placeholder="Serviço"
                                    options={services.filter(op => op.statusService !== StatusService.Cancelado)}
                                    getOptionLabel={(option) => `${option.name}`}
                                    onChange={(selected) => {
                                        this.handleValueLine("serviceId", selected !== null ? selected.id : 0);
                                        this.handleValueLine("serviceName", selected !== null && selected !== undefined ? selected.name : "")
                                    }}
                                />
                            </>
                        }
                    </td>
                    <td style={{
                        textAlign: 'left',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap'
                    }} >
                        {!edit ?
                            line.prodRef :
                            <InputGroup>
                                <Input type="text" name="prodRef" id="prodRef"
                                    valid={validate.prodRefState === "has-success"}
                                    invalid={validate.prodRefState === "has-danger"}
                                    value={line.prodRef}
                                    onChange={(e) => { this.validateString(e); this.handleChangeLine(e); }}
                                    disabled={!edit}
                                />
                                {HaveErpConfig(erpConfigs) === true && <>
                                    <Button size="sm" color="info" id="btn_searchReference" disabled={!edit} onClick={(e) => {
                                        this.searchReference(line.prodRef);
                                    }}><i className="fas fa-search"></i></Button>

                                    <UncontrolledTooltip delay={0} placement="top" target="btn_searchReference">
                                        Procurar referência no ERP
                                    </UncontrolledTooltip>
                                </>}
                            </InputGroup>
                        }
                    </td>
                    <td style={{
                        textAlign: 'left',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap'
                    }} >
                        {!edit ?
                            line.refTecDoc :
                            <Input type="text" name="refTecDoc" id="refTecDoc"
                                valid={validate.refTecDocState === "has-success"}
                                invalid={validate.refTecDocState === "has-danger"}
                                value={line.refTecDoc}
                                onChange={(e) => { this.validateString(e); this.handleChangeLine(e); }}
                                disabled={!edit}
                            />
                        }

                    </td>
                    <td style={{
                        textAlign: 'left',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap'
                    }} >
                        {!edit ?
                            line.prodDescription :
                            <Input type="text" name="prodDescription" id="prodDescription"
                                valid={validate.prodDescriptionState === "has-success"}
                                invalid={validate.prodDescriptionState === "has-danger"}
                                value={line.prodDescription}
                                onChange={(e) => { this.validateString(e); this.handleChangeLine(e); }}
                                disabled={!edit}
                            />
                        }
                    </td>
                    <td /*style={{ minWidth: 150 }}*/>
                        {!edit ?
                            line.prodQtd?.toFixed(2) :
                            <Input type="number" name="prodQtd" id="prodQtd"
                                valid={validate.prodQtdState === "has-success"}
                                invalid={validate.prodQtdState === "has-danger"}
                                value={line.prodQtd}
                                min={1}
                                onChange={(e) => { this.validateString(e); this.handleChangeLine(e); }}
                                disabled={!edit}
                            />
                        }
                    </td>
                    <td /*style={{ minWidth: 200 }}*/>
                        {!edit ?
                            line.prodPrice?.toFixed(2) :
                            <Input type="number" name="prodPrice" id="prodPrice"
                                valid={validate.prodPriceState === "has-success"}
                                invalid={validate.prodPriceState === "has-danger"}
                                value={line.prodPrice}
                                min={0}
                                onChange={(e) => { this.validateString(e); this.handleChangeLine(e); }}
                                disabled={!edit}
                            />
                        }
                    </td>
                    <td /*style={{ minWidth: 150 }}*/>
                        {!edit ?
                            line.prodIva?.toFixed(2) :
                            <Input type="number" name="prodIva" id="prodIva"
                                valid={validate.prodIvaState === "has-success"}
                                invalid={validate.prodIvaState === "has-danger"}
                                value={line.prodIva}
                                min={0}
                                onChange={(e) => { this.validateString(e); this.handleChangeLine(e); }}
                                disabled={!edit}
                            />
                        }
                    </td>
                    <td /*style={{ minWidth: 150 }}*/>
                        {!edit ?
                            line.prodDiscount?.toFixed(2) :
                            <Input type="number" name="prodDiscount" id="prodDiscount"
                                valid={validate.prodDiscountState === "has-success"}
                                invalid={validate.prodDiscountState === "has-danger"}
                                value={line.prodDiscount}
                                min={0}
                                onChange={(e) => {
                                    this.validateString(e); this.handleChangeLine(e);
                                }}
                                disabled={!edit}
                            />
                        }
                    </td>
                    <td /*style={{ minWidth: 175 }}*/>
                        {!edit ?
                            line.totalLine :
                            <Input type="number" name="totalLine" id="totalLine"
                                valid={validate.totalLineState === "has-success"}
                                invalid={validate.totalLineState === "has-danger"}
                                value={line.totalLine}
                                onChange={(e) => { this.handleChangeLine(e); }}
                                disabled={true}
                            />
                        }
                    </td>
                    <td className="text-right">
                        {/*{currentHeader?.diary === "BUDGET" &&*/}
                        {/*    (event.resources.workshop.status === Status.Oficina || event.resources.workshop.status === Status.Concluido || event.resources.workshop.status === Status.Oficina)*/}
                        {/*    && (line.destinId == null || line.destinId === 0) &&*/}
                        {/*    <>*/}
                        {/*        < Button id="btn_to_Worksheet" size="sm" color="primary" onClick={(e) => { if (this.checkLine(line)) { this.linetoWorksheet(line); } }}><i className="fas fa-file-export"></i></Button>*/}
                        {/*        <UncontrolledTooltip delay={0} placement="top" target="btn_to_Worksheet">*/}
                        {/*            Adicionar na folha de obra*/}
                        {/*        </UncontrolledTooltip>*/}
                        {/*    </>*/}
                        {/*}*/}
                        {(currentHeader?.diary === "BUDGET" && (event.resources.workshop.status === Status.Oficina || event.resources.workshop.status === Status.Concluido || event.resources.workshop.status === Status.Oficina) && (line.destinId == null || line.destinId === 0)) && <>

                            <Button id="btn_to_Worksheet" size="sm" color="primary" onClick={(e) => { if (this.checkLine(line)) { this.linetoWorksheet(line); } }}><i className="fas fa-file-export"></i></Button>
                            <UncontrolledTooltip delay={0} placement="top" target="btn_to_Worksheet">
                                Adicionar na folha de obra
                            </UncontrolledTooltip>
                        </>
                        }


                        {/*((!line.accept && currentHeader?.diary === "BUDGET") || currentHeader?.diary === "WORKSHEET" && [undefined, "", null].includes(currentHeader?.generated))*/
                            ((!line.accept && currentHeader?.diary === "BUDGET") || (currentHeader?.diary === "WORKSHEET" && [undefined, "", null].includes(currentHeader?.generated)))
                            &&
                            <>
                                {edit ?
                                    <>
                                        < Button id="btn_finish_Line1" size="sm" color="success" onClick={(e) => {
                                            if (this.checkLine(line)) {
                                                this.handleValue('edit', false);
                                                if (line?.status === StatusLine.Rejeitado) { line.status = StatusLine.Pendente }
                                                this.props.onClick(line);
                                            }
                                        }}><i className="fas fa-check"></i></Button>

                                    </> :
                                    <>
                                        <Button id="btn_edit_Line" size="sm" color="info" onClick={(e) => { this.handleValue('edit', true); }}><i className="fas fa-edit"></i></Button>
                                        <UncontrolledTooltip delay={0} placement="top" target="btn_edit_Line">
                                            Editar linha
                                        </UncontrolledTooltip>
                                    </>
                                }
                                <Button id="btn_remove_Line" size="sm" color="danger" onClick={(e) => { this.removeLine(e) }}><i className="fas fa-trash"></i></Button>
                                <UncontrolledTooltip delay={0} placement="top" target="btn_remove_Line">
                                    Remover linha
                                </UncontrolledTooltip>

                            </>
                        }
                    </td >
                </tr >
            </>
        )
    }
}

export default Line;