import React from 'react';
import { Container, Card, CardHeader, Row, Col } from "reactstrap";
import Select from 'react-select';
//import BackOffice from '../Text/Backoffice'

class Language extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            loading: false,
            language: [{ "name": "PT", "value": "pt" }],
            selectLanguage: null
        }
        this.back = this.back.bind(this);

    }
    onSelect = (e) => {
        this.setState({ selectLanguage: e });
    }

    back = () => {
        this.state.props.onChange(this.state.selectLanguage);
    }

    onClick = (e) => {
        this.setState({ selectLanguage: null })
    }

    componentDidMount() {
        if (this.state.language?.length === 1) {
            this.setState({ selectLanguage: this.state.language[0] })
        }
    }

    render() {
        const { language, loading } = this.state;

        const set = (e) => {
            this.props.onChange(e);
        }

        return (
            /* selectLanguage === null ?*/
            <Container className="mt--7" fluid>
                <div className="col">
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="heading-small text-muted mb-4">Idioma</h6>
                                </Col>
                                <Col className="text-right" xl="4">
                                </Col>
                            </Row>
                        </CardHeader>
                        <div style={{ padding: 20 }}>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isLoading={loading}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                placeholder="Selecionar idioma"
                                options={language}
                                getOptionLabel={(option) => `${option.name}`}
                                onChange={(e) => { this.onSelect(e); set(e); }}
                            />
                        </div>
                    </Card>
                </div>
            </Container>
            /* : <BackOffice language={selectLanguage} onClick={this.onClick} />*/

        );
    }
}


export default Language;