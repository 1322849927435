import React from 'react';
import { Card, Button, CardBody, CardTitle, CardSubtitle, CardText, FormGroup, Form, Input, FormFeedback, Row, Col } from "reactstrap";
import { ApiError, GetHeader, notification, Loading } from "../utils/Utilities";
import axios from 'axios';
import Select from 'react-select';

class ERPConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            currentWorkShopConfigs: props.currentWorkShopConfigs,
            old_currentWorkShopConfigs: props.currentWorkShopConfigs,
            edit: false,
            validate: {},
            loadingData: false,
            envoiceSystems: [{ name: "MasterWay" }],
            taxRegionList: [{ Code: "PT", Description: "PT: Portugal Continental" }, { Code: "PT-AC", Description: "PT-AC: Região Autónoma Açores" }, { Code: "PT-MA", Description: "PT-MA: Região Autónoma da Madeira" }],
            erp: {
                erpName: 'MasterWay',
                erpData: {
                    credentials: {
                        backendUrl: "https://app.masterway.net/?action=apisoap&wsdl",
                        APIKey: "",
                        APISecret: "",
                        CompanyCode: "1",
                        Serie: "A",
                        TaxRegion: "PT"
                    }
                }
            }
        }
    }

    componentDidMount() {
        const { currentWorkShopConfigs } = this.state;
        this.setState(state => ({ erp: { ...state.erp, erpName: currentWorkShopConfigs?.erpName } }));
        if (currentWorkShopConfigs.erpData !== undefined && currentWorkShopConfigs?.erpData !== null) {
            var data = JSON.parse(currentWorkShopConfigs?.erpData);
            if (data !== null && data !== undefined) {
                this.setState(state => ({ erp: { ...state.erp, erpData: data } }));
            }
        } else {
            this.setState(state => ({ erp: { ...state.erp, erpData: "" } }));
        }
    }

    validateString(e) {
        const { validate } = this.state;
        if (e.target.value !== null && e.target.value !== "") {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }

        this.setState(({ validate }));
    }

    cleanValidate = () => { this.setState({ validate: { eRPUrlState: '', userErpState: '', pwErpState: '' } }); }

    handleValue = async (name, value) => { await this.setState({ [name]: value }); return value; }

    handleCurrentWorkShopConfigs = async (name, value) => { await this.setState(state => ({ currentWorkShopConfigs: { ...state.currentWorkShopConfigs, [name]: value } })); }

    changeEdit = (state) => { this.handleValue('edit', state); }

    saveConfig = () => {
        const { currentWorkShopConfigs, erp } = this.state;
        if (this.validate() === true) {
            this.handleValue('loadingData', true);
            erp.erpData.credentials.backendUrl = erp.erpData.credentials?.backendUrl === undefined ? "https://app.masterway.net/?action=apisoap&wsdl" : erp.erpData.credentials.backendUrl;
            erp.erpData.credentials.CompanyCode = erp.erpData.credentials?.CompanyCode === undefined ? "1" : erp.erpData.credentials.CompanyCode;
            erp.erpData.credentials.Serie = erp.erpData.credentials?.Serie === undefined ? "A" : erp.erpData.credentials.Serie;
            var json = JSON.stringify(erp.erpData);
            this.handleCurrentWorkShopConfigs("erpData", json.toString()).then(() => {
                currentWorkShopConfigs.erpData = json.toString();
                var headers = GetHeader();
                axios.post(global.api_url + "WorkshopConfigs/Change", currentWorkShopConfigs, { headers })
                    .then((response) => {
                        if (response.status === 200) {
                            this.handleValue('currentWorkShopConfigs', response.data);
                            notification("Portal Leirilis", "Dados atualizados com sucesso!", "success", null, "top-right", null, false);
                        }
                        this.setState({ edit: false })
                        this.cleanValidate();
                        this.handleValue('loadingData', false);
                    }).catch(error => {
                        this.handleValue('loadingData', false);
                        ApiError(error, "Configurações").then(call => call === true ? this.saveConfig() : "");
                    })

            })
        }
    }

    validate = () => {
        var error = "";
        var { validate } = this.state;
        Object.entries(this.state.erp.erpData.credentials).forEach(data => {
            if (data[1] === "") {
                if (data[0] === "APIKey" || data[0] === "APISecret") {
                    validate[data[0]] = '';
                    this.setState({ validate });
                } else {
                    error += data[0] + ", ";
                    validate[data[0]] = 'has-danger';
                    this.setState({ validate });
                }

            } else {
                validate[data[0]] = '';
                this.setState({ validate });
            }
        });
        return error === "" ? true : false;
    }


    render() {
        const { edit, taxRegionList, validate, envoiceSystems, loadingData } = this.state;
        const { erpName, erpData } = this.state.erp;


        return (
            <Card>
                {Loading(loadingData)}
                <CardBody>
                    <CardTitle tag="h3">
                        Sistema de Faturação
                    </CardTitle>
                    <CardSubtitle className="mb-2 text-muted" tag="h4">
                        Integração com sistema:

                        <Select
                            valid={validate.erpname !== undefined ? validate.erpname === "has-success" : ""}
                            invalid={validate.erpname !== undefined ? validate.erpname === "has-danger" : ""}
                            className="basic-single"
                            classNamePrefix="select"
                            isLoading={loadingData}
                            value={envoiceSystems.forEach(x => { if (x.name.startsWith(erpName)) { return x; } })}
                            isClearable={true}
                            isDisabled={!edit}
                            isRtl={false}
                            isSearchable={true}
                            placeholder={"Selecionar sistema de faturação"}
                            options={envoiceSystems}
                            getOptionLabel={(option) => `${option.name}`}
                            onChange={(e) => { this.setState({ erpName: e.name }) }}
                        />
                    </CardSubtitle>
                    {erpName === 'MasterWay' &&
                        <CardText>
                            <Form>


                                <Row>
                                    <Col lg="12">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-BackendUrl" >
                                                BackendUrl
                                            </label>
                                            <Input type="text" name="BackendUrl" id="BackendUrl"
                                                value={erpData.credentials?.backendUrl}
                                                valid={validate.backendUrl !== undefined ? validate.backendUrl === "has-success" : false}
                                                invalid={validate.backendUrl !== undefined ? validate.backendUrl === "has-danger" : false}
                                                onChange={(e) => { this.setState(state => ({ erp: { ...state.erp, erpData: { ...state.erp.erpData, credentials: { ...state.erp.erpData.credentials, backendUrl: e.target.value } } } })); }}
                                                disabled={!edit}
                                                required
                                            />
                                            <FormFeedback>
                                                BackendUrl inválido.
                                            </FormFeedback>
                                            <FormFeedback valid>
                                                BackendUrl válido.
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-CompanyCode" >
                                                CompanyCode
                                            </label>
                                            <Input type="text" name="CompanyCode" id="CompanyCode"
                                                valid={validate.CompanyCode !== undefined ? validate.CompanyCode === "has-success" : false}
                                                invalid={validate.CompanyCode !== undefined ? validate.CompanyCode === "has-danger" : false}
                                                value={erpData.credentials?.CompanyCode}
                                                onChange={(e) => { this.setState(state => ({ erp: { ...state.erp, erpData: { ...state.erp.erpData, credentials: { ...state.erp.erpData.credentials, CompanyCode: e.target.value } } } })); }}
                                                disabled={!edit}
                                                required
                                            />
                                            <FormFeedback>
                                                CompanyCode inválido.
                                            </FormFeedback>
                                            <FormFeedback valid>
                                                CompanyCode válido.
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-Serie" >
                                                Serie
                                            </label>
                                            <Input type="text" name="Serie" id="Serie"
                                                value={erpData.credentials?.Serie}
                                                valid={validate.Serie !== undefined ? validate.Serie === "has-success" : false}
                                                invalid={validate.Serie !== undefined ? validate.Serie === "has-danger" : false}
                                                onChange={(e) => { this.setState(state => ({ erp: { ...state.erp, erpData: { ...state.erp.erpData, credentials: { ...state.erp.erpData.credentials, Serie: e.target.value } } } })); }}
                                                disabled={!edit}
                                                required
                                            />
                                            <FormFeedback>
                                                Serie inválida.
                                            </FormFeedback>
                                            <FormFeedback valid>
                                                Serie válida.
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-TaxRegion" >
                                                TaxRegion
                                            </label>

                                            <Select
                                                valid={validate.TaxRegion !== undefined ? validate.TaxRegion === "has-success" : false}
                                                invalid={validate.TaxRegion !== undefined ? validate.TaxRegion === "has-danger" : false}
                                                className="basic-single"
                                                classNamePrefix="select"
                                                isLoading={this.state.loadingData}
                                                value={taxRegionList.forEach(x => { if (x.Code === erpData.credentials?.TaxRegion) { return x; } })}
                                                isClearable={true}
                                                isDisabled={!edit}
                                                isRtl={false}
                                                isSearchable={true}
                                                placeholder={"Região de IVA"}
                                                options={taxRegionList}
                                                getOptionLabel={(option) => `${option.Description}`}
                                                onChange={(e) => { this.setState(state => ({ erp: { ...state.erp, erpData: { ...state.erp.erpData, credentials: { ...state.erp.erpData.credentials, TaxRegion: e.Code } } } })); }}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-eRPUrl" >
                                                APIKey
                                            </label>
                                            <Input type="text" name="APIKey" id="APIKey"
                                                valid={validate.APIKey !== undefined ? validate.APIKey === "has-success" : false}
                                                invalid={validate.APIKey !== undefined ? validate.APIKey === "has-danger" : false}
                                                value={erpData.credentials?.APIKey}
                                                onChange={(e) => { this.setState(state => ({ erp: { ...state.erp, erpData: { ...state.erp.erpData, credentials: { ...state.erp.erpData.credentials, APIKey: e.target.value } } } })); }}
                                                disabled={!edit}
                                                required
                                            />
                                            <FormFeedback>
                                                APIKey inválido.
                                            </FormFeedback>
                                            <FormFeedback valid>
                                                APIKey válido.
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-pwErp" >
                                                APISecret
                                            </label>
                                            <Input type="password" name="APISecret" id="APISecret"
                                                value={erpData.credentials?.APISecret}
                                                valid={validate.APISecret !== undefined ? validate.APISecret === "has-success" : false}
                                                invalid={validate.APISecret !== undefined ? validate.APISecret === "has-danger" : false}
                                                onChange={(e) => { this.setState(state => ({ erp: { ...state.erp, erpData: { ...state.erp.erpData, credentials: { ...state.erp.erpData.credentials, APISecret: e.target.value } } } })); }}
                                                disabled={!edit}
                                                required
                                            />
                                            <FormFeedback>
                                                APISecret inválido.
                                            </FormFeedback>
                                            <FormFeedback valid>
                                                APISecret válido.
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>

                        </CardText>
                    }

                    <div className="text-right">
                        {!edit && <Button color="danger" size="sm" onClick={(e) => { e.preventDefault(); this.handleValue('edit', true) }}><i className="far fa-edit"></i> Editar</Button>}
                        {/*{edit && <Button color="success" size="sm" onClick={(e) => { e.preventDefault(); this.handleValue('edit', false) }}><i className="fas fa-link"></i> Testar conecção</Button>}*/}
                        {edit && <Button color="success" size="sm" onClick={(e) => { e.preventDefault(); this.saveConfig() }}><i className="far fa-save"></i> Guardar</Button>}
                        {edit && <Button color="primary" size="sm" onClick={(e) => { e.preventDefault(); this.handleValue('edit', false); this.cleanValidate(); }}><i className="far fa-window-close"></i> Fechar</Button>}
                    </div>
                </CardBody>
            </Card>
        );
    }
}


export default ERPConfig;