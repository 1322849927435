import React from 'react';
//import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import { createStore } from 'redux';
import accountApp from './store/accounts/accounts';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import App from './App/App'
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import "./assets/css/custom.css";
import 'react-awesome-query-builder/lib/css/styles.css';


//variaveis globais
global.versionInfo = "RedService - Build 03012024";
global.api_url = window.location.href.startsWith("http://localhost") ? "http://api.redservice.pt/" : "https://api.redservice.pt/";
global.portal_Leirilis = window.location.href.startsWith("http://localhost") ? "https://www.dpautomotive.pt/" : "https://www.dpautomotive.pt/";
global.geoapify = "https://api.geoapify.com/v1/geocode/reverse";
global.geoapifyKEY = "9614fd0558664e18b62135be41ab82fb";
global.currentUser = null;
global.connectionSignalR = null;
global.masterWayLogin = "https://app.masterway.net/?action=login&AutKey="
const store = createStore(accountApp);

// After
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.Fragment>
        <Provider store={store}>
            <ReactNotifications />
            <App />
        </Provider>
    </React.Fragment>,
);





