import React from 'react';
import { FormGroup, Form, Input, FormFeedback, Row, Col, Card, CardBody, CardTitle, CardSubtitle, CardText, Button } from "reactstrap";
import axios from 'axios';
import { Loading, notification, GetHeader, ApiError } from "../../utils/Utilities";
import Moment from 'moment';
import 'moment/locale/pt'
Moment.locale('pt');


class AddBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validate: {
                titleState: "",
                descriptionState: "",
                startState: "",
                removeState: "",
                imageState: ""
            },
            form: {
                title: "",
                description: "",
                start: Moment(new Date()).format('YYYY-MM-DD'),
                remove: "",
                image: "",
            },
            loadingData: false,
            edit: false
        }
    }

    handleBase64 = (data) => {
        this.setState(state => ({ form: { ...state.form, image: data } }));
    }

    handleValue = (name, value) => { this.setState({ [name]: value }) }

    saveBanner = (banner) => {
        if (this.check(banner) === true) {
            var headers = GetHeader();
            axios.post(global.api_url + "Banners", banner, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        notification("Banner", "Banner adicionado com sucesso.", "success", null, "top-right", null, false);
                        this.props.onChange("CLOSE")
                    }
                }).catch(error => {
                    ApiError(error, "Adicionar Banner").then(call => call === true ? this.saveBanner(banner) : "");
                }).then(() => {
                    this.handleValue('loadingData', false);
                })
        }
    }

    check = (banner) => {
        var { validate } = this.state;
        var valid = true;
        Object.entries(banner).forEach(data => {
            if ((data[0] === "start" || data[0] === "remove" || data[0] === "image") && (data[1] === "" || data[1] === null)) {
                validate[data[0] + 'State'] = 'has-danger';
                this.setState({ validate });
                valid = false;
            } else {
                validate[data[0] + 'State'] = 'has-success';
                this.setState({ validate });
            }
        });
        return valid;
    }




    render() {
        const { validate, form, edit, loadingData } = this.state;

        function getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }

        return (
            <Card>
                {loadingData === true ? (
                    Loading(loadingData)
                ) : null}
                <CardBody>
                    <CardTitle tag="h3">
                        <Row className="align-items-center">
                            <Col>
                                <h6 className="heading-small text-muted mb-4">Adicionar banner</h6>
                            </Col>
                            <Col className="text-right" xl="4">

                                <Button size="sm" color="success" onClick={(e) => { this.saveBanner(form); }}><i className="fas fa-save"></i> Guardar </Button>

                            </Col>
                        </Row>

                    </CardTitle>
                    <CardSubtitle className="mb-2 text-muted" tag="h6">
                    </CardSubtitle>
                    <CardText>
                        <Form>
                            <div>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-title" >
                                                Título
                                            </label>
                                            <Input type="title" name="title" id="title"
                                                valid={validate.titleState === "has-success"}
                                                invalid={validate.titleState === "has-danger"}
                                                value={form.title}
                                                onChange={(e) => { this.setState(state => ({ form: { ...state.form, title: e.target.value } })) }}
                                                disabled={edit}
                                                required
                                            />
                                            <FormFeedback>
                                                Título inválido.
                                            </FormFeedback>
                                            <FormFeedback valid>
                                                Título válido.
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-description" >
                                                Descrição
                                            </label>
                                            <Input type="text" name="description" id="description"
                                                valid={validate.descriptionState === "has-success"}
                                                invalid={validate.descriptionState === "has-danger"}
                                                value={form.description}
                                                onChange={(e) => { this.setState(state => ({ form: { ...state.form, description: e.target.value } })) }}
                                                disabled={edit}
                                                required
                                            />
                                            <FormFeedback>
                                                Descrição inválida.
                                            </FormFeedback>
                                            <FormFeedback valid>
                                                Descrição válida.
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-start" >
                                                Início
                                            </label>
                                            <Input type="date" name="start" id="start"
                                                valid={validate.startState === "has-success"}
                                                invalid={validate.startState === "has-danger"}
                                                value={form.start}
                                                onChange={(e) => { this.setState(state => ({ form: { ...state.form, start: e.target.value } })) }}
                                                disabled={edit}
                                                required
                                            />
                                            <FormFeedback>
                                                Data inválida.
                                            </FormFeedback>
                                            <FormFeedback valid>
                                                Data válida.
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-remove" >
                                                Remover
                                            </label>
                                            <Input type="date" name="remove" id="remove"
                                                valid={validate.removeState === "has-success"}
                                                invalid={validate.removeState === "has-danger"}
                                                value={form.remove}
                                                onChange={(e) => { this.setState(state => ({ form: { ...state.form, remove: e.target.value } })) }}
                                                disabled={edit}
                                                required
                                            />
                                            <FormFeedback>
                                                Título inválido.
                                            </FormFeedback>
                                            <FormFeedback valid>
                                                Título válido.
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="8">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-image" >
                                                Imagem
                                            </label>
                                            <Input type="file" name="image" accept=".png" id="image"
                                                valid={validate.imageState === "has-success"}
                                                invalid={validate.imageState === "has-danger"}
                                                //value={form.image}
                                                onChange={(e) => {
                                                    if (e.target.files.length > 0) {
                                                        getBase64(e.target.files[0]).then(data => {
                                                            if (data !== null) { this.handleBase64(data); }
                                                        });
                                                    }
                                                }}
                                                disabled={edit}
                                                required
                                            />
                                            <FormFeedback>
                                                Imagem inválida.
                                            </FormFeedback>
                                            <FormFeedback valid>
                                                Imagem válida.
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <div style={{ border: 1, borderColor: "red" }}>
                                            <img style={{ borderRadius: 10, alignSelf: 'center' }} width={250} src={form.image} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Form>

                    </CardText>
                </CardBody>
            </Card>
        );
    }
}


export default AddBanner;