import React, { useState } from 'react';
import { Container } from "reactstrap";
import { CurrentUser, notification, ApiError, GetHeader, Loading } from "views/backoffice/utils/Utilities";
import axios from 'axios';
import { useEffect } from 'react';

const Index = (props) => {
    const iframeRef = React.useRef(null);
    let currentUser = CurrentUser();
    const [url, setUrl] = useState(null);

    var url_base = "https://webmail.redservice.pt/mondo/lang/sys/login.aspx?";
    var url_temp = url_base + "LanguageID=EN&UserID=@EMAIL&Password=@PASSWORD&Method=Auto&skin=default&offset=-600";

    React.useEffect(() => {
        const iframe = iframeRef.current;

        if (!iframe) return undefined;
        const listener = (e) => {
            //console.log("event listener");
            //console.log(e)
        }
        iframe.addEventListener("load", listener);

        iframe.onload = function () {
            if (url !== null && url !== url_base) {
                notification("Email RedService", "Tentiva de login efetuada!", "success", null, "top-right", null, false);
            }
        };
        iframe.onerror = function () { console.log("Something wrong happened"); };
        return () => { iframe.removeEventListener("load", listener); };

    });

    useEffect(() => { getWorkShopConfigs(); }, [])



    const getWorkShopConfigs = () => {
        var headers = GetHeader();
        axios.get(global.api_url + "WorkshopConfigs", { headers })
            .then((response) => {
                if (response.status === 200) {
                    var data = response.data;
                    var obj = data?.emailLogin !== undefined ? JSON.parse(data.emailLogin) : null
                    if (obj !== null && url_temp !== undefined) {
                        if (obj?.Email !== "" && obj?.Password !== "") {
                            url_temp = url_temp.replace("@EMAIL", obj?.Email).replace("@PASSWORD", obj?.Password);
                            setUrl(url_temp);
                        } else {
                            notification("Email RedService", "Email não configurado, aceder ás configuração para adicionar a autênticação do email.", "warning", null, "top-right", null, false);
                            setUrl("https://webmail.redservice.pt/mondo/lang/sys/login.aspx?");
                        }
                    }
                }
            }).catch(error => { ApiError(error, "Configurações").then(call => call === true ? this.getWorkShopConfigs() : ""); })
    }

    return (

        <div>
            {url !== null ?
                <>
                    <div className="header bg-RS-grey pt-5 ">
                        <Container fluid>
                            <div className="header-body" />
                        </Container>
                    </div>

                    <center>
                        <iframe
                            referrerPolicy="no-referrer"
                            width="100%" height={window.innerHeight * 0.95}
                            ref={iframeRef}
                            sandbox='allow-same-origin allow-scripts'
                            src={url}
                            seamless
                        >
                        </iframe >
                    </center>
                </>
                : <>
                    <div className="header bg-RS-grey pt-5 ">
                        <Container fluid>
                            <div className="header-body" />
                        </Container>
                    </div>
                    <div style={{ paddingBottom: window.innerHeight * 0.8 }}> {Loading(true)}</div>
                </>}
        </div>
    );

}

export default Index;
