import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

class CustomPagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination: props.pagination,
            array: props.array,
            pageSize: props.pageSize
        };
        this.handleClick = this.handleClick.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.pagination !== state.pagination) {
            return { pagination: props.pagination };
        }
        if (props.array !== state.array) {
            return { array: props.array };
        }
        if (props.pageSize !== state.pageSize) {
            return { pageSize: props.pageSize };
        }
        return null;
    }


    handleClick(e, index) {
        e.preventDefault();
        var pageRedirect = e.target.value !== undefined && e.target.value !== "" ? e.target.value : index;
        this.setState(state => ({ pagination: { ...state.pagination, CurrentPage: parseInt(pageRedirect) } }));
        this.props.onChange(pageRedirect, this.state.pagination.PageSize);
    }

    render() {
        const { CurrentPage, TotalPages } = this.state.pagination;
        var elements = [];
        for (var i = 0; i < TotalPages; i++) {
            elements.push(
                <PaginationItem active={i === CurrentPage - 1} key={i}>
                    <PaginationLink onClick={e => { this.handleClick(e, i) }} value={i + 1}>
                        {i + 1}
                    </PaginationLink>
                </PaginationItem >
            );
        }

        return (
            <>
                <nav aria-label="...">
                    <Pagination className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">

                        <PaginationItem disabled={CurrentPage < 2}>
                            <PaginationLink onClick={e => this.handleClick(e, CurrentPage - 1)} previous href="#" value="0">
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">Previous</span>
                            </PaginationLink>
                        </PaginationItem>

                        {elements.length > 4 ? <>
                            {CurrentPage > 0 && elements.slice(0, CurrentPage + 3 >= elements.length - 3 ? elements.length - 3 : CurrentPage + 3)}
                            <PaginationItem>
                                <PaginationLink >
                                    ...
                                </PaginationLink>
                            </PaginationItem >
                            {elements.slice(elements.length - 3, elements.length)}
                        </> : elements}

                        <PaginationItem disabled={CurrentPage - 1 >= TotalPages - 1}>
                            <PaginationLink onClick={e => this.handleClick(e, CurrentPage + 1)} href="#">
                                <i className="fas fa-angle-right" />
                                <span className="sr-only">Next</span>
                            </PaginationLink>
                        </PaginationItem>
                    </Pagination>
                </nav>
            </>
        );
    }


}
export default CustomPagination;