import React, { useState, useEffect } from 'react';
import Header from "components/Headers/Header.js";
import { CurrentUser, Role, Loading, GetHeader, ApiError } from "../utils/Utilities";
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import ProfileForm from "./ProfileForm";
import { changeAccount } from '../../../store/accounts/accounts';

const Profile = (props) => {
    const dispatch = useDispatch();
    var currentUser = CurrentUser();
    const location = useLocation();
    const [user, setUser] = useState([]);

    const getUser = async (user) => {
        var headers = GetHeader();
        return axios.get(global.api_url + "Accounts/" + user.id, { headers })
            .then((response) => {
                if (response.status === 200) {
                    const userData = response.data;
                    setUser(userData);
                    return userData;
                }
            }).catch(error => {
                ApiError(error, "Utilizador").then(call => call === true ? getUser(user) : "");
                return null;
            })
    }

    const reloadUser = (user) => { setUser(user); }

    useEffect(() => {
        if (location?.state !== null && location?.state?.create === true) {
            setUser({ id: 0, role: Role.User });
        } else {
            if (location?.state !== null && location?.state !== undefined && location?.state?.user) {
                getUser(location.state.user)
            } else
                if (currentUser !== null) {
                    getUser(currentUser).then(us => {
                        dispatch(changeAccount(us));
                        setUser(us)
                    })
                }
        }
    }, []);

    return (
        <>
            <Header />
            {user.id !== undefined ? (
                <ProfileForm edit={true} currentUser={currentUser} formUser={user} onChange={reloadUser} redirect={false} props={props} />
            ) : Loading(true)}
        </>
    )
};

export default Profile;
