import React from 'react';
import { Container, Card, CardHeader, Row, Col, Button, Table } from "reactstrap";
import { GetHeader, ApiError, notification } from "../../../utils/Utilities";
import axios from 'axios';
import RowTable from './RowTable'
import Details from './Details'


class TableData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: props.language,
            page: props.page,
            loading: false,
            jsonObject: null,
            editJsonObject: null,
            objectName: null,
            detailData: null
        }
    }

    componentDidMount() {
        this.onLoad();
    }

    onLoad = () => {
        this.GetJsonLanguage(this.state.language, this.state.page);
    }

    GetJsonLanguage = (lng, page) => {
        var headers = GetHeader();
        axios.get(global.api_url + "FrontEnd/" + lng?.value + "/" + page, { headers })
            .then((response) => {
                if (response.status === 200) {
                    var data = response.data["Section1"]["details"];
                    this.setState({ jsonObject: data });
                }
            }).catch(error => {
                ApiError(error, "Json").then(call => call === true ? this.GetJsonLanguage(lng) : "");
            })
    }

    onClick = (data) => {
        this.setState({ detailData: data });
    }
    newAgreement = () => {
        this.setState({ detailData: { id: "-1", title: "", description: "", image: "data:image", points: [], footer: "", button: "Fazer agendamento" } })
    }

    toRemove = (obj) => {
        var headers = GetHeader();
        axios.post(global.api_url + "FrontEnd/Remove", { language: this.state.language?.value, json: JSON.stringify(obj), page: this.state.page }, { headers })
            .then((response) => {
                if (response.status === 200) {

                    this.onLoad();
                    notification(this.state.page, "Removido com sucesso!", "success", null, "top-right", null, false);
                }
            }).catch(error => {
                ApiError(error, "Json").then(call => call === true ? this.toRemove(obj) : "");
            })
    }


    save = () => {
        this.postJsonLanguage(this.state.language?.value, JSON.stringify(this.state.detailData), this.state.page)
    }

    postJsonLanguage = (language, json, page) => {
        var headers = GetHeader();
        axios.post(global.api_url + "FrontEnd/PostPage", { language: language, json: json, page: page, route: ["Section1", "details"] }, { headers })
            .then((response) => {
                if (response.status === 200) {
                    var data = response.data;
                    this.setState({ jsonObject: data })
                    this.setState({ detailData: null });
                    this.onLoad();
                    notification(this.state.page, "Atualizado com sucesso!", "success", null, "top-right", null, false);
                }
            }).catch(error => {
                ApiError(error, "Json").then(call => call === true ? this.postJsonLanguage(language, json) : "");
            })
    }

    onChange = (json) => {
        this.setState({ detailData: json });
    }


    render() {
        const { language, jsonObject, editJsonObject, page, detailData } = this.state;

        return (

            <Container className="mt--7" fluid>
                <div className="col">
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="heading-small text-muted mb-4">Linguagem -{page}- {language?.name}</h6>
                                </Col>
                                <Col className="text-right" xl="4">
                                    {editJsonObject === null && detailData === null &&
                                        <>
                                        <Button color="warning" size="sm" onClick={(e) => { this?.props?.onClick(null) }}><i className="far fa-window-close"></i>{' '} Fechar</Button>
                                        <Button color="success" size="sm" onClick={(e) => { this.newAgreement() }}><i className="fas fa-plus-circle"></i>{' '} Novo Acordo</Button>

                                        </>
                                    }
                                    {detailData !== null && <>
                                        <Button color="warning" size="sm" onClick={(e) => { this.setState({ detailData: null }); this.onLoad() }}><i className="fas fa-chevron-circle-left"></i>{' '} Voltar</Button>
                                        <Button color="success" size="sm" onClick={(e) => { this.save(); }}><i className="fas fa-save"></i>{' '} Guardar</Button>
                                    </>}
                                </Col>
                            </Row>
                        </CardHeader>

                        <div style={{ padding: 20 }}>
                            {detailData === null &&
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Imagem</th>
                                            <th scope="col">Título</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(jsonObject) && jsonObject.map((data, key) => {
                                            return <RowTable key={key} data={data} onClick={(a) => { this.onClick(a) }} toRemove={this.toRemove} />
                                        })}
                                    </tbody>
                                </Table>
                            }
                            {detailData !== null &&
                                <Details data={detailData} onChange={this.onChange} />
                            }

                        </div>

                    </Card>
                </div>
            </Container>

        );
    }
}


export default TableData;