import React from 'react';
import { Card, CardHeader, CardBody, Row } from "reactstrap";
import { Pie } from "react-chartjs-2";
import { GetHeader, Loading, ApiError, Role, notification } from '../../utils/Utilities';
import axios from 'axios';


class BrandsByWorkshop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            loading: false,
            activeNav: 1,
            data: null
        };
    }
    handleValue = (name, value) => { this.setState({ [name]: value }); }

    componentDidMount() {
        this.getBrandsByWorkshop();
    }

    getBrandsByWorkshop = () => {
        var headers = GetHeader();
        axios.get(global.api_url + "Graph/GetBrandsByWorkshop", { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.handleValue("data", response.data)
                }
                this.handleValue("loading", false)
            }).catch(error => {
                this.handleValue("loading", false)
                ApiError(error, "Gráfico: Marcas por oficina.").then(call => call === true ? this.getBrandsByWorkshop() : "");
            });


    }


    render() {
        const { data, loading, currentUser } = this.state;


        return (

            <Card className="shadow">
                <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                        <div className="col">
                            <h6 className="text-uppercase text-dark ls-1 mb-1">
                                Marcas
                            </h6>
                            {currentUser?.role === Role.Admin ?
                                <h2 className="text-dark mb-0">Global Oficinas RedService</h2>
                                :
                                <h2 className="text-dark mb-0">Oficina</h2>
                            }
                        </div>
                    </Row>
                </CardHeader>
                <CardBody>
                    <div className="chart">
                        {loading === true || data === null ?
                            Loading(true) :
                            <Pie
                                data={data}
                                options={{
                                    legend: { display: true, position: "bottom" },
                                    datalabels: {
                                        display: true,
                                    },
                                    tooltips: {
                                        backgroundColor: "#e3231a",
                                    },
                                }}
                                getElementAtEvent={(e) => {
                                    if (e.length > 0) {
                                        var clickedDatasetIndex = e[0]._datasetIndex;
                                        var clickedElementindex = e[0]._index;
                                        var label = data.labels[clickedElementindex];
                                        var value = data.datasets[clickedDatasetIndex].data[clickedElementindex];
                                        notification("Marcas - Global", label + " - " + value + " veículos.", "info", null, "top-center", null, false);
                                    }

                                }}
                            />
                        }
                    </div>
                </CardBody>
            </Card>

        );
    }
}

export default BrandsByWorkshop;

