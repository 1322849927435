import React, { useEffect } from 'react';
import Chart from "chart.js";
import { Container, Row, Col } from "reactstrap";
import { chartOptions, parseOptions } from "variables/charts.js";
import TotalSchedules from '../Dashboard/Gaphs/TotalSchedules'
import BrandsByWorkshop from '../Dashboard/Gaphs/BrandsByWorkshop'
import Header from "components/Headers/Header.js";
import WorkshopsSchedules from './Gaphs/WorkshopsSchedules';
import ScheduleDay from './Gaphs/ScheduleDay';
import { CurrentUser, Role, GetHeader, ApiError } from '../utils/Utilities';
import NewUsers from './Gaphs/NewUsers';
import GraphServices from './Gaphs/GraphServices';
import { useState } from 'react';
import axios from 'axios';

const Dashboard = (props) => {
    const currentUser = CurrentUser();
    const [workshop, setWorkshop] = useState(null);
    if (window.Chart) { parseOptions(Chart, chartOptions()); }


    useEffect(() => {
        getWorkshop();
    }, []);

    const getWorkshop = () => {
        if (currentUser.defaultWorkshop !== undefined) {
            var headers = GetHeader();
            axios.get(global.api_url + "Workshops/" + currentUser.defaultWorkshop, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        const workshopData = response.data;
                        setWorkshop(workshopData);
                    }
                }).catch(error => {
                    ApiError(error, "Oficina").then(call => call === true ? getWorkshop(workshop) : "");
                })
        }

    }

    return (
        <>
            {currentUser.role !== Role.Admin ?
                <Header subText={"Oficina: " + workshop?.name} /> :
                <Header subText={"Oficina definida: " + workshop?.name} />}


            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="mb-5 mb-xl-0" xl="6">
                        <TotalSchedules />
                    </Col>
                    <Col xl="6">
                        {currentUser.role === Role.Admin && <WorkshopsSchedules />}
                        {currentUser.role !== Role.Admin && <BrandsByWorkshop currentUser={currentUser} />}

                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col className="mb-5 mb-xl-0" xl="6">
                        <GraphServices currentUser={currentUser} />
                    </Col>
                    <Col xl="6">
                        {currentUser.role === Role.Admin && <NewUsers />}
                        {currentUser.role !== Role.Admin && <ScheduleDay currentUser={currentUser} />}
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col className="mb-5 mb-xl-0" xl="6">
                        {currentUser.role === Role.Admin && <BrandsByWorkshop currentUser={currentUser} />}
                    </Col>
                    <Col xl="6">
                       

                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Dashboard;
