import React from 'react';
import { Row, Col, FormGroup, Input } from "reactstrap";



class EditPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editJsonObject: props.editJsonObject
        }
    }


    updateJson = () => {
        this.props.onChange(this.state.editJsonObject)
    }

    render() {
        const { editJsonObject } = this.state;

        return (
            editJsonObject !== null && <>
                <Row>
                    {Object?.entries(editJsonObject).forEach(entitie => {
                        if (typeof entitie[1] === 'string') {

                            return (
                                <Col lg="6" key={entitie[0]}>
                                    <FormGroup>
                                        <label className="form-control-label">
                                            {entitie[0]}
                                        </label>
                                        {entitie[1].startsWith("data:image") ?
                                            <img alt="img" src={entitie[1]} />
                                            :
                                            <Input type="textarea" name={entitie[0]} id={entitie[0]}
                                                value={entitie[1]}
                                                onChange={(e) => { this.setState(state => ({ editJsonObject: { ...state.editJsonObject, [entitie[0]]: e.target.value } }), () => { this.updateJson(); }); }}
                                                disabled={false}
                                                required
                                            />
                                        }
                                    </FormGroup>
                                </Col>
                            );
                        }
                        if (typeof entitie[1] === 'object' && entitie[0] !== "Services") {
                            return (<>
                                <Col lg="12">
                                    <h2>{entitie[0]}</h2>
                                </Col>
                                <Col lg="12" style={{ marginLeft: 20 }}>
                                    {entitie[1] !== null && Object.entries(entitie[1]).forEach(A => {
                                        if (typeof A[1] === 'object') {
                                            return (
                                                A[1] !== null && Object?.entries(A[1]).forEach(B => {
                                                    return (
                                                        <>
                                                            {typeof B[1] === 'object' &&
                                                                (<>
                                                                    <Col lg="12" >
                                                                        <h3 className="form-control-label" htmlFor="input-firstName" >
                                                                            {B[0]}
                                                                        </h3>
                                                                    </Col>
                                                                    <Col lg="12" style={{ marginLeft: 20 }}>
                                                                        {B[1] !== null && Object?.entries(B[1]).forEach(C => {
                                                                            if (typeof C[1] === 'object') {

                                                                                return (
                                                                                    C[1] !== null && Object?.entries(C[1]).forEach(D => {
                                                                                        return (
                                                                                            <>
                                                                                                <div style={{ marginLeft: 10 }}>
                                                                                                    {typeof D[1] === 'string' && (
                                                                                                        <Col lg="12">
                                                                                                            <FormGroup>
                                                                                                                {/*{console.log("--- D ----")}*/}
                                                                                                                <label className="form-control-label"  >
                                                                                                                    {D[0]}
                                                                                                                </label>
                                                                                                                {D[1].startsWith("data:image") ?
                                                                                                                    <img alt="" src={D[1]} /> :
                                                                                                                    <Input type="textarea" name={entitie[0] + "." + A[0] + "." + B[0] + "." + C[0] + "." + D[0]} id={entitie[0] + "." + A[0] + "." + B[0] + "." + C[0] + "." + D[0]}
                                                                                                                        value={D[1]}
                                                                                                                        onChange={(e) => {
                                                                                                                            this.setState(state => ({ editJsonObject: { ...state.editJsonObject, [entitie[0]]: { ...state.editJsonObject[entitie[0]], [A[0]]: { ...state.editJsonObject[entitie[0]][A[0]], [B[0]]: { ...state.editJsonObject[entitie[0]][A[0]][B[0]], [C[0]]: { ...state.editJsonObject[entitie[0]][A[0]][B[0]][C[0]], [D[0]]: e.target.value } } } } } }), () => { this.updateJson(); });
                                                                                                                        }}
                                                                                                                        disabled={false}
                                                                                                                        required
                                                                                                                    />
                                                                                                                }
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                    )}

                                                                                                    {typeof D[1] === 'object' && (
                                                                                                        D[1] !== null && Object.entries(D[1]).forEach(E => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    {typeof E[1] === 'string' && (
                                                                                                                        <Col style={{}} lg="12">
                                                                                                                            {/* {console.log("--- E ----")}*/}
                                                                                                                            <h3 className="form-control-label" htmlFor="input-firstName" >
                                                                                                                                {E[0]}
                                                                                                                            </h3>
                                                                                                                        </Col>
                                                                                                                    )}

                                                                                                                    {typeof E[1] === 'object' && (
                                                                                                                        E[1] !== null && Object.entries(E[1]).forEach(F => {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    {typeof F[1] === 'string' && (
                                                                                                                                        <Col lg="6" >
                                                                                                                                            {/*{console.log("--- F ----")}*/}
                                                                                                                                            <FormGroup>
                                                                                                                                                <label className="form-control-label" htmlFor="input-firstName" >
                                                                                                                                                    {F[0]}
                                                                                                                                                </label>
                                                                                                                                                {F[1].startsWith("data:image") ?
                                                                                                                                                    <img alt="" src={F[1]} />
                                                                                                                                                    :
                                                                                                                                                    <Input type="textarea" name={F[0]} id={F[0]}
                                                                                                                                                        value={F[1]}
                                                                                                                                                        onChange={(e) => { this.handleChangeJson(e); }}
                                                                                                                                                        disabled={false}
                                                                                                                                                        required
                                                                                                                                                    />
                                                                                                                                                }
                                                                                                                                            </FormGroup>
                                                                                                                                        </Col>
                                                                                                                                    )}
                                                                                                                                </>
                                                                                                                            );
                                                                                                                        })
                                                                                                                    )}
                                                                                                                </>
                                                                                                            );
                                                                                                        })
                                                                                                    )}
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    })
                                                                                );
                                                                            }
                                                                            if (typeof C[1] === 'string') {

                                                                                return (
                                                                                    <Col lg="6">
                                                                                        <FormGroup>
                                                                                            {/*{console.log("--- C ----")}*/}
                                                                                            <label className="form-control-label" htmlFor="input-firstName" >
                                                                                                {C[0]}
                                                                                            </label>
                                                                                            {C[1].startsWith("data:image") ?
                                                                                                <img alt="" src={C[1]} />
                                                                                                :
                                                                                                <Input type="textarea" name={entitie[0] + "." + A[0] + "." + B[0] + "." + C[0]} id={entitie[0] + "." + A[0] + "." + B[0] + "." + C[0]}
                                                                                                    value={C[1]}
                                                                                                    onChange={(e) => {
                                                                                                        this.setState(state => ({ editJsonObject: { ...state.editJsonObject, [entitie[0]]: { ...state.editJsonObject[entitie[0]], [A[0]]: { ...state.editJsonObject[entitie[0]][A[0]], [B[0]]: { ...state.editJsonObject[entitie[0]][A[0]][B[0]], [C[0]]: e.target.value } } } } }), () => { this.updateJson(); });
                                                                                                    }}
                                                                                                    disabled={false}
                                                                                                    required
                                                                                                />
                                                                                            }
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </Col>
                                                                </>)
                                                            }
                                                            {typeof B[1] === 'string' &&
                                                                (<Col lg="6">
                                                                    {/*{console.log("--- B ----")}*/}
                                                                    <FormGroup>
                                                                        <label className="form-control-label"  >
                                                                            {B[0]}
                                                                            {/*{console.log(B[0])}*/}
                                                                        </label>
                                                                        {B[1].startsWith("data:image") ?
                                                                        <img alt="" src={B[1]} /> :
                                                                            <Input type="textarea" name={entitie[0] + "." + A[0] + "." + B[0]} id={entitie[0] + "." + A[0] + "." + B[0]}
                                                                                value={B[1]}
                                                                                onChange={(e) => {
                                                                                    this.setState(state => ({ editJsonObject: { ...state.editJsonObject, [entitie[0]]: { ...state.editJsonObject[entitie[0]], [A[0]]: { ...state.editJsonObject[entitie[0]][A[0]], [B[0]]: e.target.value } } } }), () => { this.updateJson(); });
                                                                                }}
                                                                                disabled={false}
                                                                                required
                                                                            />
                                                                        }
                                                                    </FormGroup>
                                                                </Col>
                                                                )
                                                            }
                                                        </>
                                                    );
                                                })

                                            );
                                        } else {
                                            return (
                                                <Col lg="6">
                                                    {/*{console.log("--- A ----")}*/}
                                                    <FormGroup>
                                                        <label className="form-control-label" htmlFor="input-firstName" >
                                                            {A[0]}
                                                        </label>
                                                        <Input type="textarea" name={entitie[0] + "." + A[0]} id={entitie[0] + "." + A[0]}
                                                            value={A[1]}
                                                            onChange={(e) => {
                                                                this.setState(state => ({ editJsonObject: { ...state.editJsonObject, [entitie[0]]: { ...state.editJsonObject[entitie[0]], [A[0]]: e.target.value } } }), () => { this.updateJson(); });
                                                            }}
                                                            disabled={false}
                                                            required
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            );
                                        }
                                    })}
                                </Col>
                            </>
                            );

                        }
                    })}
                </Row>
            </>
        );
    }
}


export default EditPage;