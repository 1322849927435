import React from 'react';
import { Row, Col, Container, CardHeader, Card, CardBody, Button } from "reactstrap";
import axios from 'axios';
import { GetHeader, ApiError, Loading } from '../utils/Utilities';
import throttle from "lodash.throttle"
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


class ShowDoc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            currentDoc: props.currentDoc,
            loading: false,
            numPages: null,
            pageNumber: 1,
            currentPDF: null,
            width: null

        };
    }
    componentDidMount() {
        this.getDocumentation();
        this.setDivSize();
        window.addEventListener("resize", throttle(this.setDivSize, 500))
    }
    handleValue = (name, value) => { this.setState({ [name]: value }); }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.handleValue("numPages", numPages);
    }

    setDivSize = () => {
        this.setState({ width: this.pdfWrapper.getBoundingClientRect().width * 0.8 })
    }

    getDocumentation = () => {
        if (this.state.currentDoc !== null && this.state.currentDoc !== undefined) {

            this.handleValue("loading", true)
            var headers = GetHeader();
            axios.get(global.api_url + "Documentation/" + this.state.currentDoc.id, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data;
                        this.setState({ currentPDF: data.base64 });
                    }
                    this.handleValue("loading", false)
                }).catch(error => {
                    this.handleValue("loading", false)
                    ApiError(error, "Documentação").then(call => call === true ? this.getDocumentation() : "");
                });
        }
    }


    render() {
        const { currentDoc, numPages, currentPDF } = this.state;
        return (
            <>
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col>
                                            <h3 className="mb-0">Documento {currentDoc.name}</h3>
                                        </Col>
                                        <Col className="text-right" xl="4">
                                            <Button color="danger" size="sm" onClick={(e) => { this.props.onChange(null); }}><i className="far fa-window-close"></i>{' '} Fechar</Button>
                                        </Col>

                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {Loading(currentPDF === null ? true : false)}
                                    <div id="pdfWrapper" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }} ref={(ref) => this.pdfWrapper = ref}>
                                        {(currentPDF !== null && currentPDF !== undefined) ?
                                            <Document
                                                file={currentPDF}
                                                options={{ workerSrc: "/pdf.worker.js" }}
                                                onLoadSuccess={this.onDocumentLoadSuccess}
                                            >
                                                {Array.from(new Array(numPages), (el, index) => (
                                                    <Page key={`page_${index + 1}`} width={this.state.width} pageNumber={index + 1} />
                                                ))}
                                            </Document>
                                            : <h3 className="mb-0">Não foi possível obter o Pdf.</h3>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>);
    }
}

export default ShowDoc;

