import React from 'react';
import { Row, Col, Input, FormGroup } from "reactstrap";
import { Editor, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';


class TableData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            jsonObject: props.data,
            editorState: EditorState.createEmpty()
        }
    }

    onImageChange = (e, key) => {
        if (e.target.files.length > 0) {
            this.getBase64(e.target.files[0]).then(data => {
                this.setState(state => ({ jsonObject: { ...state.jsonObject, [key]: data } }));
                this.updateObject();
                //this.handleBase64(e.target.files[0].name, data);
            });
        }
    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    updateObject = () => {
        this.props.onChange(this.state.jsonObject);
    }


    render() {
        const { jsonObject } = this.state;

        return (
            <Row>
                {Object.entries(jsonObject).map(data => {
                    if (typeof data[1] === 'string') {
                        if (data[0] !== 'id') {
                            return (
                                <Col lg="12">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="input-role">
                                            {data[0]}
                                        </label >
                                        {data[1].startsWith("data:image") ?
                                            <>
                                                <Col xl="6">
                                                    <img src={data[1]} style={{ height: 100 }} />
                                                    <h3>Usar imagens comprimidas (690X490)</h3>

                                                </Col>
                                                <Col xl="6">
                                                    <input type="file" accept="image/*" onChange={(e) => this.onImageChange(e, data[0])} />
                                                </Col>
                                            </>
                                            :
                                            data[0] === 'category' ?
                                                <Input type="select" name={data[0]} id={data[0]}
                                                    value={data[1]}
                                                    onChange={(e) => {
                                                        this.setState(state => ({ jsonObject: { ...state.jsonObject, [data[0]]: e.target.value } }), () => { this.updateObject(); });
                                                    }}
                                                    disabled={data[0] === "id" ? true : false}
                                                    required
                                                >
                                                    <option value="PNEUS">PNEUS</option>
                                                    <option value="Mecanica">Mecanica</option>
                                                    <option value="Manutenção">Manutenção</option>
                                                </Input>
                                                :
                                                data[0] === 'type' ?
                                                    <Input type="select" name={data[0]} id={data[0]}
                                                        value={data[1]}
                                                        onChange={(e) => {
                                                            this.setState(state => ({ jsonObject: { ...state.jsonObject, [data[0]]: e.target.value } }), () => { this.updateObject(); });
                                                        }}
                                                        disabled={data[0] === "id" ? true : false}
                                                        required
                                                    >
                                                        <option value="tires">PNEUS</option>
                                                        <option value="mechanics">Mecanica</option>
                                                        <option value="maintenance">Manutenção</option>
                                                    </Input>
                                                    :
                                                    //<Editor editorState={this.state.editorState} onChange={(e) => {
                                                    //    this.setState(state => ({ jsonObject: { ...state.jsonObject, [data[0]]: e.target.value } }), () => { this.updateObject(); });
                                                    //}} />
                                                    <Input type="textarea" name={data[0]} id={data[0]}
                                                        rows="5"
                                                        value={data[1]}
                                                        onChange={(e) => {
                                                            this.setState(state => ({ jsonObject: { ...state.jsonObject, [data[0]]: e.target.value } }), () => { this.updateObject(); });
                                                        }}
                                                        disabled={data[0] === "id" ? true : false}

                                                        required
                                                    />
                                        }
                                    </FormGroup>
                                </Col>
                            );
                        }
                    }
                })}

                {Object.entries(jsonObject).map(data => {
                    if (typeof data[1] === 'object') {
                        return Object.entries(data[1]).map(A => {
                            if (typeof data[1] === 'object') {
                                return Object.entries(A[1]).map(B => {
                                    if (typeof B[1] === 'string') {
                                        return (
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor="input-role">
                                                        {data[0] + " - " + A[0] + " - " + B[0]}
                                                    </label >
                                                    <Input type="textarea" name={B[0]} id={B[0]}
                                                        value={B[1]}
                                                        onChange={(e) => { this.setState(state => ({ jsonObject: { ...state.jsonObject, [data[0]]: { ...state.jsonObject[data[0]], [A[0]]: { ...state.jsonObject[data[0]][A[0]], [B[0]]: e.target.value } } } }), () => { this.updateObject(); }); }}
                                                        disabled={data[0] === "id" ? true : false}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                        );
                                    }
                                });
                            }
                        })
                    }
                })}
            </Row>
        );
    }
}


export default TableData;


