import React from 'react';
import { Card, Button, CardBody, CardTitle, CardSubtitle, CardText, FormGroup, Form, Input, FormFeedback, Row, Col } from "reactstrap";
import { ApiError, GetHeader, Loading, notification } from "../utils/Utilities";
import axios from 'axios';

class LeirilisConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            currentWorkShopConfigs: props.currentWorkShopConfigs,
            edit: false,
            loadingData: false,
            validate: {
                webSiteLeirilisState: '',
                userLeirilisState: '',
                pwLeirilisState: ''
            }
        }
    }

    validateString(e) {
        const { validate } = this.state;
        if (e.target.value !== null && e.target.value !== "") {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState(({ validate }));
    }

    handleValue = async (name, value) => { await this.setState({ [name]: value }); return value; }
    handleCurrentWorkShopConfigs = (e) => {
        this.setState(state => ({ currentWorkShopConfigs: { ...state.currentWorkShopConfigs, [e.target.name]: e.target.value } }));
    }
    changeEdit = (state) => { this.handleValue('edit', state); }


    saveConfig = () => {
        const { currentWorkShopConfigs } = this.state;

        this.handleValue('loadingData', true);
        var headers = GetHeader();
        axios.post(global.api_url + "WorkshopConfigs/Change", currentWorkShopConfigs, { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.handleValue('currentWorkShopConfigs', response.data);
                    notification("Portal Leirilis", "Dados atualizados com sucesso!", "success", null, "top-right", null, false);
                }
                this.cleanValidate();
                this.handleValue('loadingData', false);
            }).catch(error => {
                this.handleValue('loadingData', false);
                ApiError(error, "Configurações").then(call => call === true ? this.saveConfig() : "");
            })

    }

    cleanValidate = () => {
        this.setState({ validate: { webSiteLeirilisState: '', userLeirilisState: '', pwLeirilisState: '' } })
    }

    render() {
        const { edit, currentWorkShopConfigs, validate, loadingData } = this.state;
        return (
            <Card style={{ height: '100%' }}>
                {Loading(loadingData)}
                <CardBody>
                    <CardTitle tag="h3">
                        Acesso ao portal Leirilis
                    </CardTitle>
                    <CardSubtitle className="mb-2 text-muted" tag="h4">
                        Serviço de orçamentação HaynesPro Leirilis
                    </CardSubtitle>
                    <CardText>
                        <Form>
                            <Row>
                                <Col lg="12">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="input-webSiteLeirilis" >
                                            Site Leirilis
                                        </label>
                                        <Input type="title" name="webSiteLeirilis" id="webSiteLeirilis"
                                            valid={validate.webSiteLeirilisState === "has-success"}
                                            invalid={validate.webSiteLeirilisState === "has-danger"}
                                            value={currentWorkShopConfigs.webSiteLeirilis}
                                            onChange={(e) => { this.validateString(e); this.handleCurrentWorkShopConfigs(e); }}
                                            disabled={!edit}
                                            required
                                        />
                                        <FormFeedback>
                                            Site inválido.
                                        </FormFeedback>
                                        <FormFeedback valid>
                                            Site válido.
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>

                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="input-userLeirilis" >
                                            Utilizador Leirilis
                                        </label>
                                        <Input type="text" name="userLeirilis" id="userLeirilis"
                                            valid={validate.userLeirilisState === "has-success"}
                                            invalid={validate.userLeirilisState === "has-danger"}
                                            value={currentWorkShopConfigs.userLeirilis}
                                            onChange={(e) => { this.validateString(e); this.handleCurrentWorkShopConfigs(e); }}
                                            disabled={!edit}
                                            required
                                        />
                                        <FormFeedback>
                                            Utilizador inválido.
                                        </FormFeedback>
                                        <FormFeedback valid>
                                            Utilizador válido.
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="input-pwLeirilis" >
                                            Password Leirilis
                                        </label>
                                        <Input type="password" name="pwLeirilis" id="pwLeirilis"
                                            valid={validate.pwLeirilisState === "has-success"}
                                            invalid={validate.pwLeirilisState === "has-danger"}
                                            value={currentWorkShopConfigs.pwLeirilis}
                                            onChange={(e) => { this.validateString(e); this.handleCurrentWorkShopConfigs(e); }}
                                            disabled={!edit}
                                            required
                                        />
                                        <FormFeedback>
                                            Password inválido.
                                        </FormFeedback>
                                        <FormFeedback valid>
                                            Password válido.
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Form>

                    </CardText>
                    <div className="text-right" style={{ position: 'absolute', bottom: 24, right: 24 }} >
                        {!edit && <Button color="danger" size="sm" onClick={(e) => { e.preventDefault(); this.handleValue('edit', true) }}><i className="far fa-edit"></i> Editar</Button>}
                        {/*{edit && <Button color="success" size="sm" onClick={(e) => { e.preventDefault(); this.handleValue('edit', false) }}><i className="fas fa-link"></i> Testar conecção</Button>}*/}
                        {edit && <Button color="success" size="sm" onClick={(e) => { e.preventDefault(); this.handleValue('edit', false); this.saveConfig() }}><i className="far fa-save"></i> Guardar</Button>}
                        {edit && <Button color="primary" size="sm" onClick={(e) => { e.preventDefault(); this.handleValue('edit', false) }}><i className="far fa-window-close"></i> Fechar</Button>}

                    </div>
                </CardBody>
            </Card>
        );
    }
}


export default LeirilisConfig;