import React from 'react';
import { CurrentUser } from "views/backoffice/utils/Utilities";
import Cupons from '../Cupons/Cupons';

const Index = (props) => {
    const currentUser = CurrentUser();
    return (<Cupons currentUser={currentUser} />);
}

export default Index;

