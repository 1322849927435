
//Autenticacao
import Register from "views/frontend/Autenticacao/Register.js";
import Login from "views/frontend/Autenticacao/Login.js";
import Logout from "views/frontend/Autenticacao/Logout.js";
import ForgotPassword from "views/frontend/Autenticacao/ForgotPassword.js";
import ResetPassword from "views/frontend/Autenticacao/ResetPassword.js";


//BACKEND
//ADMIN
//import Base from "views/backoffice/Base";
import Users from "./views/backoffice/Accounts/Users";
import Profile from "views/backoffice/Accounts/Profile";
import Dashboard from "views/backoffice/Dashboard/Dashboard";
import Workshops from "views/backoffice/Workshops/Workshops";
import Workshop from "views/backoffice/Workshops/Workshop";
import ScheduleIndex from "views/backoffice/Schedules/Index";

import ServiceTypeIndex from "views/backoffice/ServiceTypes/Index";

//import Error from "views/backoffice/Error";

//CUPONS
import cuponsIndex from "views/backoffice/Cupons/Index";
import configsIndex from "views/backoffice/Configurations/Index";
import bannersIndex from "views/backoffice/SiteSettings/Banners/Index";
import TextIndex from "views/backoffice/SiteSettings/Text/Index";
import DocumentationIndex from "views/backoffice/Documentation/Index";
import SearchIndex from 'views/backoffice/Search/Index'

import joinUsIndex from "views/backoffice/JoinUs/Index";


import AdvicesIndex from 'views/backoffice/SiteSettings/BackOffice/Advices/Index'
import ServicesIndex from 'views/backoffice/SiteSettings/BackOffice/Services/Index'
import AgreementsIndex from 'views/backoffice/SiteSettings/BackOffice/Agreements/Index'
import CampaignsIndex from 'views/backoffice/SiteSettings/BackOffice/Campaigns/Index'
import TextBannerIndex from 'views/backoffice/SiteSettings/BackOffice/TextBanner/Index'
import MasterWayIndex from 'views/backoffice/MasterWay/Index'
import MailIndex from 'views/backoffice/Mail/Index'



var routes = [

    {
        path: "/login",
        name: "Login",
        icon: "ni ni-key-25 text-info",
        component: Login,
        layout: "",
    },
    {
        path: "/logout",
        name: "Logout",
        icon: "ni ni-key-25 text-info",
        component: Logout,
        layout: "/auth",
    },
    {
        path: "/forgot-password",
        name: "Forgot Password",
        icon: "ni ni-key-25 text-info",
        component: ForgotPassword,
        layout: "",
    },
    {
        path: "/reset-password",
        name: "Repor Password",
        icon: "ni ni-key-25 text-info",
        component: ResetPassword,
        layout: "",
    },
    {
        path: "/register",
        name: "Register",
        icon: "ni ni-circle-08 text-pink",
        component: Register,
        layout: "",
    },


    //BACKEND - ADMIN
    //SO ADMIN

    {
        path: "/Dashboard",
        name: "WMS",
        icon: "fas fa-chart-line rs-text-red",
        component: Dashboard,
        layout: "/admin",
    },
    {
        path: "/Users",
        name: "Utilizadores",
        icon: "fas fa-users rs-text-red",
        component: Users,
        layout: "/admin",
    },

    {
        path: "/Profile",
        name: "Perfil",
        icon: "ni ni-single-02 rs-text-red",
        component: Profile,
        layout: "/admin",
    },

    {
        path: "/Workshops",
        name: "Oficinas",
        icon: "fas fa-warehouse rs-text-red",
        component: Workshops,
        layout: "/admin",
    },
    {
        path: "/Workshop",
        name: "Oficina",
        icon: "fas fa-tools rs-text-red",
        component: Workshop,
        layout: "/admin",
    },
    {
        path: "/Schedule",
        name: "Agenda",
        icon: "fas fa-calendar rs-text-red",
        component: ScheduleIndex,
        layout: "/admin",

    },
    {
        path: "/Search",
        name: "Histórico",
        icon: "fas fa-search rs-text-red",
        component: SearchIndex,
        layout: "/admin",
        slideMenu: true,
    },
    {
        path: "/ServiceType",
        name: "Serviços",
        icon: "fab fa-buffer rs-text-red",
        component: ServiceTypeIndex,
        layout: "/admin",

    },
    {
        path: "/Cupons",
        name: "Cupões (dev)",
        //icon: "fab fab fa-get-pocket rs-text-red",
        icon: "fas fa-ticket-alt rs-text-red",
        component: cuponsIndex,
        layout: "/admin",
    },

    {
        path: "/Documentation",
        name: "Documentação",
        icon: "fas fa-font rs-text-red",
        component: DocumentationIndex,
        layout: "/admin",
        slideMenu: false,
    },


    //OFICINA && ADMIN
    {
        path: "/JoinUs",
        name: "Pedidos",
        icon: "fas fa-plus-square rs-text-red",
        component: joinUsIndex,
        layout: "/admin",
    },
    {
        path: "/Banners",
        name: "Banners",
        icon: "fas fa-images rs-text-red",
        component: bannersIndex,
        layout: "/admin",
        slideMenu : false,
    },
    {
        path: "/Text",
        name: "Textos",
        icon: "fas fa-text rs-text-red",
        component: TextIndex,
        layout: "/admin",
        slideMenu: false,
    },
    {
        path: "/Advices",
        name: "Conselhos",
        icon: "fas fa-font rs-text-red",
        component: AdvicesIndex,
        layout: "/admin",
        slideMenu: false,
    },
    {
        path: "/Agreements",
        name: "Acordos",
        icon: "fas fa-font rs-text-red",
        component: AgreementsIndex,
        layout: "/admin",
        slideMenu: false,
    },
    {
        path: "/Campaigns",
        name: "Campanhas",
        icon: "fas fa-font rs-text-red",
        component: CampaignsIndex,
        layout: "/admin",
        slideMenu: false,
    },
    {
        path: "/Services",
        name: "Serviços",
        icon: "fas fa-font rs-text-red",
        component: ServicesIndex,
        layout: "/admin",
        slideMenu: false,
    },
    {
        path: "/MasterWay",
        name: "MasterWay",
        icon: "fab fa-mixcloud rs-text-red",
        component: MasterWayIndex,
        layout: "/admin",
        slideMenu: true,
    },
    {
        path: "/Mail",
        name: "Email",
        icon: "fas fa-inbox rs-text-red",
        component: MailIndex,
        layout: "/admin",
        slideMenu: true,
    },
    {
        path: "/BannerText",
        name: "Banners Text",
        icon: "fab fa-mixcloud rs-text-red",
        component: TextBannerIndex,
        layout: "/admin",
        slideMenu: false,
    },
    {
        path: "/Configurations",
        name: "Configurações",
        icon: "fas fa-cogs rs-text-red",
        component: configsIndex,
        layout: "/admin",
    },

    




    //EXEMPLOS

    //{
    //    path: "/Base",
    //    name: "Base",
    //    icon: "ni ni-world-2 rs-text-red",
    //    component: Base,
    //    layout: "/admin",
    //},

    //{
    //    path: "/Dashboard1",
    //    name: "Dashboard",
    //    icon: "ni ni-tv-2 text-primary",
    //    component: Dashboard1,
    //    layout: "/admin",
    //},
    //{
    //    path: "/icons",
    //    name: "Icons",
    //    icon: "ni ni-planet text-blue",
    //    component: Icons,
    //    layout: "/admin",
    //},
    //{
    //    path: "/maps",
    //    name: "Maps",
    //    icon: "ni ni-pin-3 text-orange",
    //    component: Maps,
    //    layout: "/admin",
    //},
    //{
    //    path: "/user-profile",
    //    name: "User Profile",
    //    icon: "ni ni-single-02 text-yellow",
    //    component: Profile1,
    //    layout: "/admin",
    //},
    //{
    //    path: "/tables",
    //    name: "Tables",
    //    icon: "ni ni-bullet-list-67 text-red",
    //    component: Tables,
    //    layout: "/admin",
    //}

    //AUTH
    //Template for example
    /*
    ,
    */
    /*{
        path: "/login",
        name: "Login",
        icon: "ni ni-key-25 text-info",
        component: Login,
        layout: "/auth",
    },*/
    /*
    {
        path: "/register",
        name: "Register",
        icon: "ni ni-circle-08 text-pink",
        component: Register,
        layout: "/auth",
    },
    */
];
export default routes;
