import React from 'react';
import { FormGroup, Row, Col, Container, CardHeader, Card, CardBody, Button, Form, Input, FormFeedback } from "reactstrap";
import axios from 'axios';
import { notification, ApiError, Role, GetHeaderPut, Loading, GetHeader } from '../utils/Utilities';
import Select from 'react-select';

class NewDoc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            workshops: [],
            loading: false,
            formDoc: props.fromDoc === true ? { id: 0, name: '', nameFile: '', description: '', base64: '', workShopId: 0 } : props.fromDoc,
            edit: true,
            validate: {
                nameState: '',
                descriptionState: '',
                base64State: ''
            },
            selectedWorkshops: null
        };
    }

    componentDidMount() {
        this.getAllWorkshops();
    }

    onSelect(selected) {
        this.setState(state => ({ formDoc: { ...state.formDoc, "workShopId": selected?.id !== undefined && selected !== null ? +selected.id : 0 } }));
    }

    handleValue = (name, value) => { this.setState({ [name]: value }); }

    handleForm = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        if (name !== '') {
            this.setState(state => ({ formDoc: { ...state.formDoc, [name]: value } }));
        }
    };

    handleBase64 = (name, data) => {
        this.setState(state => ({ formDoc: { ...state.formDoc, base64: data } }));
        this.setState(state => ({ formDoc: { ...state.formDoc, nameFile: name } }));
    }

    validateString(e) {
        const { validate } = this.state;

        if (e.target.value !== null && e.target.value !== "") {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }

        this.setState(({ validate }));
    }

    getAllWorkshops() {
        this.handleValue('loading', true);
        var headers = GetHeader();
        axios.get(global.api_url + "Workshops", { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.handleValue('workshops', response.data);
                }
                this.handleValue('loading', false);
            }).catch(error => {
                this.handleValue('loading', false);
                ApiError(error, "Oficinas").then(call => call === true ? this.getAllWorkshops() : "");
            })
    }

    setDocumentation = (formDoc) => {
        if (this.validateForm(formDoc)) {

            this.handleValue("loading", true)
            var headers = GetHeaderPut();
            axios.post(global.api_url + "Documentation", formDoc, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        this.handleValue("loading", false)
                        this.props.onClick(false);
                    }
                    this.handleValue("loading", false)
                }).catch(error => {
                    this.handleValue("loading", false)
                    ApiError(error, "Novo Documento").then(call => call === true ? this.setDocumentation(formDoc) : "");
                });
        } else {
            notification("Documento", "Existem erros patra criar um novo documento.", "danger", null, "top-right", null, false);
        }
    }

    updateDoc = (formDoc) => {
        if (this.validateForm(formDoc, true)) {

            this.handleValue("loading", true)
            var headers = GetHeaderPut();
            axios.post(global.api_url + "Documentation/Update", formDoc, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        this.handleValue("loading", false)
                        this.props.onClick(false);
                    }
                    this.handleValue("loading", false)
                }).catch(error => {
                    this.handleValue("loading", false)
                    ApiError(error, "Novo Documento").then(call => call === true ? this.setDocumentation(formDoc) : "");
                });
        } else {
            notification("Documento", "Existem erros patra criar um novo documento.", "danger", null, "top-right", null, false);
        }

    }

    validateForm = (formDoc, ignoreImage = false) => {
        const { validate } = this.state;
        var valid = false;
        if (formDoc.name !== '') {
            if (ignoreImage === false) {
                if (formDoc.base64 !== '' && formDoc?.base64?.startsWith('data:application/pdf;base64')) {
                    valid = true;

                } else {
                    validate['base64State'] = 'has-danger';
                    valid = false;
                }
            } else {
                valid = true;
            }
        } else {
            validate['nameState'] = 'has-danger';
            valid = false;
        }
        return valid;
    }


    render() {
        const { formDoc, validate, currentUser, edit, loading, workshops } = this.state;
        function getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }

        return (
            <>
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col>
                                            {formDoc.id === 0 ?
                                                <h3 className="mb-0">Adicionar Documentação</h3>
                                                : <h3 className="mb-0">Editar Documentação</h3>}
                                        </Col>
                                        <Col className="text-right" xl="4">
                                            {currentUser.role === Role.Admin && formDoc.id === 0 ?
                                                <Button color="success" size="sm" onClick={(e) => { this.setDocumentation(formDoc) }}><i className="fas fa-save"></i>{' '} Guardar</Button>
                                                :
                                                <Button color="success" size="sm" onClick={(e) => { this.updateDoc(formDoc) }}><i className="fas fa-save"></i>{' '} Atualizar</Button>

                                            }
                                            <Button color="danger" size="sm" onClick={(e) => { this.props.onClick(false); }}><i className="far fa-window-close"></i>{' '} Fechar</Button>
                                        </Col>

                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {Loading(loading)}
                                    <Form>
                                        <div>

                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label" htmlFor="input-title" >
                                                            Titulo
                                                        </label>
                                                        <Input type="text" name="name" id="name"
                                                            valid={validate.nameState === "has-success"}
                                                            invalid={validate.nameState === "has-danger"}
                                                            value={formDoc.name}
                                                            onChange={(e) => { this.validateString(e); this.handleForm(e); }}
                                                            disabled={!edit}
                                                            required
                                                        />
                                                        <FormFeedback>
                                                            Título inválido.
                                                        </FormFeedback>
                                                        <FormFeedback valid>
                                                            Título válido.
                                                        </FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label" htmlFor="input-title" >
                                                            Descrição
                                                        </label>
                                                        <Input type="text" name="description" id="description"
                                                            valid={validate.descriptionState === "has-success"}
                                                            invalid={validate.descriptionState === "has-danger"}
                                                            value={formDoc.description}
                                                            onChange={(e) => { this.validateString(e); this.handleForm(e); }}
                                                            disabled={!edit}
                                                            required
                                                        />
                                                        <FormFeedback>
                                                            Descrição inválida.
                                                        </FormFeedback>
                                                        <FormFeedback valid>
                                                            Descrição válida.
                                                        </FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label" htmlFor="input-defaultWorkshop">
                                                            Oficina que pode visualizar
                                                        </label >
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            isLoading={loading}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            placeholder="Selecionar oficina"
                                                            options={workshops}
                                                            getOptionLabel={(option) => `${option.name}`}
                                                            onChange={(e) =>
                                                                //console.log(e)
                                                                this.onSelect(e)


                                                            }
                                                            value={
                                                                formDoc?.workShopId !== 0 ?
                                                                    workshops.filter(option =>
                                                                        option.id === formDoc.workShopId)
                                                                    : null
                                                            }
                                                        />

                                                    </FormGroup>
                                                </Col>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <label className="form-control-label" htmlFor="base64" >
                                                            PDF
                                                        </label>
                                                        <Input type="file" accept=".pdf" name="base64" id="base64"
                                                            valid={validate.base64State === "has-success"}
                                                            invalid={validate.base64State === "has-danger"}
                                                            onChange={(e) => {
                                                                if (e.target.files.length > 0) {
                                                                    getBase64(e.target.files[0]).then(data => {
                                                                        this.handleBase64(e.target.files[0].name, data);
                                                                    });
                                                                }
                                                            }}
                                                            disabled={!edit}
                                                            required
                                                        />
                                                        <FormFeedback>
                                                            PDF inválido.
                                                        </FormFeedback>
                                                        <FormFeedback valid>
                                                            PDF válido.
                                                        </FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </>);
    }
}

export default NewDoc;

