import React from 'react';
import { Card, Button, CardBody, CardTitle, CardText, FormGroup, Form, Input, FormFeedback, Row, Col } from "reactstrap";
import { ApiError, GetHeader, Loading, notification } from "../utils/Utilities";
import axios from 'axios';

class HaynesProConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            currentWorkShopConfigs: props.currentWorkShopConfigs,
            edit: false,
            loadingData: false,
            validate: {
                emailState: '',
                passwordState: ''
            },
            haynesProConfig: {
                workShopId: props.currentWorkShopConfigs?.workshopId,
                salesMargin: props.currentWorkShopConfigs?.salesMargin
            }
        }

        console.log(props.currentWorkShopConfigs)
    }


    validateString(e) {
        const { validate } = this.state;
        if (e.target.value !== null && e.target.value !== "") {
            if (e.target.name === 'salesMargin') {
                if (e.target.value >= 0) {
                    validate[e.target.name + 'State'] = 'has-success';
                } else { validate[e.target.name + 'State'] = 'has-danger'; }
            } else { validate[e.target.name + 'State'] = 'has-success'; }
        }
        else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState(({ validate }));
    }

    handleValue = async (name, value) => { await this.setState({ [name]: value }); return value; }
    handleCurrentWorkShopConfigs = (e) => {
        this.setState(state => ({ haynesProConfig: { ...state.haynesProConfig, [e.target.name]: e.target.value } }));
    }
    changeEdit = (state) => { this.handleValue('edit', state); }

    oldValues = () => {
        this.setState(state => ({ haynesProConfig: { ...state.haynesProConfig, salesMargin: this.state.currentWorkShopConfigs.salesMargin } }));
    }


    saveConfig = () => {
        const { haynesProConfig } = this.state;

        this.handleValue('loadingData', true);
        var headers = GetHeader();
        axios.post(global.api_url + "WorkshopConfigs/ChangeHaynesProConfig", haynesProConfig, { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.handleValue('currentWorkShopConfigs', response.data);
                    notification("Configurações Haynespro", "Dados atualizados com sucesso!", "success", null, "top-right", null, false);
                }
                this.cleanValidate();
                this.handleValue('loadingData', false);
            }).catch(error => {
                this.handleValue('loadingData', false);
                ApiError(error, "Configurações").then(call => call === true ? this.saveConfig() : "");
            })

    }

    cleanValidate = () => {
        this.setState({ validate: { webSiteLeirilisState: '', userLeirilisState: '', pwLeirilisState: '' } })
    }

    render() {
        const { edit, validate, loadingData, haynesProConfig } = this.state;

        return (
            <Card style={{ height: '100%' }}>
                {Loading(loadingData)}
                <CardBody>
                    <CardTitle tag="h3">
                        Confirgurações HaynesPro
                    </CardTitle>
                    <CardText>
                        <Form>

                            <Row>
                                <Col lg="12">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="input-userLeirilis" >
                                            Margem Venda (%):
                                        </label>
                                        <Input type="number" name="salesMargin" id="salesMargin"
                                            valid={validate.salesMarginState === "has-success"}
                                            invalid={validate.salesMarginState === "has-danger"}
                                            value={haynesProConfig.salesMargin}
                                            onChange={(e) => { this.validateString(e); this.handleCurrentWorkShopConfigs(e); }}
                                            disabled={!edit}
                                            required
                                        />
                                        <FormFeedback>
                                            Margem inválida.
                                        </FormFeedback>
                                        <FormFeedback valid>
                                            Margem válida.
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                {/*<Col lg="6">*/}
                                {/*    <FormGroup>*/}
                                {/*        <label className="form-control-label" htmlFor="input-pwLeirilis" >*/}
                                {/*            Password*/}
                                {/*        </label>*/}
                                {/*        <Input type="password" name="Password" id="Password"*/}
                                {/*            valid={validate.passwordState === "has-success"}*/}
                                {/*            invalid={validate.passwordState === "has-danger"}*/}
                                {/*            value={emailLogin.Password}*/}
                                {/*            onChange={(e) => { this.validateString(e); this.handleCurrentWorkShopConfigs(e); }}*/}
                                {/*            disabled={!edit}*/}
                                {/*            required*/}
                                {/*        />*/}
                                {/*        <FormFeedback>*/}
                                {/*            Password inválido.*/}
                                {/*        </FormFeedback>*/}
                                {/*        <FormFeedback valid>*/}
                                {/*            Password válido.*/}
                                {/*        </FormFeedback>*/}
                                {/*    </FormGroup>*/}
                                {/*</Col>*/}
                            </Row>

                        </Form>

                    </CardText>
                    <div className="text-right">
                        {!edit && <Button color="danger" size="sm" onClick={(e) => { e.preventDefault(); this.handleValue('edit', true) }}><i className="far fa-edit"></i> Editar</Button>}
                        {edit && <Button color="success" size="sm" onClick={(e) => { e.preventDefault(); this.handleValue('edit', false); this.saveConfig() }}><i className="far fa-save"></i> Guardar</Button>}
                        {edit && <Button color="primary" size="sm" onClick={(e) => { e.preventDefault(); this.handleValue('edit', false); this.oldValues() }}><i className="far fa-window-close"></i> Fechar</Button>}

                    </div>
                </CardBody>
            </Card>
        );
    }
}


export default HaynesProConfig;