import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal, ModalHeader, ModalBody, Label, Col, ButtonGroup, Row } from "reactstrap"
import { notification } from '../utils/Utilities';


class ModalFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkListFilter:
                props.checkListFilter !== null && props.checkListFilter !== undefined ?
                    props.checkListFilter : {
                        Validacao: true,
                        Pendente: true,
                        Aceite: true,
                        Rejeitado: true,
                        Oficina: true,
                        Concluido: true,
                        Entregue: true,
                        Cancelado: true,
                    },
        };
    }

    onchange = (e, result) => {
        this.setState(state => ({ checkListFilter: { ...state.checkListFilter, [e.target.id]: result } }),
            (response) => { })
    }

    render() {
        const { checkListFilter } = this.state;
        var mostrar = "Mostrar";
        var esconder = "Esconder";

        const returnVal = (val) => {
            switch (val) {
                case val === true && this.state.checkListFilter !== "":
                    this.props.onClick(val === true ? this.state.checkListFilter : null);
                    break;
                case false:
                    this.props.onClick(false);
                    break;
                default:
                    notification("Aviso", "Com os filtros todos escondidos, não é possível mostrar informação de agendamentos.", "warning", null, "top-right", null, false);
                    break;
            }
        }

        return (
            <Modal className="modal-dialog-centered" contentClassName="bg-RS-grey-modal" isOpen={true} size="xl" style={{ maxWidth: '600px', width: '100%' }}>
                <ModalHeader >
                    <h3 className="mb-0">Filtros de agendamento</h3>
                </ModalHeader>
                <ModalBody className="">
                    <Label for="checkbox2" >Estados de agendamento visíveis:</Label>
                    <Row style={{ paddingTop: 5 }}>
                        <Col xl="6">
                            Validação
                        </Col>
                        <Col xl="6">
                            <ButtonGroup>
                                <Button color={checkListFilter.Validacao === true ? "success" : "white"} size="sm" id="Validacao" onClick={(e) => this.onchange(e, !checkListFilter.Validacao)}>{mostrar}</Button>
                                <Button color={!checkListFilter.Validacao === true ? "success" : "white"} size="sm" id="Validacao" onClick={(e) => this.onchange(e, !checkListFilter.Validacao)}>{esconder}</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 5 }}>
                        <Col xl="6">
                            Pendente
                        </Col>
                        <Col xl="6">
                            <ButtonGroup>
                                <Button color={checkListFilter.Pendente === true ? "success" : "white"} size="sm" id="Pendente" onClick={(e) => this.onchange(e, !checkListFilter.Pendente)}>{mostrar}</Button>
                                <Button color={!checkListFilter.Pendente === true ? "success" : "white"} size="sm" id="Pendente" onClick={(e) => this.onchange(e, !checkListFilter.Pendente)}>{esconder}</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 5 }}>
                        <Col xl="6">
                            Aceite
                        </Col>
                        <Col xl="6">
                            <ButtonGroup>
                                <Button color={checkListFilter.Aceite === true ? "success" : "white"} size="sm" id="Aceite" onClick={(e) => this.onchange(e, !checkListFilter.Aceite)}>{mostrar}</Button>
                                <Button color={!checkListFilter.Aceite === true ? "success" : "white"} size="sm" id="Aceite" onClick={(e) => this.onchange(e, !checkListFilter.Aceite)}>{esconder}</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 5 }}>
                        <Col xl="6">
                            Rejeitado
                        </Col>
                        <Col xl="6">
                            <ButtonGroup>
                                <Button color={checkListFilter.Rejeitado === true ? "success" : "white"} size="sm" id="Rejeitado" onClick={(e) => this.onchange(e, !checkListFilter.Rejeitado)}>{mostrar}</Button>
                                <Button color={!checkListFilter.Rejeitado === true ? "success" : "white"} size="sm" id="Rejeitado" onClick={(e) => this.onchange(e, !checkListFilter.Rejeitado)}>{esconder}</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 5 }}>
                        <Col xl="6">
                            Oficina
                        </Col>
                        <Col xl="6">
                            <ButtonGroup>
                                <Button color={checkListFilter.Oficina === true ? "success" : "white"} size="sm" id="Oficina" onClick={(e) => this.onchange(e, !checkListFilter.Oficina)}>{mostrar}</Button>
                                <Button color={!checkListFilter.Oficina === true ? "success" : "white"} size="sm" id="Oficina" onClick={(e) => this.onchange(e, !checkListFilter.Oficina)}>{esconder}</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 5 }}>
                        <Col xl="6">
                            Concluido
                        </Col>
                        <Col xl="6">
                            <ButtonGroup>
                                <Button color={checkListFilter.Concluido === true ? "success" : "white"} size="sm" id="Concluido" onClick={(e) => this.onchange(e, !checkListFilter.Concluido)}>{mostrar}</Button>
                                <Button color={!checkListFilter.Concluido === true ? "success" : "white"} size="sm" id="Concluido" onClick={(e) => this.onchange(e, !checkListFilter.Concluido)}>{esconder}</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 5 }}>
                        <Col xl="6">
                            Entregue
                        </Col>
                        <Col xl="6">
                            <ButtonGroup>
                                <Button color={checkListFilter.Entregue === true ? "success" : "white"} size="sm" id="Entregue" onClick={(e) => this.onchange(e, !checkListFilter.Entregue)}>{mostrar}</Button>
                                <Button color={!checkListFilter.Entregue === true ? "success" : "white"} size="sm" id="Entregue" onClick={(e) => this.onchange(e, !checkListFilter.Entregue)}>{esconder}</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 5 }}>
                        <Col xl="6">
                            Cancelado
                        </Col>
                        <Col xl="6">
                            <ButtonGroup>
                                <Button color={checkListFilter.Cancelado === true ? "success" : "white"} size="sm" id="Cancelado" onClick={(e) => this.onchange(e, !checkListFilter.Cancelado)}>{mostrar}</Button>
                                <Button color={!checkListFilter.Cancelado === true ? "success" : "white"} size="sm" id="Cancelado" onClick={(e) => this.onchange(e, !checkListFilter.Cancelado)}>{esconder}</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 40 }} >
                        <Col xl="6" >
                            <Button size="sm" color="success" type="button" onClick={() => this.setState({
                                checkListFilter: {
                                    Validacao: true,
                                    Pendente: true,
                                    Aceite: true,
                                    Rejeitado: true,
                                    Oficina: true,
                                    Concluido: true,
                                    Entregue: true,
                                    Cancelado: true
                                }
                            })}><i className="fa fa-filter"></i> Mostrar tudo</Button>
                        </Col>
                        <Col xl="6" className="text-right">
                            <Button size="sm" color="success" type="button" onClick={() => returnVal(true)}><i className="fa fa-filter"></i> Aplicar</Button>
                            <Button size="sm" type="button" onClick={() => returnVal(false)}><i className="far fa-window-close"></i> Fechar</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal >
        )
    }
}

export default ModalFilter;