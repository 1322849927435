import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';


class ModalCarrousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: props.title,
            items: props.assets,
            activeIndex: 0
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    returnVal = () => {
        this.props.onClick();

    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    render() {
        const { title, items, activeIndex } = this.state;

        const slides = items.map((item) => {
            return (
                <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item.id}>
                    <img src={item.base64} alt={item.serviceId} />
                    {/*<CarouselCaption captionText={item.caption} captionHeader={item.caption} />*/}
                </CarouselItem>
            );
        });

        return (
            <Modal className="modal-dialog-centered" contentClassName="bg-RS-grey-modal" isOpen={true} size="xl" style={{ maxWidth: '600px', width: '100%' }}>
                <ModalHeader >
                    {title} - {activeIndex + 1}/{items.length}
                </ModalHeader>
                <ModalBody className="">
                    <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous}>
                        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                        {slides}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                    </Carousel>


                </ModalBody>
                <ModalFooter>
                    <Button size="sm" type="button" onClick={this.returnVal}><i className="far fa-window-close"></i> Fechar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ModalCarrousel;