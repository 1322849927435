import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Loading, notification, GetHeader, ApiError, StatusLine } from "../../utils/Utilities";
import { Table, UncontrolledTooltip, Button, Card } from "reactstrap"
import Line from './Line'
import logoHaynespro from '../../../../assets/RedService/HaynesPro_Logo.png';
//import logoDpAutomotive from '../../../../assets/RedService/DpAutomotive.png';

class LineList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            event: props.event,
            currentUser: props.currentUser,
            currentHeader: props.currentHeader !== null && props.currentHeader !== undefined ? props.currentHeader : null,
            currentService: props.currentService !== null && props.currentService !== undefined ? props.currentService : null,
            services: props.services,//null,
            erpConfigs: props.erpConfigs,
            edit: false,
            loading: false,
            lines: props.lines !== null ? props.lines : [],
            totaldocLines: 0,
            totalDocIva: 0,
            totalDocIliq: 0,
        }
        this.reload = this.reload.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.currentUser !== state.currentUser) {
            return { currentUser: props.currentUser };
        }
        return null;
    }

    componentDidMount = () => {
        this.calculateTotalDoc();
    }

    handleValue = (name, value) => { this.setState({ [name]: value }); }

    updateLine = async (line) => {
        const { lines } = this.state;
        let updatedList = lines.map(item => {
            if (item.id === line.id) {
                return {
                    ...item,
                    status: line.status,
                    serviceId: line.serviceId,
                    serviceName: line.serviceName,
                    prodRef: line.prodRef,
                    refTecDoc: line.refTecDoc,
                    prodDescription: line.prodDescription,
                    prodQtd: +line.prodQtd,
                    prodPrice: +line.prodPrice,
                    prodIva: +line.prodIva,
                    prodDiscount: +line.prodDiscount,
                    totalLine: +line.totalLine,
                    totalIva: + line.totalIva,
                };
            }
            return item;
        });

        await this.setState(prevState => ({ lines: updatedList }), () => { this.props.onChange(this.state.lines); });
    }
    addLine = () => {
        const { lines, currentService, currentHeader } = this.state;
        var create = true;
        lines.forEach(l => { if (l.prodRef === "") { create = false; } })

        if (create) {
            lines.push({
                id: +("-" + Math.floor((1 + Math.random()) * 0x1000).toString(6).substring(1)),
                serviceId: currentService !== null ? currentService.id : 0,
                serviceName: currentService !== null ? currentService.name : "",
                refTecDoc: '',
                referenceErp: '',
                prodQtd: 1,
                prodRef: '',
                prodDescription: '',
                prodIva: 0,
                prodDiscount: 0,
                prodPrice: 0,
                totalDiscount: 0,
                totalIva: 0,
                totalLine: 0,
                status: currentHeader?.diary === "WORKSHEET" ? StatusLine.Aceite : StatusLine.Pendente,
                accept: currentHeader?.diary === "WORKSHEET" ? true : false,
            })
            this.setState({ lines: lines }, () => { this.props.onChange(this.state.lines); });
        } else { notification("Documento", "Existe uma linha do documento sem Referência, Poderá utilizar essa linha para adiconar um novo produto.", "warning", null, "top-right", null, false); }
    }

    removeLine = (line) => {
        const { lines } = this.state;
        const updatedLines = lines.filter(item => item.id !== line.id);

        //let newList = lines.filter(item => item.id !== line.id);

        //this.setState({ lines: newList }, () => { this.calculateTotalDoc(); this.props.onChange(newList, line); });
        this.setState({ lines: [] }, () => {
            this.setState({ lines: updatedLines }, () => { this.calculateTotalDoc(); this.props.onChange(updatedLines, line); });
        })
    }

    calculateTotalDoc = () => {
        const { lines } = this.state;
        let valTotalLine = 0;
        let valTotalDocIva = 0;
        let valTotalDocIliq = 0;
        lines.forEach(l => {
            valTotalLine += +l.totalLine;
            valTotalDocIva += +l.totalIva;
            valTotalDocIliq += +l.prodQtd * + l.prodPrice
        });

        this.handleValue('totaldocLines', valTotalLine.toFixed(2));
        this.handleValue('totalDocIva', valTotalDocIva.toFixed(2));
        this.handleValue('totalDocIliq', valTotalDocIliq.toFixed(2));
    }

    reload = (line) => { this.updateLine(line).then(a => { this.calculateTotalDoc(); }); }

    getWorkShopConfigs = async () => {
        this.handleValue('loading', true);
        var headers = GetHeader();
        return axios.get(global.api_url + "WorkshopConfigs", { headers })
            .then((response) => {
                this.handleValue('loading', false);
                return response.data
            }).catch(error => {
                this.handleValue('loading', false);
                ApiError(error, "Configurações").then(call => call === true ? this.getWorkShopConfigs() : "");
                return null;
            })
    }

    openHaynesPro = (scheduleId) => {
        var { event } = this.state;
        if (event?.resources?.vehicule?.plate !== undefined) {

            this.getWorkShopConfigs().then(response => {
                if (response !== null) {
                    this.handleValue('loading', true);
                    //var headers = GetHeader();
                    axios.post(global.portal_Leirilis + "desktopmodules/NBright/API/OpenStore/SilenceAuth", { username: response.userLeirilis, password: response.pwLeirilis, url: global.api_url })
                        .then((auth) => {
                            this.handleValue('loading', false);
                            if (auth.status === 200) {
                                window.open(global.portal_Leirilis + "pt-pt/Pe%C3%A7as/categorias/platenumber/" + event?.resources?.vehicule?.plate + "?r=" + auth.data + "&rs=" + GetHeader().Authorization + "&s=" + scheduleId, "_blank");
                                notification("Portal DpAutomotive", "HaynesPro foi aberto no portal Leirilis.", "success", null, "top-right", null, false);
                            }
                        }).catch(error => {
                            notification("Login Portal DpAutomotive", "Verificar username e password nas configurações.", "danger", null, "top-right", null, false);
                            this.handleValue('loading', false);
                        });
                } else {
                    notification("Definições da conta", "Não foi possível obter as definições da conta.", "danger", null, "top-right", null, false);
                }
            });
        } else {
            notification("HaynesPro", "Não foi possível obter a matrícula.", "warning", null, "top-right", null, false);
        }
    }

    openDpautomotive = (scheduleId) => {
        var { event } = this.state;
        if (event?.resources?.vehicule?.plate !== undefined) {

            this.getWorkShopConfigs().then(response => {
                if (response !== null) {
                    this.handleValue('loading', true);
                    //var headers = GetHeader();
                    axios.post(global.portal_Leirilis + "desktopmodules/NBright/API/OpenStore/SilenceAuth", { username: response.userLeirilis, password: response.pwLeirilis, url: global.api_url })
                        .then((auth) => {
                            this.handleValue('loading', false);
                            if (auth.status === 200) {
                                window.open(global.portal_Leirilis + "pt-pt/Pe%C3%A7as/categorias/platenumber/" + event?.resources?.vehicule?.plate + "?r=" + auth.data + "&rs=" + GetHeader().Authorization + "&s=" + scheduleId + "&haynes=false", "_blank");
                                notification("Portal DpAutomotive", "HaynesPro foi aberto no portal Leirilis.", "success", null, "top-right", null, false);
                            }
                        }).catch(error => {
                            notification("Login Portal DpAutomotive", "Verificar username e password nas configurações.", "danger", null, "top-right", null, false);
                            this.handleValue('loading', false);
                        });
                } else {
                    notification("Definições da conta", "Não foi possível obter as definições da conta.", "danger", null, "top-right", null, false);
                }
            });
        } else {
            notification("HaynesPro", "Não foi possível obter a matrícula.", "warning", null, "top-right", null, false);
        }
    }

    render() {
        const { erpConfigs, loading, lines, services, totaldocLines, totalDocIliq, totalDocIva, currentHeader, currentService, currentUser, event } = this.state;
        return (
            <Card className="shadow" style={{ minHeight: '500px', height: '450px' }}>
                <Table className="align-items-center table-flush" responsive size="sm">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col-md" style={{ width: "5%", minWidth: 50, maxWidth: 100, textAlign: 'left' }}>Estado</th>
                            <th scope="col-md" style={{ width: "5%", minWidth: 50, maxWidth: 100, textAlign: 'left' }}>ERP</th>
                            <th scope="col-md" style={{ width: "25%", minWidth: 300, maxWidth: 900, textAlign: 'left' }}>Serviço</th>
                            <th scope="col-md" style={{ width: "5%", minWidth: 200, maxWidth: 200, textAlign: 'left' }}>Referência</th>
                            <th scope="col-md" style={{ width: "5%", minWidth: 200, maxWidth: 200, textAlign: 'left' }}>TecDoc</th>
                            <th scope="col-md" style={{ width: "25%", minWidth: 300, maxWidth: 900, textAlign: 'left' }}>Descrição</th>
                            <th scope="col-md" style={{ width: "5%", minWidth: 150, maxWidth: 150, textAlign: 'left' }}>Quantidade</th>
                            <th scope="col-md" style={{ width: "5%", minWidth: 200, maxWidth: 250, textAlign: 'left' }} >Preço</th>
                            <th scope="col-md" style={{ width: "5%", minWidth: 150, maxWidth: 150, textAlign: 'left' }} >Taxa Iva</th>
                            <th scope="col-md" style={{ width: "5%", minWidth: 150, maxWidth: 150, textAlign: 'left' }}>Desconto</th>
                            <th scope="col-md" style={{ width: "5%", minWidth: 200, maxWidth: 250, textAlign: 'left' }}>Total</th>
                            <th scope="col-md" className="text-right">

                                {/*<th scope="col-1">Estado</th>*/}
                                {/*<th scope="col-1">ERP</th>*/}
                                {/*<th scope="col-1">Serviço</th>*/}
                                {/*<th scope="col-1">Referência</th>*/}
                                {/*<th scope="col-1">TecDoc</th>*/}
                                {/*<th scope="col-1">Descrição</th>*/}
                                {/*<th scope="col-1">Quantidade</th>*/}
                                {/*<th scope="col-1">Preço</th>*/}
                                {/*<th scope="col-1">Taxa Iva</th>*/}
                                {/*<th scope="col-1">Desconto</th>*/}
                                {/*<th scope="col-1">Total</th>*/}
                                {/*<th scope="col-1" className="text-right">*/}

                                {currentHeader?.generated !== null && currentHeader?.generated !== undefined && currentHeader?.diary === "BUDGET" ?
                                    <>
                                        <Button id="btn_add_Line" size="sm" color="success" onClick={(e) => { this.addLine(); }}><i className="fas fa-plus"></i></Button>
                                    </> :
                                    <>
                                        {((currentHeader?.diary === "BUDGET" && (currentHeader?.reference === undefined || currentHeader?.reference === "")) ||
                                            (currentHeader?.diary === "WORKSHEET" && (currentHeader?.reference === undefined || currentHeader?.reference === ""))) &&


                                            <>
                                                {/*<Button id="btn_DpAutomotive" color="info" size="sm" name="update" type="button" onClick={e => { this.openDpautomotive(event.id !== undefined ? event.id : null) }} >
                                                    <img src={logoDpAutomotive} style={{ maxHeight: '20px' }} />
                                                </Button>
                                                <UncontrolledTooltip delay={0} placement="top" target="btn_DpAutomotive">
                                                    Abrir identificação de veículo no Portal Leirilis
                                                </UncontrolledTooltip>*/}

                                                <Button id="btn_HaynesPro" color="info" size="sm" name="update" type="button" onClick={e => { this.openHaynesPro(event.id !== undefined ? event.id : null) }} >
                                                    <img src={logoHaynespro} style={{ width: '75px' }} alt="HaynsProLogo" />
                                                </Button>
                                                <UncontrolledTooltip delay={0} placement="top" target="btn_HaynesPro">
                                                    Orçamento HaynesPro no Portal DpAutomotive - Leirilis
                                                </UncontrolledTooltip>
                                                <Button id="btn_add_Line" size="sm" color="success" onClick={(e) => { this.addLine(); }}><i className="fas fa-plus"></i></Button>
                                                <UncontrolledTooltip delay={0} placement="top" target="btn_add_Line">
                                                    Adicionar nova linha
                                                </UncontrolledTooltip>
                                            </>
                                        }
                                    </>}
                            </th>
                        </tr>
                    </thead>
                    <tbody style={{ height: '100%' }} >
                        {lines.length > 0 ? (<>
                            {lines.map((line, key) => {
                                return <Line erpConfigs={erpConfigs} line={line} event={event} services={services} currentHeader={currentHeader} currentUser={currentUser} currentService={currentService} key={key} onClick={this.reload} removeLine={this.removeLine} />
                            })}
                        </>) : Loading(loading)}
                    </tbody>
                    <tfoot style={{ backgroundColor: 'aliceblue' }}>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Total Iva</td>
                            <td>{totalDocIva}</td>
                            <td></td>
                            <td>Total Iliquido</td>
                            <td>{totalDocIliq}</td>
                            <td></td>
                            <td>Total</td>
                            <td>{totaldocLines}</td>
                        </tr>
                    </tfoot>
                </Table>
            </Card >
        )
    }
}

export default LineList;