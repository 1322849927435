import React, { useState } from 'react';
import { CurrentUser } from "views/backoffice/utils/Utilities";
import Header from '../../../components/Headers/Header';
import NewDoc from './NewDoc';
import SelectDoc from './SelectDoc';
import ShowDoc from './ShowDoc';

const Index = (props) => {
    const currentUser = CurrentUser();
    const [currentDoc, setCurrentDoc] = useState(null);
    const [newDoc, setNewDoc] = useState(false);
    const changeCurrentDoc = (doc) => { setCurrentDoc(doc); }
    const addDocument = (doc) => { setNewDoc(doc) }

    return (
        <>
            <Header subText="Documentação disponibilizada pela RedService" />
            {currentDoc !== null ?
                <ShowDoc currentDoc={currentDoc} onChange={changeCurrentDoc} />
                :
                newDoc === false ?
                    <SelectDoc onChange={changeCurrentDoc} currentUser={currentUser} onClick={addDocument} />
                    :
                    <NewDoc currentUser={currentUser} onClick={addDocument} fromDoc={newDoc} />

            }
        </>
    );
}

export default Index;

