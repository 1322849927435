import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, UncontrolledTooltip } from "reactstrap"

class ProductLine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: props?.product
        }
    }


    render() {
        const { product } = this.state;

        return (
            <tr>
                <td >
                    {product?.codProduto }
                </td>
                <td >
                    {product?.produtoDesignacao}
                </td>
                <td >
                    {Number(product?.precoVenda)?.toFixed(2)}
                </td>
                <td >
                    {product?.codIva}
                </td>
                {/*<td >*/}
                {/*    {product?.pesoUnitario}*/}
                {/*</td>*/}
                {/*<td >*/}
                {/*    {product?.centroCusto}*/}
                {/*</td>*/}
                
                <td className="text-right">
                    <Button id="btn_select" size="sm" color="success" onClick={(e) => { this.props.selectedProduct(product) }}><i className="far fa-check-circle"></i></Button>
                    <UncontrolledTooltip delay={0} placement="top" target="btn_select">
                        Selecionar produto
                    </UncontrolledTooltip>
                </td >
            </tr >
        )
    }
}

export default ProductLine;