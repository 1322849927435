import React, { useEffect } from 'react';
import { CurrentUser } from "views/backoffice/utils/Utilities";
import { Container } from "reactstrap";
import Cupons from '../Cupons/Cupons';
import Header from '../../../components/Headers/Header';
import JoinUs from './JoinUs';


const Index = (props) => {
    const currentUser = CurrentUser();



    return (
        <>
            <Header />
            <JoinUs />
        </>);
}

export default Index;

