import React, { useEffect, useState } from 'react';
import AdminLayout from "../layouts/Admin";
import Auth from "../layouts/Auth.js";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from './ProtectedRoute'
import axios from 'axios';
import { useDispatch } from 'react-redux'
import { addAccount } from '../store/accounts/accounts.js';
import { Role, ApiError, RemoveUser, CurrentUser } from "views/backoffice/utils/Utilities";

function App() {
    const dispatch = useDispatch();
    global.dispatch = dispatch;
    const [logged, setLogged] = useState(false);

    const currentUser = CurrentUser();

    useEffect(() => {
        //Refresh login after load (F5)
        const refreshLogin = () => {
            if (localStorage.getItem('refreshToken') !== null && localStorage.getItem('refreshToken') !== undefined && localStorage.getItem('refreshToken') !== "" && localStorage.getItem('jwtToken') !== null && localStorage.getItem('jwtToken') !== undefined && localStorage.getItem('jwtToken') !== "") {
                var headers = { 'Accept': '*', 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
                axios.post(global.api_url + "Accounts/refresh-token", { 'token': localStorage.getItem('refreshToken') }, { headers }).then(response => {
                    if (response.status === 200) {
                        localStorage.setItem('refreshToken', response.data.refreshToken);
                        localStorage.setItem('jwtToken', response.data.jwtToken);
                        dispatch(addAccount(response.data));
                        setLogged(true);
                        return (<Redirect to="/admin/Dashboard" />)
                    };

                }).catch(error => {
                    ApiError(error, "").then(call => call === true ? this.refreshLogin() : "");
                    RemoveUser(dispatch);
                });
            }
        }
        refreshLogin();
    }, []);


    return (
        <BrowserRouter>
            <Switch>
                <ProtectedRoute role={[Role.Admin, Role.Oficina, Role.GestorOficina, Role.Mecanico]} path={"/admin"} render={(props) => <AdminLayout {...props} />} />
                <Route path={currentUser === null ? "/login" : "/auth/logout"} render={(props) => <Auth {...props} />} />
                <Redirect from="/" to={currentUser === null ? "/login" : "/auth/logout"} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;