import React from 'react';
import { Button } from "reactstrap";
const RowTable = (props) => {


    const remove = (a) => {
        props.toRemove(a);
    }


    return (
        <tr>
            <th scope="row">
                <img src={props.data?.image} style={{ maxHeight: 50 }} alt={"image" + Math.random()} />
            </th>
            <td>
                {props.data?.title}
            </td>
            <td className="text-right">
                <Button color="success" size="sm" onClick={(e) => { props.onClick(props.data) }}><i className="fas fa-eye"></i>{' '} Visualizar</Button>
                <Button color="danger" size="sm" onClick={(e) => { remove(props.data) }}><i className="fas fa-trash"></i>{' '} Eliminar</Button>
            </td>

        </tr >
    );
}

export default RowTable;