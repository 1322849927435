import React from 'react';
import { Container, Card, CardHeader, Row, Col, Button } from "reactstrap";
import { GetHeader, ApiError, notification } from "../../utils/Utilities";
import axios from 'axios';
import EditPage from './EditPage';


class Backoffice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: props.language,
            loading: false,
            jsonObject: null,
            editJsonObject: null,
            objectName: null
        }
    }

    componentDidMount() {
        this.GetJsonLanguage(this.state.language);
    }

    GetJsonLanguage = (lng) => {
        var headers = GetHeader();
        axios.get(global.api_url + "FrontEnd/" + lng?.value, { headers })
            .then((response) => {
                if (response.status === 200) {
                    var data = response.data;
                    this.setState({ jsonObject: data })
                }
            }).catch(error => {
                ApiError(error, "Json").then(call => call === true ? this.GetJsonLanguage(lng) : "");
            })
    }

    onChange = (json) => {
        this.setState({ editJsonObject: json }, () => { });
    }

    saveJsonFile = (e) => {
        this.setState(state => ({ jsonObject: { ...state.jsonObject, [this.state.objectName]: this.state.editJsonObject } }), () => {
            this.postJsonLanguage(this.state.language?.value, JSON.stringify(this.state.jsonObject));
        });

    }

    postJsonLanguage = (language, json) => {
        var headers = GetHeader();
        axios.post(global.api_url + "FrontEnd", { language: language, json: json }, { headers })
            .then((response) => {
                if (response.status === 200) {
                    var data = response.data;
                    this.setState({ jsonObject: data })
                    notification("Textos", "Texto atualizado com sucesso.", "success", null, "top-right", null, false);
                }
            }).catch(error => {
                ApiError(error, "Json").then(call => call === true ? this.postJsonLanguage(language, json) : "");
            })
    }


    render() {
        const { language, jsonObject, editJsonObject } = this.state;

        return (

            <Container className="mt--7" fluid>
                <div className="col">
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="heading-small text-muted mb-4">Linguagem - {language?.name}</h6>
                                </Col>
                                <Col className="text-right" xl="4">
                                    {editJsonObject === null ?
                                        <Button color="success" size="sm" onClick={(e) => { this.props.onClick(e) }}><i className="far fa-window-close"></i>{' '} Fechar</Button>
                                        :
                                        <>
                                            <Button color="success" size="sm" onClick={(e) => { this.setState({ editJsonObject: null }); this.setState({ objectName: null }); }}><i className="fas fa-chevron-circle-left"></i>{' '} Voltar</Button>
                                            <Button color="success" size="sm" onClick={(e) => { this.saveJsonFile(e) }}><i className="fas fa-save"></i>{' '} Guardar</Button>
                                        </>
                                    }
                                </Col>
                            </Row>
                        </CardHeader>
                        <div style={{ padding: 20 }}>
                            {editJsonObject === null && jsonObject !== null &&
                                <>
                                    {Object.keys(jsonObject).map(key => {
                                        if (!["Advices", "Campaigns", "Agreements"].includes(key)) {


                                            return (
                                                <Button key={Math.random(0, 500)} color="success" size="sm" onClick={(e) => {
                                                    this.setState({ editJsonObject: jsonObject[key] });
                                                    this.setState({ objectName: key });
                                                }}><i className="fas fa-eye"></i>{' '} {key}</Button>
                                            );
                                        }
                                    })}
                                </>
                            }
                            {editJsonObject !== null &&
                                <EditPage editJsonObject={editJsonObject} onChange={this.onChange} />
                            }
                        </div>
                    </Card>
                </div>
            </Container>

        );
    }
}


export default Backoffice;