import { Card, CardHeader, CardFooter, Table, Container, Row, Col, Button, Input, InputGroup } from "reactstrap";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "components/Headers/Header.js";
import WorkshopsList from "views/backoffice/Workshops/WorkshopsList";
import { Loading, GetHeader, ApiError } from "../utils/Utilities";
import CustomPagination from "views/backoffice/utils/CustomPagination";


const Workshops = (props) => {
    const [workshops, setWorkshops] = React.useState([]);
    const [pagination, setPagination] = React.useState(null);
    const [loadingData, setLoadingData] = useState(true);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(props?.location?.state?.currentPage !== undefined ? props?.location?.state?.currentPage : 1);

    const click_OpenWorshopFormEmpety = (e) => {
        e.preventDefault();
        props.history.push("/admin/Workshop", { create: true });
    }

    const getAllWorkshops = async (pageNumber, pageSize) => {
        setLoadingData(true);
        var headers = GetHeader();
        await axios.get(global.api_url + "Workshops?PageNumber=" + pageNumber + "&PageSize=" + pageSize + "&Search=" + search, { headers })
            .then((response) => {
                if (response.headers["x-pagination"]) {
                    setPagination(JSON.parse(response.headers["x-pagination"]));
                }
                const workshopsData = response.data;
                setWorkshops(workshopsData);
                setLoadingData(false);

            }).catch(error => {
                setLoadingData(false)
                ApiError(error, "Ofina").then(call => call === true ? getAllWorkshops(pageNumber, pageSize) : "");
            })
    }

    const changeHandler = (pageNumber, pagesize) => {
        setCurrentPage(pageNumber);
        getAllWorkshops(pageNumber, pagesize)
    }

    const searchWorkShop = (search) => {
        getAllWorkshops(currentPage, 10, search === null ? "" : search);
    }

    const load = (workshop, newStatus, newAvailable) => {
        const update = workshops.map(obj => { if (obj.id === workshop.id) { return { ...obj, active: newStatus, available: newAvailable }; } return obj; });
        setWorkshops([]);
        setWorkshops(update);
    }


    useEffect(() => {
        if (currentPage !== null && currentPage !== undefined)
            getAllWorkshops(currentPage, 10, "");
    }, []);

    return (
        <>
            <Header />
            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <Col xl="4">
                                        <h3 className="mb-0">Rede de Oficinas</h3>
                                    </Col>
                                    <Col className="text-right" xl="4">
                                        <InputGroup>
                                            <Input type="text" name="seacrh" id="input-seacrh"
                                                bsSize="sm"
                                                value={search}
                                                placeholder="Pesquisar oficina"
                                                onChange={(e) => setSearch(e.target.value !== null && e.target.value !== undefined ? e.target.value : "")}
                                                onKeyDown={(e) => { if (e.key === 'Enter') { searchWorkShop(search) } }}
                                                disabled={false}
                                            />
                                            {/*<InputGroupText addonType="append">*/}
                                            <Button size="sm" color="success" id="btn_search" onClick={(e) => {
                                                searchWorkShop(search);
                                            }}><i className="fas fa-search"></i></Button>
                                            {/*</InputGroupText>*/}
                                        </InputGroup>
                                    </Col>

                                    <Col className="text-right" xl="4">
                                        <Button color="success" onClick={(e) => click_OpenWorshopFormEmpety(e)} size="sm"><i className="fas fa-plus-circle"></i> Adicionar Oficina</Button>
                                    </Col>
                                </Row>
                            </CardHeader>

                            {loadingData ? (Loading(loadingData)) : (
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Nome</th>
                                            <th scope="col">Morada</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Contacto</th>
                                            <th scope="col">Ativa</th>
                                            <th scope="col">Agendamento</th>
                                            <th scope="col" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {workshops.map((workshop, key) => { return <WorkshopsList key={key} props={props} workshop={workshop} currentPage={currentPage} onChange={load} /> })}
                                    </tbody>
                                </Table>
                            )}
                            <CardFooter className="py-4">
                                {pagination !== null /*&& workshops.length > 0 */ ? (
                                    <CustomPagination pagination={pagination} array={workshops} onChange={changeHandler} />
                                ) : null}

                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Workshops;
