// reactstrap components
import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupText, InputGroup, Row, Col, Modal } from "reactstrap";
import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux'
import { addAccount, removeAllAccounts } from 'store/accounts/accounts.js';
import { Redirect } from "react-router-dom";
import { Role, notification, joinSignalR } from "views/backoffice/utils/Utilities";

const Login = () => {
    const dispatch = useDispatch();
    const [form, setForm] = useState({ email: '', password: '' });
    const updateField = e => { setForm({ ...form, [e.target.name]: e.target.value }); };

    const [modalVisible, setModalVisible] = useState(false);
    const toggleModal = () => { setModalVisible(!modalVisible); };

    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const toggleSpinner = (state) => { setSpinnerVisible(state); };

    const [result/*, setResult*/] = useState([]);
    //const changeResult = (data) => { setResult(data) };

    const click_Login = function (event) {
        event.preventDefault();
        toggleSpinner(true);
        axios.post(global.api_url + "Accounts/authenticate", form)
            .then(response => {
                global.currentUser = response;
                //changeResult(response.status === 200 ? true : false);
                //toggleModal();
                if (response.status === 200) {
                    setForm({ email: '', password: '' })
                    if (response.data.role !== Role.User) {
                        localStorage.setItem('refreshToken', response.data.refreshToken)
                        localStorage.setItem('jwtToken', response.data.jwtToken)
                        dispatch(addAccount(response.data));
                        //toggleModal();
                        joinSignalR(response.data.id, response.data.defaultWorkshop);
                        notification("Autenticação", "Login efetuado com sucesso.", "success", null, "top-right", null, false);
                        window.location.replace(window.location.origin + '/admin/Dashboard');
                        //return (<Route to="/admin/Dashboard" />)
                    } else {
                        dispatch(removeAllAccounts());
                        localStorage.setItem('refreshToken', '');
                        localStorage.setItem('jwtToken', '');
                        localStorage.setItem('path', '');
                        notification("Autenticação", "Não tem permissões para efetuar login com esta conta no backoffice.", "warning", null, "top-right", null, false);
                    }
                }

            })
            .catch(error => {
                notification("Autenticação", error.response?.data?.message !== undefined ? error.response?.data?.message : "Erro na API.", "danger", null, "top-right", null, false);
            }).then(() => {
                toggleSpinner(false);
            })
    };


    //const joinSignalR = async (accountId, workshopId) => {
    //    try {
    //        const connectionSignalR = new HubConnectionBuilder()
    //            .withUrl(global.api_url + "hub")
    //            .configureLogging(LogLevel.Information)
    //            .build();

    //        connectionSignalR.on("ReceiveMessage", (accountId, workshopId) => {
    //            console.log("message received " + accountId + " - " + workshopId);
    //        });
    //        await connectionSignalR.start();

    //        connectionSignalR.invoke("joinRoom", {accountId: +accountId, workshopId: +workshopId })

    //        global.connectionSignalR = connectionSignalR;

    //    } catch (e) {
    //        notification("SignalR", e, "warning", null, "top-right", null, false);
    //    }
    //}

    return (
        <>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>Login</small>
                        </div>
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupText >
                                        <InputGroupText>
                                            <i className="ni ni-email-83" />
                                        </InputGroupText>
                                    </InputGroupText>
                                    <Input placeholder="Email" autoComplete="new-email" name="email" value={form.email} onChange={updateField} onKeyDown={(e) => { e.key === 'Enter' && click_Login(e) }} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupText >
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupText>
                                    <Input placeholder="Password" type="password" autoComplete="new-password" name="password" value={form.password} onChange={updateField} onKeyDown={(e) => { e.key === 'Enter' && click_Login(e) }} />
                                </InputGroup>
                            </FormGroup>

                            {spinnerVisible ?
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <div className="text-center">
                                    <Button className="my-4" color="primary" type="button" onClick={(e) => click_Login(e)}>
                                        Sign in
                                    </Button>
                                </div>
                            }
                        </Form>
                    </CardBody>
                </Card>
                <Row className="mt-3">
                    <Col xs="6">
                        {/*<a className="text-light" href="https://front.redservice.pt/Recuperar-password" onClick={(e) => e.preventDefault()}> sds</a>*/}
                        {/*<small><Link href="https://front.redservice.pt/Recuperar-password">Recuperar password</Link></small>*/}
                        {/*</a>*/}
                    </Col>
                    <Col className="text-right" xs="6">
                        {/*<a className="text-light" href="#pablo" onClick={(e) => e.preventDefault()}>*/}
                        {/*<small><Link to="/register">Efetuar registo</Link></small>*/}
                        {/*</a>*/}
                    </Col>
                </Row>


                <Modal
                    className="modal-dialog-centered modal-danger"
                    contentClassName="bg-gradient-light"
                    isOpen={modalVisible}
                    toggle={() => toggleModal()}>

                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-notification">
                            Registo!
                        </h6>
                        {/*<button*/}
                        {/*    aria-label="Close"*/}
                        {/*    className="close"*/}
                        {/*    data-dismiss="modal"*/}
                        {/*    type="button"*/}
                        {/*    onClick={() => toggleModal()}>*/}
                        {/*    <span aria-hidden={true}>×</span>*/}
                        {/*</button>*/}
                    </div>
                    <div className="modal-body">
                        <div className="py-3 text-center">
                            {result === true ? <i className="far fa-4x fa-smile-beam"></i> : <i className="far fa-4x fa-frown"></i>}
                            < h4 className="heading mt-4">{result === true ? 'Login efetuado com sucesso!' : 'Erro a efetuar o Login'}</h4>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            className="text-white ml-auto"
                            color="link"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => {
                                //toggleModal();
                                return <Redirect to="/login" />
                            }}>
                            Fechar
                        </Button>
                    </div>
                </Modal>
            </Col>
        </>
    );
};


export default Login;
