import React from 'react';
import { FormGroup, Input, Row, Col, Button, ButtonGroup, Modal, UncontrolledTooltip } from "reactstrap";
import { Status, StatusColor, notification, Loading } from "../../utils/Utilities";
import Moment from 'moment-timezone';
import 'moment/locale/pt'
Moment.locale('en');


class ModalScheduleInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            event: props.event,
            savedEvent: null,
            validate: props.validate,
            rSelected: null,
            loadingData: false
        };
    }

    componentDidMount() {
        this.handleChangeScheduleTimes("start", Moment().utc().format("YYYY-MM-DD"));
        this.handleChangeScheduleTimes("end", Moment().utc().format("YYYY-MM-DD"));

        if (this.state.savedEvent === null) {
            this.setState({ savedEvent: this.state.event });
        }
    }

    validateDatetime(name, dateTime) {
        const { validate } = this.state;
        if (Moment(dateTime).isValid()) {
            if (Moment(dateTime).format("hh:mm:ss a").startsWith("12:00:00 am")) {
                var text = name === "start" ? "início" : "fim";
                notification("Agendamento", "Horário do " + text + " do agendamento é inválido.", "warning", null, "top-right", null, false);
                validate[name + 'State'] = 'has-danger';
            } else { validate[name + 'State'] = 'has-success'; }
        } else { validate[name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }

    handleChangeScheduleTimes = async (name, value) => {
        await this.setState(state => ({ event: { ...state.event, [name]: value } }));
    }

    checkSchedule = () => {
        const { rSelected, event } = this.state;
        if (rSelected != null) {
            //console.log(Moment(event.start).utc(true).format() + "  " + Moment().utc(true).format())
            if (Moment(event.start).utc(true).isAfter(Moment().utc(true))) {
                if (Moment(event.end).utc(true).isAfter(Moment(event.start).utc(true))) {
                    event.start = Moment(event.start).utc(true).toDate();
                    event.end = Moment(event.end).utc(true).toDate();
                    event.resources.workshop.status = rSelected;
                    console.log(event);
                    this.updateSchedule(event, true);

                } else { notification("Orçamento", "Data do fim do agendamento tem de ser superior á data inical.", "warning", null, "top-right", null, false); }
            } else { notification("Orçamento", "Data do início do agendamento tem de ser superior á data atual.", "warning", null, "top-right", null, false); }
        } else { notification("Orçamento", "Precisa selecionar o estado do agendamento.", "warning", null, "top-right", null, false); }
    }


    updateSchedule = (event = null, serverUpdate = false) => {
        if (event != null) {
            this.props.updateSchedule(event, serverUpdate, true);
            this.props.viewModalBudgetInfo();
        } else {
            notification("Agendamento", "Não foi possível atualizar o agendamento.", "danger", null, "top-right", null, false);
        }
    }



    render() {
        const { event, validate, rSelected, loadingData } = this.state;
        return (
            <Modal className="modal-dialog-centered" contentClassName="bg-RS-grey-modal" isOpen={true} size="xl" style={{ minWidth: '50vw' }}>

                {loadingData === true ? (
                    Loading(loadingData)
                ) : null}


                <div style={{ padding: 40, borderRadius: 8, borderWidth: 2 }} className='custom-ui'>
                    <Row style={{ paddingTop: 20, paddingRight: 30, paddingBottom: 30 }} lg="12">
                        <Col lg="6">
                            <h2 className="mb-0">Validar orçamento</h2>
                        </Col>
                        <Col lg="6" className="text-right" >
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-start" >
                                    Estado do agendamento
                                </label>
                                <Row>
                                    <Col xl="12" style={{ justifyContent: "center", display: "flex" }}>
                                        <ButtonGroup>
                                            <Button
                                                id="btn_status_validacao"
                                                style={{ width: "23vw", backgroundColor: (rSelected === Status.Validacao ? StatusColor(Status.Validacao) : "white"), borderColor: StatusColor(Status.Validacao) }}
                                                outline
                                                onClick={() => this.setState({ rSelected: rSelected == null || rSelected === Status.Aceite ? Status.Validacao : null })}
                                                active={rSelected === Status.Validacao}
                                            >
                                                Validação
                                            </Button>
                                            <UncontrolledTooltip delay={0} placement="top" target="btn_status_validacao">
                                                Cria o agendamento no estado "Validacao", irá carecer a validação do cliente
                                            </UncontrolledTooltip>


                                            <Button
                                                id="btn_status_Aceite"
                                                style={{ width: "23vw", backgroundColor: (rSelected === Status.Aceite ? StatusColor(Status.Aceite) : "white"), borderColor: StatusColor(Status.Aceite) }}
                                                outline
                                                onClick={() => this.setState({ rSelected: rSelected == null || rSelected === Status.Validacao ? Status.Aceite : null })}
                                                active={rSelected === Status.Aceite}
                                            >
                                                Aceite
                                            </Button>
                                            <UncontrolledTooltip delay={0} placement="top" target="btn_status_Aceite">
                                                Cria o agendamento no estado "Aceite", o agendamento fica automaticamente aceite pelo cliente
                                            </UncontrolledTooltip>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col lg="12">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-start" >
                                    Início*
                                </label>
                                <Row>
                                    <Col xl="6">
                                        <Input
                                            id="start"
                                            name="start"
                                            type="date"
                                            valid={validate?.startState === "has-success"}
                                            invalid={validate?.startState === "has-danger"}
                                            onChange={(value) => {
                                                var a = Moment(new Date(value.target.value)).format('YYYY-MM-DD');
                                                this.validateDatetime('start', new Date(a)); this.handleChangeScheduleTimes('start', new Date(a));
                                                this.validateDatetime('end', new Date(a)); this.handleChangeScheduleTimes('end', new Date(a));
                                            }}
                                            value={Moment(event.start).utc(true).format("YYYY-MM-DD")}
                                            required={true}
                                            disabled={false}
                                        />

                                    </Col>
                                    <Col xl="6">
                                        <Input
                                            id="start"
                                            name="start"
                                            type="time"
                                            valid={validate.startState === "has-success"}
                                            invalid={validate?.startState === "has-danger"}
                                            onChange={(value) => {
                                                var received = value.target.value.split(':');
                                                var newDate = Moment(event.start)
                                                var a = newDate.hours(received[0]).minutes(received[1]);
                                                this.validateDatetime('start', new Date(a)); this.handleChangeScheduleTimes('start', new Date(a));
                                            }}
                                            value={Moment(event.start).utc(true).format("HH:mm")}
                                            required={true}
                                            disabled={false}
                                        />
                                    </Col>
                                </Row>
                                {/*</div>*/}
                            </FormGroup>
                        </Col>
                        <Col lg="12">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-end" id="lbl_end" >
                                    Fim*
                                </label>
                                <Row>
                                    <Col xl="6">
                                        <Input
                                            id="end"
                                            name="end"
                                            type="date"
                                            valid={validate?.endState === "has-success"}
                                            invalid={validate?.endState === "has-danger"}
                                            onChange={(value) => {
                                                var a = Moment(new Date(value.target.value)).format('YYYY-MM-DD');
                                                this.validateDatetime('end', new Date(a)); this.handleChangeScheduleTimes('end', new Date(a));
                                            }}
                                            value={Moment(event.end).utc(true).format("yyyy-MM-DD")}
                                            required={true}
                                            disabled={false}
                                        />

                                    </Col>
                                    <Col xl="6">
                                        <Input
                                            id="end"
                                            name="end"
                                            type="time"
                                            valid={validate?.endState === "has-success"}
                                            invalid={validate?.endState === "has-danger"}
                                            onChange={(value) => {
                                                var received = value.target.value.split(':');
                                                if (received.length === 2) {
                                                    var newDate = Moment(event.end)
                                                    var b = newDate.hour(+received[0]).minute(+received[1]);
                                                    this.validateDatetime('end', new Date(b)); this.handleChangeScheduleTimes('end', new Date(b));
                                                }
                                            }}
                                            value={Moment(event.end).utc(true).format("HH:mm")}
                                            required={true}
                                            disabled={false}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row style={{ paddingTop: '20px' }}>
                        <Col xl="6"  >
                        </Col>
                        <Col xl="6" className="text-right" >
                            <Button id="btn_update1" color="success" size="sm" onClick={(e) => { e.preventDefault(); this.checkSchedule() }}><i className="far fa-calendar-plus"></i> Adicionar</Button>
                            <UncontrolledTooltip delay={0} placement="top" target="btn_update1">
                                Criar agendamento
                            </UncontrolledTooltip>

                            <Button id="btn_cancel" size="sm" onClick={(e) => {
                                e.preventDefault();
                                this.updateSchedule(this.state.savedEvent);
                                this.props.viewModalBudgetInfo();
                            }}><i className="far fa-window-close"></i> Fechar</Button>
                            <UncontrolledTooltip delay={0} placement="top" target="btn_cancel">
                                Fechar validador do orçamento
                            </UncontrolledTooltip>

                        </Col>
                    </Row>

                </div>
            </Modal>
        );


    }
}

export default ModalScheduleInfo;