import React from "react";
import { Row, Col, Container, CardHeader, Card, CardBody, CardFooter, Button, Table, Badge, UncontrolledTooltip, InputGroup, Input } from "reactstrap";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Loading, Role, notification, Status, StatusColor, ApiError, GetHeader, GetHeaderPut, joinSignalR, RemoveNotifications, DeviceType, Device, ViewToolBar } from "../utils/Utilities";
import ModalScheduleInfo from '../Schedules/ModalScheduleInfo'
import axios from 'axios';
import ScheduleList from "./ScheduleList";
import BudgetList from "../Schedules/Budget/BudgetList";
import CustomPagination from "views/backoffice/utils/CustomPagination";
import Moment from 'moment';
import 'moment/locale/pt'
import ModalFilter from "./ModalFilter";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
const localizer = momentLocalizer(Moment);
Moment.locale('pt');
const DragAndDropCalendar = withDragAndDrop(Calendar);


class Schedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            currentWorkshop: props.workshop,
            workshopAccounts: [],
            currentEvent: null,
            showTableEvents: false,
            showSchedules: true,
            loadingData: true,
            events: [],
            budgetEvents: [],
            event: {
                id: 0, title: "Agendamento oficina", start: "", end: "",
                resources: {
                    client: { accountId: 0, firstName: "", lastName: "", email: "", phone: "", nif: "" },
                    vehicule: { vehiculeId: 0, plate: "", brand: "", model: "", kms: 0 },
                    workshop: { workShopId: props.workshop.id, status: Status.Pendente, colabId: 0, notes: "" },
                    schedule: { created: "", updated: "", deleted: "" },
                    services: []
                }
            },
            selectedEvent: null,
            pendents: 0,
            pagination: null,
            defaultDate: Moment().toDate(),
            showModalFilter: false,
            currentView: Views.WEEK,
            search: "",

            statusFilter: null,
            currentDateSchedule: new Date(),
        };
        this.moveEvent = this.moveEvent.bind(this);
        this.selectSlot = this.selectSlot.bind(this);
    }


    componentDidMount() {
        const { currentWorkshop, currentDateSchedule, statusFilter, currentView, currentUser } = this.state;
        this.getWorkshopAccounts();
        this.getShedules(currentWorkshop, currentDateSchedule, currentView, statusFilter);
        this.getNumberOfValidations(currentWorkshop);
        this.signalRConnection(currentUser, currentWorkshop);
    }

    signalRConnection = (currentUser, currentWorkshop) => {
        if (global.connectionSignalR !== null && global.connectionSignalR !== undefined) {
            //global.connectionSignalR.invoke("joinRoom", { accountId: +currentUser.id, workshopId: +currentWorkshop.id })

            global.connectionSignalR.on("UpdateSchedule", () => {
                this.getShedules(this.state.currentWorkshop, this.state.currentDateSchedule, this.state.currentView, this.state.statusFilter);
                RemoveNotifications();
                notification("Servidor", "Agenda atualizada.", "info", null, "bottom-right", null, false);
            });

            global.connectionSignalR.on("NewSchedule", () => {
                RemoveNotifications();
                notification("Tem uma novo agendamento pendente.", <Button color='primary' name='update' type='button' size='sm' onClick={e => { this.changeView(e) }}>Pendentes</Button>, "danger", null, "bottom-right", null, false)
            });

            global.connectionSignalR.on("UserResponse", () => {
                RemoveNotifications();
                notification("Ação de cliente", "Recebeu uma resposta de um cliente sobre um agendamento.", "info", null, "bottom-right", null, false)
            });

        } else {
            joinSignalR(+currentUser.id, +currentWorkshop.id).then(() => { this.signalRConnection(currentUser, currentWorkshop); });
        }
    }

    handleValue = async (name, value) => { await this.setState({ [name]: value }); }

    handleAllEvent = async (event) => { await this.setState(prevState => ({ events: [...prevState.events, event] })) }

    getShedules = async (workshop, date, view, statusFilter) => {
        this.handleValue('events', []);
        this.handleValue('loadingData', true);
        var headers = GetHeader();
        var rangeDate = Moment(date) !== undefined ?
            `${"?Start=" + Moment(date).subtract(view === Views.MONTH ? 30 : 7, 'days').toISOString() + "&End=" + Moment(date).add(view === Views.MONTH ? 30 : 7, 'days').toISOString()}` :
            `${"?Start=" + Moment().subtract(view === Views.MONTH ? 30 : 7, 'days').toISOString() + "&End=" + Moment().add(view === Views.MONTH ? 30 : 7, 'days').toISOString()}`;
        var filter = [];
        if (statusFilter !== null && statusFilter !== undefined) { Object.keys(statusFilter).forEach((key, value) => { if (statusFilter[key] === true) { filter.push(key) } }) }
        var rangefilter = filter.length > 0 ? `${(rangeDate !== "" ? "&" : "?") + "status=" + filter}` : "";

        await axios.get(global.api_url + "Schedules/" + workshop.id + rangeDate + rangefilter, { headers })
            .then((response) => {
                if (response.status === 200) {
                    response.data.forEach(event => { event.start = new Date(event.start); event.end = new Date(event.end); });
                    var schedules = response.data;

                    schedules = schedules.filter(x => x.resources.workshop.status !== Status.Orcamento);


                    this.setState({ events: schedules }, () => {
                        this.handleValue('loadingData', false);
                        this.handleValue('showTableEvents', true)
                        return schedules;
                    });
                } else {
                    this.handleValue('showTableEvents', true)
                    this.handleValue('loadingData', false);
                }
            }).catch(error => {
                this.handleValue('showTableEvents', true)
                this.handleValue('loadingData', false);
                ApiError(error, "Agendamento").then(call => call === true ? this.getShedules(workshop, date, view, statusFilter) : "");

            })
    }

    getShedulesBudget = async (workshop, search = null) => {
        this.handleValue('budgetEvents', []);
        this.handleValue('loadingData', true);
        var headers = GetHeader();
        var rangefilter = "status=Orcamento";

        await axios.get(global.api_url + "Schedules/" + workshop.id + "?" + rangefilter + (search != null ? "&search=" + search : ""), { headers })
            .then((response) => {
                if (response.status === 200) {
                    response.data.forEach(event => { event.start = new Date(event.start); event.end = new Date(event.end); });
                    var schedules = response.data;

                    this.setState({ budgetEvents: schedules }, () => {
                        this.handleValue('loadingData', false);
                        return schedules;
                    });
                } else {
                    this.handleValue('loadingData', false);
                }
            }).catch(error => {
                this.handleValue('loadingData', false);
                ApiError(error, "Agendamento").then(call => call === true ? this.getShedulesBudget(workshop, search) : "");

            })
    }

    async getShedulesValidation(workshop) {
        this.handleValue('events', [])
        this.handleValue('loadingData', true);
        var headers = GetHeader();
        await axios.get(global.api_url + "Schedules/Validation/" + workshop.id, { headers }).then((response) => {
            if (response.status === 200) {
                response.data.forEach(event => { event.start = new Date(event.start); event.end = new Date(event.end); });
                this.handleValue('events', response.data)
            }
            this.handleValue('loadingData', false);
        }).catch(error => {
            this.handleValue('loadingData', false);
            ApiError(error, "Agendamento").then(call => call === true ? this.getShedulesValidation(workshop) : "");
        })
    }

    getNumberOfValidations(workshop) {
        var headers = GetHeader();
        axios.get(global.api_url + "Schedules/Validation/" + workshop.id, { headers })
            .then((response) => {
                if (response.data.length && this.state.showTableEvents) {
                    RemoveNotifications();
                    var pendente = 0;
                    var oficina = 0;
                    response.data.forEach(x => {
                        if (x.resources.workshop.status === Status.Pendente) { pendente++; }
                        if (x.resources.workshop.status === Status.Oficina) { oficina++; }
                    });
                    notification("Tem " + oficina + " veículos na sua oficina.", "", "success", null, "bottom-right", null, false)
                    notification("Tem " + pendente + " marcações pendentes.", <Button color='primary' name='update' type='button' size='sm' onClick={e => { this.changeView(e) }}>Pendentes</Button>, "danger", null, "bottom-right", null, false)
                }
            }).catch(error => {
                ApiError(error, "Agendamento").then(call => call === true ? this.getShedulesValidation(workshop) : "");
            });
    }

    getWorkshopAccounts() {
        const { currentUser } = this.state;
        this.handleValue('loadingData', true);
        var headers = GetHeader();
        axios.get(global.api_url + "Accounts/Workshop/" + currentUser.defaultWorkshop, { headers })
            .then((response) => {
                if (response.status === 200) { this.handleValue('workshopAccounts', response.data); }
                this.handleValue('loadingData', false);
            }).catch(error => {
                this.handleValue('loadingData', false);
                ApiError(error, "Agendamento").then(call => call === true ? this.getWorkshopAccounts() : "");
            })
    }

    async getSchedule(id) {
        var headers = GetHeader();
        return await axios.get(global.api_url + "Schedules/Grid/" + id, { headers })
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data;
                    data.start = new Date(data.start); data.end = new Date(data.end);
                    return data;
                }
                return null;
            }).catch(error => {
                ApiError(error, "Agendamento").then(call => call === true ? this.getSchedule(id) : "");
                return null;
            })
    }

    update(event) {
        this.handleValue('loadingData', true);
        var headers = GetHeaderPut();
        event.start = new Date((event.start).getTime() - (event.start).getTimezoneOffset() * 60000);
        event.end = new Date((event.end).getTime() - (event.end).getTimezoneOffset() * 60000);
        event.resources.vehicule.kms = parseInt(event.resources.vehicule.kms);
        axios.put(global.api_url + "Schedules", event, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Agenda", "Agendamento atualizado com sucesso.", "success", null, "top-right", null, false);
                    this.handleValue('edit', false);
                }
                this.handleValue('validate', {});
            }).catch(error => {
                ApiError(error, "Agendamento").then(call => call === true ? this.update(event) : "");
            }).then(() => {
                this.handleValue('loadingData', false);
            })
    }

    sendNotification(sendPushNotification) {
        axios.post(global.api_url + "Notifications/Send", sendPushNotification)
            .then((response) => {
                if (response.status === 200) {
                    notification("Notificação", "Enviada notificação ao cliente", "success", null, "top-right", null, false);
                }
            }).catch(error => {
                notification("Notificação", "Erro:" + error.message, "warning", null, "top-right", null, false);
            }).then(() => { this.handleValue('loadingData', false); })
    }

    selectSlot({ start, end }) {
        if (Moment(start).isAfter(Moment())) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div style={{ borderStyle: "solid", padding: 40, borderRadius: 8, borderWidth: 2 }} className='custom-ui'>
                            <h1>Adicionar agendamento</h1>
                            <div>Início:<p style={{ fontSize: '15px', fontWeight: "bold" }}>{Moment(start).format('LLLL')}</p></div>
                            <div>Fim:<p style={{ fontSize: '15px', fontWeight: "bold" }}>{Moment(end).format('LLLL')}</p></div>
                            <center>
                                <Button color="success" size="sm" type="button" onClick={(e) => {
                                    e.preventDefault();
                                    this.newSchedule(start, end);
                                    onClose();
                                }}><i className="fas fa-plus-circle"></i> Adicionar</Button>
                                <Button color="danger" size="sm" onClick={onClose}><i className="fas fa-times"></i> Cancelar</Button>
                            </center>
                        </div>
                    );
                }
            });
        } else {
            RemoveNotifications();
            notification("Agendamento", "Não é possível efetuar um agendamento com data inferior a atual.", "warning", null, "top-right", null, false);
        }

    }
    moveEvent({ event, start, end }) {
        const { currentUser } = this.state;
        if (currentUser.role !== Role.User) {
            if (event.resources.workshop.status === Status.Pendente || event.resources.workshop.status === Status.Rejeitado) {
                if (Moment(start).isAfter(Moment())) {
                    const { events } = this.state;
                    const idx = events.indexOf(event);
                    const updatedEvent = { ...event, start, end };
                    const nextEvents = [...events];
                    nextEvents.splice(idx, 1, updatedEvent);
                    this.setState({ events: nextEvents });
                    event.start = start;
                    event.end = end;
                    RemoveNotifications();
                    this.update(event);
                    notification("Agendamento alterado", "Alteração para " + Moment(start).format('LLL') + " - " + Moment(end).format('LLL'), "success", null, "top-right", null, false);
                } else {
                    notification("Agendamento", "Não é possível mover um agendamento para uma data inferior a atual.", "warning", null, "top-right", null, false);
                }
            }
        }
    }

    resizeEvent = (resizeType) => {
        const { currentUser, events } = this.state;
        const { event, start, end } = resizeType;
        if (currentUser.role !== Role.User) {
            if (event.resources.workshop.status === Status.Pendente || event.resources.workshop.status === Status.Rejeitado) {
                const nextEvents = events.map(existingEvent => { return existingEvent.id === event.id ? { ...existingEvent, start, end } : existingEvent; });
                this.setState({ events: nextEvents });
                event.start = start;
                event.end = end;
                this.update(event);
            }
        }
    };

    onSelectEvent = (event) => {
        if (event !== null) {
            this.setState({ currentEvent: event });
            this.handleValue('selectedEvent', null)
        } else {
            this.newSchedule(new Date(), new Date(new Date().setMinutes(120)));
        }
    }

    onCreateBudget = (e) => {
        e.preventDefault();
        var newEvent = {
            id: 0, title: "Orçamento", start: null, end: null,
            resources: {
                client: { accountId: 0, firstName: "", lastName: "", email: "", phone: "", nif: "" },
                vehicule: { vehiculeId: 0, plate: "", brand: "", model: "", kms: 0 },
                workshop: { workShopId: this.state.currentWorkshop.id, status: Status.Orcamento, colabId: 0, notes: "" },
                schedule: { created: "", updated: "", deleted: "" },
                services: []
            }
        };
        this.setState({ currentEvent: newEvent });
    }

    newSchedule = (start, end) => {
        var newEvent = {
            id: 0, title: "Agendamento oficina", start: start, end: end,
            resources: {
                client: { accountId: 0, firstName: "", lastName: "", email: "", phone: "", nif: "" },
                vehicule: { vehiculeId: 0, plate: "", brand: "", model: "", kms: 0 },
                workshop: { workShopId: this.state.currentWorkshop.id, status: Status.Pendente, colabId: 0, notes: "" },
                schedule: { created: "", updated: "", deleted: "" },
                services: []
            }
        };
        this.setState({ currentEvent: newEvent });
    }

    validateModal = (loadData, eventData, toRemoveId, toLoadId, refreshEventId = null) => {
        const { currentWorkshop, currentDateSchedule, statusFilter, currentView } = this.state;
        this.setState({ currentEvent: null });

        if (loadData) {
            this.getShedules(currentWorkshop, currentDateSchedule, currentView, statusFilter);
            this.getShedulesBudget(currentWorkshop);
        }

        if (eventData !== null) { this.handleAllEvent(eventData); }

        if (toRemoveId !== null && toRemoveId !== undefined) { this.state.events.forEach(e => { if (e.id === toRemoveId) { this.removeEvent(e); return true; } }); }

        if (toLoadId !== null && toLoadId !== undefined) {
            this.getSchedule(toLoadId).then(response => {
                if (response !== undefined && response !== null) {
                    this.replaceEvent(response).then(val => {
                        if (val === true) {
                            notification("Agendamento", "Agendamento atualizado com sucesso.", "success", null, "top-right", null, false);
                        } else { notification("Agendamento", "Não foi possivel obter o agendamento atualizado, faça refresh a pagina.", "warning", null, "top-right", null, false); }
                    });
                } else { notification("Agendamento", "Não foi possivel obter o agendamento atualizado, faça refresh a pagina.", "warning", null, "top-right", null, false); }
            });
        }

        if (refreshEventId != null) {
            this.getSchedule(refreshEventId).then(response => { this.setState({ currentEvent: response }); });
        }
    }

    replaceEvent = async (event) => {
        var eventGrid = null;
        var findEvent = async (event) => { this.state.events.forEach(e => { if (e.id === event.id) { return eventGrid = e; } }); };
        return await findEvent(event).then(e => { return this.removeEvent(eventGrid).then(a => { if (a === true) { return this.addEvent(event).then(b => { return b; }); } return false; }) });
    }

    removeEvent = async (event) => {
        var array = [...this.state.events];
        var index = array.indexOf(event)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ events: array });
            return await true;
        }
        return await false;
    }

    addEvent = async (event) => {
        var array = [...this.state.events];
        array.push(event);
        this.setState({ events: array });
        var index = array.indexOf(event)
        if (index !== -1) { return await true; }
        return await false;
    }

    Event = ({ event }) => {
        return (
            <span>
                <h4>{event.resources.vehicule.plate + ": " + event.title}</h4>
                <h6>{event.resources.vehicule.brand + ' ' + event.resources.vehicule.model}</h6>
                <h6>{'Estado: ' + event.resources.workshop.status}</h6>
            </span>
        )
    }

    eventStyleGetter = (event, start, end, isSelected) => {
        var backgroundColor = '#' + event.hexColor;
        event?.id === this.state.selectedEvent?.id ? backgroundColor = '#bdf4ff' : backgroundColor = StatusColor(event.resources.workshop.status);
        var style = { backgroundColor: backgroundColor, opacity: 0.8, color: 'black', border: '0px', display: 'block' };
        return { style: style };
    }


    changeView(e, budget = false) {
        e.preventDefault();
        const { currentWorkshop, showTableEvents, currentDateSchedule, statusFilter, currentView, showSchedules } = this.state;
        if (budget === false) {
            if (!showTableEvents) {
                this.getShedules(currentWorkshop, currentDateSchedule, currentView, statusFilter);
                this.getNumberOfValidations(currentWorkshop);
            } else {
                this.getShedulesValidation(currentWorkshop);
            }
            this.handleValue('showTableEvents', !showTableEvents);
        } else {
            this.getShedules(currentWorkshop, currentDateSchedule, currentView, statusFilter);
            this.getShedulesBudget(currentWorkshop).then(response => { this.handleValue('showSchedules', !showSchedules); });
        }
    }

    changeHandler = (pageNumber, pagesize) => { }

    moveToSchedule = (movedEvent) => {
        const { currentWorkshop, statusFilter, currentView } = this.state;
        this.handleValue('defaultDate', new Date(movedEvent.start)).then(response => {
            this.handleValue('selectedEvent', movedEvent).then(response => {
                this.getShedules(currentWorkshop, movedEvent?.start, currentView, statusFilter).then(data => {
                    if (movedEvent.resources.workshop.status === Status.Rejeitado) { this.setState(prevState => ({ events: [...prevState.events, movedEvent] })) }
                    this.handleValue('showTableEvents', true);
                });
            });
        })
    }

    onClick = (e) => { this.getShedulesValidation(this.state.currentWorkshop); }

    modalFilter = () => { this.handleValue('showModalFilter', !this.state.showModalFilter); }

    returnModalFilter = (checkListFilter) => {
        if (checkListFilter !== null) {
            const { currentDateSchedule, currentWorkshop, currentView } = this.state;
            this.handleValue("statusFilter", checkListFilter);
            this.getShedules(currentWorkshop, currentDateSchedule, currentView, checkListFilter);
        }
        this.modalFilter();
    }


    CustomToolbar = (toolBar) => {
        const { currentWorkshop, statusFilter } = this.state;

        return (
            <Row>
                <Col xl="5" style={{ marginBottom: 10 }}>
                    <Button id="btn_back" color="success" size="sm" type="button" onClick={() => {
                        toolBar.onNavigate('PREV')
                        this.getShedules(currentWorkshop, Moment(toolBar.date).subtract(7, 'days'), toolBar.view, statusFilter);
                    }}>
                        <i className="fas fa-backward"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Anterior" : ""}
                    </Button>
                    <UncontrolledTooltip delay={0} placement="top" target="btn_back">
                        {ViewToolBar(toolBar.view) + " "}anterior
                    </UncontrolledTooltip>
                    <Button id="btn_today" color="info" size="sm" type="button" onClick={() => { toolBar.onNavigate('TODAY'); this.getShedules(currentWorkshop, new Date(), toolBar.view, statusFilter); }}>
                        <i className="fas fa-calendar"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Data atual" : ""}
                    </Button>
                    <UncontrolledTooltip delay={0} placement="top" target="btn_today">
                        {ViewToolBar(toolBar.view) + " "}atual
                    </UncontrolledTooltip>

                    <Button id="btn_next" color="success" size="sm" type="button" onClick={() => { toolBar.onNavigate('NEXT'); this.getShedules(currentWorkshop, Moment(toolBar.date).add(7, 'days'), toolBar.view, statusFilter) }}>
                        <i className="fas fa-forward"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Seguinte" : ""}
                    </Button>
                    <UncontrolledTooltip delay={0} placement="top" target="btn_next">
                        {ViewToolBar(toolBar.view) + " "}seguinte
                    </UncontrolledTooltip>
                </Col>

                <Col>
                    <h3 className="mb-0">{toolBar.view === Views.DAY && "Dia " + Moment(toolBar.date).format('D') + " - "} {toolBar.view === Views.WEEK && "Semana " + Moment(toolBar.date).week() + " - "}  Mês de {Moment(toolBar.date).format('MMMM')}</h3>
                </Col>

                <Col xl="5" className="text-right" style={{ marginBottom: 10 }}>
                    <Button id="btn_week" color={toolBar.view === Views.WEEK ? "success" : "light"} size="sm" type="button" onClick={() => { toolBar.onView(Views.WEEK); this.getShedules(currentWorkshop, new Date(), Views.WEEK, statusFilter); this.handleValue("currentView", Views.WEEK); }}>
                        <i className="fas fa-calendar-week"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Semana" : ""}
                    </Button>
                    <UncontrolledTooltip delay={0} placement="top" target="btn_week">
                        Semana
                    </UncontrolledTooltip>
                    <Button id="btn_day" color={toolBar.view === Views.DAY ? "success" : "light"} size="sm" type="button" onClick={() => { toolBar.onView(Views.DAY); this.getShedules(currentWorkshop, new Date(), Views.DAY, statusFilter); this.handleValue("currentView", Views.DAY); }} >
                        <i className="fas fa-calendar-day"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Dia" : ""}
                    </Button>
                    <UncontrolledTooltip delay={0} placement="top" target="btn_day">
                        Dia
                    </UncontrolledTooltip>
                    <Button id="btn_month" color={toolBar.view === Views.MONTH ? "success" : "light"} size="sm" type="button" onClick={() => { toolBar.onView(Views.MONTH); this.getShedules(currentWorkshop, new Date(), Views.MONTH, statusFilter); this.handleValue("currentView", Views.MONTH); }}>
                        <i className="fas fa-calendar-alt"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Mês" : ""}
                    </Button>
                    <UncontrolledTooltip delay={0} placement="top" target="btn_month">
                        Mês
                    </UncontrolledTooltip>
                </Col>
            </Row>
        );
    }


    legend = () => {
        return (<>
            Legenda {" - "}
            {Object.values(Status).map(x => {
                return (
                    <div style={{ display: "inline" }} key={x} >
                        {x + " "}
                        <Badge color="" className="badge-dot mr-4">
                            <i style={{ 'zoom': 3, 'background': StatusColor(x) }} />
                        </Badge>
                    </div>
                );
            })}
        </>);
    }

    searchAccount = (search = null) => {
        this.getShedulesBudget(this.state.currentWorkshop, search)
    }


    render() {
        const { currentUser, currentWorkshop, currentEvent, showTableEvents, loadingData, workshopAccounts, events, pagination, showModalFilter, statusFilter, showSchedules, budgetEvents } = this.state;

        return (
            <>
                {currentEvent !== null && currentWorkshop !== null && <ModalScheduleInfo event={currentEvent} currentUser={currentUser} currentWorkshop={currentWorkshop} workshopAccounts={workshopAccounts} onChange={this.validateModal} />}
                {showModalFilter && <ModalFilter checkListFilter={statusFilter} onClick={(data) => { this.returnModalFilter(data) }} />}

                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xs-2" xs="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row>
                                        <Col xs="4">
                                            <h3 className="mb-0">Agenda - {currentWorkshop.name}</h3>
                                        </Col>
                                        <Col xs="8" className="text-right">
                                            {showSchedules === true ? <>
                                                {showTableEvents &&
                                                    <>
                                                        <Button id="btn_filters" color="primary" size="sm" type="button" onClick={e => { this.modalFilter(); }}>
                                                            <i className="fa fa-filter"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Filtros" : ""}
                                                        </Button>
                                                        <UncontrolledTooltip delay={0} placement="top" target="btn_filters">
                                                            Selecionar filtros a mostrar no agendamento
                                                        </UncontrolledTooltip>
                                                    </>
                                                }
                                                <Button id="btn_new_schedule" color="success" size="sm" type="button" onClick={e => { e.preventDefault(); this.onSelectEvent(null); }}>
                                                    <i className="far fa-calendar-plus"></i>  {DeviceType(window.innerWidth) !== Device.Mobile ? "Novo agendamento" : ""}
                                                </Button>
                                                <UncontrolledTooltip delay={0} placement="top" target="btn_new_schedule">
                                                    Adicionar novo agendamento
                                                </UncontrolledTooltip>

                                                <Button id="btn_list" color="info" size="sm" type="button" onClick={e => { this.changeView(e) }}><i className="far fa-list-alt"></i>
                                                    {DeviceType(window.innerWidth) !== Device.Mobile ? (showTableEvents ? ' Lista de agendamentos' : ' Mostrar agenda') : ""}
                                                </Button>
                                                <UncontrolledTooltip delay={0} placement="top" target="btn_list">
                                                    {showTableEvents ? ' Mostrar agendamento pendentes, rejeitados, oficina ou aceites' : ' Mostra calendário'}
                                                </UncontrolledTooltip>

                                                <Button id="btn_buget" color="danger" size="sm" type="button" onClick={e => { this.changeView(e, true) }}>
                                                    <i className="fas fa-file-invoice"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Orçamentos" : ""}
                                                </Button>
                                                <UncontrolledTooltip delay={0} placement="top" target="btn_buget">
                                                    Orçamentação
                                                </UncontrolledTooltip>


                                            </> : <>
                                                <Button id="btn_reload_budget" color="success" size="sm" type="button" onClick={e => { this.getShedulesBudget(currentWorkshop, null) }}><i className="fas fa-sync"></i></Button>
                                                <UncontrolledTooltip delay={0} placement="top" target="btn_reload_budget">
                                                    Atualizar orçamentos
                                                </UncontrolledTooltip>

                                                <Button id="btn_buget_add" color="success" size="sm" type="button" onClick={e => { this.onCreateBudget(e); }}>
                                                    <i className="fas fa-folder-plus"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Adicionar Orçamento" : ""}
                                                </Button>
                                                <UncontrolledTooltip delay={0} placement="top" target="btn_buget_add">
                                                    Adicionar orçamento
                                                </UncontrolledTooltip>

                                                <Button id="btn_buget1" color="danger" size="sm" type="button" onClick={e => { this.changeView(e, true) }}>
                                                    <i className="fas fa-file-invoice"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Fechar Orçamentos" : ""}
                                                </Button>
                                                <UncontrolledTooltip delay={0} placement="top" target="btn_buget1">
                                                    Orçamentação
                                                </UncontrolledTooltip>

                                            </>}


                                            {currentUser.role === Role.Admin &&
                                                <>
                                                    <Button id="btn_close_schedule" color="danger" size="sm" type="button" onClick={e => {
                                                        this.props.onChange(null);
                                                    }}><i className="far fa-window-close"></i> Fechar</Button>
                                                    <UncontrolledTooltip delay={0} placement="top" target="btn_close_schedule">
                                                        Fechar agenda atual
                                                    </UncontrolledTooltip>

                                                </>
                                            }
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="pl-lg-4">
                                        {showSchedules ? <>

                                            {showTableEvents ? (
                                                <>
                                                    {Loading(loadingData)}
                                                    <DragAndDropCalendar
                                                        selectable
                                                        startAccessor="start"
                                                        endAccessor="end"
                                                        localizer={localizer}
                                                        events={this.state.events}
                                                        onEventDrop={this.moveEvent}
                                                        resizable
                                                        onEventResize={this.resizeEvent}
                                                        onSelectEvent={this.onSelectEvent}
                                                        defaultView={DeviceType(window.innerWidth) === Device.Mobile ? Views.DAY : Views.WEEK}
                                                        defaultDate={this.state.defaultDate}
                                                        style={{ height: '75vh' }}
                                                        eventPropGetter={(this.eventStyleGetter)}
                                                        components={{
                                                            toolbar: this.CustomToolbar,
                                                            event: this.Event
                                                        }}
                                                        onSelectSlot={this.selectSlot}
                                                        min={new Date(1972, 0, 1, 7, 0, 0, 0)}
                                                        max={new Date(1972, 0, 1, 20, 0, 0, 0)}
                                                    />
                                                    <div style={{ textAlign: "end" }}>
                                                        {this.legend()}
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {Loading(loadingData)}
                                                    <h3 className="mb-0">Lista de agendamentos</h3>
                                                    <Table className="align-items-center table-flush" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Estado</th>
                                                                <th scope="col">Nome</th>
                                                                <th scope="col">Email</th>
                                                                <th scope="col">Telefone</th>
                                                                <th scope="col">Veículo</th>
                                                                <th scope="col">Matricula</th>
                                                                <th scope="col">Data</th>
                                                                <th scope="col">Hora</th>
                                                                <th scope="col" />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {events.map((schedule, key) => {
                                                                return <ScheduleList key={key} schedule={schedule} currentUser={currentUser} currentWorkshop={currentWorkshop} workshopAccounts={workshopAccounts} onChange={this.moveToSchedule} onClick={this.onClick} />
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                    <CardFooter className="py-4">
                                                        {pagination !== null && events.length > 0 ? (<CustomPagination pagination={pagination} array={events} onChange={this.changeHandler} />) : null}
                                                        {events.length === 0 && loadingData === false &&
                                                            <div style={{ paddingTop: '20px', textAlign: 'center' }}>
                                                                <Button color="success" size="sm" type="button" onClick={e => { e.preventDefault(); this.getShedulesValidation(this.state.currentWorkshop) }}><i className="fas fa-sync"></i> Atualizar</Button>
                                                            </div>}
                                                    </CardFooter>
                                                </>)
                                            }
                                        </> : <>
                                            {Loading(loadingData)}
                                            <Row className="align-items-center">
                                                <Col xs={DeviceType(window.innerWidth) === Device.Mobile ? "12" : "4"}>
                                                    <h3 className="mb-0">Orçamentos</h3>
                                                </Col>
                                                <Col className="text-right" xs={DeviceType(window.innerWidth) === Device.Mobile ? "12" : "4"}>
                                                    <InputGroup>
                                                        <Input type="text" name="seacrh" id="input-seacrh"
                                                            bsSize="sm"
                                                            value={this.state.search}
                                                            placeholder="Pesquisar orçamento"
                                                            onChange={(e) => { this.setState({ search: e.target.value !== null && e.target.value !== undefined ? e.target.value : "" }) }}
                                                            onKeyDown={(e) => { if (e.key === 'Enter') { this.setState({ search: e.target.value !== null && e.target.value !== undefined ? e.target.value : "" }, () => { this.searchAccount(this.state.search) }) } }}
                                                            disabled={false}
                                                        />

                                                        <Button size="sm" color="success" id="btn_search" onClick={(e) => {
                                                            this.searchAccount(this.state.search)
                                                        }}><i className="fas fa-search"></i></Button>

                                                    </InputGroup>
                                                </Col>
                                                    <Col className="text-right" xs={DeviceType(window.innerWidth) === Device.Mobile ? "12" : "4"}></Col>
                                                <Col style={{ paddingTop: 20 }} xs="12">
                                                    <Table className="align-items-center table-flush" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col" style={{ width: "5%", minWidth: 50, maxWidth: 100, textAlign: 'center' }}>Estado</th>
                                                                <th scope="col">Nome</th>
                                                                <th scope="col">Email</th>
                                                                <th scope="col">Telefone</th>
                                                                <th scope="col">Veículo</th>
                                                                <th scope="col">Matrícula</th>
                                                                <th scope="col" />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {budgetEvents.map((schedule, key) => {
                                                                return <BudgetList key={key} schedule={schedule} currentUser={currentUser} currentWorkshop={currentWorkshop} workshopAccounts={workshopAccounts} onChange={this.moveToSchedule} onClick={this.onClick} onValidate={this.validateModal} />
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                    <CardFooter className="py-4">
                                                        {pagination !== null && events.length > 0 ? (<CustomPagination pagination={pagination} array={events} onChange={this.changeHandler} />) : null}
                                                        {events.length === 0 && loadingData === false &&
                                                            <div style={{ paddingTop: '20px', textAlign: 'center' }}>
                                                                <Button color="success" size="sm" type="button" onClick={e => { e.preventDefault(); this.getShedulesValidation(this.state.currentWorkshop) }}><i className="fas fa-sync"></i> Atualizar</Button>
                                                            </div>}
                                                    </CardFooter>
                                                </Col>
                                            </Row>
                                        </>}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </>
        );
    }
}


export default Schedule;

