import React, { useState, useEffect } from 'react';
import Header from "components/Headers/Header.js";
import { CurrentUser, notification, Loading, GetHeader, ApiError } from "../utils/Utilities";
import axios from 'axios';
import { useLocation } from "react-router-dom";
import WorkshopForm from "./WorkshopForm";


const Workshop = (props) => {
    var currentUser = CurrentUser();
    const location = useLocation();
    const [workshop, setWorkshop] = useState([]);
    const [load, setLoad] = useState(false);

    const getWorkshop = (workshop) => {
        setLoad(true);
        var headers = GetHeader();
        axios.get(global.api_url + "Workshops/" + workshop.id, { headers })
            .then((response) => {
                if (response.status === 200) {
                    const workshopData = response.data;
                    setWorkshop(workshopData);
                }
                setLoad(false);
            }).catch(error => {
                setLoad(false);
                ApiError(error, "Oficina").then(call => call === true ? getWorkshop(workshop) : "");
            })
    }



    useEffect(() => {
        
        if (location?.state !== null && location?.state?.create === true) {
            setWorkshop({ id: 0 });
        } else {
            if (location?.state !== null && location?.state !== undefined && location?.state?.workshop) {
                getWorkshop(location.state.workshop)
            } else if (currentUser !== null) {

                if (currentUser.defaultWorkshop !== undefined && currentUser.defaultWorkshop !== null) {
                    getWorkshop({ id: currentUser.defaultWorkshop })
                } else {
                    setLoad(false);
                    notification("Gestão de oficinas", "A sua conta não tem um oficina preferêncial.", "warning", null, "top-right", null, false);
                }
            }
        }
    }, []);

    return (
        <>
            <Header />
            {workshop.id !== undefined ? (
                <>
                    <WorkshopForm edit={true} currentUser={currentUser} formWorkshop={workshop} props={props} />
                </>
            ) :
                <>
                    {Loading(load)}
                </>
            }
        </>
    )
};

export default Workshop;
