import React from 'react';
import { CurrentUser } from "views/backoffice/utils/Utilities";
import Banners from '../Banners/Banners'




const Index = (props) => {
    const currentUser = CurrentUser();

    return (<Banners currentUser={currentUser} />);
}

export default Index;

