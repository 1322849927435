import React from 'react';
import { Button, Input, UncontrolledTooltip, FormGroup, FormFeedback } from "reactstrap";
import Moment from 'moment';
import axios from 'axios';
import { notification, GetHeader, ApiError, LoadingFixed } from '../../utils/Utilities';
Moment.locale('pt');

class BannerItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            item: props.item !== undefined ? props.item : {},
            old_item: props.item !== undefined ? props.item : {},
            edit: false,
            loading: false,
            validate: {
                startState: '',
                endState: '',
            },
        };
    }



    edit = () => {
        this.setState({ edit: true });
    }
    delete = (newItem) => {
        this.setState({ loading: true });
        const { item } = this.state;
        var headers = GetHeader();
        axios.post(global.api_url + "Banners/Delete", { id: newItem.id }, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Banner", "Banner atualizado com sucesso.", "success", null, "top-right", null, false);
                    this.setState({ item: newItem });
                }
                this.setState({ loading: false });
                this.props.onChange("LOAD");
            }).catch(error => {
                ApiError(error, "Atualizar banner").then(call => call === true ? this.save(item) : "");
            }).then(() => { this.setState({ loading: false }); })
        this.setState({ edit: false });


    }

    save = (newItem) => {
        if (this.check()) {
            this.setState({ loading: true });
            const { item } = this.state;
            var headers = GetHeader();
            axios.post(global.api_url + "Banners/Update", newItem, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        notification("Banner", "Banner atualizado com sucesso.", "success", null, "top-right", null, false);
                        this.setState({ item: newItem });
                    }
                    this.setState({ loading: false });
                }).catch(error => {
                    ApiError(error, "Atualizar banner").then(call => call === true ? this.save(item) : "");
                }).then(() => { this.setState({ loading: false }); })
            this.setState({ edit: false });
        } else {
            notification("Banner", "Erro na edição do banner.", "danger", null, "top-right", null, false);
        }

    }

    cancel = () => {
        const { old_item } = this.state;
        this.setState({ item: old_item });
        this.setState({ edit: false });
    }

    validate(e) {
        const { validate, item } = this.state;




        if (Moment(new Date(item.remove)).isBefore(new Date(item.start))) {


            validate[e.target.name + 'State'] = 'has-success';
        } else {
            validate[e.target.name + 'State'] = 'has-danger';
        }
        this.setState(({ validate }));
    }

    check = () => {
        var valid = true;
        Object.entries(this.state.validate).forEach(data => {
            if (data[1] === 'has-danger') {
                valid = false;
            }
        });
        return valid;
    }



    render() {
        const { item, edit, validate, loading } = this.state;

        return (
            <tr style={{ alignSelf: 'center' }}>
                <td width="5%">
                    {Moment(item.remove) === Moment() || Moment(item.remove).isAfter(Moment()) ? <i className="fas fa-2x fa-eye" style={{ color: "green" }}></i> : <i className="fas fa-2x fa-eye-slash" style={{ color: "red" }}></i>}
                </td>
                <td>
                    {edit ?
                        <Input type="text" name="title" id="title"
                            value={item.title}
                            onChange={(e) => { this.setState(state => ({ item: { ...state.item, title: e.target.value } })) }}
                            required
                        />
                        : item.title}
                </td>
                <td>
                    {edit ?
                        <Input type="text" name="description" id="description"
                            value={item.description}
                            onChange={(e) => { this.setState(state => ({ item: { ...state.item, description: e.target.value } })) }}

                            required
                        />
                        : item.description}
                </td>
                <td width="10%">
                    <img style={{ borderRadius: 10 }} width={180} src={item.image} />
                </td>
                <td width="10%">
                    {edit ?
                        <FormGroup>
                            <Input type="date" name="start" id="start"
                                value={Moment(item.start).utc().format("YYYY-MM-DD")}
                                onChange={(e) => { this.validate(e); this.setState(state => ({ item: { ...state.item, start: new Date(e.target.value) } })) }}
                                valid={validate.startState === "has-success"}
                                invalid={validate.startState === "has-danger"}
                                required
                            />
                            <FormFeedback>
                                Título inválido.
                            </FormFeedback>
                            <FormFeedback valid>
                                Título válido.
                            </FormFeedback>
                        </FormGroup>
                        : Moment(item.start).format('LL')}
                </td>
                <td width="10%">
                    {edit ?
                        <FormGroup>
                            <Input type="date" name="end" id="end"
                                value={Moment(item.remove).utc().format("YYYY-MM-DD")}
                                onChange={(e) => { this.validate(e); this.setState(state => ({ item: { ...state.item, remove: new Date(e.target.value) } })) }}
                                valid={validate.endState === "has-success"}
                                invalid={validate.endState === "has-danger"}
                                required
                            />
                            <FormFeedback>
                                Título inválido.
                            </FormFeedback>
                            <FormFeedback valid>
                                Título válido.
                            </FormFeedback>
                        </FormGroup>
                        : Moment(item.remove).format('LL')}
                </td>
                <td width="5%" className="text-right">

                    {loading === false ? <>

                        {edit === false ? <>
                            <Button id="btn_edit" size="sm" color="warning" onClick={(e) => { this.edit(); }}><i className="fas fa-edit"></i></Button>
                            <UncontrolledTooltip placement="top" target="btn_edit">
                                Editar banner
                            </UncontrolledTooltip>

                            <Button id="btn_eliminar" size="sm" color="danger" onClick={(e) => { this.delete(item); }}><i className="fas fa-trash"></i></Button>
                            <UncontrolledTooltip placement="top" target="btn_eliminar">
                                Eliminar banner
                            </UncontrolledTooltip>
                        </> :
                            <>
                                <Button size="sm" color="danger" onClick={(e) => { this.cancel(); }}><i className="fas fa-times"></i></Button>
                                <Button size="sm" color="success" onClick={(e) => { this.save(item); }}><i className="fas fa-save"></i></Button>
                            </>
                        }
                    </> :
                        <>
                            {LoadingFixed(loading)}
                        </>}
                </td>
            </tr>
        );
    }
};

export default BannerItem;
