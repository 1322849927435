import React from 'react';
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import moment, { locale } from "moment";
import axios from 'axios';
import { Loading, notification, ApiError, GetHeader } from "../utils/Utilities";
locale('pt-pt');



class CuponsTypeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cuponType: props.cuponType,
            loadingData: false,
            currentUser: props.currentUser,
        };

    }

    static getDerivedStateFromProps(props, state) {
        if (props.currentUser !== state.currentUser) {
            return { currentUser: props.currentUser };
        }
        return null;
    }

    handleValue = async (name, value) => { await this.setState({ [name]: value }); return value; }

    openForm = (e, editForm) => {
        e.preventDefault()
        const { cuponType } = this.state;
        this.props.onChange(cuponType, editForm, this, false);
    }

    revokeCuponType = (e) => {
        e.preventDefault();
        this.handleValue('loadingData', true);
        const { cuponType } = this.state;
        var headers = GetHeader();
        axios.delete(global.api_url + "CuponType/" + cuponType.id, { headers })
            .then((response) => {
                if (response.status === 200) { notification("Regras de cupão", "Regra removida com sucesso.", "success", null, "top-right", null, false); this.handleValue('edit', false); }
                this.props.onChange(null, null, null, true);
            }).catch(error => {
                ApiError(error, "Cupões").then(call => call === true ? this.revokeCuponType(e) : "");
                
            }).then(() => { this.handleValue('loadingData', false); })
    }

    generateCupon(e) {
        e.preventDefault();
        this.handleValue('loadingData', true);
        const { cuponType } = this.state;
        var headers = GetHeader();
        axios.post(global.api_url + "Cupon/generate/" + 1, cuponType, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Regras de cupões", "Foi criado um novo cupão.", "success", null, "top-right", null, false);
                    this.handleValue('loadingData', false);
                }
            }).catch(error => {
                ApiError(error, "Cupões").then(call => call === true ? this.generateCupon(e) : "");
                this.handleValue('loadingData', false);
            })
    }


    render() {

        const { cuponType, loadingData } = this.state;

        return (
            <>
                <tr>
                    <th scope="row">
                        {cuponType.name}
                    </th>
                    <td>
                        {cuponType.description}
                    </td>
                    <td>
                        {cuponType.condition}
                    </td>
                    <td>
                        {moment(cuponType.start).format('llll')}
                    </td>
                    <td>
                        {cuponType.finish !== null ? moment(cuponType.finish).format('llll') : ""}
                    </td>
                    <td>
                        <Badge color="" className="badge-dot mr-4">
                            {cuponType.deleted === undefined || cuponType.deleted === null ? (<i className="bg-success" style={{ 'zoom': 3 }} />) : (<i className="bg-danger" style={{ 'zoom': 3 }} />)}
                        </Badge>
                    </td>
                    <td className="text-right">
                        {loadingData === true ? Loading(loadingData, 5) : (<>
                            <Button id="btn_revoke_cupontype" color="danger" name="revoke" type="button" size="sm" onClick={(e) => this.revokeCuponType(e)} ><i className="far fa-trash-alt"></i></Button>
                            <UncontrolledTooltip delay={0} placement="top" target="btn_revoke_cupontype">
                                Desativar validade da regra do cupão
                            </UncontrolledTooltip>

                            <Button id="btn_show_cupontype" color="success" name="show" type="button" size="sm" onClick={(e) => this.openForm(e, false)}><i className="far fa-eye"></i></Button>
                            <UncontrolledTooltip delay={0} placement="top" target="btn_show_cupontype">
                                Visualizar regra de cupão
                            </UncontrolledTooltip>


                            <Button id="btn_generate_cupontype" color="success" type="button" size="sm" onClick={(e) => this.generateCupon(e)} ><i className="fas fa-ticket-alt"></i></Button>
                            <UncontrolledTooltip delay={0} placement="top" target="btn_generate_cupontype">
                                Gerar cupão
                            </UncontrolledTooltip>
                        </>)}
                    </td>

                </tr>
            </>
        );
    }
}


export default CuponsTypeList;