import React from 'react';
import { UncontrolledTooltip, Button } from "reactstrap";
import { Loading } from "../utils/Utilities";
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

class ServiceTypesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceType: props.serviceType,
            loadingData: false,
            currentUser: props.currentUser,
            length: props?.length
        };
    }

    openEditServiceType(e) {
        e.preventDefault()
        const { serviceType } = this.state;
        this.props.onChange(serviceType);
    }

    changeOrder(e, order) {
        e.preventDefault();
        const { serviceType } = this.state;

        this.props.renderOrder(order, serviceType);

    }

    render() {
        const { serviceType, loadingData } = this.state;
        const iconList = Object.keys(Icons).filter((key) => key !== 'fas' && key !== 'prefix').map((icon) => Icons[icon]);
        library.add(...iconList);
        return (
            <>
                <tr>
                    <th style={{ width: '5%' }}>
                        {serviceType?.logo !== null && serviceType?.logo !== undefined && serviceType?.logo !== "" && serviceType?.logo.startsWith("data:image") && <center><img height={25} src={serviceType.logo} /></center>}
                    </th>
                    <td scope="row">
                        {serviceType.name}
                    </td>
                    <td>
                        {serviceType.description}
                    </td>
                    {loadingData ? (<>
                        <td className="text-right">
                            {Loading(loadingData, 5)}
                        </td>
                    </>) : (<>
                        <td className="text-right">
                            {/*{reOrder === true && <>*/}
                            {/*    {serviceType?.ordination !== 0 && <Button id="" color="info" name="btn_edit_serviceType" type="button" size="sm" onClick={e => { this.changeOrder(e, "UP") }} ><i class="fas fa-chevron-up"></i></Button>}*/}
                            {/*    {serviceType?.ordination !== length - 1 && <Button id="" color="info" name="btn_edit_serviceType" type="button" size="sm" onClick={e => { this.changeOrder(e, "DOWN") }} ><i class="fas fa-chevron-down"></i></Button>}*/}
                            {/*</>}*/}

                            <Button id="btn_edit_serviceType" color="warning" name="btn_edit_serviceType" type="button" size="sm" onClick={e => { this.openEditServiceType(e) }} ><i className="far fa-edit"></i></Button>
                            <UncontrolledTooltip delay={0} placement="top" target="btn_edit_serviceType">
                                Editar serviço
                            </UncontrolledTooltip>
                        </td>
                    </>)}
                </tr>
            </>
        );
    }
}


export default ServiceTypesList;