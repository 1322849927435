import React from 'react';
import { Button } from "reactstrap";
import Moment from 'moment';

Moment.locale('pt');

class SearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item !== undefined ? props.item : {},
            loadingData: false,
        };
    }

    openSchedule = (item) => { this.props.onClick(item); };

    render() {
        const { item } = this.state;
        return (<>
            <tr style={{ alignSelf: 'center' }}>
                <td>
                    {/*item.resources.workshop.workShopId + '-' +*/ item.id}
                </td>
                <td>
                    {item.resources.client.email}
                </td>
                <td>
                    {item.resources.client.firstName + ' ' + item.resources.client.lastName}
                </td>
                <td>
                    {item.resources.client.nif}
                </td>
                <td>
                    {item.resources.vehicule.plate}
                </td>

                <td>
                    {item.resources.vehicule.brand}
                </td>
                <td>
                    {item.resources.vehicule.model}
                </td>
                <td>
                    {Moment(item.start).format('LLL')}
                </td>
                <td>
                    {Moment(item.end).format('LLL')}
                </td>
                <td className="text-right">
                    <Button size="sm" color="success" onClick={(e) => { this.openSchedule(item); }}><i className="fas fa-eye"></i></Button>
                </td>
            </tr>
        </>);
    }
}

export default SearchForm;

