import React from 'react';
import { Card, CardHeader, CardBody, Row } from "reactstrap";
import { Bar } from "react-chartjs-2";
import { GetHeader, Loading, ApiError, Role } from '../../utils/Utilities';
import axios from 'axios';


class GraphServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            loading: false,
            activeNav: 1,
            data: null
        };
    }
    handleValue = (name, value) => { this.setState({ [name]: value }); }

    componentDidMount() {
        this.getBrandsByWorkshop();
    }

    getBrandsByWorkshop = () => {
        var headers = GetHeader();
        axios.get(global.api_url + "Graph/GetGraphServices", { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.handleValue("data", response.data)
                }
                this.handleValue("loading", false)
            }).catch(error => {
                this.handleValue("loading", false)
                ApiError(error, "Gráfico: Marcas por oficina.").then(call => call === true ? this.getBrandsByWorkshop() : "");
            });


    }


    render() {
        const { data, loading, currentUser } = this.state;

        const options = {
            responsive: true,

            options: {
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                color: "#e3231a",
                                zeroLineColor: "#e3231a",
                            },
                            ticks: {
                                callback: function (value) {
                                    if (!(value % 10)) {
                                        return "$" + value + "k";
                                    }
                                },
                            },
                        },
                    ],
                },
                tooltips: {
                    callbacks: {
                        label: function (item, data) {
                            var label = data.datasets[item.datasetIndex].label || "";
                            var yLabel = item.yLabel;
                            var content = "";

                            if (data.datasets.length > 1) {
                                content += label;
                            }

                            content += "$" + yLabel + "k";
                            return content;
                        },
                    },
                },
            },
        }
        return (

            <Card className="bg-gradient-neutral shadow">
                <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                        <div className="col">
                            <h6 className="text-uppercase text-dark ls-1 mb-1">
                                Serviços
                            </h6>

                           
                            {currentUser?.role === Role.Admin ?
                                <h2 className="text-dark mb-0">Todas as oficinas</h2>
                                : <h2 className="text-dark mb-0">Oficina</h2>}
                        </div>
                    </Row>
                </CardHeader>
                <CardBody>
                    <div className="chart">
                        {loading === true || data === null ?
                            Loading(true) :
                            <Bar
                                data={data}
                                options={options}
                                getDatasetAtEvent={(e) => console.log(e)}
                            />
                        }
                    </div>
                </CardBody>
            </Card>

        );
    }
}

export default GraphServices;

