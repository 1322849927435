import React from 'react';
import { Badge, Media } from "reactstrap";
import { Role } from "../utils/Utilities";
import Moment from 'moment-timezone';
import 'moment/locale/pt'
Moment.locale('pt');

const UserList = ({ props, user, currentPage }) => {


    const click_OpenProfile = (e, userData) => {
        e.preventDefault();
        props.history.push("/admin/profile", { user: userData, redirect: true, currentPage: currentPage });
    }
    const userAvatar = (userRole) => {
        switch (userRole) {
            case Role.Admin:
                return (<i className="fas fa-user-shield rs-text-green"></i>);
            case Role.GestorOficina:
                return (<i className="fas fa-user-cog rs-text-red"></i>);
            case Role.Oficina:
                return (<i className="fas fa-tools rs-text-red"></i>);
            case Role.User:
                return (<i className="fas fa-user rs-text-blue"></i>);
            case Role.Mecanico:
                return (<i className="fas fa-tools rs-text-red"></i>);
            default:
                //return (<i className="fas fa-user rs-text-red"></i>);
                break;
        }
    }

    return (
        <tr onClick={(e) => { e.preventDefault(); click_OpenProfile(e, user) }}>
            <th scope="row">
                <Media className="align-items-center">
                    <a className="avatar rounded-circle mr-3" onClick={(e) => { click_OpenProfile(e, user) }} >
                        {userAvatar(user.role)}
                    </a>
                    <Media>
                        <span className="mb-0 text-sm">
                            {user.firstName + " " + user.lastName}
                        </span>
                    </Media>
                </Media>
            </th>
            <td style={{ textAlign: 'center' }}>
                {user.role}
            </td>
            <td style={{ textAlign: 'center' }}>
                {user.email}
            </td>
            <td style={{ textAlign: 'center' }}>
                <Badge color="" className="badge-dot">
                    {user.isVerified ? (<i className="bg-success" style={{ 'zoom': 3 }} />) :
                        (<i className="bg-warning" style={{ 'zoom': 3 }} />)}
                </Badge>
            </td>
            <td style={{ textAlign: 'center' }}>
                {Moment(user?.created).format('LLL')}
            </td>
        </tr>
    );
}

export default UserList;