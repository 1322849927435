import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardFooter, Table, Container, Row, Col, Button, Input, InputGroup, UncontrolledTooltip } from "reactstrap";
import axios from 'axios';
import CustomPagination from "views/backoffice/utils/CustomPagination";
import Header from "components/Headers/Header.js";
import UserList from "views/backoffice/Accounts/UserList.js";
import { CurrentUser, Loading, Role, ApiError, GetHeader, DeviceType, Device } from "views/backoffice/utils/Utilities";

const Users = (props) => {
    //const dispatch = useDispatch();
    var currentUser = CurrentUser();
    const [users, setUsers] = React.useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [pagination, setPagination] = useState(null);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(props?.location?.state?.currentPage !== undefined ? props?.location?.state?.currentPage : 1);



    const getAllUsers = (pageNumber, pageSize, search) => {
        setLoadingData(true)
        var headers = GetHeader();
        axios.get(global.api_url + "Accounts?PageNumber=" + pageNumber + "&PageSize=" + pageSize + "&Search=" + search, { headers })
            .then((response) => {
                if (response.headers["x-pagination"] !== undefined) {
                    setPagination(JSON.parse(response.headers["x-pagination"]));
                }
                const usersData = response.data;
                setUsers(usersData);
                setLoadingData(false);
            }).catch(error => {
                ApiError(error, "Utilizadores").then(call => call === true ? getAllUsers(pageNumber, pageSize, search) : "");
                setLoadingData(false);
            })
    }

    const searchAccount = (search) => {
        getAllUsers(1, 10, search === null ? "" : search);
    }

    const click_OpenProfileFormEmpety = (e) => {
        e.preventDefault();
        props.history.push("/admin/Profile", { create: true });
    }

    const changeHandler = (pageNumber, pagesize) => {
        setCurrentPage(pageNumber);
        getAllUsers(pageNumber, pagesize, "")
    }

    useEffect(() => { getAllUsers(currentPage, 10, ""); }, []);



    return (
        <>
            <Header subText={currentUser.role === Role.Admin ? "Todos os utilizadores da plataforma RedService" : "Colaboradores da oficina"} />
            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <Col xs={DeviceType(window.innerWidth) === Device.Mobile ? "12" : "4"}>
                                        <h3 className="mb-0">Utilizadores</h3>
                                    </Col>
                                    {currentUser.role === Role.Admin || currentUser.role === Role.GestorOficina ? (
                                        <>

                                            <Col className="text-right" xs={DeviceType(window.innerWidth) === Device.Mobile ? "12" : "4"} >
                                                <InputGroup>
                                                    <Input type="text" name="seacrh" id="input-seacrh"
                                                        bsSize="sm"
                                                        value={search}
                                                        placeholder="Pesquisar utilizador"
                                                        onChange={(e) => setSearch(e.target.value !== null && e.target.value !== undefined ? e.target.value : "")}
                                                        onKeyDown={(e) => { if (e.key === 'Enter') { searchAccount(search) } }}
                                                        disabled={false}
                                                    />
                                                    <Button size="sm" color="success" id="btn_search" onClick={(e) => {
                                                        searchAccount(search);
                                                    }}><i className="fas fa-search"></i></Button>

                                                </InputGroup>
                                            </Col>
                                            <Col className="text-right" xs={DeviceType(window.innerWidth) === Device.Mobile ? "12" : "4"}>
                                                <Button id="btn_add_user" color="success" onClick={(e) => click_OpenProfileFormEmpety(e)} size="sm">
                                                    <i className="fas fa-plus-circle"></i>{DeviceType(window.innerWidth) !== Device.Mobile ? "Adicionar utilizador" : ""}
                                                </Button>
                                                <UncontrolledTooltip delay={0} placement="top" target="btn_add_user">
                                                    Adicionar ultilizado a plataforma RedService
                                                </UncontrolledTooltip>
                                            </Col>
                                        </>
                                    ) : null}
                                </Row>
                            </CardHeader>

                            {Loading(loadingData)}
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" style={{ textAlign: 'center' }}>Nome</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Role</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Email</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Verificado</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Data de criação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.length > 0 && users.map((user, key) => { return <UserList key={key} props={props} user={user} currentPage={currentPage} /> })}
                                </tbody>
                            </Table>
                            <CardFooter className="py-4">
                                {pagination !== null && users.length > 0 ? (<CustomPagination pagination={pagination} array={users} onChange={changeHandler} />) : null}
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Users;
