import React from 'react';
import { FormGroup, Form, Input, FormFeedback, Row, Col, Table, Button, UncontrolledTooltip, Card, CardFooter, InputGroup, InputGroupText } from "reactstrap";
import { Loading, notification, Status, NotificationType, NotificationSend, GetHeader, GetHeaderPut, ApiError, StatusService } from "../utils/Utilities";
import axios from 'axios';
import logoHaynespro from 'assets/RedService/HaynesPro_Logo.png';
import ServiceList from './ServiceList';
import CustomPagination from "views/backoffice/utils/CustomPagination";
import ServiceForm from '../Services/ServiceForm';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import ModalScheduleDocument from '../Schedules/Document/ModalScheduleDocument';
import ModalBudgetInfo from '../Schedules/Budget/ModalBudgetInfo';
import confirm from "reactstrap-confirm";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { confirmAlert } from 'react-confirm-alert';
import Moment from 'moment-timezone';
import 'moment/locale/pt'
Moment.locale('pt');

class ScheduleForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workshopAccounts: props.workshopAccounts,
            carBrands: [],
            carModels: [],
            eventIsUpdated: false,
            services: [],
            currentUser: props.currentUser,
            currentWorkshop: props.currentWorkshop,
            currentService: null,
            edit: props.formEvent.id === 0 ? true : false,
            loadingData: false,
            loadingSelectData: true,
            loadingDataServices: true,
            event: props.formEvent,
            pagination: null,
            validate: {
                titleState: '',
                startState: '',
                endState: '',
                brandState: '',
                modelState: '',
                plateState: '',
                kmsState: '',
                firstNameState: '',
                emailState: '',
                phoneState: '',
            },
            tableServices: true,
            visibleModalScheduleDocument: false,
            loadVehicules: false,
            accountVehicules: [],
            oldStatusSchedule: null,
            ShowModalBudgetInfo: false,
        };

        this.getScheduleServices = this.getScheduleServices.bind(this);
        this.handleValue = this.handleValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSchedule = this.handleChangeSchedule.bind(this);
        this.handleChangeScheduleTimes = this.handleChangeScheduleTimes.bind(this);
    }

    componentDidMount() {
        this.getScheduleServices(1, 5);
        this.getTecDoc("getManufacturers", { articleCountry: "PT", country: "pt", lang: "pt", linkingTargetType: "P" }).then(res => { this.forceUpdate(); })
        document.addEventListener('keydown', this.keyDownHandler);
        this.signalRConnection(this.currentUser, this.currentWorkshop)
    }

    signalRConnection = (currentUser, currentWorkshop) => {
        if (global.connectionSignalR !== null && global.connectionSignalR !== undefined) {

            global.connectionSignalR.on("HaynesProResponse", (_botUser, scheduleId, docId) => {
                notification("HaynesPro", "Recebeu o orçamento do HaynesPro - DpAutomotive, faça refresh ao documento.", "info", null, "bottom-right", null, false)
                if (docId !== null && docId !== undefined) {
                    this.getScheduleServices(1, 5);
                }
            });
        }
    }

    keyDownHandler = (event) => { if (event.key === "Escape") { this.validateModal(this.state.eventIsUpdated, null, null, null); } }

    handleValue = async (name, value) => {
        await this.setState({ [name]: value }); return value;
    }

    handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        this.setState({ [name]: value });
    };

    handleChangeSchedule = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        if (name === '' || name === '') {
            this.setState(state => ({ event: { ...state.event, [name]: parseInt(value) } }));
        } else {
            this.setState(state => ({ event: { ...state.event, [name]: value } }));
        }
    };

    handleChangeScheduleTimes = async (name, value) => {
        await this.setState(state => ({ event: { ...state.event, [name]: value } }));
    }
    handleAllServices = async (value) => {
        await this.setState(state => ({ event: { ...state.event, resources: { ...state.event.resources, services: value } } }));
    }

    handleResources = async (resourceName, name, value) => {
        await this.setState(state => ({ event: { ...state.event, resources: { ...state.event.resources, [resourceName]: { ...state.event.resources[resourceName], [name]: value } } } }));
    }

    hanldeService = async (service) => {
        await this.setState(prevState => ({ services: [...prevState.services, service] }))
    }

    /*VALIDATIONS*/

    validateString(e) {
        const { validate } = this.state;

        if (e.target.value !== null && e.target.value !== "") {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }

        this.setState(({ validate }));
    }

    regexValidate(e, regex) {
        const { validate } = this.state;
        if (regex.test(e.target.value)) {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }

    validatePlate(e) {
        const regex = /^(([A-Z]{2}-\d{2}-(\d{2}|[A-Z]{2}))|(\d{2}-(\d{2}-[A-Z]{2}|[A-Z]{2}-\d{2})))$/;
        const { validate } = this.state;
        if (regex.test(e.target.value.toUpperCase())) {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }

    validateEmail(e) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state;

        if (regex.test(e.target.value) || e.target.value === "") {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });

    }

    validateKms(e) {
        const regex = /^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$/;///^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
        const { validate } = this.state;

        if (regex.test(e.target.value)) {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }


    validateSelect(resource, prop) {
        const { validate, event } = this.state;
        if (this.state.event.resources[resource][prop] !== "" && event.resources[resource][prop] !== null && event.resources[resource][prop] !== 0) {
            validate[prop + 'State'] = 'has-success';
        } else {
            validate[prop + 'State'] = 'has-danger';
        }
        this.setState({ validate });
    }

    validateDatetime(name, dateTime) {
        const { validate } = this.state;
        if (Moment(dateTime).isValid()) {
            if (Moment(dateTime).format("hh:mm:ss a").startsWith("12:00:00 am")) {
                var text = name === "start" ? "início" : "fim";
                notification("Agendamento", "Horário do " + text + " do agendamento é inválido.", "warning", null, "top-right", null, false);
                validate[name + 'State'] = 'has-danger';
            } else { validate[name + 'State'] = 'has-success'; }
        } else { validate[name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }

    validForm = () => {
        //var emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //Conte @ e .
        var { event, validate } = this.state;
        var valid = true;

        Object.entries(event).forEach(data => {
            if (data[0] !== 'resources') {
                if ((data[1] === '' || data[1] === 0) && (data[0] === 'start' || data[0] === 'end')) {
                    validate[data[0] + 'State'] = 'has-danger';
                    this.setState({ validate });
                    valid = false;
                }
            } else {
                Object.entries(event.resources).forEach(data => {
                    switch (data[0]) {
                        case 'client':
                            Object.entries(event.resources.client).forEach(data => {

                                //if (data[0] === 'email') {
                                //    if (data[1].match(emailPattern)) {
                                //        validate[data[0] + 'State'] = 'has-success';
                                //        this.setState({ validate });
                                //    } else {
                                //        validate[data[0] + 'State'] = 'has-danger';
                                //        this.setState({ validate });
                                //        valid = false;
                                //    }
                                //}

                                if (data[1] === '' && (data[0] === 'firstName' || data[0] === 'phone')) {
                                    validate[data[0] + 'State'] = 'has-danger';
                                    this.setState({ validate });
                                    valid = false;
                                } else {
                                    if (data[0] !== 'email') {
                                        validate[data[0] + 'State'] = 'has-success';
                                        this.setState({ validate });
                                    }
                                }
                            });
                            break;
                        case 'vehicule':
                            Object.entries(event.resources.vehicule).forEach(data => {
                                if ((data[1] === '' || data[1] === 0) && (data[0] === 'brand' || data[0] === 'kms' || data[0] === 'plate' || data[0] === 'model')) {
                                    validate[data[0] + 'State'] = 'has-danger';
                                    this.setState({ validate });
                                    valid = false;
                                } else {
                                    validate[data[0] + 'State'] = 'has-success';
                                    this.setState({ validate });
                                }
                            });
                            break;
                        case 'workshop':
                            Object.entries(event.resources.workshop).forEach(data => {
                                if ((data[1] === '' || data[1] === 0) && (data[0] === 'workShopId')) {
                                    validate[data[0] + 'State'] = 'has-danger';
                                    this.setState({ validate });
                                    valid = false;
                                    if (data[0] === 'workShopId' && data[1] === 0) { notification("Oficina", "Erro a submeter o pedido porque não é possivel obter a oficina destino.", "danger", null, "top-right", null, false); }
                                } else {
                                    validate[data[0] + 'State'] = 'has-success';
                                    this.setState({ validate });
                                }
                            });
                            break;
                        default:
                            break;
                    }
                });
            }
        })

        Object.entries(validate).forEach(data => {
            if (data[1] === 'has-danger') { valid = false; }
            if ((data[0] === 'startState' || data[0] === 'endState') && (data[1] === 'has-danger')) { notification("Agendamento", "É obrigatório definir uma data para o agendamento.", "danger", null, "top-right", null, false); }
        });
        return valid;
    }

    findCar(e) {
        const { carBrands } = this.state;
        this.handleResources('vehicule', 'plate', e.target.value.toUpperCase())
        var plate = e.target.value.trim().split("-");
        if (plate.length === 3) {
            if (plate[0].length === 2 && plate[1].length === 2 && plate[2].length === 2) {
                this.getPlate(e.target.value).then(response => {
                    if (response !== null) {
                        carBrands.forEach(e => {
                            if (e.manuName.toUpperCase() === response.marca.toUpperCase()) {
                                this.handleResources('vehicule', 'brand', e !== null && e !== undefined ? e.manuName : null).then(r => { this.validateSelect('vehicule', 'brand'); })
                                this.handleResources('vehicule', 'model', "");
                                this.handleValue("carModels", []);
                                this.getTecDoc("getModelSeries", { country: "PT", lang: "pt", linkingTargetType: "P", manuId: e.manuId }).then(models => {
                                    if (models !== null) {
                                        var found = false;
                                        models.forEach(model => {
                                            if (model.modelname.toLowerCase().valueOf().includes(response.modelo.toLowerCase().valueOf())) {
                                                this.handleResources('vehicule', 'model', model.modelname).then(r => { this.validateSelect('vehicule', 'model') });
                                                found = true;
                                            }
                                        });

                                        if (found === false) {
                                            this.handleResources('vehicule', 'model', response.modelo).then(r => { this.validateSelect('vehicule', 'model') });
                                            this.handleValue('loadingSelectData', false);
                                        }
                                    }

                                });
                            }
                        });
                    } else {
                        this.handleResources('vehicule', 'brand', null);
                        this.handleResources('vehicule', 'model', null);
                    }
                })
            }
        }
    }

    getPlate = async (plate) => {
        this.handleValue('loadingSelectData', true);
        this.handleValue('loadingData', true);
        return axios.get(`${global.api_url}TecDoc/GetByMatricula/${plate}`)
            .then((response) => {
                this.handleValue('loadingSelectData', false);
                this.handleValue('loadingData', false);
                if (response.status === 200) { return response.data; }
                return null;
            }).catch(error => {
                notification("Pesquisa de matrícula", "Não foi possível obter os dados do veículo.", "danger", null, "top-right", null, false);
                this.handleValue('loadingSelectData', false);
                this.handleValue('loadingData', false);
                return null;
            })
    }


    /*SUBMISSIONS*/
    submitForm(e) {
        e.preventDefault();
        var { event } = this.state;
        event.id === 0 ? this.create(e) : this.update(e, true);
    }

    cancel(e) {
        e.preventDefault();
        this.props.onChange(false);
    }

    delete() { }

    edit(e) {
        e.preventDefault();
        this.handleValue('edit', true);
    }

    /*GET VALUES */
    getScheduleServices(pageNumber, pageSize) {
        const { event } = this.state;
        this.handleValue('loadingDataServices', true);
        var headers = GetHeader();
        axios.get(global.api_url + "Services/" + event.id, { headers, params: { PageNumber: pageNumber, PageSize: pageSize } })
            .then((response) => {
                this.handleValue('loadingDataServices', false);
                if (response.status === 200) {
                    this.handleValue('services', []);
                    var defer = this.handleValue('services', response.data);
                    defer.then(value => { this.forceUpdate(); });
                }
                if (response.headers["x-pagination"] !== undefined) { this.handleValue('pagination', JSON.parse(response.headers["x-pagination"])); }
                this.handleValue('loadingDataServices', false);
            }).catch(error => {
                this.handleValue('loadingDataServices', false);
                ApiError(error, "Agendamento").then(call => call === true ? this.getScheduleServices(pageNumber, pageSize) : "");
            })
    }

    async getTecDoc(functionName, parameters) {
        this.handleValue('loadingSelectData', true);
        var headers = GetHeader();
        return axios.get(global.api_url + "TecDoc/" + functionName, { headers, params: parameters })
            .then((response) => {
                if (response.status === 200) {
                    switch (functionName) {
                        case 'getManufacturers':
                            var carBrands = JSON.parse(response.data.result.data)
                            this.handleValue('carBrands', carBrands);
                            this.handleValue('loadingSelectData', false);
                            return carBrands;
                        case 'getModelSeries':
                            var carModels = JSON.parse(response.data.result.data)
                            this.handleValue('carModels', carModels);
                            this.handleValue('loadingSelectData', false);
                            return carModels;
                        default:
                            break;
                    }
                    this.forceUpdate();
                }
                this.handleValue('loadingSelectData', false);
                return null;
            }).catch(error => {
                this.handleValue('loadingSelectData', false);
                ApiError(error, "TecDoc").then(call => call === true ? this.updateVehicule() : "");
                return null;
            })
    }



    changeHandler = (pageNumber, pagesize) => { this.getScheduleServices(pageNumber, pagesize); }

    serviceFormResponse = (type, service) => {
        const { event } = this.state;

        switch (type) {
            case 'ADD':
                this.handleValue('currentService', service);
                this.handleValue('tableServices', false);
                break;
            case 'CANCEL':
                this.handleValue('tableServices', true);
                break;
            case 'UPDATE':
                if (event.id === 0) {//Guardar temporáriamente
                    this.handleValue('tableServices', true);
                } else {// Guardar na BD
                    this.updateService(service);
                }
                break;
            case 'SAVE':
                if (event.id === 0) {//Guardar temporáriamente
                    this.hanldeService(service).then(response => {
                        this.handleValue('tableServices', true);
                    });
                } else {// Guardar na BD
                    this.AddService(service);
                }
                break;

            case 'REFRESH':
                if (event.id !== 0) {
                    this.getScheduleServices(1, 5)

                } else {// Guardar na BD
                    notification("Serviços", "Não foi possível atualizar os serviços.", "success", null, "top-right", null, false);
                }
                break;
            default:
                break;
        }
    }


    serviceListResponse = (type, service) => {
        switch (type) {
            case 'EDIT':
                this.handleValue('currentService', service);
                this.handleValue('tableServices', false);
                break;
            case 'DELETE':
                this.removeService(service);
                break;
            case 'BUDGET':
                this.handleValue('currentService', service);
                this.handleValue('visibleModalScheduleDocument', true);
                break;
            default:
                break;
        }
    }

    removeService = (service) => {
        const serviceList = [...this.state.services]; // create a new copy of the state array
        this.setState({ services: [] }, () => {
            const updatedServiceList = serviceList.filter(x => x.id !== service.id);
            this.setState({ services: updatedServiceList }, () => {
                //console.log(updatedServiceList);
            });
        });
    }

    validateModal = (loadData, event, toRemoveId, toLoadId, refreshEventId) => {
        this.props.onChange(loadData, event, toRemoveId !== undefined ? toRemoveId : null, toLoadId, refreshEventId);
    }

    searchAccount = (email) => {
        this.getAccount(email).then(account => {
            if (account !== null && account !== undefined) {
                this.handleResources('client', 'accountId', account?.id);
                this.handleResources('client', 'phone', account?.phone);
                this.handleResources('client', 'firstName', account?.firstName + " " + account?.lastName);
                if (account?.id !== undefined) {
                    this.getAccountVehicules(account?.id);
                }
            } else {
                this.handleResources('client', 'accountId', 0);
                this.handleResources('client', 'phone', "");
                this.handleResources('client', 'firstName', "");
            }
        });
    }


    getAccountVehicules = (id) => {
        this.handleValue("loadVehicules", true);
        var headers = GetHeader();
        axios.get(`${global.api_url}Vehicules/Account/${id}`, { headers }).then((response) => {
            if (response.status === 200) { this.handleValue("accountVehicules", response.data); }
            this.handleValue("loadVehicules", false);
        }).catch(error => {
            this.handleValue("loadVehicules", false);
            ApiError(error, "Veículos da conta").then(call => call === true ? this.getAccountVehicules(id) : "");
        })
    }


    getAccount = async (email, show_notification = true) => {
        var emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/; // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (email.match(emailPattern)) {

            var headers = GetHeader();
            return axios.get(`${global.api_url}Accounts/email/${email}`, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        show_notification === true && notification("Conta", "Conta do cliente " + response?.data?.firstName + " encontrada.", "success", null, "top-right", null, false);
                        return response.data;
                    }
                    show_notification === true && notification("Conta", "Este email não está associado a nenhuma conta.", "warning", null, "top-right", null, false);
                    return null;
                }).catch(error => {
                    ApiError(error, "Conta").then(call => call === true ? this.searchAccount(email) : "");
                    return null;
                })
        } else {
            show_notification === true && notification("Pesquisa", "Necessário preencher um email para efetuar uma pesquisa.", "danger", null, "top-right", null, false);
        }
    }

    /*API */
    AddService = (service) => {
        const { event } = this.state;
        service.scheduleId = event !== null ? event.id : 0;
        service.price = parseFloat(service.price);
        service.statusService = event.resources.workshop.status === Status.Oficina ? StatusService.Sugestao : StatusService.Pendente;


        var headers = GetHeader();
        axios.post(global.api_url + "Services", service, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Serviço", "Serviço adicionado com sucesso ao agendamento.", "success", null, "top-right", null, false);
                    this.hanldeService(service).then(response => { this.handleValue('tableServices', true); });
                }
            }).catch(error => {
                ApiError(error, "Adicionar Serviço").then(call => call === true ? this.AddService(service) : "");
            }).then(() => { this.handleValue('loadingData', false); })
    }


    updateService = (service) => {
        const { event } = this.state;
        service.scheduleId = event !== null ? event.id : 0;
        service.price = parseFloat(service.price);
        var headers = GetHeaderPut();
        axios.put(global.api_url + "Services", service, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Serviço", "Serviço atualizado com sucesso.", "success", null, "top-right", null, false);
                    this.handleValue('tableServices', true);
                }
            }).catch(error => {
                ApiError(error, "Atualizar Serviço").then(call => call === true ? this.updateService(service) : "");

            }).then(() => { this.handleValue('loadingData', false); })
    }

    create = (e) => {
        e.preventDefault();
        var { services } = this.state;
        if (this.validForm()) {
            if (services.length === 0) {
                let result = confirm({
                    title: (<><strong>Agendamento sem Serviços</strong></>),
                    message: (<>
                        <h4>Não tem serviços associados ao agendamento.</h4>
                        <p>Deseja continuar?</p>
                    </>),
                    confirmText: "Aceitar",
                    confirmColor: "success",
                    cancelText: "Cancelar",
                    cancelColor: "danger"
                });

                result.then(response => { if (response) { this.createShedule(e); } })
            } else {
                this.handleAllServices(services).then(response => { this.createShedule(e); });
            }
        } else { notification("Agenda", "Campos em Falta ou mal preenchidos.", "warning", null, "top-right", null, false); }

    }

    createShedule = (e) => {
        e.preventDefault();
        var { event, currentWorkshop, services } = this.state;
        this.handleValue('loadingData', true);
        var headers = GetHeaderPut();
        //ConvertValues
        event.title = 'Agendamento Oficina.'
        event.start = event.start != null ? new Date((event.start).getTime() - (event.start).getTimezoneOffset() * 60000) : new Date(0);
        event.end = event.end != null ? new Date((event.end).getTime() - (event.end).getTimezoneOffset() * 60000) : new Date(0);
        event.resources.vehicule.kms = parseInt(event.resources.vehicule.kms);
        event.resources.workshop.workShopId = currentWorkshop.id;
        //event.resources.workshop.status = Status.Pendente;
        services.forEach(service => { service.price = +service.price; })

        axios.post(global.api_url + "Schedules", event, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Agenda", "Foi adicionado um agendamento.", "success", null, "top-right", null, false);
                    event.resources.workshop.status = response.data.resources.workshop.status;
                    this.validateModal(true, response.data, null, null, response.data?.id);
                }
                this.handleValue('validate', {});
            }).catch(error => {
                ApiError(error, "Criar agendamento").then(call => call === true ? this.createShedule(e) : "");
            }).then(() => { this.handleValue('loadingData', false); })
    }

    cancelSchedule = (e) => {
        e.preventDefault();
        var { event } = this.state;
        this.validateModal(true, null, null, null);
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div style={{ borderStyle: "solid", padding: 40, borderRadius: 8, borderWidth: 2 }} className='custom-ui'>
                        <h1>Cancelar agendamento</h1>
                        <div>Cliente : <p style={{ fontSize: '15px', fontWeight: "bold" }}>{event.resources.client.firstName}</p></div>
                        <div>Veículo : <p style={{ fontSize: '15px', fontWeight: "bold" }}> {event.resources.vehicule.brand} {event.resources.vehicule.model} - {event.resources.vehicule.plate}</p></div>
                        <div>Horário : <p style={{ fontSize: '15px', fontWeight: "bold" }}> {Moment(event.start).format('LLL')} - {Moment(event.end).format('LLL')}</p></div>
                        <p>Deseja continuar?</p>
                        <center>
                            <Button color="danger" size="sm" type="button" onClick={(e) => {
                                e.preventDefault();
                                var headers = GetHeader();
                                axios.post(global.api_url + "Schedules/Soft/" + event.id, null, { headers })
                                    .then((response) => {
                                        if (response.status === 200) {
                                            notification("Agenda", "Agendamento e serviço(s) removido(s) com sucesso.", "success", null, "top-right", null, false);
                                            this.handleValue('edit', false);
                                            this.handleValue('eventIsUpdated', true);
                                        }
                                        this.validateModal(false, null, event.id, null);
                                    }).catch(error => {
                                        ApiError(error, "Agendamento").then(call => call === true ? this.cancelSchedule(e) : "");
                                    }).then(() => {
                                        this.handleValue('loadingData', false);
                                        this.validateModal(true, null, null, null);
                                        onClose();
                                    })
                            }}><i className="fas fa-trash"></i> Cancelar agendamento</Button>
                            <Button color="success" size="sm" onClick={() => { onClose(); }}><i className="fas fa-times"></i> Cancelar</Button>
                        </center>
                    </div>
                );
            }
        });
    }

    acceptSchedule = (e) => {
        e.preventDefault();
        var { event, eventIsUpdated } = this.state;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div style={{ borderStyle: "solid", padding: 40, borderRadius: 8, borderWidth: 2 }} className='custom-ui'>
                        <h1>Aceitar gendamento</h1>
                        <div>Cliente : <p style={{ fontSize: '15px', fontWeight: "bold" }}>{event.resources.client.firstName}</p></div>
                        <div>Veículo : <p style={{ fontSize: '15px', fontWeight: "bold" }}> {event.resources.vehicule.brand} {event.resources.vehicule.model} - {event.resources.vehicule.plate}</p></div>
                        <div>Horário : <p style={{ fontSize: '15px', fontWeight: "bold" }}> {Moment(event.start).format('LLL')} - {Moment(event.end).format('LLL')}</p></div>
                        <p>Deseja continuar?</p>
                        <center>
                            <Button color="success" size="sm" type="button" onClick={(e) => {
                                e.preventDefault();
                                var headers = GetHeader();
                                axios.post(global.api_url + "Schedules/Accept/" + event.id, null, { headers })
                                    .then((response) => {
                                        if (response.status === 200) {
                                            notification("Agenda", "Agendamento e serviço(s) aceite(s) com sucesso.", "success", null, "top-right", null, false);
                                            this.handleValue('edit', false);
                                            this.handleValue('eventIsUpdated', true);
                                        }
                                        this.validateModal(eventIsUpdated, null, event.id, event.id);
                                    }).catch(error => {
                                        ApiError(error, "Agendamento").then(call => call === true ? this.cancelSchedule(e) : "");
                                    }).then(() => { this.handleValue('loadingData', false); onClose(); })
                            }}><i className="fas fa-check"></i> Aceitar agendamento</Button>
                            <Button color="danger" size="sm" onClick={() => { onClose(); }}><i className="fas fa-times"></i> Fechar</Button>
                        </center>
                    </div>
                );
            }
        });

        this.validateModal(eventIsUpdated, null, null, null)
    }


    sendEmailNotification = (e) => {
        var { event } = this.state;
        if (event?.resources?.client?.email !== "" && event?.resources?.client?.email !== undefined) {

            var notificationRequest = {};

            if (event?.resources?.workshop?.status === Status.Aceite) {
                notificationRequest = { NotificationType: NotificationType.Aceite, NotificationSend: NotificationSend.Email, ScheduleId: event.id, Title: "Agendamento", Body: "" };
            } else {
                notificationRequest = { NotificationType: NotificationType.Orcamento, NotificationSend: NotificationSend.Email, ScheduleId: event.id, Title: "Novo Agendamento", Body: "" };
            }

            var headers = GetHeader();
            this.handleValue('loadingData', true);
            axios.post(global.api_url + "Notifications/Send/Email", notificationRequest, { headers })
                .then((response) => {
                    this.handleValue('loadingData', false);
                    if (response.status === 200) {
                        response.data === true ?
                            notification("Notificação", "Email enviado.", "success", null, "top-right", null, false) :
                            notification("Notificação", "Não foi possível enviar o email.", "warning", null, "top-right", null, false)
                    }
                }).catch(error => {
                    ApiError(error, "Enviar Notificação").then(call => call === true ? this.cancelSchedule(e) : "");
                }).then(() => { this.handleValue('loadingData', false); })
        }
    }


    sendNotification = (e) => {
        var { event } = this.state;
        if (event?.resources?.client?.email !== "" && event?.resources?.client?.email !== undefined) {
            event.notificate = true
            var headers = GetHeader();
            this.handleValue('loadingData', true);
            axios.post(global.api_url + "Notifications/sendNotification", event, { headers })
                .then((response) => {
                    this.handleValue('loadingData', false);
                    if (response.status === 200) {
                        notification("Notificação", "Notificação App enviada.", "success", null, "top-right", null, false);
                    } else {
                        notification("Notificação", "Não foi possivel enviar notificação ao cliente.", "warning", null, "top-right", null, false);
                    }
                }).catch(error => {
                    ApiError(error, "Enviar Notificação").then(call => call === true ? this.sendNotification(e) : "");
                }).then(() => { this.handleValue('loadingData', false); })
        }
    }

    updateAllServicesBySchedule = (scheduleId, status) => {

        if (scheduleId !== "" && scheduleId !== undefined && status !== "" && status !== undefined) {
            var headers = GetHeader();
            this.handleValue('loadingData', true);
            axios.post(global.api_url + "Services/UpdateAllServicesBySchedule", { ScheduleId: scheduleId, StatusService: status }, { headers })
                .then((response) => {
                    if (response.status === 200) { this.handleAllServices(response.data); }
                    this.handleValue('loadingData', false);
                }).catch(error => {
                    ApiError(error, "Atualizar Agendamento").then(call => call === true ? this.updateAllServicesBySchedule(scheduleId, status) : "");
                }).then(() => { this.handleValue('loadingData', false); })
        } else {
            notification("Serviços", "Não foi possivel atualizar os serviços.", "warning", null, "top-right", null, false);
        }
    }

    async update(e, changeData, sendNotification) {
        e.preventDefault();
        if (this.validForm()) {
            this.handleValue('loadingData', true);
            var { event } = this.state;

            event.resources.vehicule.kms = parseInt(event.resources.vehicule.kms);

            if (event.resources.workshop.status === Status.Validacao || event.resources.workshop.status === Status.Concluido) { event.notificate = true; }
            if (event.resources.workshop.status === Status.Validacao && (!Moment(event.start).isAfter(Moment()))) {
                this.handleResources('workshop', 'status', Status.Pendente)
                notification("Agendamento", "Para submeter um agendamento o início tem de ser superior ao dia e hora atual.", "warning", null, "top-right", null, false);
                this.handleValue('loadingData', false);
                return null;
            }

            if ((changeData === false || changeData === undefined) && changeData !== null) {
                if (event?.start !== undefined && event?.start.toString().startsWith("20")) {
                    event.start = new Date(event.start);
                    event.end = new Date(event.end);
                } else {
                    event.start = new Date((event?.start)?.getTime() - (event?.start)?.getTimezoneOffset() * 60000);
                    event.end = new Date((event?.end).getTime() - (event?.end)?.getTimezoneOffset() * 60000);
                }
            }

            var headers = GetHeader();
            await axios.post(global.api_url + "Schedules/App", event, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        notification("Agenda", "Agenda atualizada com sucesso.", "success", null, "top-right", null, false);
                        this.handleValue('edit', false);
                        this.handleValue('eventIsUpdated', true);
                        if (response.data !== null) {
                            this.handleChangeScheduleTimes("start", new Date((event.start).getTime() + (event.start).getTimezoneOffset() * 60000));
                            this.handleChangeScheduleTimes("end", new Date((event.end).getTime() + (event.end).getTimezoneOffset() * 60000));
                            if (sendNotification === true) {
                                if (response.data.resources.workshop.status !== Status.Pendente && response.data.resources.workshop.status !== Status.Rejeitado) {
                                    this.sendNotification(e);
                                    this.sendEmailNotification(e);
                                }
                            }
                        }
                    }
                    this.handleValue('validate', {});
                    return response;
                }).catch(error => {
                    ApiError(error, "Atualizar Agendamento").then(call => call === true ? this.update(e, false) : "");
                }).then(() => { this.handleValue('loadingData', false); })
        } else {
            notification("Agenda", "Campos em Falta ou mal preenchidos.", "warning", null, "top-right", null, false);
            if (this.state.oldStatusSchedule !== null) {
                this.handleResources('workshop', 'status', this.state.oldStatusSchedule);
            }
        }
    }

    time = (time) => { return new Date((time).getTime() - (time).getTimezoneOffset() * 60000); }

    onClickModalScheduleDocument = (e) => {
        this.getScheduleServices();
        this.handleValue('currentService', undefined);
        this.handleValue('visibleModalScheduleDocument', e)
    }

    getReception = (scheduleId) => {
        var headers = GetHeader();
        this.handleValue('loadingData', true);
        axios.get(`${global.api_url}Reception/PdfReception/${scheduleId}`, { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.openBase64NewTab(response.data);
                }
                this.handleValue('loadingData', false);
            }).catch(error => {
                ApiError(error, "PDF - Receção ativa").then(call => call === true ? this.getReception() : "");
                this.handleValue('loadingData', false);
            })
    }

    openBase64NewTab = (base64Pdf) => {
        var blob = this.base64toBlob(base64Pdf);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
        } else {
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl);
        }
    }

    base64toBlob = (base64Data) => {
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);
            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) { bytes[i] = byteCharacters[offset].charCodeAt(0); }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: "application/pdf" });
    }

    ckeckAccount = (client = null, email = null) => {
        if (client != null && email != null) {
            if (client.accountId > 0) {
                if (client.email !== email) {
                    this.handleResources('client', 'accountId', 0);
                    this.handleResources('client', 'phone', "");
                    this.handleResources('client', 'firstName', "");
                }
            }
        }
    }

    viewModalBudgetInfo = () => {
        const { ShowModalBudgetInfo } = this.state;
        this.setState({ ShowModalBudgetInfo: !ShowModalBudgetInfo });
    }

    updateSchedule = async (event, serverUpdate, updateStatusService = false) => {
        this.setState({ event: event });
        if (serverUpdate === true && event != null) {
            var headers = GetHeader();
            await axios.post(global.api_url + "Schedules/App", event, { headers }).then((response) => {
                if (response.status === 200) {
                    notification("Agenda", "Agenda atualizada com sucesso.", "success", null, "top-right", null, false);
                    if (response.data !== null) {


                        console.log(response.data);


                        this.setState({ event: response.data });
                        this.sendNotification();
                        this.sendEmailNotification();

                        if (updateStatusService === true) {
                            this.scheduleUpdateStatus(response.data);
                        }
                    }
                }
                return response;
            }).catch(error => {
                ApiError(error, "Atualizar Agendamento").then(call => call === true ? this.updateSchedule(event, serverUpdate) : "");
            }).then(() => { this.handleValue('loadingData', false); })
        }
    }

    scheduleUpdateStatus = (event) => {
        if (event !== null && event?.resources?.workshop?.status === Status.Aceite) {
            this.setState({ loadingData: true });
            var headers = GetHeader();
            axios.post(global.api_url + "Schedules/Accept/" + event.id, null, { headers }).then((response) => {
                if (response.status === 200) {
                    notification("Agenda", "Agendamento e serviço(s) aceite(s) com sucesso.", "success", null, "top-right", null, false);
                }
            }).catch(error => {
                this.setState({ loadingData: false });
                ApiError(error, "Agendamento").then(call => call === true ? this.scheduleUpdateStatus(event) : "");
            }).then(() => { this.setState({ loadingData: false }); })
        }
    }

    getWorkShopConfigs = async () => {
        this.handleValue('loading', true);
        var headers = GetHeader();
        return axios.get(global.api_url + "WorkshopConfigs", { headers })
            .then((response) => {
                this.handleValue('loading', false);
                return response.data
            }).catch(error => {
                this.handleValue('loading', false);
                ApiError(error, "Configurações").then(call => call === true ? this.getWorkShopConfigs() : "");
                return null;
            })
    }

    openHaynesPro = (scheduleId) => {
        var { event } = this.state;
        if (event?.resources?.vehicule?.plate !== undefined) {

            this.getWorkShopConfigs().then(response => {
                if (response !== null) {
                    this.handleValue('loading', true);
                    //var headers = GetHeader();
                    axios.post(global.portal_Leirilis + "desktopmodules/NBright/API/OpenStore/SilenceAuth", { username: response.userLeirilis, password: response.pwLeirilis, url: global.api_url })
                        .then((auth) => {
                            this.handleValue('loading', false);
                            if (auth.status === 200) {
                                window.open(global.portal_Leirilis + "pt-pt/Pe%C3%A7as/categorias/platenumber/" + event?.resources?.vehicule?.plate + "?r=" + auth.data + "&rs=" + GetHeader().Authorization + "&s=" + scheduleId, "_blank");
                                notification("Portal DpAutomotive", "HaynesPro foi aberto no portal Leirilis.", "success", null, "top-right", null, false);
                            }
                        }).catch(error => {
                            notification("Login Portal DpAutomotive", "Verificar username e password nas configurações.", "danger", null, "top-right", null, false);
                            this.handleValue('loading', false);
                        });
                } else {
                    notification("Definições da conta", "Não foi possível obter as definições da conta.", "danger", null, "top-right", null, false);
                }
            });
        } else {
            notification("HaynesPro", "Não foi possível obter a matrícula.", "warning", null, "top-right", null, false);
        }
    }

    render() {
        const { loadVehicules, accountVehicules, edit, loadingData, loadingDataServices, currentUser, event, validate, workshopAccounts, services, pagination, tableServices, carBrands, carModels, loadingSelectData, eventIsUpdated, visibleModalScheduleDocument, currentService, ShowModalBudgetInfo } = this.state;
        const { vehicule, workshop, client } = this.state.event.resources;
        return (
            <>
                {currentUser !== null &&
                    <div>
                        {loadingData === true ? (
                            Loading(loadingData)
                        ) : null}

                        {visibleModalScheduleDocument === true
                            && <ModalScheduleDocument event={event} currentUser={currentUser} currentWorkshop={workshop} currentService={currentService} onClick={(e) => { this.onClickModalScheduleDocument(e) }} />}

                        {ShowModalBudgetInfo === true &&
                            <ModalBudgetInfo event={event} validate={validate} viewModalBudgetInfo={this.viewModalBudgetInfo} updateSchedule={this.updateSchedule} />}

                        <Form>
                            <div>
                                <Row>
                                    <Col lg={workshop.status !== Status.Orcamento ? "3" : "6"}>
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-title" >
                                                Título
                                            </label>
                                            <Input type="title" name="title" id="title"
                                                valid={validate.titleState === "has-success"}
                                                invalid={validate.titleState === "has-danger"}
                                                value={event.title}
                                                onChange={(e) => { this.validateString(e); this.handleChangeSchedule(e); }}
                                                disabled={true}
                                                required
                                            />
                                            <FormFeedback>
                                                Título inválido.
                                            </FormFeedback>
                                            <FormFeedback valid>
                                                Título válido.
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={workshop.status !== Status.Orcamento ? "3" : "6"}>
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-title" >
                                                Estado
                                            </label>
                                            <InputGroup>
                                                <Input type="text" name="status" id="status"
                                                    value={workshop.status}
                                                    onChange={(e) => { this.validateString(e); this.handleChangeSchedule(e); }}
                                                    disabled={true}
                                                    required
                                                />
                                                {workshop.status === Status.Orcamento && <>
                                                    <Button size="m" color="danger" id="btn_to_Schedule"
                                                        disabled={!(event?.id !== 0)}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.viewModalBudgetInfo();
                                                        }}><i className="fas fa-share-square"></i></Button>
                                                    <UncontrolledTooltip delay={0} placement="top" target="btn_to_Schedule">
                                                        Mover orçamento para agenda.
                                                    </UncontrolledTooltip>
                                                </>}
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>

                                    {workshop.status !== Status.Orcamento && <>
                                        <Col lg="3">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-start" >
                                                    Início*
                                                </label>
                                                <Row>
                                                    <Col xl="6">
                                                        <Input
                                                            id="start"
                                                            name="start"
                                                            type="date"
                                                            valid={validate.startState === "has-success"}
                                                            invalid={validate.startState === "has-danger"}
                                                            onChange={(value) => {
                                                                var a = Moment(new Date(value.target.value)).format('YYYY-MM-DD');
                                                                this.validateDatetime('start', new Date(a)); this.handleChangeScheduleTimes('start', new Date(a));
                                                            }}
                                                            value={Moment(event.start).utc().format("YYYY-MM-DD")}
                                                            required={true}
                                                            disabled={!edit || (!(workshop.status === Status.Pendente || workshop.status === Status.Concluido || workshop.status === Status.Rejeitado || workshop.status === Status.Oficina))}
                                                        />

                                                    </Col>
                                                    <Col xl="6">
                                                        <Input
                                                            id="start"
                                                            name="start"
                                                            type="time"

                                                            valid={validate.startState === "has-success"}
                                                            invalid={validate.startState === "has-danger"}
                                                            onChange={(value) => {
                                                                var received = value.target.value.split(':');
                                                                var newDate = Moment(event.start)
                                                                var a = newDate.hours(received[0]).minutes(received[1]);
                                                                this.validateDatetime('start', new Date(a)); this.handleChangeScheduleTimes('start', new Date(a));
                                                            }}
                                                            value={Moment(event.start).format("HH:mm")}
                                                            required={true}
                                                            disabled={!edit || (!(workshop.status === Status.Pendente || workshop.status === Status.Concluido || workshop.status === Status.Rejeitado || workshop.status === Status.Oficina))}
                                                        />
                                                    </Col>
                                                </Row>
                                                {/*</div>*/}
                                            </FormGroup>
                                        </Col>
                                        <Col lg="3">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-end" id="lbl_end" >
                                                    Fim*
                                                </label>
                                                <Row>
                                                    <Col xl="6">
                                                        <Input
                                                            id="end"
                                                            name="end"
                                                            type="date"
                                                            valid={validate.endState === "has-success"}
                                                            invalid={validate.endState === "has-danger"}
                                                            onChange={(value) => {
                                                                var a = Moment(new Date(value.target.value)).format('YYYY-MM-DD');
                                                                this.validateDatetime('end', new Date(a)); this.handleChangeScheduleTimes('end', new Date(a));
                                                            }}
                                                            value={Moment(event.end).utc().format("yyyy-MM-DD")}
                                                            required={true}
                                                            disabled={!edit || (!(workshop.status === Status.Pendente || workshop.status === Status.Concluido || workshop.status === Status.Rejeitado || workshop.status === Status.Oficina))}
                                                        />

                                                    </Col>
                                                    <Col xl="6">
                                                        <Input
                                                            id="end"
                                                            name="end"
                                                            type="time"
                                                            valid={validate.endState === "has-success"}
                                                            invalid={validate.endState === "has-danger"}
                                                            onChange={(value) => {
                                                                var received = value.target.value.split(':');
                                                                if (received.length === 2) {
                                                                    var newDate = Moment(event.end)
                                                                    var b = newDate.hour(+received[0]).minute(+received[1]);
                                                                    this.validateDatetime('end', new Date(b)); this.handleChangeScheduleTimes('end', new Date(b));
                                                                }
                                                            }}
                                                            value={Moment(event.end).format("HH:mm")}
                                                            required={true}
                                                            disabled={!edit || (!(workshop.status === Status.Pendente || workshop.status === Status.Concluido || workshop.status === Status.Rejeitado || workshop.status === Status.Oficina))}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                    </>}
                                </Row>
                                <Row>

                                    <Col lg="5">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-title" >
                                                Colaborador
                                            </label>

                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={workshopAccounts.filter(x => x.id === workshop.colabId)}
                                                isDisabled={!edit}
                                                isLoading={loadingData}
                                                isClearable={true}
                                                isSearchable={true}
                                                placeholder="Selecionar Colaborador"
                                                options={workshopAccounts}
                                                onChange={(e) => { this.handleResources('workshop', 'colabId', e !== null ? e.id : null).then(re => {/* this.validateSelect('workshop', 'colabId');*/ }) }}
                                                getOptionLabel={(option) => `${option.firstName}`}
                                            />
                                            {(validate.colabIdState === "has-danger") &&
                                                <div style={{ width: '100%', margin: '0.25rem', fontSize: '80%', color: '#fb6340' }} >
                                                    Precisa de selecionar um colaborador.
                                                </div>
                                            }

                                        </FormGroup>
                                    </Col>
                                    <Col lg="7">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-notes" >
                                                Observações
                                            </label>
                                            <Input type="text" name="notes" id="notes"
                                                valid={validate.notesState === "has-success"}
                                                invalid={validate.notesState === "has-danger"}
                                                value={workshop.notes}
                                                onChange={(e) => { this.validateString(e); this.handleResources('workshop', 'notes', e.target.value) }}
                                                disabled={!edit}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="5">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-email" >
                                                Email
                                            </label>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <div>
                                                        {client.accountId > 0 && < i id="icon_account" className="fas fa-user-alt" style={{ color: 'green', alignSelf: 'center' }}></i>}
                                                        {client.accountId <= 0 && <i id="icon_without_account" className="fas fa-user-alt-slash" style={{ color: 'red' }}></i>}
                                                    </div>
                                                    {(client?.accountId > 0 && client?.accountId !== undefined) &&
                                                        <UncontrolledTooltip delay={0} placement="top" target="icon_account">
                                                            Agendamento com conta associada
                                                        </UncontrolledTooltip>}
                                                    {(client?.accountId <= 0 || client?.accountId === undefined) &&
                                                        <UncontrolledTooltip delay={0} placement="top" target="icon_without_account">
                                                            Agendamento sem conta associada
                                                        </UncontrolledTooltip>}
                                                </InputGroupText>

                                                <Input type="text" name="email" id="email"
                                                    style={{ paddingLeft: 10 }}
                                                    valid={validate.emailState === "has-success"}
                                                    invalid={validate.emailState === "has-danger"}
                                                    value={client.email}
                                                    onChange={(e) => { this.validateEmail(e); this.handleResources('client', 'email', e.target.value); this.ckeckAccount(client, e.target.value); }}
                                                    disabled={!edit}
                                                />

                                                <Button size="m" color="info" id="btn_email_search" disabled={!edit /*|| (workshop.status !== Status.Pendente || workshop.status === Status.Orcamento)*/} onClick={(e) => {
                                                    this.searchAccount(client?.email);
                                                }}><i className="fas fa-search"></i></Button>

                                            </InputGroup>
                                            <FormFeedback>
                                                Email inválido.
                                            </FormFeedback>
                                            <FormFeedback valid>
                                                Email válido.
                                            </FormFeedback>
                                            <UncontrolledTooltip delay={0} placement="top" target="btn_email_search">
                                                Procurar conta de cliente
                                            </UncontrolledTooltip>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="5">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-firstName" >
                                                Nome*
                                            </label>
                                            <Input type="text" name="firstName" id="firstName"
                                                valid={validate.firstNameState === "has-success"}
                                                invalid={validate.firstNameState === "has-danger"}
                                                value={client.firstName}
                                                onChange={(e) => { this.validateString(e); this.handleResources('client', 'firstName', e.target.value); /*this.buildTitle();*/ }}
                                                disabled={!edit}
                                            />
                                            <FormFeedback>
                                                Nome inválido.
                                            </FormFeedback>
                                            <FormFeedback valid>
                                                Nome válido.
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="2">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-phone" >
                                                Contacto*
                                            </label>
                                            <Input type="text" name="phone" id="phone"
                                                valid={validate.phoneState === "has-success"}
                                                invalid={validate.phoneState === "has-danger"}
                                                value={client.phone}
                                                onChange={(e) => { this.validateString(e); this.handleResources('client', 'phone', e.target.value) }}
                                                disabled={!edit}
                                            />
                                            <FormFeedback>
                                                Telefone inválido.
                                            </FormFeedback>
                                            <FormFeedback valid>
                                                Telefone válido.
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    {client.accountId > 0 &&
                                        <Col lg="2">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-title" >
                                                    Veículos
                                                </label>

                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    defaultValue={accountVehicules.filter(x => x.id === vehicule.vehiculeId)}
                                                    isDisabled={!edit}
                                                    isLoading={loadVehicules}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    placeholder="Selecionar Veículo"
                                                    options={accountVehicules}
                                                    onChange={(e) => {
                                                        this.handleResources('vehicule', 'vehiculeId', e?.id)
                                                        this.handleResources('vehicule', 'brand', e?.brand)
                                                        this.handleResources('vehicule', 'model', e?.model)
                                                        this.handleResources('vehicule', 'plate', e?.plate)
                                                        this.handleResources('vehicule', 'kms', e?.kms)
                                                    }}
                                                    getOptionLabel={(option) => `${option.plate + ": " + option.brand + " " + option.model}`}
                                                />
                                                {(validate.colabIdState === "has-danger") &&
                                                    <div style={{ width: '100%', margin: '0.25rem', fontSize: '80%', color: '#fb6340' }} >
                                                        Precisa de selecionar um colaborador.
                                                    </div>
                                                }

                                            </FormGroup>
                                        </Col>
                                    }
                                    <Col lg={client.accountId <= 0 ? "4" : "2"}>
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-plate" >
                                                Matrícula*
                                            </label>
                                            <Input
                                                //type="text"
                                                name="plate" id="plate"
                                                valid={validate.plateState === "has-success"}
                                                invalid={validate.plateState === "has-danger"}
                                                value={vehicule.plate}
                                                onChange={(e) => { this.validatePlate(e); this.findCar(e); }}
                                                disabled={!edit}
                                                type="tel"
                                                mask="**-**-**"
                                                maskChar=" "
                                                tag={InputMask}
                                            />
                                            <FormFeedback>
                                                Matrícula inválida.
                                            </FormFeedback>
                                            <FormFeedback valid>
                                                Matrícula válida.
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="3">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-title" >
                                                Marca*
                                            </label>

                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                value={vehicule.brand}
                                                //defaultValue={carBrands.filter(x => x.manuName === vehicule.brand)}
                                                isDisabled={!edit}
                                                isLoading={loadingSelectData}
                                                isClearable={true}
                                                isRtl={false}
                                                isSearchable={true}
                                                placeholder={vehicule.brand === '' ? "Selecionar Marca" : vehicule.brand}
                                                options={carBrands}
                                                onChange={(e) => {
                                                    this.handleResources('vehicule', 'brand', e !== null && e !== undefined ? e.manuName : null).then(r => { this.validateSelect('vehicule', 'brand'); })
                                                    this.handleResources('vehicule', 'model', "");
                                                    this.handleValue("carModels", []);
                                                    if (e !== null) { this.getTecDoc("getModelSeries", { country: "PT", lang: "pt", linkingTargetType: "P", manuId: e.manuId }) };
                                                }}
                                                getOptionLabel={(option) => `${option.manuName}`}
                                            />

                                            {(validate.brandState === "has-danger") &&
                                                <div style={{ width: '100%', margin: '0.25rem', fontSize: '80%', color: '#fb6340' }} >
                                                    Precisa de selecionar uma marca.
                                                </div>
                                            }
                                        </FormGroup>
                                    </Col>
                                    <Col lg="3">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-model" >
                                                Modelo*
                                            </label>

                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={carModels.filter(x => x.modelname === vehicule.model)}
                                                isDisabled={!edit}
                                                isLoading={loadingSelectData}
                                                isClearable={true}
                                                isRtl={false}
                                                isSearchable={true}
                                                placeholder={vehicule.model === '' ? "Selecionar Modelo" : vehicule.model}
                                                options={carModels}
                                                onChange={(e) => { this.handleResources('vehicule', 'model', e !== null && e !== undefined ? e.modelname : "").then(r => { this.validateSelect('vehicule', 'model') }) }}
                                                getOptionLabel={(option) => `${option.modelname}`}
                                            />

                                            {(validate.modelState === "has-danger") &&
                                                <div style={{ width: '100%', margin: '0.25rem', fontSize: '80%', color: '#fb6340' }} >
                                                    Precisa de selecionar um modelo.
                                                </div>
                                            }

                                        </FormGroup>
                                    </Col>
                                    <Col lg="2">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-kms" >
                                                Kms*
                                            </label>
                                            <Input type="text" name="kms" id="kms"
                                                valid={validate.kmsState === "has-success"}
                                                invalid={validate.kmsState === "has-danger"}
                                                value={vehicule.kms}
                                                onChange={(e) => { this.validateKms(e); this.handleResources('vehicule', 'kms', e.target.value) }}
                                                disabled={!edit}
                                                required
                                            />
                                            <FormFeedback>
                                                Kms inválidos.
                                            </FormFeedback>
                                            <FormFeedback valid>
                                                kms válidos.
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="col">
                                        {loadingDataServices === false ? (
                                            <>
                                                {tableServices ? (
                                                    <>
                                                        <Card className="shadow">
                                                            <Table className="align-items-center table-flush" responsive>
                                                                <thead className="thead-light">
                                                                    <tr>
                                                                        <th scope="col-md" style={{ width: "5%", minWidth: 50, maxWidth: 100, textAlign: 'center' }}>Serviço</th>
                                                                        <th scope="col-md" style={{ width: "5%", minWidth: 50, maxWidth: 100, textAlign: 'center' }}>Estado</th>
                                                                        <th scope="col-md" style={{ width: "30%", minWidth: 200, maxWidth: 800, textAlign: 'center' }}>Serviço</th>
                                                                        <th scope="col-md" style={{ width: "40%", minWidth: 200, maxWidth: 800, textAlign: 'center' }}>Descrição</th>
                                                                        <th scope="col-md" style={{ width: "5%", textAlign: 'center' }}>Preço</th>
                                                                        <th scope="col-md" style={{ width: "5%", textAlign: 'center' }}>Tempo</th>
                                                                        <th scope="col-md" className="text-right" style={{ minWidth: 125, width: "5%" }}>

                                                                            {event.id > 0 &&
                                                                                <>

                                                                                    <Button id="btn_HaynesPro" color="info" size="sm" name="update" type="button" onClick={e => {
                                                                                        this.openHaynesPro(event.id !== undefined ? event.id : null)
                                                                                    }} >
                                                                                        <img src={logoHaynespro} style={{ width: '75px' }} alt="HaynsProLogo" />
                                                                                    </Button>
                                                                                    <UncontrolledTooltip delay={0} placement="top" target="btn_HaynesPro">
                                                                                        Orçamento HaynesPro no Portal DpAutomotive - Leirilis
                                                                                    </UncontrolledTooltip>


                                                                                    <Button id="btn_Document123" color="info" size="sm" name="update" type="button" onClick={e => {
                                                                                        this.handleValue('visibleModalScheduleDocument', true);
                                                                                    }} >
                                                                                        <i className="fas fa-file-invoice"></i>
                                                                                    </Button>
                                                                                    <UncontrolledTooltip delay={0} placement="top" target="btn_Document123">
                                                                                        Visualizar Documento completo
                                                                                    </UncontrolledTooltip>
                                                                                </>
                                                                            }

                                                                            <Button id="btn_add_service12" color="success" name="update" type="button" size="sm" disabled={workshop.status !== Status.Entregue && workshop.status !== Status.Validacao && workshop.status !== Status.Aceite ? false : true}
                                                                                onClick={e => { e.preventDefault(); this.serviceFormResponse('ADD', { id: 0 }); }} >
                                                                                <i className="fas fa-plus-circle"></i>
                                                                            </Button>
                                                                            <UncontrolledTooltip delay={0} placement="top" target="btn_add_service12">
                                                                                Adicionar novo serviço
                                                                            </UncontrolledTooltip>

                                                                        </th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {services.length > 0 ? (<>
                                                                        {services.map((service, key) => {
                                                                            return <ServiceList currentUser={currentUser} event={this.state.event} disabled={workshop.status !== Status.Entregue ? false : true} key={key} service={service} workshop={workshop} onChange={this.serviceListResponse} onClick={(service) => { this.handleValue('currentService', service); this.handleValue('visibleModalScheduleDocument', true); }} />
                                                                        })}
                                                                    </>) : Loading(loadingDataServices)}
                                                                </tbody>
                                                            </Table>
                                                            <CardFooter className="py-4">
                                                                {pagination !== null ? (<CustomPagination pagination={pagination} array={services} onChange={this.changeHandler} />) : null}
                                                            </CardFooter>
                                                        </Card>
                                                    </>
                                                ) : (<ServiceForm currentUser={currentUser} formService={this.state.currentService} onChange={this.serviceFormResponse} />)}
                                            </>
                                        ) : <><Col lg="12"> {Loading(loadingDataServices, 5)}</Col></>}
                                    </div>
                                </Row>
                                <Row style={{ paddingTop: '20px' }}>
                                    {loadingData === false && <>
                                        <Col xl="6" >
                                            {event.resources.workshop.status === Status.Validacao &&
                                                (<>
                                                    {(loadingData === false && Moment(event.start).isAfter(Moment())) && <>
                                                        <Button id="btn_job_cancel" size="sm" color="danger" onClick={(e) => this.cancelSchedule(e)}><i className="fas fa-trash"></i> Cancelar</Button>
                                                        <UncontrolledTooltip placement="top" target="btn_job_cancel">
                                                            Irá cancelar o agendamento e notificar o cliente.
                                                        </UncontrolledTooltip>

                                                        <Button id="btn_send_noti" size="sm" color="warning" onClick={(e) => { this.sendEmailNotification(e); this.sendNotification(e); }}><i className="fas fa-paper-plane"></i> Enviar notificação</Button>
                                                        <UncontrolledTooltip placement="top" target="btn_send_noti">
                                                            Irá notificar o cliente.
                                                        </UncontrolledTooltip>
                                                    </>}
                                                    {Moment(event.start).isBefore(Moment()) && <>

                                                        <Button id="btn_job_cancel" size="sm" color="danger" onClick={(e) => this.cancelSchedule(e)}><i className="fas fa-trash"></i> Cancelar agendamento</Button>
                                                        <UncontrolledTooltip placement="top" target="btn_job_cancel">
                                                            Irá cancelar o agendamento e notificar o cliente.
                                                        </UncontrolledTooltip>

                                                    </>}

                                                    <Button id="btn_send_AcceptAll" size="sm" color="success" onClick={(e) => { this.acceptSchedule(e) }}><i className="fas fa-check"></i> Aceitar agendamento</Button>
                                                    <UncontrolledTooltip placement="top" target="btn_send_AcceptAll">
                                                        Irá Aceitar todo o agendamento
                                                    </UncontrolledTooltip>
                                                </>)
                                            }

                                            {event.id !== 0 && edit === false ? (
                                                <>

                                                    {(event.resources.workshop.status === Status.Oficina || event.resources.workshop.status === Status.Entregue || event.resources.workshop.status === Status.Concluido) && <></>
                                                        //<>
                                                        //    <Button id="btn_Reception" size="sm" color="info" onClick={(e) => { alert("gerar pdf no servidor") }}><i className="fas fa-file-contract"></i> Folha de obra</Button>
                                                        //    <UncontrolledTooltip delay={0} placement="top" target="btn_Reception">
                                                        //        Visualizar PDF de folha de obra de reparação
                                                        //    </UncontrolledTooltip>
                                                        //</>
                                                    }

                                                    {(event.resources.workshop.status === Status.Pendente || event.resources.workshop.status === Status.Rejeitado) && <>
                                                        <Button id="btn_job_cancel" size="sm" color="danger" onClick={(e) => this.cancelSchedule(e)}> <i className="fas fa-trash"></i> Cancelar</Button>
                                                        <UncontrolledTooltip placement="top" target="btn_job_cancel">
                                                            Irá cancelar a marcação e notificar o cliente.
                                                        </UncontrolledTooltip>

                                                        {event.resources.client.email !== "" ? <>
                                                            <Button id="btn_receive" size="sm" color="success" onClick={(e) => {
                                                                this.setState({ oldStatusSchedule: event.resources.workshop.status }, () => {
                                                                    this.handleResources('workshop', 'status', Status.Validacao).then(r => {
                                                                        this.update(e, false, true);
                                                                    });
                                                                });
                                                            }}><i className="fas fa-clipboard-check"></i>{' '}Submeter Agendamento</Button>
                                                            <UncontrolledTooltip delay={0} placement="top" target="btn_receive">
                                                                Informar cliente sobre a marcação da oficina.
                                                            </UncontrolledTooltip>
                                                        </> : <>
                                                            <Button id="btn_aprov" size="sm" color="warning" onClick={(e) => {
                                                                this.setState({ oldStatusSchedule: event.resources.workshop.status }, () => {
                                                                    this.handleResources('workshop', 'status', Status.Aceite).then(r => {
                                                                        this.update(e, false, true);
                                                                        this.updateAllServicesBySchedule(event?.id, StatusService.Validado);
                                                                    });
                                                                });
                                                            }}><i className="fas fa-clipboard-check"></i> Aprovar Agendamento</Button>
                                                            <UncontrolledTooltip delay={0} placement="top" target="btn_aprov">
                                                                Irá aceitar diretamente o agendamento
                                                            </UncontrolledTooltip>
                                                        </>}
                                                    </>}

                                                    {event.resources.workshop.status === Status.Aceite &&
                                                        <>
                                                            <Button id="btn_job_cancel" size="sm" color="danger" onClick={(e) => this.cancelSchedule(e)}><i className="fas fa-trash"></i> Cancelar</Button>
                                                            <UncontrolledTooltip placement="top" target="btn_job_cancel">
                                                                Irá cancelar o agendamento e notificar o cliente.
                                                            </UncontrolledTooltip>

                                                            <Button id="btn_job_Aceite" size="sm" color="success" onClick={(e) => {
                                                                this.setState({ oldStatusSchedule: event.resources.workshop.status }, () => {
                                                                    this.handleResources('workshop', 'status', Status.Oficina).then(r => { this.update(e); });
                                                                })
                                                            }}><i className="fas fa-warehouse"></i> Receber Veículo</Button>
                                                            <UncontrolledTooltip delay={0} placement="top" target="btn_job_Aceite">
                                                                Receber o veículo na Oficina.
                                                            </UncontrolledTooltip>
                                                        </>}

                                                    {event.resources.workshop.status === Status.Oficina && <>

                                                        <Button id="btn_job_done" size="sm" color="success" onClick={(e) => { this.setState({ oldStatusSchedule: event.resources.workshop.status }, () => { this.handleValue('notificate', true).then(r => { this.handleResources('workshop', 'status', Status.Concluido).then(r => { this.update(e); }); }) }) }}><i className="fas fa-flag-checkered"></i> Veículo pronto para recolha</Button>
                                                        <UncontrolledTooltip delay={0} placement="top" target="btn_job_done">
                                                            Informa o cliente, que a sua viatura encontra-se pronta para recolha na oficina.
                                                        </UncontrolledTooltip>
                                                    </>}

                                                    {event.resources.workshop.status === Status.Concluido && <>
                                                        <Button id="btn_job_complete" size="sm" color="warning" onClick={(e) => { this.setState({ oldStatusSchedule: event.resources.workshop.status }, () => { this.handleResources('workshop', 'status', Status.Oficina).then(r => { this.update(e, false); }); }) }}><i className="fas fa-warehouse"></i> Oficina</Button>
                                                        <UncontrolledTooltip delay={0} placement="top" target="btn_job_complete">
                                                            Voltar a por o estado Oficina no agendamento
                                                        </UncontrolledTooltip>

                                                        <Button id="btn_job_complete" size="sm" color="success" onClick={(e) => { this.setState({ oldStatusSchedule: event.resources.workshop.status }, () => { this.handleResources('workshop', 'status', Status.Entregue).then(r => { this.update(e, false); }); }) }}><i className="fas fa-flag-checkered"></i> Concluir</Button>
                                                        <UncontrolledTooltip delay={0} placement="top" target="btn_job_complete">
                                                            O pedido de agendamento fica conluído com a entrega do veiculo ao cliente.
                                                        </UncontrolledTooltip>
                                                    </>}

                                                    {(event.resources.workshop.status === Status.Oficina || event.resources.workshop.status === Status.Concluido || event.resources.workshop.status === Status.Entregue) &&
                                                        <>
                                                            <Button id="btn_reception" size="sm" color="info" onClick={(e) => { this.getReception(event?.id) }}><i className="far fa-file-pdf"></i> Recepção ativa</Button>
                                                            <UncontrolledTooltip delay={0} placement="top" target="btn_reception">
                                                                Obter pdf da recepção ativa.
                                                            </UncontrolledTooltip>
                                                        </>}
                                                </>
                                            ) : null}</Col>

                                        <Col xl="6" className="text-right" >
                                            {event.id === 0 ? (<>
                                                <Button id="btn_create1" color="success" size="sm" onClick={(e) => this.create(e)}><i className="far fa-calendar-plus"></i> Adicionar</Button>
                                                <UncontrolledTooltip delay={0} placement="top" target="btn_create1">
                                                    Criar agendamento
                                                </UncontrolledTooltip>

                                            </>) : (
                                                edit === true ? <>
                                                    <Button color="danger" size="sm" onClick={(e) =>
                                                        this.handleValue('edit', false)}><i className="fas fa-times"></i> Cancelar</Button>

                                                    <Button color="success" size="sm" onClick={(e) =>
                                                        this.update(e)}><i className="far fa-save"></i> Guardar</Button></>
                                                    :
                                                    <>
                                                        <Button id="btn_edit1" color="danger" size="sm" disabled={workshop.status !== Status.Entregue ? false : true} onClick={(e) => {
                                                            e.preventDefault();
                                                            this.handleValue('edit', true);

                                                            client?.id !== 0 &&
                                                                this.getAccount(client?.email, false).then(account => {
                                                                    if (account !== null && account !== undefined) {
                                                                        if (account?.id !== undefined) {
                                                                            this.getAccountVehicules(account?.id);
                                                                        }
                                                                    }
                                                                })



                                                        }}><i className="far fa-edit"></i> Editar</Button>
                                                        <UncontrolledTooltip delay={0} placement="top" target="btn_edit1">
                                                            Editar formulário
                                                        </UncontrolledTooltip>
                                                    </>
                                            )}
                                            {' '}
                                            {event.resources.workshop.status === Status.Validacao || event.resources.workshop.status === Status.Rejeitado ? (<>
                                                <Button size="sm" id="btn_close1" onClick={(e) => this.validateModal(eventIsUpdated, null, null, null)}><i className="far fa-window-close"></i> Fechar</Button></>

                                            ) : (<>
                                                <Button size="sm" id="btn_close1" onClick={(e) => this.validateModal(eventIsUpdated, true, null, null)}><i className="far fa-window-close"></i> Fechar</Button>
                                            </>
                                            )}
                                            <UncontrolledTooltip delay={0} placement="top" target="btn_close1">
                                                Fechar janela do agendamento
                                            </UncontrolledTooltip>
                                        </Col>
                                    </>}
                                </Row>
                            </div>
                        </Form>
                    </div >
                }
            </>
        );
    }
}

export default ScheduleForm;

