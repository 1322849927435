import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap"
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import RedServiceIcon from 'assets/RedService/favicon-32x32.png';
import NewRedServiceIcon from 'assets/RedService/location_pin.png';
import axios from 'axios';
import { Device, DeviceType, notification } from "../utils/Utilities";

class ModalMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lat: props.lat !== null && props.lat !== undefined && props.lat !== "" ? parseFloat(props.lat) : null,
            lng: props.lng !== null && props.lng !== undefined && props.lng !== "" ? parseFloat(props.lng) : null,
            zoom: 10,
            currentLocation: props.currentLocation !== null && props.currentLocation !== undefined ? { lat: props.currentLocation.lat, lng: props.currentLocation.lng } : null,
            newLocation: null,
            adress: null
        };
        //this.getCoord = this.getCoord.bind(this);
    }

    getCoord = () => {
        var $this = this;
        if (this.state.currentLocation === null) {
            navigator.geolocation.getCurrentPosition(function (position) {
                if (position.coords !== null) {
                    $this.setState(state => ({ currentLocation: { ...state.currentLocation, lat: position.coords.latitude, lng: position.coords.longitude } }));
                }
            });
        }
    }

    componentDidMount() {
        this.getCoord()
    }


    returnVal = (newLocation) => {
        this.props.onClick(newLocation);
    }


    getAdress = async (lat, lng) => {
        var config = {
            method: 'get',
            url: global.geoapify + '?lat=' + lat + '&lon=' + lng + '&apiKey=' + global.geoapifyKEY,
        };

        return axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return null;
            });
    }

    render() {
        const { zoom, lat, lng, currentLocation, adress, newLocation } = this.state;
        const workShopPosition = (lat !== null && lng !== null) ? [lat, lng] : null;
        const currentPosition = currentLocation !== null ? [currentLocation.lat, currentLocation.lng] : null;
        var $this = this;



        const MapEvents = () => {
            useMapEvents({
                click(e) {

                    $this.getAdress(e.latlng.lat, e.latlng.lng).then(response => {
                        if (response !== null) {
                            if (response.features.length > 0) {
                                $this.setState(state => ({
                                    adress: {
                                        ...state.adress,
                                        latitude: response.features[0].properties.lat.toString(),
                                        longitude: response.features[0].properties.lon.toString(),
                                        adress: response.features[0].properties.formatted,
                                        city: response.features[0].properties.city,
                                        postalCode: response.features[0].properties.postcode
                                    }
                                }));
                                $this.setState({ newLocation: [e.latlng.lat, e.latlng.lng] });

                            } else {
                                $this.setState(state => ({ adress: { ...state.adress, lat: e.latlng.lat, lgn: e.latlng.lng } }));
                            }

                        } else {
                            notification("Morada", "Erro a obter a morada.", "success", null, "top-right", null, false);
                        }
                    })
                },
            });
            return false;
        }

        return (
            <Modal className="modal-dialog-centered" contentClassName="bg-RS-grey-modal" isOpen={true} size="xl" style={{ minWidth: DeviceType(window.innerWidth) !== Device.Mobile ? "100vw" : "70vw", }}>
                <ModalHeader >
                    Mapa
                </ModalHeader>
                <ModalBody className="">
                    {adress !== null &&
                        <Row className="align-items-center">
                            <Col xl="2">
                                <h3 className="mb-0">Latitude</h3>
                                {adress.latitude}
                            </Col>
                            <Col xl="2">
                                <h3 className="mb-0">Longitude</h3>
                                {adress.longitude}
                            </Col>
                            <Col xl="4">
                                <h3 className="mb-0">Morada</h3>
                                {adress.adress}
                            </Col>
                            <Col xl="2">
                                <h3 className="mb-0">Código postal</h3>
                                {adress.postalCode}
                            </Col>
                            <Col xl="2">
                                <h3 className="mb-0">Cidade</h3>
                                {adress.city}
                            </Col>

                        </Row>
                    }

                    {currentLocation !== null &&
                        <MapContainer
                            center={workShopPosition !== null ? workShopPosition : currentPosition}
                            zoom={zoom}
                            style={{ width: '80vw', height: "70vh" }}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.RedService.pt">RedService</a>'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />

                            {currentPosition !== {} &&
                                <Marker position={currentPosition} icon={L.icon({ iconUrl: icon, iconShadow: iconShadow, iconRetinaUrl: icon })} >
                                    <Popup>
                                        Localização atual.
                                    </Popup>
                                </Marker>
                            }
                            {workShopPosition !== null && workShopPosition !== undefined &&
                                <Marker position={workShopPosition} icon={L.icon({ iconUrl: RedServiceIcon, iconShadow: RedServiceIcon, iconRetinaUrl: RedServiceIcon })} >
                                    <Popup>
                                        Oficina RedService
                                    </Popup>
                                </Marker>
                            }

                            {newLocation !== null &&
                                <Marker position={newLocation} icon={L.icon({ iconUrl: NewRedServiceIcon, iconShadow: NewRedServiceIcon, iconRetinaUrl: NewRedServiceIcon })} >
                                    <Popup>
                                        Nova localização Oficina RedService
                                    </Popup>
                                </Marker>
                            }


                            <MapEvents />

                        </MapContainer>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" type="button" onClick={(e) => { e.preventDefault(); this.props.onClick(null) }}><i className="far fa-window-close"></i> Fechar</Button>
                    {adress !== null &&
                        <Button color="success" size="sm" type="button" onClick={(e) => { e.preventDefault(); this.props.onClick(adress) }}><i className="fas fa-times"></i> Atualizar Morada</Button>}
                </ModalFooter>
            </Modal>
        )
    }
}

export default ModalMap;