import React from 'react';
import { Container, Row, Col } from "reactstrap";


const AuthFooter = (props) => {


    return (
        <>
            <footer className="py-5 default-rs-red " style={{
                position: 'fixed',
                bottom: 0,
                width: '100%'
            }} >
                {/*<div className="separator separator-bottom separator-skew zindex-100">*/}

                {/*</div>*/}
                <Container >

                    <Row className="align-items-center justify-content-xl-between">
                        <Col xl="6">
                            <div className="copyright text-center text-xl-left text-muted rs-text-white">
                                © {new Date().getFullYear()}{" "}
                                <a className="font-weight-bold ml-1 rs-text-white " href="https://www.RedService.pt" target="_blank" >RedService</a>
                            </div>
                        </Col>
                        <Col xl="6">
                            {/*<Nav className="nav-footer justify-content-center justify-content-xl-end ">*/}
                            {/*    <NavItem>*/}
                            {/*        <NavLink href="https://www.facebook.com/OficinasRedService/" target="_blank" className="rs-text-white">*/}
                            {/*            */}{/*Facebook */}{/*<i className="fab fa-2x fa-facebook"></i>*/}
                            {/*        </NavLink>*/}
                            {/*    </NavItem>*/}
                            {/*    <NavItem>*/}
                            {/*        <NavLink href="https://www.instagram.com/redservice_oficina/" target="_blank" className="rs-text-white">*/}
                            {/*            */}{/*Instagram*/}{/*<i className="fab fa-2x fa-instagram"></i>*/}
                            {/*        </NavLink>*/}
                            {/*    </NavItem>*/}

                            {/*</Nav>*/}
                        </Col>
                    </Row>

                </Container>



            </footer>
        </>
    );
};

export default AuthFooter;
