import React from 'react';
import { FormGroup, Form, Input, FormFeedback, Row, Col, Button, Card, CardBody, CardHeader } from "reactstrap";
import { Loading, StatusService } from "../utils/Utilities";
import Select from 'react-select';
import axios from 'axios';


class ServiceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            loadingData: false,
            service: {
                id: props.formService.id !== 0 ? props.formService.id : 0,
                scheduleId: props.formService.id !== 0 ? props.formService.scheduleId : 0,
                name: props.formService.id !== 0 ? props.formService.name : '',
                description: props.formService.id !== 0 ? props.formService.description : '',
                price: props.formService.id !== 0 ? props.formService.price : '',
                cupon: props.formService.id !== 0 ? props.formService.cupon : '',
                statusService: props.formService.id !== 0 ? props.formService.statusService : StatusService.Pendente,
            },
            validate: { nameState: '', descriptionState: '', cuponState: '', priceState: '' },
            serviceTypes: [],
            edit: true,
            products: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeService = this.handleChangeService.bind(this);
    }

    handleValue = (name, value) => { this.setState({ [name]: value }); }
    handleService = (name, value) => { this.setState(state => ({ service: { ...state.service, [name]: value } })); }

    componentDidMount() {

        this.getServiceTypes()
    }

    getServiceTypes = () => {
        this.setState({ loadingData: true });
        axios.get(`${global.api_url}ServiceTypes`).then((response) => {
            if (response.status === 200) {
                this.setState({ serviceTypes: response.data });
            }
            this.setState({ loadingData: false });
        }).catch(error => { this.setState({ loadingData: false }); })
    };


    handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        this.setState({ [name]: value });
    };

    handleChangeService = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        if (name === '' || name === '') {
            this.setState(state => ({ service: { ...state.service, [name]: parseInt(value) } }));
        } else {
            this.setState(state => ({ service: { ...state.service, [name]: value } }));
        }
    };
    /*VALIDATIONS*/

    validateString(e) {
        const { validate } = this.state;

        if (e.target.value !== null && e.target.value !== "") {
            validate[e.target.name + 'State'] = 'has-success';
        } else {
            validate[e.target.name + 'State'] = '';//'has-danger';
        }

        this.setState(({ validate }));
    }

    regexValidate(e, regex) {
        const { validate } = this.state;
        if (regex.test(e.target.value)) {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }
    /*SUBMISSIONS*/

    submitForm(e) {
        e.preventDefault();
        var { product } = this.state;
        product.id === 0 ? this.create() : this.update();
    }

    cancel(e) {
        e.preventDefault();
        this.props.onChange(false);
    }

    edit(e) {
        e.preventDefault();
        this.handleValue('edit', true);
    }

    serviceFormResponse(type) {
        const { service } = this.state;

        switch (type) {
            case 'CANCEL':
                this.props.onChange(type, service);
                break;
            case 'SAVE':
                if (this.validForm()) {
                    this.props.onChange('SAVE', service);
                }
                break;
            case 'UPDATE':
                if (this.validForm()) {
                    this.props.onChange('UPDATE', service);
                }
                break;
            default:
                break;
        }
    }

    validForm() {
        var { service, validate } = this.state;
        var valid = true;
        Object.entries(service).forEach(data => {
            if (data[1] === '' && (data[0] === 'name' || data[0] === 'desciption')) {
                validate[data[0] + 'State'] = 'has-danger';
                this.setState({ validate });
                valid = false;
            } else {
                validate[data[0] + 'State'] = '';
                this.setState({ validate });
            }

        });
        Object.entries(validate).forEach(data => { if (data[1] === 'has-danger') { valid = false; } });
        return valid;
    }

    render() {
        const { service, currentUser, loadingData, validate, edit, serviceTypes } = this.state;
        return (
            <>
                {currentUser !== null &&
                    //<Container className="mt--7" fluid>
                    <div>
                        {Loading(loadingData)}
                        <Form>
                            {/*<div className="pl-lg-4">*/}
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <Row>
                                            <Col xl="8">
                                                Adicionar serviço
                                            </Col>
                                        <Col xl="4" className="text-right">
                                            <Button color="danger" size="sm" type="button" onClick={e => { e.preventDefault(); this.serviceFormResponse('CANCEL'); }}><i className="fas fa-times"></i> Cancelar</Button>
                                            {service.id === 0 && <Button color="success" size="sm" type="button" onClick={e => { e.preventDefault(); this.serviceFormResponse('SAVE'); }}><i className="fas fa-save"></i> Guardar</Button>}
                                                {service.id !== 0 && <Button color="success" size="sm" type="button" onClick={e => { e.preventDefault(); this.serviceFormResponse('UPDATE'); }}>Atualizar</Button>}
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xl="3">
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor="input-type" >
                                                        Tipos de Serviço
                                                    </label>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        defaultValue={""}
                                                        isDisabled={!edit}
                                                        isLoading={loadingData}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        placeholder="Selecionar Tipo de serviço"
                                                        options={serviceTypes}
                                                        onChange={(e) => { this.setState(state => ({ service: { ...state.service, name: e.name } })); this.setState(state => ({ service: { ...state.service, description: e.description } })); }}
                                                        getOptionLabel={(option) => `${option.name}`}
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col xl="5">
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor="input-name" >
                                                        Nome*
                                                    </label>
                                                    <Input type="text" name="name" id="name"
                                                        valid={validate.nameState === "has-success"}
                                                        invalid={validate.nameState === "has-danger"}
                                                        value={service.name}
                                                        onChange={(e) => { this.validateString(e); this.handleChangeService(e); }}
                                                        disabled={!edit}
                                                        required
                                                    />
                                                    <FormFeedback>
                                                        Nome inválido.
                                                    </FormFeedback>
                                                    <FormFeedback valid>
                                                        Nome válido.
                                                    </FormFeedback>

                                                </FormGroup>
                                            </Col>
                                            <Col xl="4">
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor="input-name" >
                                                        Descrição
                                                    </label>
                                                    <Input type="textarea" name="description" id="description"
                                                        valid={validate.descriptionState === "has-success"}
                                                        invalid={validate.descriptionState === "has-danger"}
                                                        value={service.description}
                                                        onChange={(e) => { this.validateString(e); this.handleChangeService(e); }}
                                                        disabled={!edit}
                                                        required
                                                    />
                                                    <FormFeedback>
                                                        Descrição inválida.
                                                    </FormFeedback>
                                                    <FormFeedback valid>
                                                        Descrição válida.
                                                    </FormFeedback>

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            {/*</div>*/}
                        </Form>
                    </div>
                    //</Container>
                }
            </>
        );
    }
}

export default ServiceForm;

