// reactstrap components
import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupText, InputGroup, Row, Col, Modal } from "reactstrap";
import React, { useState } from 'react';
import axios from 'axios';
import { Link, Redirect,useLocation } from "react-router-dom";



const ResetPassword = () => {

    function useQuery() { const { search } = useLocation(); return React.useMemo(() => new URLSearchParams(search), [search]); }
    let query = useQuery();

    const [form, setForm] = useState({ token: query.get('token') ? query.get('token') : '', password: '', confirmPassword: '' });
    const updateField = e => { setForm({ ...form, [e.target.name]: e.target.value }); };

    const [modalVisible, setModalVisible] = useState(false);
    const toggleModal = () => { setModalVisible(!modalVisible); };

    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const toggleSpinner = (state) => { setSpinnerVisible(state); };

    const [result, setResult] = useState([]);
    const changeResult = (data) => { setResult(data) };

    const click_resetPassword = function (event) {
        event.preventDefault();
        toggleSpinner(true);
        axios.post(global.api_url + "Accounts/reset-password", form)
            .then(response => {
                global.currentUser = response;
                changeResult(response);
                if (response.status === 200) {
                    setForm({ email: '' })
                    return (<Redirect to={{ pathname: "/login" }} />);
                };
            })
            .catch(error => {
                changeResult(error.response);
            })
            .then(function () {
                toggleModal();
                toggleSpinner(false);
            });
    };



    return (
        <>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">

                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>Recuperação de Password</small>
                        </div>
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupText addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-email-83" />
                                        </InputGroupText>
                                    </InputGroupText>
                                    <Input placeholder="Token" autoComplete="new-token" name="token" value={form.token} onChange={updateField} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupText addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupText>
                                    <Input placeholder="Password" type="password" autoComplete="new-password" name="password" value={form.password} onChange={updateField} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupText addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupText>
                                    <Input placeholder="Confirmação Password" type="password" autoComplete="new-password" name="confirmPassword" value={form.confirmPassword} onChange={updateField} />
                                </InputGroup>
                            </FormGroup>

                            {spinnerVisible ?
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <div className="text-center">
                                    <Button className="my-4" color="primary" type="button" onClick={(e) => click_resetPassword(e)}>
                                        Recuperar
                                    </Button>
                                </div>
                            }
                        </Form>
                    </CardBody>
                </Card>
                <Row className="mt-3">
                    <Col xs="6">
                        <a className="text-light" href="#pablo" onClick={(e) => e.preventDefault()}>
                            <small><Link to="/login">Login</Link></small>

                        </a>
                    </Col>
                    <Col className="text-right" xs="6">
                        <a className="text-light" href="#pablo" onClick={(e) => e.preventDefault()}>
                            <small><Link to="/auth/register">Efetuar registo</Link></small>
                        </a>
                    </Col>
                </Row>


                <Modal
                    className="modal-dialog-centered modal-danger"
                    contentClassName="bg-gradient-info"
                    isOpen={modalVisible}
                    toggle={() => toggleModal()}>

                    <div className="modal-header">
                        {/*<h6 className="modal-title" id="modal-title-notification">*/}
                        {/*    Registo!*/}
                        {/*</h6>*/}
                        {/*<button*/}
                        {/*    aria-label="Close"*/}
                        {/*    className="close"*/}
                        {/*    data-dismiss="modal"*/}
                        {/*    type="button"*/}
                        {/*    onClick={() => toggleModal()}>*/}
                        {/*    <span aria-hidden={true}>×</span>*/}
                        {/*</button>*/}
                    </div>
                    <div className="modal-body">
                        <div className="py-3 text-center">
                            {result.status !== undefined ? (result.status === 200 ? <i className="far fa-4x fa-smile-beam"></i> : <i className="far fa-4x fa-frown"></i>) : ""}
                            {result.status !== undefined ? (< h4 className="heading mt-4">{result.status === 200 ? 'Verificar a caixa de email para efetuar a recuperação da password!' : 'Erro a enviar o email de recuperção de password.'}</h4>) : null}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button className="btn-white" color="default" type="button">
                            Ok, Got it
                        </Button>
                        <Button
                            className="text-white ml-auto"
                            color="link"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleModal()}>
                            Close
                        </Button>
                    </div>
                </Modal>


            </Col>
        </>
    );
};

export default ResetPassword;
