import React from "react";
import { Redirect, Route } from "react-router-dom";
import { CurrentUser } from "views/backoffice/utils/Utilities";

const ProtectedRouteLogged = ({ path, component, key }) => {

    var currentUser = CurrentUser();
    //const role = currentUser !== null ? currentUser.role : null;
    const isAuthenticated = currentUser !== null ? true : false;
    return (isAuthenticated === true ? <Route path={path} component={component} key={key} /> : <Redirect to="/login" />);
}

export default ProtectedRouteLogged;