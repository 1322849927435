import React from 'react';
import { StatusColor, StatusText, notification, GetHeader, ApiError } from "../../utils/Utilities";
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
//import { confirmAlert } from 'react-confirm-alert';
import confirm from "reactstrap-confirm";
import axios from 'axios';
import ModalScheduleInfo from '../../Schedules/ModalScheduleInfo'
import 'moment/locale/pt'

class BudgetList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            currentWorkshop: props.currentWorkshop,
            workshopAccounts: props.workshopAccounts,
            schedule: props.schedule,
            loadingData: false,
            showModal: false
        };
    }

    openEdit(e) {
        e.preventDefault()
        const { service } = this.state;
        this.props.onChange(service);
    }

    handleValue = (name, value) => { this.setState({ [name]: value }); }

    showSchedule = (e) => { e.preventDefault(); this.handleValue('showModal', true); }

    responseModal = (value) => { this.handleValue('showModal', value); }

    validateModal = (loadData, event, toRemoveId, toLoadId, refreshEventId) => {
        this.props.onValidate(loadData, event, toRemoveId !== undefined ? toRemoveId : null, toLoadId, refreshEventId);
    }

    delete(e, schedule) {
        e.preventDefault();

        let result = confirm({
            title: (<h2>Eliminar orçamento</h2>),
            message: (<>
                <div>Cliente:<p style={{ fontSize: '15px', fontWeight: "bold" }}>{schedule?.resources.client.firstName + " " + schedule?.resources.client.lastName}</p></div>
                <div>Contactos:<p style={{ fontSize: '15px', fontWeight: "bold" }}>{schedule?.resources.client.email + " - " + schedule?.resources.client.phone}</p></div>
                {/*<div>Data:<p style={{ fontSize: '15px', fontWeight: "bold" }}>{Moment(schedule?.start).format('LLLL') + " - " + Moment(schedule?.end).format('LLLL')}</p></div>*/}
                <div>Veículo:<p style={{ fontSize: '15px', fontWeight: "bold" }}>{schedule?.resources.vehicule.plate + " - " + schedule?.resources.vehicule.brand + " " + schedule?.resources.vehicule.model}</p></div>
                <h3><strong>Pretende eliminar o agendamento? </strong></h3>
            </>),
            confirmText: "Sim",
            confirmColor: "success",
            cancelText: "Não",
            cancelColor: "danger"
        });
        result.then(response => {
            if (response) {
                e.preventDefault();
                this.deleteApi(e);
                this.validateModal(true, null, null, null, null);
            }
        });
    }

    deleteApi = (e) => {
        const { schedule } = this.state;
        this.handleValue('loadingData', true);
        var headers = GetHeader();
        axios.post(global.api_url + "Schedules/Soft/" + schedule.id, null, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Agendamento", "Agendamento removido com sucesso.", "success", null, "top-right", null, false);
                    this.props.onClick(e);
                } else { notification("Agendamento", "Erro a remover o agendamento do cliente.", "warning", null, "top-right", null, false); }
                this.handleValue('loadingData', false);
            }).catch(error => {
                this.handleValue('loadingData', false);
                ApiError(error, "Agendamento").then(call => call === true ? this.deleteApi(e) : "");
            })
    }


    render() {
        const { schedule, showModal } = this.state;
        const changeStatus = (schedule) => {
            var color = StatusColor(schedule.resources.workshop.status);
            var text = StatusText(schedule.resources.workshop.status);
            return (<>
                <i id={'bange-' + schedule.id} style={{ 'zoom': 3, 'background': color }} />
                < UncontrolledTooltip delay={0} placement="top" target={'bange-' + schedule.id} >{text}</UncontrolledTooltip >
            </>)
        }
        return (
            <>
                {showModal && <ModalScheduleInfo event={this.state.schedule} currentUser={this.state.currentUser} currentWorkshop={this.state.currentWorkshop} workshopAccounts={this.state.workshopAccounts} onChange={this.responseModal} />}

                <tr>
                    <td style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                         paddingLeft: "40%"
                    }}>
                        <Badge className="badge-dot">
                            {changeStatus(schedule)}
                        </Badge>
                    </td>
                    <td>
                        {schedule.resources.client.firstName + " " + schedule.resources.client.lastName}
                    </td>
                    <td>
                        {schedule.resources.client.email}
                    </td>
                    <td>
                        {schedule.resources.client.phone}
                    </td>
                    <td>
                        {schedule.resources.vehicule.brand + " " + schedule.resources.vehicule.model}
                    </td>
                    <td>
                        {schedule.resources.vehicule.plate}
                    </td>
                    <td className="text-right">
                        <Button id="btn_trash" color="danger" name="update" type="button" size="sm" onClick={e => { this.delete(e, schedule) }} ><i className="fas fa-trash"></i></Button>
                        <UncontrolledTooltip delay={0} placement="top" target="btn_trash">
                            Eliminar orçamento
                        </UncontrolledTooltip>
                        <Button color="warning" id="btn_edit" name="update" type="button" size="sm" onClick={e => { this.showSchedule(e) }} ><i className="fas fa-eye"></i></Button>
                        <UncontrolledTooltip delay={0} placement="top" target="btn_edit">
                            Visualizar orçamento
                        </UncontrolledTooltip>
                    </td>
                </tr>
            </>
        );
    }
}


export default BudgetList;