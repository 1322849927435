import React from 'react';
import { Button, FormGroup, Form, Input, FormFeedback, Row, Col, Container, CardHeader, Card, CardBody, InputGroup, UncontrolledTooltip } from "reactstrap";
import { Loading, Role, notification, GetHeader, GetHeaderPut, ApiError, RemoveNotifications, Device, DeviceType } from "../utils/Utilities";
import axios from 'axios';
import ModalMap from '../utils/ModalMap';
import confirm from "reactstrap-confirm";

class WorkshopForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: props?.props?.location?.state?.currentPage,
            workshop: {
                id: props.formWorkshop.id !== 0 ? props.formWorkshop.id : 0,
                name: props.formWorkshop.id !== 0 ? props.formWorkshop.name : '',
                adress: props.formWorkshop.id !== 0 ? props.formWorkshop.adress : '',
                postalCode: props.formWorkshop.id !== 0 ? props.formWorkshop.postalCode : '',
                city: props.formWorkshop.id !== 0 ? props.formWorkshop.city : '',
                latitude: props.formWorkshop.id !== 0 ? props.formWorkshop.latitude : '',
                longitude: props.formWorkshop.id !== 0 ? props.formWorkshop.longitude : '',
                phone1: props.formWorkshop.id !== 0 ? props.formWorkshop.phone1 : '',
                phone2: props.formWorkshop.id !== 0 ? props.formWorkshop.phone2 : '',
                email: props.formWorkshop.id !== 0 ? props.formWorkshop.email : '',
                schedules: props.formWorkshop.id !== 0 ? props.formWorkshop.schedules : '',
                image: props.formWorkshop.id !== 0 ? props.formWorkshop.image : '',
                available: props.formWorkshop.id !== 0 ? props.formWorkshop.available : ''
            },
            old_workshop: props.formWorkshop.id !== 0 ? props.formWorkshop : {},
            validate: {
                nameState: '',
                adressState: '',
                postalCodeState: '',
                cityState: '',
                latitudeState: '',
                longitudeState: '',
                phone1State: '',
                phone2State: '',
                emailState: '',
                schedulesState: ''
            },
            buildSchedule:
                this.isJsonString(props.formWorkshop.schedules) === true ? JSON.parse(props.formWorkshop.schedules) : {
                    timeW1: '',
                    timeW2: '',
                    timeW3: '',
                    timeW4: '',
                    timeWD1: '',
                    timeWD2: '',
                    timeWD3: '',
                    timeWD4: '',
                },
            currentUser: props.currentUser,
            edit: props.formWorkshop.id !== 0 ? false : true,
            loadingData: false,
            showModalMap: false,
            image: null,
            changeImage: false,
            extension: "png"
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeWorkshop = this.handleChangeWorkshop.bind(this);
    }




    handleValue = (name, value) => { this.setState({ [name]: value }); }

    handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        this.setState({ [name]: value });
    };

    handleChangeWorkshop = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        this.setState(state => ({ workshop: { ...state.workshop, [name]: value.trim() } }));
    };
    handleBuildSchedule = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        this.setState(state => ({ buildSchedule: { ...state.buildSchedule, [name]: value } }));
    };
    /*VALIDATIONS*/

    validateString(e) {
        const { validate } = this.state;
        if (e.target.value !== null && e.target.value !== "") {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState(({ validate }));
    }

    validateEmail(e) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.regexValidate(e, regex);
    }

    validatePhone(e) {
        const regex = /^[0-9]{9}$/;
        this.regexValidate(e, regex);
    }
    validatePostalCode(e) {
        const regex = /^\d{4}(-\d{3})?$/;
        this.regexValidate(e, regex);
    }
    validateLatitude(e) {
        const regex = /^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}'/;
        this.regexValidate(e, regex);
    }

    regexValidate(e, regex) {
        const { validate } = this.state;
        if (regex.test(e.target.value)) {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }

    validForm() {
        var { workshop, validate } = this.state;
        var valid = true;
        Object.entries(workshop).forEach(data => {
            if (data[1] === '' && (data[0] === 'email' || data[0] === 'adress' || data[0] === 'latitude' || data[0] === 'longitude' || data[0] === 'phone1' || data[0] === 'name' || data[0] === 'postalCode' || data[0] === 'city')) {
                validate[data[0] + 'State'] = 'has-danger';
                this.setState({ validate });
                valid = false;
            }
        })
        Object.entries(validate).forEach(data => { if (data[1] === 'has-danger') { valid = false; } });
        return valid;
    }

    /*SUBMISSIONS*/

    submitForm(e) {
        e.preventDefault();
        var { workshop } = this.state;
        workshop.id === 0 ? this.createWorkShop() : this.updateWorkshop();
    }

    isJsonString = (str) => {
        try { JSON.parse(str); } catch (e) { return false; } return true;
    }

    updateWorkshop() {
        var json = JSON.stringify(this.state.buildSchedule);
        this.setState(state => ({ workshop: { ...state.workshop, schedules: json } }), () => {
            this.handleValue('loadingData', true);
            var { workshop } = this.state;
            var headers = GetHeaderPut();
            axios.put(global.api_url + "Workshops", workshop, { headers })
                .then((response) => {
                    notification("Atualização de Oficina", "Oficina atualizada com sucesso!", "success", null, "top-right", null, false);
                    this.handleValue('edit', false);
                    this.handleValue('loadingData', false);
                    this.handleValue('validate', {});
                }).catch(error => {
                    ApiError(error, "Atualiazação da oficina").then(call => call === true ? this.updateWorkshop() : "");
                    notification("Atualização de Oficina", error?.response?.data?.message, "warning", null, "top-right", null, false);
                }).then(() => { this.handleValue('loadingData', false); })
        });
    }

    createWorkShop(e) {
        e.preventDefault();
        if (this.validForm()) {
            this.handleValue('loadingData', true);
            var { workshop } = this.state;
            var headers = GetHeader();
            axios.post(global.api_url + "Workshops", workshop, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        this.handleValue('edit', false);
                        this.handleValue('loadingData', false);
                        this.handleValue('validate', {});
                        this.handleValue('workshop', response.data);
                        notification("Criação de Oficina", "Oficina criada com sucesso.", "success", null, "top-right", null, false);
                    }
                }).catch(error => {
                    ApiError(error, "Criação de oficina").then(call => call === true ? this.createWorkShop(e) : "");
                    notification("Criação de Oficina", error?.response?.data?.message, "warning", null, "top-right", null, false);
                }).then(() => {
                    this.handleValue('loadingData', false);
                })
        } else {
            notification("Criacao de oficina", "Campos em Falta ou mal preenchidos.", "danger", null, "top-right", null, false);
        }
    }

    editWorkshop(e) {
        e.preventDefault();
        this.handleValue('edit', true);
    }


    componentDidMount = () => {
        if (this.state.workshop.image !== null && this.state.workshop.image !== undefined) {
            this.getImageWorkshop(this.state?.workshop?.image);
        } else {
            this.getImageWorkshop("no-image.png");
        }
    }

    getImageWorkshop = (file) => {
        if (file !== "") {
            axios.get(global.api_url + "WorkShops/Image/" + file)
                .then(response => {
                    this.setState({ image: response.data?.base64 })
                }).catch(error => {
                    console.log(error)
                })
        }
    }

    saveWorkShopImage = (e) => {
        if (this.state.changeImage === true) {
            axios.post(global.api_url + "WorkShops/Image", { id: +this.state.workshop?.id, extension: this.state?.extension, base64: this.state?.image }).then(response => {
                if (response.status === 204) {
                    notification("Alteração de iamgem", "Imagem alterada com sucesso.", "success", null, "top-right", null, false);
                    this.setState({ changeImage: false });
                }
            }).catch(error => {
                notification("Alteração de iamgem", error, "error", null, "top-right", null, false);
            })
        }
    }

    UpdateStatus = (newStatus, newAvailable) => {
        var headers = GetHeader();
        axios.post(global.api_url + "Workshops/UpdateStatus", { id: this.state.workshop.id, status: newStatus, available: newAvailable }, { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.setState(state => ({ workshop: { ...state.workshop, active: newStatus, available: newAvailable } }));
                    RemoveNotifications();
                    notification("Atualização de Oficina", "Oficina atualizada com sucesso!", "success", null, "top-right", null, false);
                }
            }).catch(error => {
                ApiError(error, "Atualiazação da oficina").then(call => call === true ? this.UpdateStatus(newStatus, newAvailable) : "");
            })
    }

    /*GET VALUES */

    returnModalMap = (newLocation) => {
        if (newLocation !== null && newLocation !== undefined) {
            this.setState(state => ({
                workshop: {
                    ...state.workshop,
                    latitude: newLocation.latitude,
                    longitude: newLocation.longitude,
                    adress: newLocation.adress,
                    city: newLocation.city,
                    postalCode: newLocation.postalCode,
                }
            }));
        }
        this.setState({ showModalMap: false })
    }


    checkDeleteWorkshop = (e) => {
        e.preventDefault();

        var { workshop } = this.state;


        let result = confirm({
            title: (<h3><strong>Eliminar oficina</strong></h3>),
            message: (<>
                <h4>Ao aceitar eliminar esta a oficina <b>{workshop?.name}, todas as contas associadas a esta oficina serão eliminar.</b></h4>
                <p>Eliminar?</p>
            </>),
            confirmText: "Aceito eliminar tudo",
            confirmColor: "success",
            cancelText: "Cancelar",
            cancelColor: "danger"
        });

        result.then(response => { if (response) { this.deleteWorkshop(workshop); } })
    }


    deleteWorkshop = (workshop) => {
        var { currentPage } = this.state;

        this.handleValue('loadingData', true);
        var headers = GetHeader();
        axios.delete(global.api_url + "WorkShops/Delete/All/" + workshop?.id, { headers }).then(response => {
            if (response.status === 200) {
                this.handleValue('edit', false);
                this.handleValue('loadingData', false);

                if (response?.data === true) {
                    notification("Eliminação de Oficina", "Oficina e contas associadas elimnadas com sucesso", "success", null, "top-right", null, false);
                    this.props.props.history.push("/admin/Workshops", { currentPage: currentPage })
                } else {
                    notification("Eliminação de Oficina", "Não foi possível remover a oficina e contas associadas", "warning", null, "top-right", null, false);
                }
            }
        }).catch(error => {
            ApiError(error, "Eliminação de Oficina").then(call => call === true ? this.deleteWorkshop(workshop) : "");
            notification("Eliminação de Oficina", error?.response?.data?.message, "warning", null, "top-right", null, false);
        }).then(() => {
            this.handleValue('loadingData', false);
        })
    }


    render() {
        const { edit, loadingData, currentUser, old_workshop, showModalMap, image, changeImage, currentPage } = this.state;
        const { id, name, adress, postalCode, city, latitude, longitude, phone1, phone2, email, available } = this.state.workshop;
        const { nameState, adressState, postalCodeState, cityState, latitudeState, longitudeState, phone1State, phone2State, emailState } = this.state.validate;
        const { timeW1, timeW2, timeW3, timeW4, timeWD1, timeWD2, timeWD3, timeWD4 } = this.state.buildSchedule;

        function getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }

        return (
            <>
                {currentUser !== null &&
                    <>
                        {showModalMap && <ModalMap lat={latitude} lng={longitude} onClick={(newLocation) => { this.returnModalMap(newLocation) }} />}


                        <Container className="mt--7" fluid>
                            {Loading(loadingData)}

                            <Form className="form" onSubmit={(e) => this.submitForm(e)}>
                                <Row>
                                    <Col className="order-xl-1" xl="12">
                                        <Card className="bg-secondary shadow">
                                            <CardHeader className="bg-white border-0">
                                                <Row className="align-items-center">
                                                    <Col xs="8">
                                                        {id === 0 ?
                                                            <h3 className="mb-0">Nova Oficina</h3> :
                                                            <h3 className="mb-0">Oficina - {name}</h3>
                                                        }
                                                    </Col>
                                                    <Col className="text-right" xs="4">
                                                        {id === 0 ? (
                                                            <>
                                                                {this.state.currentUser.role === Role.Admin &&
                                                                    < Button color="success" size="sm" name="update" type="button" onClick={e => {
                                                                        e.preventDefault();
                                                                        this.props.props.history.push("/admin/Workshops", { currentPage: currentPage })
                                                                    }} >
                                                                        <i className="fas fa-chevron-circle-left"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Voltar" : ""}
                                                                    </Button>
                                                                }
                                                                <Button color="warning" size="sm" type="button" onClick={e => this.createWorkShop(e)}>
                                                                    <i className="far fa-save"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Criar Oficina" : ""}
                                                                </Button>
                                                            </>
                                                        )
                                                            : (!edit ? (

                                                                (currentUser.role === Role.GestorOficina || currentUser.role === Role.Admin) && <>
                                                                    {this.state.currentUser.role === Role.Admin &&
                                                                        < Button color="success" size="sm" name="update" type="button" onClick={e => {
                                                                            e.preventDefault();
                                                                            this.props.props.history.push("/admin/Workshops", { currentPage: currentPage })
                                                                        }} ><i className="fas fa-chevron-circle-left"></i> Voltar</Button>
                                                                    }
                                                                    < Button color="warning" size="sm" name="update" type="button" onClick={e => { this.editWorkshop(e); }} >
                                                                        <i className="far fa-edit"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Editar" : ""}
                                                                    </Button>
                                                                </>)
                                                                : (<>
                                                                    <Button color="danger" name="update1" type="button" size="sm" onClick={e => { this.setState({ workshop: old_workshop }); this.setState({ edit: false }); }}>
                                                                        <i className="fas fa-times"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Cancelar" : ""}
                                                                    </Button>

                                                                    {currentUser.role === Role.Admin && <>
                                                                        <Button color="danger" name="delete" type="button" size="sm" onClick={e => { this.checkDeleteWorkshop(e); }} >
                                                                        <i className="fas fa-exclamation-triangle"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "ELIMINAR OFICNA" : ""}
                                                                        </Button>
                                                                    </>}

                                                                    <Button color="success" name="update" type="button" size="sm" onClick={e => { this.updateWorkshop(e); }} >
                                                                        <i className="far fa-save"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Guardar" : ""}
                                                                    </Button>
                                                                </>))
                                                        }
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody>
                                                {currentUser.role !== Role.User ? (
                                                    <div>
                                                        <h6 className="heading-small text-muted mb-4">
                                                            Informações da Oficina
                                                        </h6>
                                                        <div className="pl-lg-4">
                                                            <Row>
                                                                <Col lg="2">
                                                                    <Row>

                                                                        <Col lg="12" style={{ justifyContent: 'center' }}>
                                                                            <FormGroup>
                                                                                <label className="form-control-label" htmlFor="input-name" >
                                                                                </label>
                                                                                {image !== null && <center><img alt={name} style={{ borderRadius: 10, resize: 'both', maxHeight: "250px", maxWidth: "100%" }} src={image} /></center>}
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {edit &&
                                                                            <Col lg="12">
                                                                                <FormGroup>
                                                                                    <label className="form-control-label" htmlFor="input-name" >
                                                                                        Imagem
                                                                                    </label>
                                                                                    <Col lg="12">
                                                                                        <Input type="file" id="image" onChange={(e) => {
                                                                                            if (e.target.files.length > 0) {
                                                                                                getBase64(e.target.files[0]).then(data => {
                                                                                                    if (data !== null) {
                                                                                                        this.setState({ image: data, changeImage: true, extension: e.target.files[0]?.type.split("/")[1] });
                                                                                                    }
                                                                                                });
                                                                                            }
                                                                                        }} />
                                                                                    </Col>
                                                                                    {changeImage === true &&
                                                                                        <Col lg="12">
                                                                                            <Button color="success" size="sm" type="button" onClick={e => this.saveWorkShopImage(e)}><i className="far fa-save"></i> Guardar</Button>
                                                                                        </Col>
                                                                                    }
                                                                                </FormGroup>
                                                                            </Col>
                                                                        }
                                                                    </Row>
                                                                </Col>
                                                                <Col lg="10">
                                                                    <Row>
                                                                        <Col lg="6">
                                                                            <label className="form-control-label" htmlFor="input-available">

                                                                                {available ?
                                                                                    (<>
                                                                                        <i className="far fa-calendar-check rs-text-green" style={{ 'zoom': 1.5 }} id="a_success"></i>
                                                                                        <UncontrolledTooltip delay={0} placement="top" target="a_success">
                                                                                            Oficina disponível para receber agendamentos.
                                                                                        </UncontrolledTooltip>
                                                                                    </>) :
                                                                                    (<>
                                                                                        <i className="far fa-calendar-times rs-text-red" style={{ 'zoom': 1.5 }} id="a_warning" />
                                                                                        <UncontrolledTooltip delay={0} placement="top" target="a_warning">
                                                                                            Oficina não pode receber agendamentos.
                                                                                        </UncontrolledTooltip>
                                                                                    </>)
                                                                                }
                                                                                {'  '}
                                                                                Agendamento
                                                                            </label>

                                                                            <InputGroup>

                                                                                <Input type="text" name="available" id="Available"
                                                                                    value={available ? "Agendamento Ativo" : "Agendamento Inativo"}
                                                                                    disabled={true}
                                                                                />
                                                                                <Button id="btn_schdule_available" color={available ? "danger" : "success"} name="update" type="button" disabled={!edit} size="m" onClick={e => { this.UpdateStatus(true, !available) }} >
                                                                                    {available ?
                                                                                        <i className="far fa-calendar-times" /> :
                                                                                        <i className="far fa-calendar-check " />}
                                                                                </Button>
                                                                                <UncontrolledTooltip delay={0} placement="top" target="btn_schdule_available">
                                                                                    Ativar/desativar agendamentos pelos clientes
                                                                                </UncontrolledTooltip>


                                                                            </InputGroup>



                                                                        </Col>
                                                                        <Col lg="6">
                                                                            <FormGroup>
                                                                                <label className="form-control-label" htmlFor="input-name" >
                                                                                    Nome
                                                                                </label>
                                                                                <Input type="text" name="name" id="name"
                                                                                    valid={nameState === "has-success"}
                                                                                    invalid={nameState === "has-danger"}
                                                                                    value={name}
                                                                                    onChange={(e) => { this.validateString(e); this.handleChangeWorkshop(e); }}
                                                                                    disabled={!edit}
                                                                                    required
                                                                                />
                                                                                <FormFeedback>
                                                                                    Nome inválido.
                                                                                </FormFeedback>
                                                                                <FormFeedback valid>
                                                                                    Nome válido.
                                                                                </FormFeedback>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col lg="6">
                                                                            <FormGroup>
                                                                                <label className="form-control-label" htmlFor="input-adress">
                                                                                    Morada
                                                                                </label>
                                                                                <Input type="text" name="adress" id="adress"
                                                                                    valid={adressState === "has-success"}
                                                                                    invalid={adressState === "has-danger"}
                                                                                    value={adress}
                                                                                    onChange={(e) => { this.validateString(e); this.handleChangeWorkshop(e); }}
                                                                                    disabled={!edit}
                                                                                />
                                                                                <FormFeedback>
                                                                                    Morada inválida.
                                                                                </FormFeedback>
                                                                                <FormFeedback valid>
                                                                                    Morada válida.
                                                                                </FormFeedback>
                                                                            </FormGroup>
                                                                        </Col>

                                                                        <Col lg="3">
                                                                            <FormGroup>
                                                                                <label className="form-control-label" htmlFor="input-postalCode" >
                                                                                    Código Postal
                                                                                </label>

                                                                                <Input type="text" name="postalCode" id="postalCode"
                                                                                    valid={postalCodeState === "has-success"}
                                                                                    invalid={postalCodeState === "has-danger"}
                                                                                    value={postalCode}
                                                                                    onChange={(e) => { this.validatePostalCode(e); this.handleChangeWorkshop(e); }}
                                                                                    disabled={!edit}
                                                                                />
                                                                                <FormFeedback>
                                                                                    Código Postal inválido.
                                                                                </FormFeedback>
                                                                                <FormFeedback valid>
                                                                                    Código Postal válido.
                                                                                </FormFeedback>

                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col lg="3">
                                                                            <FormGroup>
                                                                                <label className="form-control-label" htmlFor="input-city">
                                                                                    Cidade
                                                                                </label>
                                                                                <Input type="text" name="city" id="city"
                                                                                    valid={cityState === "has-success"}
                                                                                    invalid={cityState === "has-danger"}
                                                                                    value={city}
                                                                                    onChange={(e) => { this.validateString(e); this.handleChangeWorkshop(e); }}
                                                                                    disabled={!edit}
                                                                                />
                                                                                <FormFeedback>
                                                                                    Cidade inválida.
                                                                                </FormFeedback>
                                                                                <FormFeedback valid>
                                                                                    Cidade válida.
                                                                                </FormFeedback>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col lg="6">
                                                                            <FormGroup>
                                                                                <label className="form-control-label" htmlFor="input-latitude">
                                                                                    Latitude
                                                                                </label>

                                                                                <InputGroup>
                                                                                    <Button color="info" name="update" type="button" disabled={!edit} size="m" onClick={e => { this.setState({ showModalMap: true }) }} ><i className="fas fa-map"></i></Button>
                                                                                    <Input type="text" name="latitude" id="latitude"
                                                                                        valid={latitudeState === "has-success"}
                                                                                        invalid={latitudeState === "has-danger"}
                                                                                        value={"  " + latitude}
                                                                                        onChange={(e) => { this.validateString(e); this.handleChangeWorkshop(e); }}
                                                                                        disabled={!edit}
                                                                                    />
                                                                                </InputGroup>
                                                                                <FormFeedback>
                                                                                    Latitude inválida.
                                                                                </FormFeedback>
                                                                                <FormFeedback valid>
                                                                                    Latitude válida.
                                                                                </FormFeedback>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col lg="6">
                                                                            <FormGroup>
                                                                                <label className="form-control-label" htmlFor="input-longitude">
                                                                                    Longitude
                                                                                </label>
                                                                                <Input type="text" name="longitude" id="longitude"
                                                                                    valid={longitudeState === "has-success"}
                                                                                    invalid={longitudeState === "has-danger"}
                                                                                    value={longitude}
                                                                                    onChange={(e) => { this.validateString(e); this.handleChangeWorkshop(e); }}
                                                                                    disabled={!edit}
                                                                                />
                                                                                <FormFeedback>
                                                                                    Longitude inválida.
                                                                                </FormFeedback>
                                                                                <FormFeedback valid>
                                                                                    Longitude válida.
                                                                                </FormFeedback>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </div>


                                                        <hr className="my-4" />

                                                        <h6 className="heading-small text-muted mb-4">
                                                            Contactos
                                                        </h6>
                                                        <div className="pl-lg-4">
                                                            <Row>
                                                                <Col lg="4">
                                                                    <FormGroup>
                                                                        <label className="form-control-label" htmlFor="input-phone1">
                                                                            Telemóvel 1
                                                                        </label>
                                                                        <Input type="number" name="phone1" id="phone1"
                                                                            valid={phone1State === "has-success"}
                                                                            invalid={phone1State === "has-danger"}
                                                                            value={phone1}
                                                                            onChange={(e) => { this.validateString(e); this.handleChangeWorkshop(e); }}
                                                                            disabled={!edit}
                                                                        />
                                                                        <FormFeedback>
                                                                            Telefone 1 inválido.
                                                                        </FormFeedback>
                                                                        <FormFeedback valid>
                                                                            Telefone 1 válido.
                                                                        </FormFeedback>

                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <FormGroup>
                                                                        <label className="form-control-label" htmlFor="input-phone2">
                                                                            Telemóvel 2
                                                                        </label>
                                                                        <Input type="number" name="phone2" id="phone2"
                                                                            valid={phone2State === "has-success"}
                                                                            invalid={phone2State === "has-danger"}
                                                                            value={phone2}
                                                                            onChange={(e) => { this.validateString(e); this.handleChangeWorkshop(e); }}
                                                                            disabled={!edit}
                                                                        />
                                                                        <FormFeedback>
                                                                            Telefone 2 inválido.
                                                                        </FormFeedback>
                                                                        <FormFeedback valid>
                                                                            Telefone 2 válido.
                                                                        </FormFeedback>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <FormGroup>
                                                                        <label className="form-control-label" htmlFor="input-email">
                                                                            Email
                                                                        </label>
                                                                        <Input type="email" name="email" id="email"
                                                                            valid={emailState === "has-success"}
                                                                            invalid={emailState === "has-danger"}
                                                                            value={email}
                                                                            onChange={(e) => { this.validateEmail(e); this.handleChangeWorkshop(e); }}
                                                                            disabled={!edit}
                                                                        />
                                                                        <FormFeedback>
                                                                            Email inválido.
                                                                        </FormFeedback>
                                                                        <FormFeedback valid>
                                                                            Email válido.
                                                                        </FormFeedback>

                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                        <hr className="my-4" />

                                                        <h6 className="heading-small text-muted mb-4">
                                                            Horário Semanal
                                                        </h6>
                                                        <div className="pl-lg-4">
                                                            <Row>
                                                                {/*<Col lg="12">*/}
                                                                {/*    <FormGroup>*/}
                                                                {/*        <label className="form-control-label" htmlFor="input-schedules">*/}
                                                                {/*            Horário*/}
                                                                {/*        </label>*/}

                                                                {/*        <Input type="textarea" name="schedules" id="schedules"*/}
                                                                {/*            valid={schedulesState === "has-success"}*/}
                                                                {/*            invalid={schedulesState === "has-danger"}*/}
                                                                {/*            value={schedules}*/}
                                                                {/*            rows={5}*/}
                                                                {/*            onChange={(e) => { this.validateString(e); this.handleChangeWorkshop(e); }}*/}
                                                                {/*            disabled={!edit}*/}
                                                                {/*        />*/}
                                                                {/*        <FormFeedback>*/}
                                                                {/*            Horário inválido.*/}
                                                                {/*        </FormFeedback>*/}
                                                                {/*        <FormFeedback valid>*/}
                                                                {/*            Horário válido.*/}
                                                                {/*        </FormFeedback>*/}

                                                                {/*    </FormGroup>*/}
                                                                {/*</Col>*/}

                                                                <Col lg="12">
                                                                    <Row>
                                                                        <Col lg="3">
                                                                            <FormGroup>
                                                                                <label className="form-control-label" htmlFor="input-timeW1">
                                                                                    Abertura
                                                                                </label>

                                                                                <Input type="time" name="timeW1" id="timeW1"
                                                                                    value={timeW1}
                                                                                    onChange={(e) => { this.handleBuildSchedule(e); }}
                                                                                    disabled={!edit}
                                                                                />
                                                                                <FormFeedback>
                                                                                    Horário inválido.
                                                                                </FormFeedback>
                                                                                <FormFeedback valid>
                                                                                    Horário válido.
                                                                                </FormFeedback>

                                                                            </FormGroup>
                                                                        </Col>

                                                                        <Col lg="3">
                                                                            <FormGroup>
                                                                                <label className="form-control-label" htmlFor="input-timeW2">
                                                                                    Fecho para almoço
                                                                                </label>
                                                                                <Input type="time" name="timeW2" id="timeW2"
                                                                                    value={timeW2}
                                                                                    onChange={(e) => { this.handleBuildSchedule(e); }}
                                                                                    disabled={!edit}
                                                                                />
                                                                                <FormFeedback>
                                                                                    Horário inválido.
                                                                                </FormFeedback>
                                                                                <FormFeedback valid>
                                                                                    Horário válido.
                                                                                </FormFeedback>

                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col lg="3">
                                                                            <FormGroup>
                                                                                <label className="form-control-label" htmlFor="input-schedules">
                                                                                    Abertura após almoço
                                                                                </label>

                                                                                <Input type="time" name="timeW3" id="timeW3"
                                                                                    value={timeW3}
                                                                                    onChange={(e) => { this.handleBuildSchedule(e); }}
                                                                                    disabled={!edit}
                                                                                />
                                                                                <FormFeedback>
                                                                                    Horário inválido.
                                                                                </FormFeedback>
                                                                                <FormFeedback valid>
                                                                                    Horário válido.
                                                                                </FormFeedback>

                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col lg="3">
                                                                            <FormGroup>
                                                                                <label className="form-control-label" htmlFor="input-schedules">
                                                                                    Fecho
                                                                                </label>

                                                                                <Input type="time" name="timeW4" id="timeW4"
                                                                                    value={timeW4}
                                                                                    onChange={(e) => { this.handleBuildSchedule(e); }}
                                                                                    disabled={!edit}
                                                                                />
                                                                                <FormFeedback>
                                                                                    Horário inválido.
                                                                                </FormFeedback>
                                                                                <FormFeedback valid>
                                                                                    Horário válido.
                                                                                </FormFeedback>

                                                                            </FormGroup>
                                                                        </Col>

                                                                    </Row>

                                                                </Col>

                                                            </Row>
                                                        </div>


                                                        <h6 className="heading-small text-muted mb-4">
                                                            Horário ao sábado
                                                        </h6>
                                                        <div className="pl-lg-4">
                                                            <Row>

                                                                <Col lg="12">
                                                                    <Row>
                                                                        <Col lg="3">
                                                                            <FormGroup>
                                                                                <label className="form-control-label" htmlFor="input-timeWD1">
                                                                                    Abertura
                                                                                </label>

                                                                                <Input type="time" name="timeWD1" id="timeWD1"
                                                                                    value={timeWD1}
                                                                                    onChange={(e) => { this.handleBuildSchedule(e); }}
                                                                                    disabled={!edit}
                                                                                />
                                                                                <FormFeedback>
                                                                                    Horário inválido.
                                                                                </FormFeedback>
                                                                                <FormFeedback valid>
                                                                                    Horário válido.
                                                                                </FormFeedback>

                                                                            </FormGroup>
                                                                        </Col>

                                                                        <Col lg="3">
                                                                            <FormGroup>
                                                                                <label className="form-control-label" htmlFor="input-timeWD2">
                                                                                    Fecho para almoço
                                                                                </label>

                                                                                <Input type="time" name="timeWD2" id="timeWD2"
                                                                                    value={timeWD2}
                                                                                    onChange={(e) => { this.handleBuildSchedule(e); }}
                                                                                    disabled={!edit}
                                                                                />
                                                                                <FormFeedback>
                                                                                    Horário inválido.
                                                                                </FormFeedback>
                                                                                <FormFeedback valid>
                                                                                    Horário válido.
                                                                                </FormFeedback>

                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col lg="3">
                                                                            <FormGroup>
                                                                                <label className="form-control-label" htmlFor="input-timeWD3">
                                                                                    Abertura após almoço
                                                                                </label>

                                                                                <Input type="time" name="timeWD3" id="timeWD3"
                                                                                    value={timeWD3}
                                                                                    onChange={(e) => { this.handleBuildSchedule(e); }}
                                                                                    disabled={!edit}
                                                                                />
                                                                                <FormFeedback>
                                                                                    Horário inválido.
                                                                                </FormFeedback>
                                                                                <FormFeedback valid>
                                                                                    Horário válido.
                                                                                </FormFeedback>

                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col lg="3">
                                                                            <FormGroup>
                                                                                <label className="form-control-label" htmlFor="input-timeWD4">
                                                                                    Fecho
                                                                                </label>

                                                                                <Input type="time" name="timeWD4" id="timeWD4"
                                                                                    value={timeWD4}
                                                                                    onChange={(e) => { this.handleBuildSchedule(e); }}
                                                                                    disabled={!edit}
                                                                                />
                                                                                <FormFeedback>
                                                                                    Horário inválido.
                                                                                </FormFeedback>
                                                                                <FormFeedback valid>
                                                                                    Horário válido.
                                                                                </FormFeedback>

                                                                            </FormGroup>
                                                                        </Col>

                                                                    </Row>

                                                                </Col>

                                                            </Row>
                                                        </div>

                                                    </div>
                                                ) : null}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form>
                        </Container>
                    </>
                }
            </>
        );
    }
}

export default WorkshopForm;
