import React from "react";
import { Redirect, Route } from "react-router-dom";
import { addAccount } from '../store/accounts/accounts.js';
import { ApiError, CurrentUser, GetHeader } from "views/backoffice/utils/Utilities";
import axios from "axios";


const ProtectedRoute = ({ path, render, role }) => {
    const currentUser = CurrentUser();


    const getAccount = () => {
        if (currentUser === null && localStorage.getItem('refreshToken') !== null && localStorage.getItem('refreshToken') !== undefined && localStorage.getItem('refreshToken') !== '') {
            var headers = GetHeader();
            axios.get(global.api_url + `Accounts/${localStorage.getItem('refreshToken')}`, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        global.dispatch(addAccount(response.data));
                    }
                }).catch(error => {
                    ApiError(error, "").then(call => call === true ? getAccount() : "");
                })
        }

    }
    getAccount();

    const isAuthenticated = currentUser !== null || localStorage.getItem('refreshToken')?.length > 70 ? true : false;
    localStorage.setItem('path', window.location.pathname);



    return (
        isAuthenticated === true ?
            role.includes(currentUser?.role) ?
                <Route render={render} path={path} /> : <Redirect to={localStorage.getItem('path')} /> : <Redirect to="/login" />

    );
}

export default ProtectedRoute;