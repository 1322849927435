import React from 'react';
import { FormGroup, Form, Input, FormFeedback, Row, Col, Container, Button, CardHeader, CardBody, Card } from "reactstrap";
import { Loading, notification, ApiError, GetHeader, GetHeaderPut } from "../utils/Utilities";
import axios from 'axios';
import DatePicker from 'react-date-picker'
import "react-datepicker/dist/react-datepicker.css";
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder';
import BootstrapConfig from "react-awesome-query-builder/lib/config/bootstrap";
import Moment from 'moment';
Moment.locale('pt');



const InitialConfig = BootstrapConfig; // or MaterialConfig or BootstrapConfig or BasicConfig

// You need to provide your own config. See below 'Config format'
//const config = {
//    ...InitialConfig,
//    fields: {
//        //aa: {
//        //    label: 'a',
//        //    type: 'number',
//        //    fieldSettings: { min: 0, max: 999999 },
//        //    valueSources: ['value'],
//        //    preferWidgets: ['number'],
//        //},
//        Product_ServiceType: {
//            label: 'ServiceType',
//            type: 'select',
//            valueSources: ['value'],
//            fieldSettings: {
//                listValues: [
//                    { value: 'Pack', title: 'Pack' },
//                    { value: 'Service', title: 'Service' },
//                ],
//            }
//        },
//        Vehicule_KMS: {
//            label: 'KMS',
//            type: 'number',
//            valueSources: ['value'],
//            fieldSettings: {
//                min: 0,
//                max: 999999,
//            },
//            preferWidgets: ['slider', 'rangeslider'],
//        },
//        Account_DefaultWorkshop: {
//            label: 'DefaultWorkshop',
//            type: 'select',
//            valueSources: ['value'],
//            fieldSettings: {
//                listValues: [
//                    { value: '1', title: 'Loja 1' },
//                    { value: '2', title: 'Loja 2' },
//                    { value: '3', title: 'Loja 3' }
//                ],
//            }
//        },
//        Products_pricePromo: {
//            label: 'PricePromo',
//            type: 'boolean',
//            operators: ['equal'],
//            valueSources: ['value'],
//        },
//    }
//};

const queryValue = { "id": QbUtils.uuid(), "type": "group" };


class CuponTypeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cuponType: {
                id: props.cuponType.id !== 0 ? props.cuponType.id : 0,
                name: props.cuponType.id !== 0 ? props.cuponType.name : '',
                description: props.cuponType.id !== 0 ? props.cuponType.description : '',
                value: props.cuponType.id !== 0 ? props.cuponType.value : '',
                condition: props.cuponType.id !== 0 ? props.cuponType.condition : '',
                start: props.cuponType.id !== 0 ? props.cuponType.start : '',
                end: props.cuponType.id !== 0 ? props.cuponType.end : '',
                created: props.cuponType.id !== 0 ? props.cuponType.created : '',
                updated: props.cuponType.id !== 0 ? props.cuponType.updated : '',
                deleted: props.cuponType.id !== 0 ? props.cuponType.deleted : '',
            },
            edit: props.edit,
            loadingData: false,
            currentUser: props.currentUser,
            validate: { nameState: '', descriptionState: '', valueState: '', conditionState: '', startState: '', endState: '' },
            config: {
                ...InitialConfig,
                fields: {
                    Product_ServiceType: {
                        label: 'ServiceType',
                        type: 'select',
                        valueSources: ['value'],
                        fieldSettings: {
                            listValues: [
                                { value: 'Pack', title: 'Pack' },
                                { value: 'Service', title: 'Service' },
                            ],
                        }
                    },
                    Vehicule_KMS: {
                        label: 'KMS',
                        type: 'number',
                        valueSources: ['value'],
                        fieldSettings: {
                            min: 0,
                            max: 999999,
                        },
                        preferWidgets: ['slider', 'rangeslider'],
                    },
                    Account_DefaultWorkshop: {
                        label: 'DefaultWorkshop',
                        type: 'select',
                        valueSources: ['value'],
                        fieldSettings: {
                            listValues: [],
                        }
                    },
                },
                tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), InitialConfig)
            }
        }
    }


    componentDidMount() {
        this.getWorkShops();
        this.getServicesType();


    }

    componentDidUpdate() {
        console.log(this.state.config)
    }

    getServicesType = () => {
        axios.get(global.api_url + 'ServiceTypes').then((response) => {
            if (response.status === 200) {
                var st = response.data;
                st.forEach(w => {
                    this.setState(state => ({
                        config: {
                            ...state.config,
                            fields: {
                                ...state.config.fields,
                                Product_ServiceType: {
                                    ...state.config.fields.Product_ServiceType,
                                    fieldSettings: {
                                        ...state.config.fields.Product_ServiceType.fieldSettings,
                                        listValues: [
                                            ...state.config.fields.Product_ServiceType.fieldSettings.listValues,
                                            { value: w?.id, title: w?.name }
                                        ]
                                    }
                                }
                            }
                        }
                    }));
                });
            }
           
        }).catch(error => { console.log(error.response); })
    }

    getWorkShops = () => {
        axios.get(global.api_url + "Workshops")
            .then((response) => {
                if (response.status === 200) {
                    var ws = response.data;
                    ws.forEach(w => {
                        this.setState(state => ({
                            config: {
                                ...state.config,
                                fields: {
                                    ...state.config.fields,
                                    Account_DefaultWorkshop: {
                                        ...state.config.fields.Account_DefaultWorkshop,
                                        fieldSettings: {
                                            ...state.config.fields.Account_DefaultWorkshop.fieldSettings,
                                            listValues: [
                                                ...state.config.fields.Account_DefaultWorkshop.fieldSettings.listValues,
                                                { value: w?.id, title: w?.name }
                                            ]
                                        }
                                    }
                                }
                            }
                        }));
                    });
                }
            }).catch(error => { console.log(error.response); });
    }



    static getDerivedStateFromProps(props, state) {
        if (props.currentUser !== state.currentUser) {
            return { currentUser: props.currentUser };
        }
        return null;
    }




    handleValue = async (name, value) => { await this.setState({ [name]: value }); return value; }

    handleCuponType = async (name, value) => { await this.setState(state => ({ cuponType: { ...state.cuponType, [name]: value } })) }


    /*VALIDATIONS*/

    validateString(e) {
        const { validate } = this.state;

        if (e.target.value !== null && e.target.value !== "") {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }

        this.setState(({ validate }));
    }

    regexValidate(e, regex) {
        const { validate } = this.state;
        if (regex.test(e.target.value)) {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }

    validateSelect(resource, prop) {
        const { validate, event } = this.state;
        if (this.state.event.resources[resource][prop] !== "" && event.resources[resource][prop] !== null && event.resources[resource][prop] !== 0) {
            validate[prop + 'State'] = 'has-success';
        } else { validate[prop + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }

    validateDatetime(name, dateTime) {
        const { validate } = this.state;

        if (Moment(dateTime).format('L') === Moment(new Date()).format('L') || Moment(dateTime).isAfter(Moment(new Date()))) {
            validate[name + 'State'] = 'has-success';
        } else { validate[name + 'State'] = 'has-danger'; notification("Reggra de cupão", "A data tem de ser superior ao dia atual.", "warning", null, "top-right", null, false); }

        this.setState({ validate });
    }



    /*SUBMISSIONS*/

    validForm = () => {
        var { cuponType, validate } = this.state;
        var valid = true;
        Object.entries(cuponType).map(data => {
            if ((data[0] === 'name' || data[0] === 'description' || data[0] === 'start') && data[1] === '') {
                validate[data[0] + 'State'] = 'has-danger';
                this.setState({ validate });
                valid = false;
            }
        });
        return valid;
    }


    submitForm(e) {
        e.preventDefault();
        var { cuponType } = this.state;
        if (this.validForm()) {
            cuponType.id === 0 ? this.create(e) : this.update(e);
        } else { notification("Agenda", "Campos em Falta ou mal preenchidos.", "warning", null, "top-right", null, false); }
    }

    cancel(e) {
        e.preventDefault();
        this.state.edit === false && this.state.cuponType.id !== 0 ? this.props.onChange(this.constructor.name) : this.handleValue('edit', false);
        if (this.state.edit === true && this.state.cuponType.id === 0) { this.props.onChange(this.constructor.name) };
    }

    create(e) {
        e.preventDefault();
        var { cuponType } = this.state;
        this.handleValue('loadingData', true);
        //var headers = { 'Accept': '*/*', 'Content-Type': 'application/json', 'Authorization': `Bearer ${currentUser.jwtToken}` };
        var headers = GetHeader();//{ 'Authorization': `Bearer ${currentUser.jwtToken}` };
        axios.post(global.api_url + "CuponType", cuponType, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Regra de cupão", "Foi adicionado uma regra de cupões.", "success", null, "top-right", null, false);
                    this.handleValue('cuponType', response.data);
                    this.handleValue('edit', false);
                }
                this.handleValue('validate', {});
            }).catch(error => {
                //notification("Regra de cupão", "Erro:" + error.message, "warning", null, "top-right", null, false);
                ApiError(error, "Cupões").then(call => call === true ? this.create(e) : "");
            }).then(() => { this.handleValue('loadingData', false); })
    }

    update(e) {
        e.preventDefault();
        this.handleValue('loadingData', true);
        var { cuponType } = this.state;
        //var headers = { 'Accept': '*/*', 'Content-Type': 'application/json', 'Authorization': `Bearer ${currentUser.jwtToken}` };
        var headers = GetHeaderPut();
        axios.put(global.api_url + "CuponType", cuponType, { headers })
            .then((response) => {
                if (response.status === 200) { notification("Regra de cupão", "Regra atualizada com sucesso.", "success", null, "top-right", null, false); this.handleValue('edit', false); }
                this.handleValue('validate', {});
            }).catch(error => {
                //notification("Regra de cupão", "Erro:" + error.message, "warning", null, "top-right", null, false);
                ApiError(error, "Cupões").then(call => call === true ? this.update(e) : "");
            }).then(() => { this.handleValue('loadingData', false); })

    }



    edit(e) {
        e.preventDefault();
        this.handleValue('edit', true);
    }

    /*GET VALUES */

    onChange = (immutableTree, config) => {
        this.setState({ tree: immutableTree, config: config });
        //const jsonTree = QbUtils.getTree(immutableTree);
        this.setState(state => ({ cuponType: { ...state.cuponType, condition: JSON.stringify(QbUtils.sqlFormat(immutableTree, config)) } }))
    }

    renderBuilder = (props) => (
        <div className="query-builder-container" style={{ padding: '10px' }}>
            <div className="query-builder qb-lite">
                <Builder {...props} />
            </div>
        </div>
    )

    renderResult = ({ tree: immutableTree, config }) => (
        <div className="query-builder-result">
            <div>Query string: <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre></div>
            <div>SQL where: <pre>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre></div>
            <div>JsonLogic: <pre>{JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}</pre></div>
        </div>
    )

    render() {
        const { edit, loadingData, currentUser, validate, cuponType } = this.state;

        return (
            <>
                {currentUser !== null &&
                    <Container className="mt--7" fluid>

                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <Form className="form" onSubmit={(e) => this.submitForm(e)}>
                                        <CardHeader className="bg-white border-0">
                                            <Row className="align-items-center">

                                                <Col xl="8">

                                                    <h3 className="mb-0">Adicionar Regra de cupão</h3>

                                                </Col>
                                                <Col className="text-right" xl="4">

                                                    <Button color="danger" size="sm" type="button" onClick={e => this.cancel(e)}><i className="far fa-window-close"></i> Cancelar</Button>

                                                    {edit === false && < Button color="warning" size="sm" type="button" onClick={e => this.edit(e)}><i className="far fa-edit"></i> Editar</Button>}

                                                    {edit === true && <Button color="success" size="sm" type="button" onClick={e => this.submitForm(e)}><i className="far fa-save"></i> Guardar</Button>}

                                                </Col>

                                            </Row>
                                        </CardHeader>
                                        <CardBody>

                                            {Loading(loadingData)}
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-name" >
                                                                Nome
                                                            </label>
                                                            <Input type="text" name="name" id="name"
                                                                valid={validate.nameState === "has-success"}
                                                                invalid={validate.nameState === "has-danger"}
                                                                value={cuponType.name}
                                                                onChange={(e) => { this.validateString(e); this.handleCuponType('name', e.target.value) }}
                                                                disabled={!edit}
                                                                required
                                                            />
                                                            <FormFeedback>
                                                                Nome inválido.
                                                            </FormFeedback>
                                                            <FormFeedback valid>
                                                                Nome válido.
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-description" >
                                                                Descrição
                                                            </label>
                                                            <Input type="text" name="description" id="description"
                                                                valid={validate.descriptionState === "has-success"}
                                                                invalid={validate.descriptionState === "has-danger"}
                                                                value={cuponType.description}
                                                                onChange={(e) => { this.validateString(e); this.handleCuponType('description', e.target.value) }}
                                                                disabled={!edit}
                                                            />
                                                            <FormFeedback>
                                                                Descrição inválida.
                                                            </FormFeedback>
                                                            <FormFeedback valid>
                                                                Descrição válida.
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="2">
                                                        <FormGroup>

                                                            <label className="form-control-label" htmlFor="input-start" >
                                                                Início
                                                            </label>

                                                            <Input
                                                                id="start"
                                                                name="start"
                                                                type="date"
                                                                valid={validate.startState === "has-success"}
                                                                invalid={validate.startState === "has-danger"}
                                                                onChange={(value) => {
                                                                    this.validateDatetime('start', value); this.handleCuponType('start', value)
                                                                }}
                                                                value={Moment(cuponType.start).utc().format("YYYY-MM-DD")}
                                                                required={true}
                                                                disabled={!edit}
                                                            />



                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="2">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-end" id="lbl_end" >
                                                                Fim
                                                            </label>

                                                            <Input
                                                                id="end"
                                                                name="end"
                                                                type="date"
                                                                valid={validate.endState === "has-success"}
                                                                invalid={validate.endState === "has-danger"}
                                                                onChange={(value) => {
                                                                    this.validateDatetime('end', value); this.handleCuponType('end', value)
                                                                }}
                                                                value={Moment(cuponType.end).utc().format("YYYY-MM-DD")}
                                                                required={true}
                                                                disabled={!edit}
                                                            />

                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-value" >
                                                                Valor
                                                            </label>
                                                            <Input type="text" name="value" id="value"
                                                                valid={validate.valueState === "has-success"}
                                                                invalid={validate.valueState === "has-danger"}
                                                                value={cuponType.value}
                                                                onChange={(e) => { this.validateString(e); this.handleCuponType('value', e.target.value) }}
                                                                disabled={!edit}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col lg="8">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-condition" >
                                                                Condições
                                                            </label>
                                                            <Input type="text" name="condition" id="condition"
                                                                valid={validate.conditionState === "has-success"}
                                                                invalid={validate.conditionState === "has-danger"}
                                                                value={cuponType.condition}
                                                                onChange={(e) => { this.validateString(e); this.handleCuponType('condition', e.target.value) }}
                                                                disabled={!edit}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>


                                                <Row style={{ paddingTop: '20px' }}>
                                                    <Col lg="6" >
                                                    </Col>
                                                    <Col lg="6" className="text-right" >

                                                    </Col>
                                                </Row>
                                            </div>


                                        </CardBody>
                                    </Form>
                                    <Col lg="12">

                                        {edit && <Query  {...this.state.config} value={this.state.tree} onChange={this.onChange} renderBuilder={this.renderBuilder} />}
                                        {/*{this.renderResult(this.state)}*/}

                                    </Col>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                }
            </>
        );
    }
}

export default CuponTypeForm;

