import React, { useEffect, useState } from 'react';
import { Badge, Media, UncontrolledTooltip, Button } from "reactstrap";
import { ApiError, GetHeader, notification } from '../utils/Utilities';
import axios from 'axios';
import { Loading } from "../utils/Utilities";

const WorkshopsList = ({ props, workshop, currentPage, onChange }) => {
    const [loadingStatus, setLoadingStatus] = useState(false);


    const click_OpenWorshopForm = (e, workshopData) => {
        e.preventDefault();
        props.history.push("/admin/Workshop", { workshop: workshopData, currentPage: currentPage });
    }

    const UpdateStatus = (newStatus, newAvailable) => {
        setLoadingStatus(true)
        var headers = GetHeader();
        axios.post(global.api_url + "Workshops/UpdateStatus", { id: workshop.id, status: newStatus, available: newAvailable }, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Atualização de Oficina", "Oficina atualizada com sucesso!", "success", null, "top-right", null, false);
                    onChange(workshop, newStatus, newAvailable);
                }
                setLoadingStatus(false)
            }).catch(error => {
                ApiError(error, "Atualiazação da oficina").then(call => call === true ? UpdateStatus(newStatus, newAvailable) : "");
                setLoadingStatus(false)
            })
    }

    useEffect(() => { }, [workshop]);

    return (
        <tr onClick={(e) => { }}>
            <th scope="row">
                <Media className="align-items-center">
                    <a className="avatar rounded-circle mr-3" onClick={(e) => { e.preventDefault(); click_OpenWorshopForm(e, workshop) }} >
                        <i className="fas fa-tools rs-text-red"></i>
                    </a>
                    <Media>
                        <span className="mb-0 text-sm">
                            {workshop?.name}
                        </span>
                    </Media>
                </Media>
            </th>
            <td>
                {workshop?.adress + " " + workshop?.postalCode + " " + workshop?.city}
            </td>
            <td>
                {workshop?.email}
            </td>
            <td>
                {workshop?.phone1}
            </td>
            <td style={{ paddingLeft: 30 }}>
                <Badge color="" className="badge-dot mr-4">

                    {loadingStatus === false ? <>
                        {workshop?.active ?
                            (<>
                                <i className="bg-success" style={{ 'zoom': 3 }} id="i_success" />
                                <UncontrolledTooltip delay={0} placement="top" target="i_success">
                                    Oficina encontra se ativa.
                                </UncontrolledTooltip>
                            </>) :
                            (<>
                                <i className="bg-warning" style={{ 'zoom': 3 }} id="i_warning" />
                                <UncontrolledTooltip delay={0} placement="top" target="i_warning">
                                    Oficina não se encontra ativa.
                                </UncontrolledTooltip>
                            </>)
                        }
                    </> :
                        Loading(true)
                    }
                </Badge>
            </td>
            <td style={{ paddingLeft: 60 }}>

                {loadingStatus === false ? <>
                    {workshop?.available ?
                        (<>
                            <i className="far fa-calendar-check rs-text-green" style={{ 'zoom': 1.5 }} id="a_success"></i>
                            <UncontrolledTooltip delay={0} placement="top" target="a_success">
                                Oficina disponível para receber agendamentos.
                            </UncontrolledTooltip>
                        </>) :
                        (<>
                            <i className="far fa-calendar-times rs-text-red" style={{ 'zoom': 1.5 }} id="a_warning" />
                            <UncontrolledTooltip delay={0} placement="top" target="a_warning">
                                Oficina não pode receber agendamentos.
                            </UncontrolledTooltip>
                        </>)
                    }
                </> :
                    Loading(true)
                }

            </td>

            <td className="text-right">

                <Button id="btn_show_WS" color="success" name="show" type="button" size="sm" onClick={(e) => { e.preventDefault(); click_OpenWorshopForm(e, workshop) }}><i className="far fa-eye"></i></Button>
                <UncontrolledTooltip delay={0} placement="top" target="btn_show_WS">
                    Visualizar Oficina
                </UncontrolledTooltip>



                {workshop.active === true ?
                    <>
                        <Button id="btnSWS" color="danger" type="button" size="sm" onClick={(e) => { e.preventDefault(); UpdateStatus(false, false); }}><i className="fas fa-toggle-off"></i>
                            <UncontrolledTooltip delay={0} placement="top" target="btnSWS">
                                Desativar oficina
                            </UncontrolledTooltip>
                        </Button>
                    </>
                    :
                    <>
                        <Button id="btnAWS1" color="success" type="button" size="sm" onClick={(e) => { e.preventDefault(); UpdateStatus(true, false); }}><i className="fas fa-toggle-on"></i></Button>
                        <UncontrolledTooltip delay={0} placement="top" target="btnAWS1">
                            Ativar Oficina
                        </UncontrolledTooltip>

                    </>
                }
                {workshop.available === true ?
                    <>
                        <Button id="btn_S_WS_A" color="danger" type="button" size="sm" onClick={(e) => { e.preventDefault(); UpdateStatus(workshop.active, false); }}><i className="fas fa-calendar-times"></i>
                            <UncontrolledTooltip delay={0} placement="top" target="btn_S_WS_A">
                                Desativar agendamento
                            </UncontrolledTooltip>
                        </Button>
                    </>
                    :
                    <>
                        <Button id="btn_A_WS_A" color="success" type="button" size="sm" onClick={(e) => { e.preventDefault(); UpdateStatus(workshop.active, true); }}><i className="fas fa-calendar-check"></i> </Button>
                        <UncontrolledTooltip delay={0} placement="top" target="btn_A_WS_A">
                            Ativar agendamento
                        </UncontrolledTooltip>


                    </>
                }

            </td>
        </tr>
    );
}

export default WorkshopsList;