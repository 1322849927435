import React from 'react';
import { Button, FormGroup, Form, Input, FormFeedback, Row, Col, Container, CardHeader, Card, CardBody, UncontrolledTooltip } from "reactstrap";
import { Loading, Role, notification, GetHeader, ApiError, DeviceType, Device } from "../utils/Utilities";
import axios from 'axios';
import Vehicules from '../Vehicles/Vehicules';
import VehiculeForm from '../Vehicles/VehiculeForm';
import { confirmAlert } from 'react-confirm-alert';
import Moment from 'moment-timezone';
import 'moment/locale/pt'
Moment.locale('pt');


class ProfileForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            create: props?.props?.location?.state?.create !== undefined ? props?.props?.location?.state?.create : false,
            currentPage: props?.props?.location?.state?.currentPage,
            user: {
                id: props.formUser.id !== 0 ? props.formUser.id : 0,
                adress: props.formUser.id !== 0 ? props.formUser.adress : '',
                city: props.formUser.id !== 0 ? props.formUser.city : '',
                country: props.formUser.id !== 0 ? props.formUser.country : '',
                email: props.formUser.id !== 0 ? props.formUser.email : '',
                firstName: props.formUser.id !== 0 ? props.formUser.firstName : '',
                born: props.formUser.id !== 0 ? props.formUser.born : '',
                isVerified: props.formUser.id !== 0 ? props.formUser.isVerified : '',
                lastName: props.formUser.id !== 0 ? props.formUser.lastName : '',
                nif: props.formUser.id !== 0 ? props.formUser.nif : '',
                phone: props.formUser.id !== 0 ? props.formUser.phone : '',
                postalCode: props.formUser.id !== 0 ? props.formUser.postalCode : '',
                defaultWorkshop: props.formUser.id !== 0 ? props.formUser.defaultWorkshop : '',
                role: props.formUser.id !== 0 ? props.formUser.role : Role.User,
                erpSilenceLogin: props.formUser.id !== 0 ? props.formUser.erpSilenceLogin : '',
            },
            redirect: props.redirect !== undefined ? props.redirect : false,
            old_user: props.formUser.id !== 0 ? props.formUser : {},
            validate: {
                firstNameState: '',
                lastNameState: '',
                emailState: '',
                phoneState: '',
                nifState: '',
                adressState: '',
                bornState: '',
                cityState: '',
                countryState: '',
                postalCodeState: '',
                roleState: Role.User,
                defaultWorkshopState: ''
            },
            formVehicule: { id: 0 },
            currentUser: props.currentUser,
            workshops: [],
            edit: props.formUser.id !== 0 ? false : true,
            loadingData: false,
            showVehiculeForm: false,
            passwordConfirm: '',

        };

        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {
        this.getAllWorkshops();
        if (this.state.create === true) {
            this.setState(state => ({ user: { ...state.user, defaultWorkshop: this.state.currentUser?.defaultWorkshop, role: Role.Mecanico } }));
        }
    }

    handleValue = (name, value) => {
        this.setState({ [name]: value });
    }

    handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        this.setState({ [name]: value });
    };

    handleChangeUser = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        this.setState(state => ({ user: { ...state.user, [name]: value } }));
    };
    /*VALIDATIONS*/

    validateString(e) {
        const { validate } = this.state;

        if (e.target.value !== null && e.target.value !== "") {
            validate[e.target.name + 'State'] = 'has-success';
        } else {
            validate[e.target.name + 'State'] = 'has-danger';
        }

        this.setState(({ validate }));
    }

    validateEmail(e) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state;

        if (regex.test(e.target.value)) {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }

    validateNif(e) {
        const regex = /^[A-Za-z0-9]*\d+[A-Za-z0-9]*$/;
        const { validate } = this.state;

        if (regex.test(e.target.value)) {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }

    validatePhone(e) {
        const regex = /^[0-9]{9}$/;
        const { validate } = this.state;

        if (regex.test(e.target.value)) {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }
    validatePostalCode(e) {
        const regex = /^\d{4}(-\d{3})?$/;
        const { validate } = this.state;

        if (regex.test(e.target.value)) {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }

    validForm() {
        var { user, validate } = this.state;
        var valid = true;
        Object.entries(user).forEach(data => {
            if (data[1] === '' && (data[0] === 'email' || data[0] === 'firstName' || data[0] === 'lastName' || data[0] === 'role' || data[0] === 'defaultWorkshop')) {
                validate[data[0] + 'State'] = 'has-danger';
                this.setState({ validate });
                valid = false;
            }
        })
        Object.entries(validate).forEach(data => { if (data[1] === 'has-danger') { valid = false; } });
        return valid;
    }

    /*SUBMISSIONS*/
    submitForm(e) {
        e.preventDefault();
        var { user } = this.state;
        user.id === 0 ? this.createUser() : this.updateUser();
    }

    updateUser() {
        this.handleValue('loadingData', true);
        var { user } = this.state;
        var headers = GetHeader();
        axios.post(global.api_url + "Accounts/user", user, { headers })
            .then((response) => {
                user = response.data
                notification("Atualização de Utilizador", "Atualizado com sucesso!", "success", null, "top-right", null, false);
                this.handleValue('edit', false);
                this.handleValue('loadingData', false);
                this.handleValue('validate', {});
                this.props.onChange(user);
            }).catch(error => {
                ApiError(error, "utilizador").then(call => call === true ? this.updateUser() : "");
            }).then(() => {
                this.handleValue('loadingData', false);
            })
        this.handleValue('edit', false);
    }

    createUser(e) {
        e.preventDefault();
        if (this.validForm()) {
            this.handleValue('loadingData', true);
            var { user } = this.state;
            var headers = GetHeader();
            axios.post(global.api_url + "Accounts/Admin-register", user, { headers })
                .then((response) => {
                    notification("Criação de Utilizador", response.data.message, "success", null, "top-right", null, false);
                    this.handleValue('edit', false);
                    this.handleValue('loadingData', false);
                    this.handleValue('validate', {});
                }).catch(error => {
                    ApiError(error, "utilizador").then(call => call === true ? this.createUser(e) : "");
                }).then(() => {
                    this.handleValue('loadingData', false);
                })
        } else {
            notification("Criação de Utilizador", "Campos em Falta ou mal preenchidos.", "danger", null, "top-right", null, false);
        }

    }

    resetPassword(e, email) {
        this.handleValue('loadingData', true);
        var headers = GetHeader();
        axios.post(global.api_url + "Accounts/forgot-password", { email: email }, { headers })
            .then((response) => {
                notification("Recuperação de password", "Foi enviado um email", "success", null, "top-right", null, false);
                this.handleValue('loadingData', false);
            }).catch(error => {
                ApiError(error, "Recuperação de password").then(call => call === true ? this.resetPassword(e, email) : "");
            }).then(() => {
                this.handleValue('loadingData', false);
            })
    }

    onchange(e) { this.setState(state => ({ ...state, passwordConfirm: e.target.value })); }

    showDeleteAccount() {
        const { firstName, lastName, email, role } = this.state.user;

        //alert("Em implementação");
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div style={{ borderStyle: "solid", padding: 40, borderRadius: 8, borderWidth: 2 }} className='custom-ui'>
                        <h1 style={{ color: '#FF0000' }}><i className="fas fa-2x fa-exclamation-triangle"></i>  Eliminar conta!</h1>
                        <div>Nome:<p style={{ fontSize: '15px', fontWeight: "bold" }}>{firstName + " " + lastName}</p></div>
                        <div>Email:<p style={{ fontSize: '15px', fontWeight: "bold" }}>{email}</p></div>
                        <div>Role:<p style={{ fontSize: '15px', fontWeight: "bold" }}>{role}</p></div>
                        <div><p style={{ fontSize: '20px', fontWeight: "bold" }}>Todos os dados serão eliminados.</p></div>
                        <div><p style={{ fontSize: '15px', fontWeight: "bold" }}>Deseja eliminar a conta?</p></div>
                        <center>
                            <Button color="success" size="sm" type="button" onClick={(e) => {
                                e.preventDefault();
                                let pw = prompt("Introduzir password:", "");
                                if (pw !== null && pw !== "") {
                                    this.deleteAccount(email, pw);
                                    onClose();
                                } else { notification("Eliminar conta", "É necessária a password para eliminar a conta.", "danger", null, "top-right", null, false); }
                            }}><i className="fas fa-trash-alt"></i> Sim</Button>
                            <Button color="danger" size="sm" onClick={onClose}><i className="fas fa-times"></i> Cancelar</Button>
                        </center>
                    </div>
                );
            }
        });
    }

    deleteAccount(email, password) {
        this.handleValue('loadingData', true);
        var headers = GetHeader();
        axios.post(global.api_url + "Accounts/Remove-admin", { email: email, password: password }, { headers })
            .then((response) => {
                notification("Conta", "A sua conta foi eliminada com sucesso.", "danger", null, "top-right", null, false);
                if (this.state.currentUser?.email === email) {
                    localStorage.setItem('refreshToken', '');
                    localStorage.setItem('jwtToken', '');
                    notification("Logout", "Efetuado com sucesso!", "success", null, "top-right", null, false);
                }
            }).catch(error => {
                ApiError(error, "Utilizador").then(call => call === true ? this.deleteAccount(password) : "");
            }).then(() => {
                this.handleValue('loadingData', false);
            })
    }


    sendVerifyEmail(e, email) {
        e.preventDefault();
        var headers = GetHeader();
        this.handleValue('loadingData', true);
        //axios.get(global.api_url + "Accounts/sendAlreadyRegisteredEmail/" + email, { headers })
        axios.get(global.api_url + "Accounts/sendVerificationEmail/" + email, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Ativação", "Foi enviado um email de verificação da conta.", "success", null, "top-right", null, false);
                }
                this.handleValue('loadingData', false);
            }).catch(error => {
                this.handleValue('loadingData', false);
                ApiError(error, "utilizador").then(call => call === true ? this.sendVerifyEmail(e, email) : "");
            })
    }

    editUser(e) {
        e.preventDefault();
        this.handleValue('edit', true);
    }

    openFormVehicule(e) {
        e.preventDefault();
        this.handleValue('showVehiculeForm', true);
    }

    editFromVehicule(vehicule) {

        this.setState(state => ({ formVehicule: vehicule }));
        this.handleValue('showVehiculeForm', true);
    }

    getAllWorkshops() {
        this.handleValue('loadingData', true);
        var headers = GetHeader();
        axios.get(global.api_url + "Workshops?Pagination=" + false, { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.handleValue('workshops', response.data);
                }
                this.handleValue('loadingData', false);
            }).catch(error => {
                this.handleValue('loadingData', false);
                ApiError(error, "utilizador").then(call => call === true ? this.getAllWorkshops() : "");
            })
    }

    render() {
        const { edit, workshops, loadingData, currentUser, user, showVehiculeForm, old_user, redirect, currentPage } = this.state;
        const { id, role, defaultWorkshop, firstName, lastName, email, phone, nif, adress, city, country, postalCode, born, isVerified, erpSilenceLogin } = this.state.user;
        const { roleState, defaultWorkshopState, firstNameState, lastNameState, emailState, phoneState, nifState, adressState, cityState, countryState, postalCodeState, bornState } = this.state.validate;

        return (
            <>
                {showVehiculeForm ?
                    (<VehiculeForm
                        formVehicule={this.state.formVehicule}
                        user={this.state.user}
                        currentUser={this.state.currentUser}
                        onChange={(value) => { this.handleValue('showVehiculeForm', value) }}
                        onEdit={(value) => { }} />) :
                    (<>
                        {currentUser !== null &&

                            <Container className="mt--7" fluid>
                                {Loading(loadingData)}
                                <Form className="form" onSubmit={(e) => this.submitForm(e)}>
                                    <Row>
                                        <Col className="order-xl-1" xs="12">
                                            <Card className="bg-secondary shadow">
                                                <CardHeader className="bg-white border-0">
                                                    <Row className="align-items-center">
                                                        <Col xs="4">
                                                            {this.state.currentUser ? (
                                                                <h3 className="mb-0">Conta</h3>
                                                            ) : (<h3 className="mb-0">A minha Conta</h3>)}
                                                        </Col>
                                                        <Col className="text-right" xs="8">
                                                            {id === 0 ? (
                                                                <>
                                                                    {(this.state.currentUser.role === Role.Admin || this.state.currentUser.role === Role.GestorOficina) &&
                                                                        <>
                                                                            <Button id="btn_return_users" color="success" size="sm" type="button" onClick={e => {
                                                                                e.preventDefault();
                                                                                this.props.props.history.push("/admin/Users", { currentPage: currentPage })
                                                                            }} >
                                                                                <i className="fas fa-chevron-circle-left"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Utilizadores" : ""}
                                                                            </Button>
                                                                            <UncontrolledTooltip delay={0} placement="top" target="btn_return_users">
                                                                                Voltar a lista de utilizadores
                                                                            </UncontrolledTooltip>
                                                                        </>
                                                                    }
                                                                    <Button id="btn_add_user" color="warning" size="sm" type="button" onClick={e => this.createUser(e)}>
                                                                        <i className="fas fa-save"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Criar Utilizador" : ""}
                                                                    </Button>
                                                                    <UncontrolledTooltip delay={0} placement="top" target="btn_add_user">
                                                                        Criar utlizador
                                                                    </UncontrolledTooltip>
                                                                </>
                                                            )
                                                                : (!edit ? (
                                                                    <>
                                                                        {(this.state.currentUser.role === Role.Admin || this.state.currentUser.role === Role.GestorOficina) &&
                                                                            <>
                                                                                <Button id="btn_return_users1" color="success" size="sm" type="button" onClick={e => {
                                                                                    e.preventDefault();
                                                                                    this.props.props.history.push("/admin/Users", { currentPage: currentPage })
                                                                                }} >
                                                                                    <i className="fas fa-chevron-circle-left"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Utilizadores" : ""}
                                                                                </Button>
                                                                                <UncontrolledTooltip delay={0} placement="top" target="btn_return_users1">
                                                                                    Voltar a lista de utilizadores
                                                                                </UncontrolledTooltip>
                                                                            </>}

                                                                        {isVerified === false &&
                                                                            <>
                                                                                <Button id="btn_email_verification" color="info" size="sm" type="button" onClick={e => { this.sendVerifyEmail(e, email); }} >
                                                                                    <i className="fas fa-paper-plane"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Email de verificação" : ""}
                                                                                </Button>
                                                                                <UncontrolledTooltip delay={0} placement="top" target="btn_email_verification">
                                                                                    Enviar email de verificação da conta
                                                                                </UncontrolledTooltip>
                                                                            </>
                                                                        }

                                                                        {(this.state.currentUser?.role === Role.Admin || this.state.currentUser?.role === Role.GestorOficina) &&
                                                                            <>
                                                                                <Button id="btn_delete_account" color="danger" size="sm" type="button" onClick={e => { this.showDeleteAccount(); }} >
                                                                                    <i className="fas fa-user-times"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Eliminar conta" : ""}
                                                                                </Button>
                                                                                <UncontrolledTooltip delay={0} placement="top" target="btn_delete_account">
                                                                                    Elminar conta da plataforma RedService
                                                                                </UncontrolledTooltip>
                                                                            </>
                                                                        }

                                                                        <Button id="btn_reset_account" color="info" size="sm" type="button" onClick={e => { this.resetPassword(e, email); }} >
                                                                            <i className="fas fa-mail-bulk"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Reset Password" : ""}
                                                                        </Button>
                                                                        <UncontrolledTooltip delay={0} placement="top" target="btn_reset_account">
                                                                            Enviar email para efetuar recupeeção da password
                                                                        </UncontrolledTooltip>

                                                                        <Button id="btn_edit_account" color="warning" size="sm" name="update" type="button" onClick={e => { this.editUser(e); }} >
                                                                            <i className="far fa-edit"></i>{DeviceType(window.innerWidth) !== Device.Mobile ? "Editar" : ""}
                                                                        </Button>
                                                                        <UncontrolledTooltip delay={0} placement="top" target="btn_edit_account">
                                                                            Editar conta
                                                                        </UncontrolledTooltip>
                                                                    </>

                                                                )
                                                                    : (<>
                                                                        <Button id="btn_cancel_account" color="danger" name="update" type="button" size="sm" onClick={e => { this.setState({ user: old_user }); this.setState({ edit: false }); }}>
                                                                            <i className="fas fa-times"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Cancelar" : ""}
                                                                        </Button>
                                                                        <UncontrolledTooltip delay={0} placement="top" target="btn_cancel_account">
                                                                            Cancelar a edição da conta, os dados alterados serão perdidos
                                                                        </UncontrolledTooltip>
                                                                        <Button id="btn_save_account" color="success" name="update" type="button" size="sm" onClick={e => { this.updateUser(e); }} >
                                                                            <i className="far fa-save"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Guardar" : ""}
                                                                        </Button>
                                                                        <UncontrolledTooltip delay={0} placement="top" target="btn_save_account">
                                                                            Atualiza os dados da conta
                                                                        </UncontrolledTooltip>
                                                                    </>
                                                                    )
                                                                )
                                                            }
                                                            {redirect === true &&
                                                                <>
                                                                    <Button id="btn_save_account1" color="success" name="update" type="button" size="sm" onClick={e => { this.props.history.push("/admin/Users"); }} >
                                                                        <i className="far fa-save"></i> Atualizar
                                                                    </Button>
                                                                    <UncontrolledTooltip delay={0} placement="top" target="btn_save_account1">
                                                                        Atualiza os dados da conta
                                                                    </UncontrolledTooltip>
                                                                </>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <CardBody>
                                                    <h6 className="heading-small text-muted mb-4">
                                                        Informações do Utilizador
                                                    </h6>
                                                    <div className="pl-lg-4">
                                                        {currentUser.role === Role.Admin ? (
                                                            <div className="pl-lg-4">
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <FormGroup>
                                                                            <label className="form-control-label" htmlFor="input-role">
                                                                                Role
                                                                            </label >

                                                                            <Input type="select" name="role" id="input-role"
                                                                                valid={roleState === "has-success"}
                                                                                invalid={roleState === "has-danger"}
                                                                                value={role}
                                                                                onChange={(e) => { this.handleChangeUser(e); }}
                                                                                disabled={!edit}
                                                                            >
                                                                                {Object.keys(Role).map((k) => { return (<option key={k} value={Role[k]}>{Role[k]}</option>) })}
                                                                            </Input>

                                                                            <FormFeedback>
                                                                                Role inválido.
                                                                            </FormFeedback>
                                                                            <FormFeedback valid>
                                                                                Role válido.
                                                                            </FormFeedback>

                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <FormGroup>
                                                                            <label className="form-control-label" htmlFor="input-defaultWorkshop">
                                                                                Oficina
                                                                            </label >

                                                                            <Input type="select" name="defaultWorkshop" id="input-defaultWorkshop"
                                                                                valid={defaultWorkshopState === "has-success"}
                                                                                invalid={defaultWorkshopState === "has-danger"}
                                                                                value={defaultWorkshop !== undefined ? defaultWorkshop : null}
                                                                                //defaultValue={defaultWorkshop}
                                                                                onChange={(e) => { this.handleChangeUser(e); }}
                                                                                disabled={!edit}
                                                                            >
                                                                                <option key={0} value={null}>Sem Oficina definida</option>
                                                                                {workshops.length !== 0 && workshops.map(data => { return (<option key={data.id} value={data.id}>{data.name}</option>); })}

                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        ) : null}
                                                        {currentUser.role === Role.GestorOficina ? (
                                                            <div className="pl-lg-4">
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <FormGroup>
                                                                            <label className="form-control-label" htmlFor="input-role">
                                                                                Role
                                                                            </label >

                                                                            <Input type="select" name="role" id="input-role"
                                                                                valid={roleState === "has-success"}
                                                                                invalid={roleState === "has-danger"}
                                                                                value={role !== undefined ? role : null}
                                                                                onChange={(e) => { this.handleChangeUser(e); }}
                                                                                disabled={!edit}
                                                                            >
                                                                                {Object.keys(Role).filter(x => x !== Role.Admin).map((k) => { return (<option key={k} value={Role[k]}>{Role[k]}</option>) })}
                                                                            </Input>

                                                                            <FormFeedback>
                                                                                Role inválido.
                                                                            </FormFeedback>
                                                                            <FormFeedback valid>
                                                                                Role válido.
                                                                            </FormFeedback>

                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <FormGroup>
                                                                            <label className="form-control-label" htmlFor="input-defaultWorkshop">
                                                                                Oficina
                                                                            </label >

                                                                            <Input type="select" name="defaultWorkshop" id="input-defaultWorkshop"
                                                                                valid={defaultWorkshopState === "has-success"}
                                                                                invalid={defaultWorkshopState === "has-danger"}
                                                                                value={currentUser.defaultWorkshop}
                                                                                defaultValue={defaultWorkshop}
                                                                                onChange={(e) => { this.handleChangeUser(e); }}
                                                                                disabled={true}
                                                                            >
                                                                                <option key={0} value={null}>Sem Oficina definida</option>
                                                                                {workshops.length !== 0 && workshops.map(data => { return (<option key={data.id} value={data.id}>{data.name}</option>); })}
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        ) : null}

                                                        {currentUser.role !== Role.User && role !== Role.User &&
                                                            <div className="pl-lg-4">
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <FormGroup>
                                                                            <label className="form-control-label" htmlFor="input-userLeirilis" >
                                                                                ERP - MasterWay URL
                                                                            </label>
                                                                            <Input type="text" name="urlErp" id="urlErp"
                                                                                value={global.masterWayLogin}
                                                                                disabled={true}/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <FormGroup>
                                                                            <label className="form-control-label" htmlFor="input-userLeirilis" >
                                                                                ERP - MasterWay Key
                                                                            </label>
                                                                            <Input type="text" name="erpSilenceLogin" id="erpSilenceLogin"
                                                                                value={erpSilenceLogin}
                                                                                onChange={(e) => { this.validateString(e); this.handleChangeUser(e); }}
                                                                                disabled={!edit}

                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        }

                                                        <div className="pl-lg-4">

                                                            <Row>
                                                                <Col lg="4">
                                                                    <FormGroup>
                                                                        <label className="form-control-label" htmlFor="input-firstName" >
                                                                            Nome
                                                                        </label>
                                                                        <Input type="text" name="firstName" id="firstName"
                                                                            valid={firstNameState === "has-success"}
                                                                            invalid={firstNameState === "has-danger"}
                                                                            value={firstName}
                                                                            onChange={(e) => { this.validateString(e); this.handleChangeUser(e); }}
                                                                            disabled={!edit}
                                                                            required
                                                                        />
                                                                        <FormFeedback>
                                                                            Nome inválido.
                                                                        </FormFeedback>
                                                                        <FormFeedback valid>
                                                                            Nome válido.
                                                                        </FormFeedback>

                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <FormGroup>
                                                                        <label className="form-control-label" htmlFor="input-lastName">
                                                                            Apelido
                                                                        </label>
                                                                        <Input type="text" name="lastName" id="lastName"
                                                                            valid={lastNameState === "has-success"}
                                                                            invalid={lastNameState === "has-danger"}
                                                                            value={lastName}
                                                                            onChange={(e) => { this.validateString(e); this.handleChangeUser(e); }}
                                                                            disabled={!edit}
                                                                        />
                                                                        <FormFeedback>
                                                                            Apelido inválido.
                                                                        </FormFeedback>
                                                                        <FormFeedback valid>
                                                                            Apelido válido.
                                                                        </FormFeedback>

                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <FormGroup>
                                                                        <label className="form-control-label" htmlFor="input-born">
                                                                            Data de nascimento
                                                                        </label>
                                                                        <Input type="date" name="born" id="born"
                                                                            valid={bornState === "has-success"}
                                                                            invalid={bornState === "has-danger"}
                                                                            value={born !== undefined ? Moment(born).format("YYYY-MM-DD") : null}
                                                                            onChange={(e) => { this.validateString(e); this.handleChangeUser(e); }}
                                                                            disabled={!edit}
                                                                        />
                                                                        <FormFeedback>
                                                                            Data inválida.
                                                                        </FormFeedback>
                                                                        <FormFeedback valid>
                                                                            Data válida.
                                                                        </FormFeedback>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="4">
                                                                    <FormGroup>
                                                                        <label className="form-control-label" htmlFor="input-email" >
                                                                            Email
                                                                        </label>

                                                                        <Input type="email" name="email" id="email"
                                                                            valid={emailState === "has-success"}
                                                                            invalid={emailState === "has-danger"}
                                                                            value={email}
                                                                            onChange={(e) => { this.validateEmail(e); this.handleChangeUser(e); }}
                                                                            disabled={id > 0 ? true : !edit}
                                                                        />
                                                                        <FormFeedback>
                                                                            Email inválido.
                                                                        </FormFeedback>
                                                                        <FormFeedback valid>
                                                                            Email válido.
                                                                        </FormFeedback>

                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <FormGroup>
                                                                        <label className="form-control-label" htmlFor="input-phone">
                                                                            Telemóvel
                                                                        </label>
                                                                        <Input type="phone" name="phone" id="phone"
                                                                            valid={phoneState === "has-success"}
                                                                            invalid={phoneState === "has-danger"}
                                                                            value={phone}
                                                                            onChange={(e) => { this.validatePhone(e); this.handleChangeUser(e); }}
                                                                            disabled={!edit}
                                                                        />
                                                                        <FormFeedback>
                                                                            Telefone inválido.
                                                                        </FormFeedback>
                                                                        <FormFeedback valid>
                                                                            Telefone válido.
                                                                        </FormFeedback>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <FormGroup>
                                                                        <label className="form-control-label" htmlFor="input-nif">
                                                                            Contribuinte
                                                                        </label>
                                                                        <Input type="nif" name="nif" id="nif"
                                                                            valid={nifState === "has-success"}
                                                                            invalid={nifState === "has-danger"}
                                                                            value={nif}
                                                                            onChange={(e) => { this.validateNif(e); this.handleChangeUser(e); }}
                                                                            disabled={!edit}
                                                                        />
                                                                        <FormFeedback>
                                                                            Contribuinte inválido.
                                                                        </FormFeedback>
                                                                        <FormFeedback valid>
                                                                            Contribuinte válido.
                                                                        </FormFeedback>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                        {/* Address */}
                                                        <hr className="my-4" />
                                                        <h6 className="heading-small text-muted mb-4">
                                                            Informações de contacto
                                                        </h6>
                                                        <div className="pl-lg-4">
                                                            <Row>
                                                                <Col md="12">
                                                                    <FormGroup>
                                                                        <label className="form-control-label" htmlFor="input-adress">
                                                                            Morada
                                                                        </label>
                                                                        <Input type="adress" name="address" id="address"
                                                                            valid={adressState === "has-success"}
                                                                            invalid={adressState === "has-danger"}
                                                                            value={adress}
                                                                            onChange={(e) => { this.validateString(e); this.handleChangeUser(e); }}
                                                                            disabled={!edit}
                                                                        />

                                                                        <FormFeedback>
                                                                            Morada inválida.
                                                                        </FormFeedback>
                                                                        <FormFeedback valid>
                                                                            Morada válida.
                                                                        </FormFeedback>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="4">
                                                                    <FormGroup>
                                                                        <label className="form-control-label" htmlFor="input-city">
                                                                            Cidade
                                                                        </label>
                                                                        <Input type="city" name="city" id="city"
                                                                            valid={cityState === "has-success"}
                                                                            invalid={cityState === "has-danger"}
                                                                            value={city}
                                                                            onChange={(e) => { this.validateString(e); this.handleChangeUser(e); }}
                                                                            disabled={!edit}
                                                                        />
                                                                        <FormFeedback>
                                                                            Cidade inválida.
                                                                        </FormFeedback>
                                                                        <FormFeedback valid>
                                                                            Cidade válida.
                                                                        </FormFeedback>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="4">

                                                                    <FormGroup>
                                                                        <label className="form-control-label" htmlFor="input-country">
                                                                            País
                                                                        </label>
                                                                        <Input type="country" name="country" id="country"
                                                                            valid={countryState === "has-success"}
                                                                            invalid={countryState === "has-danger"}
                                                                            value={country}
                                                                            onChange={(e) => { this.validateString(e); this.handleChangeUser(e); }}
                                                                            disabled={!edit}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <FormGroup>
                                                                        <label className="form-control-label" htmlFor="input-postal-code">
                                                                            Código Postal
                                                                        </label>
                                                                        <Input type="postalCode" name="postalCode" id="postalCode"
                                                                            valid={postalCodeState === "has-success"}
                                                                            invalid={postalCodeState === "has-danger"}
                                                                            value={postalCode}
                                                                            onChange={(e) => { this.validatePostalCode(e); this.handleChangeUser(e); }}
                                                                            disabled={!edit}
                                                                        />
                                                                        <FormFeedback>
                                                                            Código Postal inválido.
                                                                        </FormFeedback>
                                                                        <FormFeedback valid>
                                                                            Código Postal válido.
                                                                        </FormFeedback>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <hr className="my-4" />
                                                        {/* Carros */}
                                                        <Row>
                                                            <Col>
                                                                <h6 className="heading-small text-muted mb-4">Veiculos associados</h6>
                                                            </Col>
                                                            <Col className="text-right" xl="4">
                                                                <Button color="success" name="createVehicule" type="button" size="sm" onClick={e => { this.openFormVehicule(e); }} >
                                                                    <i className="fas fa-plus-circle"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Adicionar veículo" : ""}
                                                                </Button>
                                                            </Col>
                                                        </Row>

                                                        <div className="pl-lg-4">
                                                            <Vehicules currentUser={currentUser} user={user} onChange={(vehicule) => { this.editFromVehicule(vehicule) }} />
                                                        </div>

                                                    </div>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>
                            </Container>
                        }
                    </>

                    )
                }
            </>
        );
    }
}

export default ProfileForm;
