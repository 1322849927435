import React from 'react';
import {  Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { GetHeader, StatusColor, ApiError, Status, Loading } from '../../utils/Utilities';
import axios from 'axios';
import moment from 'moment';


class ScheduleDay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            loading: false,
            activeNav: 1,
            data: null
        };
    }
    handleValue = (name, value) => { this.setState({ [name]: value }); }

    componentDidMount() {
        this.getScheulesDay();
    }

    getScheulesDay = () => {
        var headers = GetHeader();
        axios.get(global.api_url + "Graph/GetScheulesDay", { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.handleValue("data", response.data)
                }
                this.handleValue("loading", false)
            }).catch(error => {
                this.handleValue("loading", false)
                ApiError(error, "Dados: Agendamento do dia").then(call => call === true ? this.getScheulesDay() : "");
            });


    }


    render() {
        const { data, loading } = this.state;


        return (

            <Card className="bg-gradient-neutral shadow" style={{ height: '100%' }}>
                <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                        <div className="col">
                            <h6 className="text-uppercase text-dark ls-1 mb-1">
                                Agendamentos do dia
                            </h6>
                            <h2 className="text-dark mb-0">{moment().format('LL')}</h2>
                        </div>
                    </Row>
                </CardHeader>
                <CardBody style={{ marginLeft: 20 }}>

                    {loading === true || data === null ?
                        Loading(true) :

                        <>
                            <Row className="mt-5" style={{ textAlign: 'center' }}>
                                <Col className="mb-2 mb-xl-0" xl="6" style={{ alignSelf: 'center' }}>
                                    <h3 className="text-dark">
                                        <i className="fas fa-clock" style={{ color: StatusColor(Status.Pendente) }}></i> Pendentes : {data?.tPendente}
                                    </h3>
                                </Col>
                                <Col xl="6">
                                    <h3 className="text-dark">
                                        <i className="fas fa-check" style={{ color: StatusColor(Status.Aceite) }}></i> Novos agendamentos aceites: {data?.tAceites}
                                    </h3>
                                </Col>
                            </Row>
                            <Row className="mt-3" style={{ textAlign: 'center' }}>
                                <Col className="mb-3 mb-xl-0" xl="6">
                                    <h3 className="text-dark">
                                        <i className="fas fa-wrench" style={{ color: StatusColor(Status.Oficina) }}></i> Veículos em manutenção : {data?.tOficina}
                                    </h3>
                                </Col>
                                <Col xl="6">
                                    <h3 className="text-dark">
                                        <i className="fas fa-warehouse" style={{ color: StatusColor(Status.Concluido) }}></i> Veículos concluidos : {data?.tConcluido}
                                    </h3>
                                </Col>
                            </Row>
                            <Row className="mt-3" style={{ textAlign: 'center' }}>
                                <Col className="mb-3 mb-xl-0" xl="6">
                                    <h3 className="text-dark">
                                        <i className="fas fa-user" style={{ color: StatusColor(Status.Entregue) }}></i> Veículos entregues : {data?.tEntregue}
                                    </h3>
                                </Col>
                                <Col xl="6">
                                    <h3 className="text-dark">
                                        <i className="fas fa-times" style={{ color: StatusColor(Status.Rejeitado) }}></i> Agendamentos rejeitados : {data?.tRejeitado}
                                    </h3>
                                </Col>
                            </Row>
                            <Row className="mt-5" style={{ textAlign: 'center' }}>
                                <Col className="mb-2 mb-xl-0" xl="12">
                                    <h1 className="text-dark">
                                        Total : {data?.total}
                                    </h1>
                                </Col>
                            </Row>
                        </>}
                </CardBody>
            </Card>

        );
    }
}

export default ScheduleDay;

