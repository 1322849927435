import React from 'react';
import { Container, Card, CardHeader, Row, Col, Button } from "reactstrap";
import Header from '../../../../components/Headers/Header';
import AddBanner from './AddBanner';
import BannersListPhone from './BannerListPhone';

class Banners extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            loading: false,
            view: "MOBILE",
        }
    }

    changeView = (view) => {
        if (view !== undefined) {
            this.setState({ view: view });
        } else {
            this.state.view === "WEB" || this.state.view === "ADDBANNER" ? this.setState({ view: "MOBILE" }) : this.setState({ view: "WEB" });
        }
    }

    onChange = (action) => {
        if (action === "CLOSE") {
            this.changeView("MOBILE");
        }

    }

    render() {
        const { currentUser, view } = this.state;

        return (<>
            <Header />
            <Container className="mt--7" fluid>
                <div className="col">
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="heading-small text-muted mb-4">Banners</h6>
                                </Col>
                                <Col className="text-right" xl="4">
                                    {this.state.view !== "ADDBANNER" &&
                                        <Button size="sm" color="success" onClick={(e) => { this.changeView("ADDBANNER"); }}><i className="fas fa-plus-circle"></i> Adicionar Banner</Button>

                                    }
                                    {/*<Button size="sm" color="info" onClick={(e) => { this.changeView(); }}><i className="fas fa-exchange-alt"></i> {view === "MOBILE" ? "Web" : "Mobile"} </Button>*/}

                                </Col>
                            </Row>
                        </CardHeader>
                        <div style={{ padding: 20 }}>
                            <Row className="align-items-center">
                                {view === "MOBILE" &&
                                    <Col xl="12">
                                        <BannersListPhone currentUser={currentUser} />
                                    </Col>
                                }
                                {/*{view === "WEB" &&*/}
                                {/*    <Col xl="12">*/}
                                {/*        <BannersListSite currentUser={currentUser} />*/}
                                {/*    </Col>*/}
                                {/*}*/}

                                {view === "ADDBANNER" &&
                                    <Col xl="12">
                                        <AddBanner onChange={this.onChange} />
                                    </Col>
                                }
                            </Row>
                        </div>
                    </Card>
                </div>
            </Container>
        </>);
    }
}


export default Banners;