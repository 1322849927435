/*eslint-disable*/
import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { Collapse, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Form, Media, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container, Row, Col, } from "reactstrap";
import { AcessPage, CurrentUser, Role, RemoveUser } from "views/backoffice/utils/Utilities";
import axios from 'axios';




const Sidebar = (props) => {

    const currentUser = CurrentUser();

    const [collapseOpen, setCollapseOpen] = useState();
    const activeRoute = (routeName) => {
        return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };
    const toggleCollapse = () => {
        setCollapseOpen((data) => !data);
    };
    const closeCollapse = () => {
        setCollapseOpen(false);
    };
    const createLinks = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === '/admin') {
                if (AcessPage(prop.path) && prop?.slideMenu !== false) {
                    return (
                        <NavItem key={key}>
                            <NavLink to={prop.layout + prop.path} tag={NavLinkRRD} onClick={closeCollapse} activeClassName="active">
                                <i className={prop.icon} />
                                {prop.name}
                            </NavLink>
                        </NavItem>
                    );
                }
            }
        });
    };

    const { routes, logo } = props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
        navbarBrandProps = {
            to: logo.innerLink,
            tag: Link,
        };
    } else if (logo && logo.outterLink) {
        navbarBrandProps = {
            href: logo.outterLink,
            target: "_blank",
        };
    }

    const click_Logout = function (event) {
        event.preventDefault();
        var headers = { 'Accept': '*/*', 'Content-Type': 'application/json', 'Authorization': `Bearer ${currentUser.jwtToken}` };
        axios.post(global.api_url + "Accounts/revoke-token", { 'token': currentUser.refreshToken }, { headers })
            .then(response => {
                global.currentUser = response;
                if (response.status === 200) {
                    localStorage.setItem('refreshToken', '');
                    localStorage.setItem('jwtToken', '');
                    RemoveUser(dispatch);
                    notification("Logout", "Efetuado com sucesso!", "success", null, "top-right", null, false);
                };
            })
            .catch(error => {
                localStorage.setItem('refreshToken', '');
                localStorage.setItem('jwtToken', '');
                RemoveUser(dispatch);
            });
    };

    return (
        <Navbar
            className="navbar-vertical fixed-left navbar-light bg-white"
            expand="xl"
            id="sidenav-main"
        >
            <Container fluid>
                {/* Toggler */}
                <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                    {/*<span className="navbar-toggler-icon" />*/}

                    <i className="fas fa-bars rs-text-red"></i>


                </button>
                {/* Brand */}
                {logo ? (
                    <NavbarBrand className="pt-0" {...navbarBrandProps}>
                        <img alt="RedService" className="navbar-brand-img" src={logo.imgSrc} />
                    </NavbarBrand>
                ) : null}
                {/* User */}
                <Nav className="align-items-center d-md-none ml-auto">
                    <UncontrolledDropdown nav>
                        <DropdownToggle nav>
                            <Media className="align-items-center">
                                <i className="fas fa-user-circle"></i>
                            </Media>
                        </DropdownToggle>
                        <DropdownToggle className="pr-0" nav>
                            <Media className="align-items-center">
                                <Media className="ml-2 d-none d-lg-block">
                                    <span className="mb-0 text-sm font-weight-bold">
                                        <i className="far fa-user-circle" style={{ paddingInline: 10 }}></i>
                                        {currentUser.firstName}
                                    </span>
                                </Media>
                            </Media>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem className="noti-title" header tag="div">
                                <h6 className="text-overflow m-0">Olá, {currentUser.firstName}!</h6>
                            </DropdownItem>
                            <DropdownItem to="/admin/Dashboard" tag={Link}>
                                <i className="fas fa-chart-line rs-text-red"></i>
                                <span>WMS</span>
                            </DropdownItem>

                            <DropdownItem to="/admin/Profile" tag={Link}>
                                <i className="fas fa-user rs-text-red" />
                                <span>Perfil</span>
                            </DropdownItem>

                            <DropdownItem to="/admin/Workshop" tag={Link}>
                                <i className="fas fa-tools rs-text-red" />
                                <span>Oficina</span>
                            </DropdownItem>

                            <DropdownItem to="/admin/schedule" tag={Link}>
                                <i className="fas fa-calendar rs-text-red" />
                                <span>Agenda</span>
                            </DropdownItem>

                            <DropdownItem to="/admin/Search" tag={Link}>
                                <i className="fas fa-search rs-text-red" />
                                <span>Histórico</span>
                            </DropdownItem>

                            <DropdownItem to="/admin/Mail" tag={Link}>
                                <i className="fas fa-inbox rs-text-red" />
                                <span>Email</span>
                            </DropdownItem>

                            <DropdownItem to="/admin/Documentation" tag={Link}>
                                <i className="fas fa-passport rs-text-red" />
                                <span>Documentação</span>
                            </DropdownItem>



                            <DropdownItem divider />
                            <DropdownItem onClick={(e) => click_Logout(e)}>
                                <i className="ni ni-user-run" />
                                <span>Logout</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
                {/* Collapse */}
                <Collapse navbar isOpen={collapseOpen}>
                    {/* Collapse header */}
                    <div className="navbar-collapse-header d-md-none">
                        <Row>
                            {logo ? (
                                <Col className="collapse-brand" xl="6">
                                    {logo.innerLink ? (
                                        <Link to={logo.innerLink}>
                                            <img alt={logo.imgAlt} src={logo.imgSrc} />
                                        </Link>
                                    ) : (
                                        <a href={logo.outterLink}>
                                            <img alt={logo.imgAlt} src={logo.imgSrc} />
                                        </a>
                                    )}
                                </Col>
                            ) : null}
                            <Col className="collapse-close" xl="6">
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    onClick={toggleCollapse}
                                >
                                    <span />
                                    <span />
                                </button>
                            </Col>
                        </Row>
                    </div>

                    <Form className="mt-4 mb-3 d-md-none">
                    </Form>
                    <Nav navbar>{createLinks(routes)}</Nav>
                    <hr className="my-3" />


                    {currentUser.role === Role.Admin && <>
                        <h6 className="navbar-heading text-muted">Front-End</h6>

                        <Nav className="mb-md-3" navbar>

                            <NavItem>
                                <NavLink to={"/admin/Banners"} tag={NavLinkRRD} onClick={closeCollapse} activeClassName="active">
                                    <i className="fas fa-images rs-text-red"></i>
                                    Adicionar banners
                                </NavLink>

                                <NavLink to={"/admin/BannerText"} tag={NavLinkRRD} onClick={closeCollapse} activeClassName="active">
                                    <i className="fas fa-closed-captioning rs-text-red"></i>
                                    Banner em texto
                                </NavLink>

                                <NavLink to={"/admin/Text"} tag={NavLinkRRD} onClick={closeCollapse} activeClassName="active">
                                    <i className="fas fa-font rs-text-red"></i>
                                    Textos
                                </NavLink>

                                <NavLink to={"/admin/Services"} tag={NavLinkRRD} onClick={closeCollapse} activeClassName="active">
                                    <i className="fab fa-buffer rs-text-red"></i>
                                    Serviços
                                </NavLink>
                                <NavLink to={"/admin/Campaigns"} tag={NavLinkRRD} onClick={closeCollapse} activeClassName="active">
                                    <i className="fas fa-ad rs-text-red"></i>
                                    Campanhas
                                </NavLink>
                                <NavLink to={"/admin/Agreements"} tag={NavLinkRRD} onClick={closeCollapse} activeClassName="active">
                                    <i className="fas fa-handshake rs-text-red"></i>
                                    Acordos
                                </NavLink>
                                <NavLink to={"/admin/Advices"} tag={NavLinkRRD} onClick={closeCollapse} activeClassName="active">
                                    <i className="fas fa-book rs-text-red"></i>
                                    Conselhos
                                </NavLink>


                            </NavItem>
                        </Nav>

                        <hr className="my-3" />
                    </>}
                    <h6 className="navbar-heading text-muted">Documentação</h6>
                    {/* Navigation */}
                    <Nav className="mb-md-3" navbar>

                        <NavItem>
                            <NavLink to={"/admin/Documentation"} tag={NavLinkRRD} onClick={closeCollapse} activeClassName="active">
                                <i className="fas fa-atlas rs-text-red"></i>
                                RedService
                            </NavLink>
                        </NavItem>
                    </Nav>

                    {/*<Nav className="mb-md-3" navbar>*/}
                    {/*    <NavItem className="active-pro active">*/}
                    {/*        */}{/*<NavLink className="active-pro active" to="/logout" tag={Link}>*/}
                    {/*        */}{/*    <i className="ni ni-spaceship" />*/}
                    {/*        */}{/*    RedService*/}
                    {/*        */}{/*</NavLink>*/}
                    {/*        <NavLink className="active-pro active" onClick={(e) => { e.preventDefault(); window.open('https://front.redservice.pt', '_blank') }}>*/}
                    {/*            <i className="ni ni-spaceship" />*/}
                    {/*            RedService*/}
                    {/*        </NavLink>*/}
                    {/*    </NavItem>*/}
                    {/*</Nav>*/}

                </Collapse>
            </Container>
        </Navbar>
    );
};

Sidebar.defaultProps = {
    routes: [{}],
};

Sidebar.propTypes = {
    // links that will be displayed inside the component
    routes: PropTypes.arrayOf(PropTypes.object),
    logo: PropTypes.shape({
        // innerLink is for links that will direct the user within the app
        // it will be rendered as <Link to="...">...</Link> tag
        innerLink: PropTypes.string,
        // outterLink is for links that will direct the user outside the app
        // it will be rendered as simple <a href="...">...</a> tag
        outterLink: PropTypes.string,
        // the image src of the logo
        imgSrc: PropTypes.string.isRequired,
        // the alt for the img
        imgAlt: PropTypes.string.isRequired,
    }),
};

export default Sidebar;
