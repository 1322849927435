import React from 'react';
import { StatusColor, StatusText, notification, GetHeader, ApiError } from "../utils/Utilities";
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
import ModalScheduleInfo from '../Schedules/ModalScheduleInfo'
import Moment from 'moment';
import 'moment/locale/pt'

class ScheduleList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            currentWorkshop: props.currentWorkshop,
            workshopAccounts: props.workshopAccounts,
            schedule: props.schedule,
            loadingData: false,
            showModal: false
        };
    }

    openEdit(e) {
        e.preventDefault()
        const { service } = this.state;
        this.props.onChange(service);
    }

    handleValue = (name, value) => { this.setState({ [name]: value }); }

    showSchedule = (e) => { e.preventDefault(); this.handleValue('showModal', true); }

    responseModal = (value) => { this.handleValue('showModal', value); }

    moveToSchedule(value) { this.props.onChange(this.state.schedule); }

    delete(e, schedule) {
        e.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div style={{ borderStyle: "solid", padding: 40, borderRadius: 8, borderWidth: 2 }} className='custom-ui'>
                        <h1>Eliminar {" - " + schedule?.title}</h1>
                        <div>Cliente:<p style={{ fontSize: '15px', fontWeight: "bold" }}>{schedule?.resources.client.firstName + " " + schedule?.resources.client.lastName}</p></div>
                        <div>Contactos:<p style={{ fontSize: '15px', fontWeight: "bold" }}>{schedule?.resources.client.email + " - " + schedule?.resources.client.phone}</p></div>
                        <div>Data:<p style={{ fontSize: '15px', fontWeight: "bold" }}>{Moment(schedule?.start).format('LLLL') + " - " + Moment(schedule?.end).format('LLLL')}</p></div>
                        <div>Veículo:<p style={{ fontSize: '15px', fontWeight: "bold" }}>{schedule?.resources.vehicule.plate + " - " + schedule?.resources.vehicule.brand + " " + schedule?.resources.vehicule.model}</p></div>
                        <div><p>Pretende eliminar o agendamento? </p></div>
                        <center>
                            <Button color="success" size="sm" type="button" onClick={(e) => {
                                e.preventDefault();
                                this.deleteApi(e);
                                onClose();
                            }}><i className="fas fa-trash"></i> Eliminar</Button>
                            <Button color="danger" size="sm" onClick={onClose}><i className="fas fa-times"></i> Cancelar</Button>
                        </center>
                    </div>
                );
            }
        });
    }

    deleteApi = (e) => {
        const { schedule } = this.state;
        this.handleValue('loadingData', true);
        var headers = GetHeader();
        axios.post(global.api_url + "Schedules/Soft/" + schedule.id, null, { headers })
            .then((response) => {
                if (response.status === 200) {
                    notification("Agendamento", "Agendamento removido com sucesso.", "success", null, "top-right", null, false);
                    this.props.onClick(e);
                } else { notification("Agendamento", "Erro a remover o agendamento do cliente.", "warning", null, "top-right", null, false); }
                this.handleValue('loadingData', false);
            }).catch(error => {
                this.handleValue('loadingData', false);
                ApiError(error, "Agendamento").then(call => call === true ? this.deleteApi(e) : "");
            })
    }


    render() {
        const { schedule, showModal, currentUser, currentWorkshop, workshopAccounts } = this.state;
        const changeStatus = (schedule) => {
            var color = StatusColor(schedule?.resources?.workshop?.status);
            var text = StatusText(schedule?.resources?.workshop?.status);
            return (<>
                <i id={'bange-' + schedule.id} style={{ 'zoom': 3, 'background': color }} />
                < UncontrolledTooltip delay={0} placement="top" target={'bange-' + schedule.id} >{text}</UncontrolledTooltip >
            </>)
        }
        return (
            <>
                {showModal && < ModalScheduleInfo event={schedule} currentUser={currentUser} currentWorkshop={currentWorkshop} workshopAccounts={workshopAccounts} onChange={this.responseModal} />}

                <tr>
                    <td>
                        <Badge color="" className="badge-dot mr-4">
                            {changeStatus(schedule)}
                        </Badge>
                    </td>
                    <td>
                        {schedule?.resources?.client?.firstName + " " + schedule?.resources?.client?.lastName}
                    </td>
                    <td>
                        {schedule?.resources?.client?.email}
                    </td>
                    <td>
                        {schedule?.resources?.client?.phone}
                    </td>
                    <td>
                        {schedule?.resources?.vehicule?.brand + " " + schedule?.resources?.vehicule?.model}
                    </td>
                    <td>
                        {schedule?.resources?.vehicule?.plate}
                    </td>
                    <td>
                        {Moment(schedule?.start).format('LL')}
                    </td>
                    <td>
                        {Moment(schedule?.start).format('LT')}
                    </td>
                    <td className="text-right">
                        <Button id="btn_trash" color="danger" name="update" type="button" size="sm" onClick={e => { this.delete(e, schedule) }} ><i className="fas fa-trash"></i></Button>
                        <UncontrolledTooltip delay={0} placement="top" target="btn_trash">
                            Eliminar pedido de recolha
                        </UncontrolledTooltip>
                        <Button color="warning" id="btn_edit" name="update" type="button" size="sm" onClick={e => { this.showSchedule(e) }} ><i className="fas fa-eye"></i></Button>
                        <UncontrolledTooltip delay={0} placement="top" target="btn_edit">
                            Visualizar agendamento
                        </UncontrolledTooltip>
                        <Button id="btn_calendar" color="success" name="update" type="button" size="sm" onClick={e => { this.moveToSchedule(e) }} ><i className="far fa-calendar-plus"></i></Button>
                        <UncontrolledTooltip delay={0} placement="top" target="btn_calendar">
                            Mover para a agenda
                        </UncontrolledTooltip>
                    </td>
                </tr>
            </>
        );
    }
}


export default ScheduleList;