import React from 'react';
import { UncontrolledTooltip, Button } from "reactstrap";
import { notification, GetHeader, ApiError, Device, DeviceType } from "../utils/Utilities";
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';

class Vehicules extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicule: props.vehicule,
            loadingData: false
        };
    }

    openEditVehicule(e) {
        e.preventDefault()
        const { vehicule } = this.state;
        this.props.onChange(vehicule);
    }


    deleteVehicule = (vehicule) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div style={{ borderStyle: "solid", padding: 40, borderRadius: 8, borderWidth: 2 }} className='custom-ui'>
                        <h1>Remover Veículo</h1>
                        <p>Deseja remover o veículo {vehicule?.plate + " - " + vehicule?.brand + " " + vehicule.model} ?</p>

                        <Button id="btn_remove_vehicule" color="success" size="sm" type="button" onClick={(e) => {
                            this.setState({ loadingData: true });
                            var headers = GetHeader();
                            axios.get(global.api_url + "Vehicules/Delete/" + vehicule?.id, { headers })
                                .then((response) => {
                                    if (response.status === 200) {
                                        notification("Veículo", "Removido com sucesso!", "success", null, "top-right", null, false);
                                        onClose();
                                        this.props.onLoad(vehicule);
                                    }
                                }).catch(error => {
                                    this.setState({ loadingData: false })
                                    ApiError(error, "Veículo").then(call => call === true ? this.deleteVehicule(vehicule) : "");
                                })
                        }}>
                            <i className="fas fa-trash"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Remover" : ""}
                        </Button>
                        <Button id="btn_edit_cancel" color="danger" size="sm" onClick={onClose}>
                            <i className="fas fa-times"></i> {DeviceType(window.innerWidth) !== Device.Mobile ? "Cancelar" : ""}
                        </Button>
                    </div>
                );
            }
        });
    }

    render() {

        const { vehicule } = this.state;

        return (

            <tr>
                <th scope="row">
                    {vehicule.brand}
                </th>
                <td>
                    {vehicule.model}
                </td>
                <td>
                    {vehicule.year}

                </td>
                <td>
                    {vehicule.plate}
                </td>
                <td>
                    {vehicule.kms}
                </td>
                <td className="text-right">

                    <Button id="btn_del_vehicule" size="sm" color="danger" onClick={(e) => { this.deleteVehicule(vehicule); }}><i className="fas fa-times"></i></Button>
                    <UncontrolledTooltip delay={0} placement="top" target="btn_del_vehicule">
                        Remover veículo
                    </UncontrolledTooltip>

                    <Button id="btn_edit_vehicule" size="sm" color="warning" onClick={(e) => { this.openEditVehicule(e) }}><i className="fas fa-edit"></i></Button>
                    <UncontrolledTooltip delay={0} placement="top" target="btn_edit_vehicule">
                        Editar linha
                    </UncontrolledTooltip>

                </td>
            </tr>

        );
    }
}


export default Vehicules;