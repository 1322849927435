import React from 'react';
import { Container } from "reactstrap";
import { CurrentUser, LoadingFixed, Role } from "views/backoffice/utils/Utilities";

const Index = (props) => {
    const iframeRef = React.useRef(null);
    let currentUser = CurrentUser();

    React.useEffect(() => {
        const iframe = iframeRef.current;
        if (!iframe) return undefined;

        const listener = (e) => {
            //console.log("event listener");
            //console.log(e)
        }
        iframe.addEventListener("load", listener);

        iframe.onload = function () {
            //console.log("The iframe is loaded");
        };
        iframe.onerror = function () {
            //console.log("Something wrong happened");
        };

        return () => {
            iframe.removeEventListener("load", listener);
        };
    });

    const openMasterWay = () => {
        window.open(`${global.masterWayLogin + currentUser?.erpSilenceLogin}`, '_blank')
    }


    return (
        <>
            {currentUser?.role !== Role.Admin &&
                <div className="header bg-RS-grey pt-5 ">
                    <Container fluid>
                        <div className="header-body">
                        </div>
                        <div>
                            {openMasterWay()}
                        </div>
                    </Container>
                </div>
            }





            {currentUser?.role === Role.Admin &&
                /*PARA VERS�O DE TESTE*/
                <div>
                    {((currentUser !== null && currentUser !== undefined) || currentUser.erpSilenceLogin === "") ?
                        <>
                            <div className="header bg-RS-grey pt-5 ">
                                <Container fluid>
                                    <div className="header-body">
                                    </div>
                                </Container>
                            </div>
                            <iframe
                                referrerPolicy="no-referrer"
                                width="100%" height={window.innerHeight}
                                ref={iframeRef}
                                allow="midi"
                                sandbox="allow-forms allow-scripts allow-popups allow-same-origin allow-top-navigation allow-pointer-lock"
                                src={`${global.masterWayLogin + currentUser?.erpSilenceLogin}`}
                                seamless
                            >
                            </iframe>
                        </>
                        :
                        <>{LoadingFixed(true)}</>
                    }
                </div>
            }
        </>);

}



export default Index;

//<iframe id="myIFrame" width="100%" height={window.innerHeight} src="https://app.masterway.net/?action=login&AutKey=iYwUbcWO_8N-oRAzeKLUlfmlly9AoK2329" />
