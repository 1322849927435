import React from 'react';
import { CurrentUser } from "views/backoffice/utils/Utilities";
import Configurations from "views/backoffice/Configurations/Configurations";

const Index = (props) => {
    const currentUser = CurrentUser();
    return (<Configurations currentUser={currentUser} />);
}

export default Index;

