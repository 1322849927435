import React from 'react';
import { Container } from "reactstrap";

const Header = ({ subText }) => {
    return (
        <>
            <div className="header bg-RS-grey pb-8 pt-5 pt-md-6">
                <Container fluid>
                    <div className="header-body">

                        <h6 className="text-uppercase text-white ls-1 mb-1">
                            {subText !== undefined ? subText : ""}
                        </h6>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default Header;
