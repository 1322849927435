import React from 'react';
import { Badge, Button } from "reactstrap";
import Moment from 'moment';
import 'moment/locale/pt'
Moment.locale('pt');


const JoinUsRow = ({ join, showDetail }) => {

    return (
        <tr>
            <th scope="row">
                {join?.name}
            </th>
            <td>
                {join?.company}
            </td>
            <td>
                {join?.email}
            </td>
            <td>
                {join?.city}
            </td>
            <td>
                {Moment(join?.created).format('LLL')}
            </td>
            <td>
                <Badge color="" className="badge-dot mr-4">
                    {join?.done === true ?
                        (<i className="bg-success" style={{ 'zoom': 3 }} />) :
                        (<i className="bg-danger" style={{ 'zoom': 3 }} />)}
                </Badge>
            </td>
            <td>
                <Button color="success" size="edit" name="update" type="button" size="sm" onClick={(e) => showDetail(join)} ><i className="far fa-eye"></i></Button>
            </td>
        </tr>
    );
}

export default JoinUsRow;