import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import confirm from "reactstrap-confirm";
import { ApiError, GetHeader, Loading, notification, Status } from "../../utils/Utilities";
import { Form, Row, FormGroup, Input, FormFeedback, Col, Button } from "reactstrap"

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            event: props.event,
            currentUser: props.currentUser,
            currentWorkshop: props.currentWorkshop,
            validate: { nifState: '', nameState: '', adressState: '', cityState: '', postalCodeState: '' },
            loading: false,
            loadHeader: false,
            header: props?.header !== null ? props.header : {
                id: 0,
                origin: '',
                generated: '',
                reference: '',
                destin: '',
                diary: '',
                year: '',
                workShopId: '',
                scheduleId: '',
                accountId: 0,
                name: '',
                nif: props?.currentUser?.nif,
                adress: '',
                city: '',
                postalCode: '',
                username: props?.currentUser?.firstName,
            },
            edited: false
        };

        this.fillHeader = this.fillHeader.bind(this);
    }
    componentDidMount() { this.fillHeader(this.state.event, this.state.header); }

    fillHeader = (event, header) => {
        this.setState({ loadHeader: true })
        if (header.id === "" || header.id === 0 || header.id === undefined) {
            if (event.resources.client.accountId !== 0 && event.resources.client.accountId !== "") {
                this.getHeaderData(+this.state.event.resources.client.accountId, event.id).then(response => {
                    this.handleHeader('name', response?.firstName + " " + response?.lastName);
                    this.handleHeader('nif', response?.nif);
                    this.handleHeader('adress', response?.adress);
                    this.handleHeader('city', response?.city);
                    this.handleHeader('postalCode', response?.postalCode);
                    this.setState({ loadHeader: false })
                });
            } else {
                this.handleHeader('name', event.resources.client?.firstName + " " + event.resources.client?.lastName);
                this.handleHeader('nif', event.resources.client?.nif);
                this.handleHeader('adress', '');
                this.handleHeader('city', '');
                this.handleHeader('postalCode', '');
                this.setState({ loadHeader: false })
            }
        } else {
            this.setState({ loadHeader: false })
        }
    }

    getHeaderData = async (userId, scheduleId) => {
        this.handleValue('loading', true);
        var headers = GetHeader();
        return axios.get(global.api_url + "Accounts/Header/" + userId + "/" + scheduleId, { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.handleValue('loading', false);
                    return response.data;
                }
                this.handleValue('loading', false);
            }).catch(error => {
                this.handleValue('loading', false);
                ApiError(error, "Documento").then(call => call === true ? this.getHeaderData(userId, scheduleId) : "");
                return null;
            })
    }


    UpdateHeaderData = async (updateHeader) => {
        this.handleValue('loading', true);
        var headers = GetHeader();
        return axios.post(global.api_url + "Document/Header/Update", updateHeader, { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.handleValue('loading', false);
                    notification("Cabeçalho", "Dados atualizados com sucesso!", "success", null, "top-right", null, false);
                    return response.data;
                }
                this.handleValue('loading', false);
            }).catch(error => {
                this.handleValue('loading', false);
                ApiError(error, "Documento").then(call => call === true ? this.UpdateHeaderData(updateHeader) : "");
                return null;
            })
    }

    handleHeader = async (name, value) => {
        await this.setState(state => ({ header: { ...state.header, [name]: value } }), () => { this.setState({ edited: true }); this.props.onChange(this.state.header); });
    }
    handleValue = async (name, value) => { await this.setState({ [name]: value }); return value; }

    validateString(e) {
        const { validate } = this.state;
        if (e.target.value !== null && e.target.value !== "") { validate[e.target.name + 'State'] = 'has-success'; } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState(({ validate }));
    }

    validatePostalCode(e) {
        const regex = /^\d{4}(-\d{3})?$/;
        const { validate } = this.state;

        if (regex.test(e.target.value)) {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }

    validateNIF(e) {
        const regex = /^[0-9]\d{8}$/;
        const { validate } = this.state;

        if (regex.test(e.target.value)) {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }

    render() {
        const { validate, header, loadHeader, event, edited } = this.state;
        const { generated } = this.state.header;

        return (
            <Form>
                {loadHeader === false ?
                    <>
                        {event.resources?.workshop?.status !== Status.Entregue &&
                            <Row>
                                <Col>
                                    <Button size="sm" color="info" onClick={(e) => {
                                        e.preventDefault();

                                        let result = confirm({
                                            title: (<><strong>Dados do cabeçalho</strong></>),
                                            message: (<>
                                                <h4>Substituir os dados do cabeçalho pelos dados da ficha de cliente.</h4>
                                                <p>Deseja continuar?</p>
                                            </>),
                                            confirmText: "Sim",
                                            confirmColor: "success",
                                            cancelText: "Não",
                                            cancelColor: "danger"
                                        });

                                        result.then(response => {
                                            if (response) {
                                                this.setState({ edited: true });
                                                this.getHeaderData(+this.state.event?.resources?.client?.accountId, event?.id).then(response => {
                                                    this.handleHeader('name', response?.firstName + " " + response?.lastName);
                                                    this.handleHeader('nif', response?.nif);
                                                    this.handleHeader('adress', response?.adress);
                                                    this.handleHeader('city', response?.city);
                                                    this.handleHeader('postalCode', response?.postalCode);
                                                    this.setState({ loadHeader: false })
                                                    notification("Utilizador", "Dados carregados com sucesso!", "success", null, "top-right", null, false);
                                                });
                                            }
                                        })
                                    }}><i className="fas fa-user-cog"></i> Obter Dados do utilizador</Button>
                                </Col>
                                <Col className="text-right">
                                    {edited === true &&
                                        <Button size="sm" color="success" className="text-right" onClick={(e) => {
                                            e.preventDefault();
                                            var { header } = this.state;
                                            this.setState({ edited: false });
                                            var toUpdate = { id: header?.id, name: header?.name, nif: header?.nif, adress: header?.adress, city: header?.city, postalCode: header?.postalCode };
                                            this.UpdateHeaderData(toUpdate);
                                            
                                        }}><i className="fas fa-save"></i> Guardar cabeçalho</Button>

                                    }</Col>
                            </Row>
                        }
                        <Row>
                            <Col lg={window.innerWidth < 1200 ? "4" : "2"}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-title" >
                                        Contribuinte
                                    </label>
                                    <Input type="number" name="nif" id="nif"
                                        valid={validate.nifState === "has-success"}
                                        invalid={validate.nifState === "has-danger"}
                                        value={header.nif}
                                        onChange={(e) => { this.validateNIF(e); this.handleHeader(e.target.name, e.target.value); }}
                                        disabled={![undefined, ""].includes(generated) ? true : false}
                                        maxLength={9}
                                        required
                                    />
                                    <FormFeedback>
                                        NIF inválido.
                                    </FormFeedback>
                                    <FormFeedback valid>
                                        NIF válido.
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg={window.innerWidth < 1200 ? "8" : "3"}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-title" >
                                        Nome Cliente*
                                    </label>
                                    <Input type="text" name="name" id="name"
                                        valid={validate.nameState === "has-success"}
                                        invalid={validate.nameState === "has-danger"}
                                        value={header.name}
                                        onChange={(e) => { this.validateString(e); this.handleHeader(e.target.name, e.target.value); }}
                                        disabled={![undefined, ""].includes(generated) ? true : false}
                                        required
                                    />
                                    <FormFeedback>
                                        Nome Cliente inválido.
                                    </FormFeedback>
                                    <FormFeedback valid>
                                        Nome Cliente válido.
                                    </FormFeedback>
                                </FormGroup>
                            </Col>

                            <Col lg={window.innerWidth < 1200 ? "4" : "3"} >
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-title" >
                                        Morada*
                                    </label>
                                    <Input type="text" name="adress" id="adress"
                                        valid={validate.adressState === "has-success"}
                                        invalid={validate.adressState === "has-danger"}
                                        value={header.adress}
                                        onChange={(e) => { this.validateString(e); this.handleHeader(e.target.name, e.target.value); }}
                                        disabled={![undefined, ""].includes(generated) ? true : false}
                                        required
                                    />
                                    <FormFeedback>
                                        Morada inválida.
                                    </FormFeedback>
                                    <FormFeedback valid>
                                        Morada válida.
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg={window.innerWidth < 1200 ? "4" : "2"}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-title" >
                                        Localidade*
                                    </label>
                                    <Input type="text" name="city" id="city"
                                        valid={validate.cityState === "has-success"}
                                        invalid={validate.cityState === "has-danger"}
                                        value={header.city}
                                        onChange={(e) => { this.validateString(e); this.handleHeader(e.target.name, e.target.value); }}
                                        disabled={![undefined, ""].includes(generated) ? true : false}
                                        required
                                    />
                                    <FormFeedback>
                                        Localidade inválida.
                                    </FormFeedback>
                                    <FormFeedback valid>
                                        Localidade válida.
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg={window.innerWidth < 1200 ? "4" : "2"}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-title" >
                                        Código Postal*
                                    </label>
                                    <Input type="text" name="postalCode" id="postalCode"
                                        valid={validate.postalCodeState === "has-success"}
                                        invalid={validate.postalCodeState === "has-danger"}
                                        value={header.postalCode}
                                        onChange={(e) => { this.validatePostalCode(e); this.handleHeader(e.target.name, e.target.value); }}
                                        disabled={![undefined, ""].includes(generated) ? true : false}
                                        required
                                    />
                                    <FormFeedback>
                                        Código Postal inválido.
                                    </FormFeedback>
                                    <FormFeedback valid>
                                        Código Postal válido.
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </>
                    : Loading(loadHeader)}
            </Form>
        )
    }

}

export default Header;