import React from 'react';
import { Card, CardHeader, CardFooter, Table, Container, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { Loading, notification, ApiError, GetHeader } from "../utils/Utilities";
import Header from '../../../components/Headers/Header';
import CuponsList from './CuponsList';
import CuponsTypeList from './CuponsTypeList';
import CustomPagination from "views/backoffice/utils/CustomPagination";
import axios from 'axios';
import CuponTypeForm from './CuponTypeForm';

class Cupons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingData: false,
            currentUser: props.currentUser,
            cupons: [],
            cuponsType: [],
            pageNumber: 1,
            pageSize: 7,
            cuponsToShow: 'CuponsTypeList',
            currentCuponType: null,
            pagination: null,
        }
    }

    componentDidMount() { this.updateView(); }

    static getDerivedStateFromProps(props, state) {
        if (props.currentUser !== state.currentUser) {
            return { currentUser: props.currentUser };
        }
        return null;
    }


    updateView = () => {
        switch (this.state.cuponsToShow) {
            case 'CuponsTypeList':
                this.getCuponsType(1, this.state.pageSize)
                break;
            case 'CuponsList':
                this.getCupons(1, this.state.pageSize)
                break;
            case '':
                this.getCuponsType(1, this.state.pageSize)
                break;
            default:
                break;
        }
    }

    handleValue = async (name, value) => { await this.setState({ [name]: value }); }

    getCupons(pageNumber, pageSize) {
        this.handleValue('loadingData', true);
        axios.get(global.api_url + "Cupon", { params: { PageNumber: pageNumber, PageSize: pageSize } }).then((response) => {
            if (response.status === 200) {
                this.handleValue('cupons', [])
                this.handleValue('cupons', response.data)
                if (response.headers["x-pagination"] !== undefined) { this.handleValue('pagination', JSON.parse(response.headers["x-pagination"])); }
            } else {
                notification("Cupões", "Erro a obter os cupões.", "warning", null, "top-right", null, false);
            }
            this.handleValue('loadingData', false);
        }).catch(error => {
            this.handleValue('loadingData', false);
            //notification("Cupões", error.message, "danger", null, "top-right", null, false);
            ApiError(error, "Cupões").then(call => call === true ? this.getCupons(pageNumber, pageSize) : "");
        })
    }

    getCuponsType(pageNumber, pageSize) {
        const { currentUser } = this.state;
        this.handleValue('loadingData', true);
        var headers = GetHeader();//{ 'Authorization': `Bearer ${currentUser.jwtToken}` };
        axios.get(global.api_url + "CuponType", { headers, params: { PageNumber: pageNumber, PageSize: pageSize } }).then((response) => {
            if (response.status === 200) {
                this.handleValue('cuponsType', []); this.handleValue('cuponsType', response.data);
                if (response.headers["x-pagination"] !== undefined) {
                    this.handleValue('pagination', JSON.parse(response.headers["x-pagination"]));
                }
            } else { notification("Cupões", "Erro a obter os cupões.", "warning", null, "top-right", null, false); }
            this.handleValue('loadingData', false);
        }).catch(error => {
            this.handleValue('loadingData', false);
            //notification("Cupões", error.message, "danger", null, "top-right", null, false);
            ApiError(error, "Cupões").then(call => call === true ? this.getCuponsType(pageNumber, pageSize) : "");
        })
    }

    changeView = (value) => { this.handleValue('cuponsToShow', value).then(r => { this.handleValue('pagination', null); this.updateView(); }) };

    openForm = (cuponTypeData, editForm, component, reload) => {
        if (reload === true) {
            this.updateView();
        } else {
            if (component !== null) {
                if (component.constructor.name === "CuponsTypeList") {
                    this.handleValue('cuponsToShow', 'CuponsTypeForm');
                }
            } else {
                if (this.state.cuponsToShow === "CuponsTypeList") {
                    this.handleValue('cuponsToShow', null);
                }
            }
            this.handleValue('editForm', editForm);
            this.handleValue('currentCuponType', cuponTypeData);
        }
    }

    closeForm = (value) => {
        this.handleValue('currentCuponType', null);
        this.handleValue('cuponsToShow', value === 'CuponTypeForm' ? 'CuponsTypeList' : 'CuponsList').then(r => { this.updateView(); });
    }

    changePaginationCupon = (pageNumber, pagesize) => {
        this.getCupons(pageNumber, pagesize)
    }

    changePaginationCuponType = (pageNumber, pagesize) => {
        this.getCuponsType(pageNumber, pagesize)
    }

    render() {
        const { loadingData, currentUser, cupons, cuponsType, cuponsToShow, currentCuponType, editForm, pagination } = this.state;
        return (<>

            <Header />
            {currentCuponType !== null ? (
                <Row>
                    <CuponTypeForm cuponType={currentCuponType} edit={editForm} currentUser={currentUser} onChange={this.closeForm} />
                </Row>
            ) : (


                <Container className="mt--7" fluid>
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <Col>
                                            {cuponsToShow === 'CuponsList' && <h6 className="heading-small text-muted mb-4">Cupões Ativos</h6>}
                                            {cuponsToShow === 'CuponsTypeList' && <h6 className="heading-small text-muted mb-4">Regras de cupões</h6>}
                                            {cuponsToShow === 'CuponsTypeForm' && <h6 className="heading-small text-muted mb-4">Formulário de regras de cupões</h6>}

                                        </Col>
                                        <Col className="text-right" xl="4">

                                            {cuponsToShow === 'CuponsList' && <Button color="danger" name="CuponsList" type="button" size="sm" onClick={(e) => this.changeView('CuponsTypeList')} ><i className="fas fa-eye"></i> Regras</Button>}
                                            {cuponsToShow === 'CuponsTypeList' && <Button color="danger" name="CuponsTypeList" type="button" size="sm" onClick={(e) => this.changeView('CuponsList')} ><i className="fas fa-eye"></i> Ativos</Button>}

                                            <Button color="success" id="btn_newCupon" type="button" size="sm" onClick={(e) => { e.preventDefault(); this.openForm({}, true, null, false); }} ><i className="fas fa-plus-circle"></i> Novo</Button>
                                            <UncontrolledTooltip delay={0} placement="top" target="btn_newCupon">
                                                Novo cupão
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <div >
                                    {Loading(loadingData)}
                                    {cupons.length > 0 && cuponsToShow === 'CuponsList' &&
                                        <Row>
                                            <div className="col">
                                                <Card className="shadow">


                                                    <Table className="align-items-center table-flush" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">QRCode</th>
                                                                <th scope="col">Tipo</th>
                                                                <th scope="col">Descrição</th>
                                                                <th scope="col">Valor</th>
                                                                <th scope="col">Data criação</th>
                                                                <th scope="col">Validade</th>
                                                                <th scope="col" />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {cupons.map((cupon, key) => { return <CuponsList key={key} currentUser={currentUser} cupon={cupon} /> })}
                                                        </tbody>
                                                    </Table>
                                                    <CardFooter className="py-4">
                                                        {pagination !== null && cupons.length > 0 ? (
                                                            <CustomPagination pagination={pagination} array={cupons} onChange={this.changePaginationCupon} />
                                                        ) : null}
                                                    </CardFooter>
                                                </Card>
                                            </div>
                                        </Row>
                                    }



                                    {cuponsType.length > 0 && cuponsToShow === 'CuponsTypeList' &&
                                        <Row>
                                            <div className="col">
                                                <Card className="shadow">


                                                    <Table className="align-items-center table-flush" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Nome</th>
                                                                <th scope="col">Descrição</th>
                                                                <th scope="col">Condição</th>
                                                                <th scope="col">Início</th>
                                                                <th scope="col">Fim</th>
                                                                <th scope="col">Estado</th>
                                                                <th scope="col" />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {cuponsType.map((cuponType, key) => {
                                                                return <CuponsTypeList key={key} currentUser={currentUser} cuponType={cuponType} onChange={this.openForm} />
                                                            })}
                                                        </tbody>
                                                    </Table>

                                                    <CardFooter className="py-4">
                                                        {pagination !== null && cuponsType.length > 0 ? (
                                                            <CustomPagination pagination={pagination} array={cuponsType} onChange={this.changePaginationCuponType} />
                                                        ) : null}
                                                    </CardFooter>
                                                </Card>
                                            </div>
                                        </Row>
                                    }

                                </div>
                            </Card>
                        </div>
                    </Row>
                </Container>
            )}

        </>);

    }


}


export default Cupons;