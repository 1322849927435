import React from 'react';
import { Link } from "react-router-dom";
import { UncontrolledCollapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import { CurrentUser, Role } from "views/backoffice/utils/Utilities";
import logo from '../../assets/img/RS/Logo_002_sem moldura.png';
import logoIco from '../../assets/img/RS/rsIco.png';


const AdminNavbar = () => {
    var currentUser = CurrentUser();
    const role = currentUser !== null ? currentUser.role : null;
    return (
        <>
            <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
                <Container className="px-4">
                    <NavbarBrand to="/" tag={Link}>
                        <img alt="..."
                            src={logo}
                            style={{ 'zoom': '150%' }}
                        />
                    </NavbarBrand>
                    <button className="navbar-toggler" id="navbar-collapse-main">
                        <i className="fas fa-bars rs-text-red"></i>
                    </button>
                    <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
                        <div className="navbar-collapse-header d-md-none">
                            <Row>
                                <Col className="collapse-brand" xs="6">
                                    <Link to="/">
                                        <img alt="..." src={logo}
                                        />
                                    </Link>
                                </Col>
                                <Col className="collapse-close" xs="6">
                                    <button className="navbar-toggler" id="navbar-collapse-main">
                                        <span />
                                        <span />
                                    </button>
                                </Col>
                            </Row>
                        </div>
                        <Nav className="ml-auto" navbar>
                            {currentUser === null &&
                                <NavItem>
                                    <NavLink className="nav-link-icon rs-text-grey" to="/login" tag={Link}>
                                        <i className="ni ni-key-25" />
                                        <span className="nav-link-inner--text">Login</span>
                                    </NavLink>
                                </NavItem>
                            }
                            {(role !== Role.User && role !== null) &&
                                <NavItem>
                                    <NavLink className="nav-link-icon rs-text-grey" to="/admin/Dashboard" tag={Link}>
                                        <img alt="..."
                                            src={logoIco}
                                            style={{ maxHeight:25 }}
                                        />
                                        <span className="nav-link-inner--text rs-text-red">WMS</span>
                                    </NavLink>
                                </NavItem>
                            }
                            {currentUser !== null &&
                                <NavItem>
                                    <NavLink className="nav-link-icon rs-text-grey" to="/auth/logout" tag={Link}>
                                        <i className="ni ni-circle-08" />
                                        <span className="nav-link-inner--text">Bem vindo, {currentUser.firstName}</span>
                                    </NavLink>
                                </NavItem>
                            }

                        </Nav>
                    </UncontrolledCollapse>
                </Container>
            </Navbar>
        </>
    );
};

export default AdminNavbar;
