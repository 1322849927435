import React from 'react';
import { CardTitle, CardSubtitle, CardText, Card, CardBody } from "reactstrap";
import { ApiError, Loading  } from "views/backoffice/utils/Utilities";
import TableList from '../Banners/TableList'
import axios from 'axios';


class BannersListPhone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            loading: false,
            banners: []
        }
    }

    componentDidMount() { this.getBanners(); };

    getBanners = () => {
        this.setState({ loading: true });
        axios.get(global.api_url + "Banners?all=true")
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data;
                    this.setState({ banners: data });
                }
                this.setState({ loading: false });
            }).catch(error => {
                ApiError(error, "Banners").then(call => call === true ? this.getBanners() : "");
                this.setState({ loading: false });
            })
    }

    onChange = (action) => {
        if (action === 'LOAD') {
            this.setState({ banners: [] });
            this.getBanners();
        }

    }

    render() {
        const { banners, loading } = this.state;

        return (
            <Card>
                {Loading(loading)}
                <CardBody>
                    <CardTitle tag="h3">
                        Lista de Banners Telemóvel
                    </CardTitle>
                    <CardSubtitle className="mb-2 text-muted" tag="h4">
                        Comprimir imagens para melhor performance. Exemplo de site : <a href="https://www.iloveimg.com/compress-image/compress-png" target="_blank">Compress</a>
                    </CardSubtitle>
                    <CardText>
                        {banners.length > 0 &&
                            <TableList currentBanners={banners} onChange={this.onChange} />
                        }
                    </CardText>
                </CardBody>
            </Card>
        );
    }
}


export default BannersListPhone;