import React from 'react';
import Header from '../../../components/Headers/Header';
import LeirilisConfig from '../Configurations/LeirilisConfig';
import MailConfig from '../Configurations/MailConfig';
import HaynesProConfig from '../Configurations/HaynesProConfig';
import ERPConfig from '../Configurations/ERPConfig';
import { ApiError, GetHeader, Loading } from "../utils/Utilities";
import axios from 'axios';
import { Container, Row, Col } from "reactstrap";
//import MasterWayConfig from './MasterWayConfig';

class Configurations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            currentWorkShopConfigs: null,
            loading: false,
            currentWorkShop: null,
        }
    }

    handleValue = async (name, value) => { await this.setState({ [name]: value }); return value; }
    componentWillMount() {
        this.getWorkShopConfigs();
        if (this.props.currentUser.defaultWorkshop !== undefined) {
            this.getWorkshop(this.props.currentUser.defaultWorkshop);
        }
    }

    getWorkShopConfigs = () => {
        this.handleValue('loading', true);
        var headers = GetHeader();
        axios.get(global.api_url + "WorkshopConfigs", { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.handleValue('currentWorkShopConfigs', response.data);
                }
                this.handleValue('loading', false);
            }).catch(error => {
                this.handleValue('loading', false);
                ApiError(error, "Configurações").then(call => call === true ? this.getWorkShopConfigs() : "");
            })
    }

    getWorkshop = (id) => {
        this.handleValue('loading', true);
        var headers = GetHeader();
        axios.get(global.api_url + "Workshops/" + id, { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.handleValue('currentWorkShop', response.data);
                }
                this.handleValue('loading', false);
            }).catch(error => {
                this.handleValue('loading', false);
                ApiError(error, "Oficina").then(call => call === true ? this.getWorkshop(id) : "");
            })
    }

    render() {
        const { currentUser, loading, currentWorkShopConfigs } = this.state;

        return (<>
            <Header subText="" />
            <Container className="mt--7" fluid>
                {loading === false &&
                    <>
                        <Row className="mt-5">
                            <Col className="mb-5 mb-xl-0" xl="6">
                                {currentWorkShopConfigs !== null && <LeirilisConfig currentUser={currentUser} currentWorkShopConfigs={currentWorkShopConfigs} />}
                            </Col>
                            <Col xl="6">
                                {currentWorkShopConfigs !== null && <ERPConfig currentUser={currentUser} currentWorkShopConfigs={currentWorkShopConfigs} />}
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col className="mb-5 mb-xl-0" xl="6">
                            {currentWorkShopConfigs !== null && <HaynesProConfig currentUser={currentUser} currentWorkShopConfigs={currentWorkShopConfigs} />}
                            </Col>
                            <Col xl="6">
                                {currentWorkShopConfigs !== null && <MailConfig currentUser={currentUser} currentWorkShopConfigs={currentWorkShopConfigs} />}
                            </Col>
                        </Row>
                    </>
                }
                {Loading(loading)}
            </Container>
        </>);
    }
}


export default Configurations;


