import React from 'react';
import { Link } from "react-router-dom";
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Form, FormGroup, Navbar, Nav, Container, Media } from "reactstrap";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { CurrentUser, RemoveUser, Role, notification } from "views/backoffice/utils/Utilities";

const AdminNavbar = (props) => {
    var currentUser = CurrentUser();
    const dispatch = useDispatch();

    const click_Logout = function (event) {
        event.preventDefault();
        var headers = { 'Accept': '*/*', 'Content-Type': 'application/json', 'Authorization': `Bearer ${currentUser.jwtToken}` };
        axios.post(global.api_url + "Accounts/revoke-token", { 'token': currentUser.refreshToken }, { headers })
            .then(response => {
                global.currentUser = response;
                if (response.status === 200) {
                    localStorage.setItem('refreshToken', '');
                    localStorage.setItem('jwtToken', '');
                    RemoveUser(dispatch);
                    notification("Logout", "Efetuado com sucesso!", "success", null, "top-right", null, false);
                };
            })
            .catch(error => {
                localStorage.setItem('refreshToken', '');
                localStorage.setItem('jwtToken', '');
                RemoveUser(dispatch);
            });
    };
    return (
        <>
            {currentUser !== null && currentUser.role !== Role.User ? (

                <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
                    <Container fluid>
                        <h2 className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
                            {props.brandText}
                        </h2>
                        <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
                            <FormGroup className="mb-0">
                            </FormGroup>
                        </Form>
                        <Nav className="align-items-center d-none d-md-flex" navbar>
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="pr-0" nav>
                                    <Media className="align-items-center">
                                        <Media className="ml-2 d-none d-lg-block">
                                            <span className="mb-0 text-l font-weight-bold">
                                                {/*<i className="far fa-user-circle" style={{ paddingInline: 10 }}></i>*/}
                                                <i className="fas fa-user-circle" style={{ paddingInline: 10, zoom: 1.5 }}></i>
                                                {currentUser.firstName}
                                            </span>
                                        </Media>
                                    </Media>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                    <DropdownItem className="noti-title" header tag="div">
                                        <h6 className="text-overflow m-0">Olá, {currentUser.firstName}!</h6>
                                    </DropdownItem>
                                    <DropdownItem to="/admin/Dashboard" tag={Link}>
                                        <i className="fas fa-chart-line rs-text-red"></i>
                                        <span>WMS</span>
                                    </DropdownItem>

                                    <DropdownItem to="/admin/Profile" tag={Link}>
                                        <i className="fas fa-user rs-text-red" />
                                        <span>Perfil</span>
                                    </DropdownItem>

                                    <DropdownItem to="/admin/Workshop" tag={Link}>
                                        <i className="fas fa-tools rs-text-red" />
                                        <span>Oficina</span>
                                    </DropdownItem>

                                    <DropdownItem to="/admin/schedule" tag={Link}>
                                        <i className="fas fa-calendar rs-text-red" />
                                        <span>Agenda</span>
                                    </DropdownItem>

                                    <DropdownItem to="/admin/Search" tag={Link}>
                                        <i className="fas fa-search rs-text-red" />
                                        <span>Histórico</span>
                                    </DropdownItem>

                                    <DropdownItem to="/admin/Mail" tag={Link}>
                                        <i className="fas fa-inbox rs-text-red" />
                                        <span>Email</span>
                                    </DropdownItem>

                                    <DropdownItem to="/admin/Documentation" tag={Link}>
                                        <i className="fas fa-passport rs-text-red" />
                                        <span>Documentação</span>
                                    </DropdownItem>

                                    <DropdownItem divider />
                                    <DropdownItem onClick={(e) => click_Logout(e)}>
                                        <i className="fas fa-sign-out-alt rs-text-red"></i>
                                        {/*<i className="ni ni-user-run rs-text-red" />*/}
                                        <span>Logout</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Container>
                </Navbar>
            ) :

                null}
        </>
    );
};

export default AdminNavbar;
