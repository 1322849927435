import React from 'react';
import { Button, FormGroup, Form, Input, FormFeedback, Row, Col, Container, CardHeader, Card, CardBody } from "reactstrap";
import { Loading, notification, GetHeader, GetHeaderPut, ApiError } from "../utils/Utilities";
import ModalValidate from "../utils/ModalValidate";
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';


class ServiceTypesForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceType: {
                id: props.formServiceType.id !== 0 ? props.formServiceType.id : 0,
                name: props.formServiceType.id !== 0 ? props.formServiceType.name : '',
                logo: props.formServiceType.logo !== 0 ? props.formServiceType.logo : '',
                description: props.formServiceType.id !== 0 ? props.formServiceType.description : '',
            },
            old_serviceType: props.formServiceType.id !== 0 ? props.formServiceType : {},
            validate: {
                logoState: '',
                nameState: '',
                descriptionState: '',
            },
            //visibleModel: false,
            currentUser: props.currentUser,
            edit: props.formServiceType.id !== 0 ? false : true,
            loadingData: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeServiceType = this.handleChangeServiceType.bind(this);
    }

    handleValue = (name, value) => {
        this.setState({ [name]: value });
    }

    handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        this.setState({ [name]: value });
    };

    handleChangeServiceType = (event) => {
        const { target } = event;
        const { name } = target;
        this.setState(state => ({ serviceType: { ...state.serviceType, [name]: target.value } }));

    };
    /*VALIDATIONS*/

    validateString(e) {
        const { validate } = this.state;
        if (e.target.value !== null && e.target.value !== "") {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState(({ validate }));
    }

    regexValidate(e, regex) {
        const { validate } = this.state;
        if (regex.test(e.target.value)) {
            validate[e.target.name + 'State'] = 'has-success';
        } else { validate[e.target.name + 'State'] = 'has-danger'; }
        this.setState({ validate });
    }

    validForm() {
        var { serviceType, validate } = this.state;
        var valid = true;
        Object.entries(serviceType).forEach(data => {
            if (data[1] === '' && (data[0] === 'name')) {
                validate[data[0] + 'State'] = 'has-danger';
                this.setState({ validate });
                valid = false;
            }
        })
        Object.entries(validate).forEach(data => { if (data[1] === 'has-danger') { valid = false; } });
        return valid;
    }

    /*SUBMISSIONS*/

    submitForm(e) {
        e.preventDefault();
        var { serviceType } = this.state;
        serviceType.id === 0 ? this.create() : this.update();
    }

    update() {
        this.handleValue('loadingData', true);
        var { serviceType } = this.state;
        var headers = GetHeaderPut();
        axios.put(global.api_url + "ServiceTypes", serviceType, { headers })
            .then((response) => {
                notification("Tipo de Serviço", "Tipo de serviço atualizado com sucesso!", "success", null, "top-right", null, false);
                this.handleValue('edit', false);
                this.handleValue('loadingData', false);
                this.handleValue('validate', {});
            }).catch(error => {
                ApiError(error, "Tipo de Serviço").then(call => call === true ? this.update() : "");
            }).then(() => {
                this.handleValue('loadingData', false);
            })
        this.handleValue('edit', false);
    }

    create(e) {
        e.preventDefault();
        if (this.validForm()) {
            this.handleValue('loadingData', true);
            var { serviceType } = this.state;
            var headers = GetHeader();
            axios.post(global.api_url + "ServiceTypes", serviceType, { headers })
                .then((response) => {
                    if (response.status === 200) { notification("Tipo de Serviço", "Foi adicionado um novo tipo de serviço.", "success", null, "top-right", null, false); this.props.onChange(false); }
                    this.handleValue('edit', false);
                    this.handleValue('validate', {});
                }).catch(error => {
                    ApiError(error, "Tipo de Serviço").then(call => call === true ? this.create(e) : "");
                }).then(() => {
                    this.handleValue('loadingData', false);
                })
        } else {
            notification("Tipo de Serviço", "Campos em Falta ou mal preenchidos.", "danger", null, "top-right", null, false);
        }
    }
    cancel(e) {
        e.preventDefault();
        this.props.onChange(false);
    }

    delete() {
        this.handleValue('loadingData', true);
        var { serviceType } = this.state;
        var headers = GetHeader();
        axios.post(global.api_url + "ServiceTypes/Delete/" + serviceType.id, {}, { headers })
            .then((response) => {
                if (response.status === 200) { notification("Tipo de Serviço", "Foi eliminado com sucesso.", "success", null, "top-right", null, false); this.props.onChange(false); }
                this.handleValue('edit', false);
                this.handleValue('validate', {});
            }).catch(error => {
                ApiError(error, "Tipo de Serviço").then(call => call === true ? this.delete() : "");
            }).then(() => {
                this.handleValue('loadingData', false);
            })
    }

    edit(e) {
        e.preventDefault();
        this.handleValue('edit', true);
    }

    /*GET VALUES */

    /*MODAL */
    //modalValidate(e) {
    //    e.preventDefault();
    //    this.handleValue('visibleModel', true);
    //}

    //modalResponse(val) { val ? this.delete() : this.handleValue('visibleModel', false); }


    deleteConfirmAlert = (e) => {
        e.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                var { serviceType } = this.state;

                return (
                    <div style={{ borderStyle: "solid", padding: 40, borderRadius: 8, borderWidth: 2 }} className='custom-ui'>
                        <h1>Confirmação</h1>
                        <div><p>Deseja eliminar o tipo de serviço "{serviceType.name}"?</p></div>
                        <center>
                            <Button color="success" size="sm" type="button" onClick={(e) => {
                                e.preventDefault();
                                this.delete();
                                onClose();
                            }}><i className="fas fa-trash"></i> Eliminar</Button>
                            <Button color="danger" size="sm" onClick={(e) => { onClose(); }}><i className="fas fa-times"></i> Cancelar</Button>
                        </center>
                    </div>
                );
            }
        })
    }


    render() {
        const { edit, loadingData, currentUser, old_serviceType } = this.state;
        const { id, logo, name, description } = this.state.serviceType;
        const { nameState, descriptionState } = this.state.validate;

        function getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }

        return (
            <>
                {/*visibleModel && <ModalValidate title="Confirmação" body="Deseja eliminar o tipo de serviço?" visible={visibleModel} onChange={(val) => this.modalResponse(val)} />*/}


                {
                    currentUser !== null &&
                    <Container className="mt--7" fluid>
                        <Form className="form" onSubmit={(e) => this.submitForm(e)}>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <Row className="align-items-center">

                                                <Col xl="8">
                                                    {id === 0 ? (
                                                        <h3 className="mb-0">Adicionar</h3>
                                                    ) : (<h3 className="mb-0">Editar</h3>)}
                                                </Col>
                                                <Col className="text-right" xl="4">
                                                    {id === 0 ? (
                                                        <>
                                                            <Button color="danger" size="sm" type="button" onClick={(e) => { this.props.onChange(false); }}><i className="fas fa-times"></i> Cancelar</Button>
                                                            <Button color="success" size="sm" type="button" onClick={e => this.create(e)}><i className="far fa-save"></i> Criar</Button>
                                                        </>
                                                    )
                                                        : (edit === false ?
                                                            (<>
                                                                <Button color="success" size="sm" name="update" type="button" onClick={e => { this.edit(e); }} ><i className="far fa-edit"></i> Editar</Button>
                                                                <Button color="danger" size="sm" type="button" onClick={e => this.cancel(e)}><i className="far fa-window-close"></i> Fechar</Button>
                                                            </>)
                                                            : (
                                                                <>
                                                                    {id !== 0 && <Button color="warning" size="sm" type="button" onClick={e => this.deleteConfirmAlert(e)}><i className="far fa-trash-alt"></i> Eliminar</Button>}
                                                                    <Button color="info" size="edit" name="update" type="button" size="sm" onClick={e => { this.update(e); }} ><i className="far fa-save"></i> Atualizar</Button>
                                                                    <Button color="danger" size="sm" type="button" onClick={e => { this.setState({ edit: false, serviceType: old_serviceType }); }
                                                                    }><i className="fas fa-times"></i> Cancelar</Button>
                                                                </>
                                                            ))}

                                                </Col>

                                            </Row>
                                        </CardHeader>
                                        <CardBody>

                                            <div>
                                                {Loading(loadingData)}
                                                <h6 className="heading-small text-muted mb-4">
                                                    Tipo de Serviço
                                                </h6>
                                                <div className="pl-lg-4">
                                                    <Row>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-brand" >
                                                                    Nome
                                                                </label>
                                                                <Input type="text" name="name" id="name"
                                                                    valid={nameState === "has-success"}
                                                                    invalid={nameState === "has-danger"}
                                                                    value={name}
                                                                    onChange={(e) => { this.validateString(e); this.handleChangeServiceType(e); }}
                                                                    disabled={!edit}
                                                                    required
                                                                />
                                                                <FormFeedback>
                                                                    Nome inválido.
                                                                </FormFeedback>
                                                                <FormFeedback valid>
                                                                    Nome válido.
                                                                </FormFeedback>

                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="3">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-brand" >
                                                                    Logotipo
                                                                </label>
                                                                <Input type="file" id="logo" accept=".png" disabled={!edit} onChange={(e) => {
                                                                    if (e.target.files.length > 0) {
                                                                        getBase64(e.target.files[0]).then(data => {
                                                                            if (data !== null) {
                                                                                this.setState(state => ({ serviceType: { ...state.serviceType, logo: data } }));
                                                                            }
                                                                        });
                                                                    }
                                                                }} />

                                                            </FormGroup>
                                                        </Col>

                                                        <Col lg="3">
                                                            <FormGroup>
                                                                <center>
                                                                    <img alt={name} style={{ borderRadius: 10 }} height={50} src={logo} />
                                                                </center>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-description">
                                                                    Descrição
                                                                </label>
                                                                <Input type="textarea" name="description" id="description"
                                                                    valid={descriptionState === "has-success"}
                                                                    invalid={descriptionState === "has-danger"}
                                                                    rows={8}
                                                                    value={description}
                                                                    onChange={(e) => { this.validateString(e); this.handleChangeServiceType(e); }}
                                                                    disabled={!edit}
                                                                />
                                                                <FormFeedback>
                                                                    Descrição inválida.
                                                                </FormFeedback>
                                                                <FormFeedback valid>
                                                                    Descrição válida.
                                                                </FormFeedback>

                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                </div>
                                            </div>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                }
            </>
        );
    }
}

export default ServiceTypesForm;
