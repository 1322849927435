import React, { useState } from 'react';
import { useLocation, Route, Switch } from "react-router-dom";
import { Container, Row } from "reactstrap";
import AuthNavbar from "../components/Navbars/AuthNavbar";
import AuthFooter from "../components/Footers/AuthFooter";
import ProtectedRouteLogged from '../App/ProtectedRouteLogged.js'
//import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import routes from "routes.js";



const Auth = (props) => {
    const mainContent = React.useRef(null);
    const location = useLocation();
    const [visible, setVisible] = useState(true);

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;

        (location.pathname === '/login' || location.pathname === '/register') ? setVisible(false) : setVisible(true);

    }, [location]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/auth") {
                return (<ProtectedRouteLogged path={prop.layout === '/' ? "" : prop.layout + prop.path} component={prop.component} key={key} />);
            } else if (prop.layout === "") {
                return (<Route
                    path={prop.layout === '/' ? "" : prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                />
                );
            } else { return null; }
        });
    };

    return (
        <>
            <div className="main-content" ref={mainContent}>
                <AuthNavbar />
                <div className="header bg-gradient-info-base-branco py-7 py-lg-7">
                    {/*<Container>*/}

                    <div className="header-body text-center mb-7">
                        {/*{visible ? (*/}
                        {/*    <Row className="justify-content-center">*/}
                        {/*        <Col lg="12" md="12">*/}

                        {/*            <Carousel autoPlay={true} showThumbs={false} showIndicators={false} showStatus={false}>*/}
                        {/*                <div>*/}
                        {/*                    <img src={img1} />*/}
                        {/*                </div>*/}
                        {/*            </Carousel>*/}

                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*) : null}*/}
                    </div>
                </div>
                {/* Page content */}
                <Container className="mt--9 pb-5">
                    {/*<Row className="justify-content-center">*/}
                    <Row className="justify-content-center">
                        <Switch>
                            {getRoutes(routes)}
                            {/*<Redirect from="*" to="/home" />*/}
                        </Switch>
                    </Row>
                </Container>
            </div>
            <AuthFooter />
        </>
    );
};

export default Auth;